import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import { Checkbox, LinearProgress } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

import useInfiniteScroll from "../../../custom-hooks/useInfiniteScroll";
import * as EmployeesActions from "../../../redux/actions/EmployeeActions";
import * as EmployeeSearchActions from "../../../redux/actions/EmployeeSearchActions";
import * as ForemanSearchActions from "../../../redux/actions/ForemanSearchActions";
import * as RoleEmployeesActions from "../../../redux/actions/RoleEmployeesActions";
import * as RoleEmployeesWithoutCrewActions from "../../../redux/actions/RoleEmployeesWithoutCrewActions";
import SearchAutocompleteComponent from "../../UI/SearchAutocompleteComponent";

const useStyles = makeStyles(() => ({
  listHeight: {
    minHeight: "300px",
    maxHeight: "300px",
    overflow: "scroll"
  },
  selectedEmployees: {
    height: " 41px",
    display: "flex",
    alignItems: "center",
    marginLeft: "16px"
  },
  buttonsAlign: {
    justifyContent: "space-evenly"
  }
}));

const AssignToComponent = (props) => {
  const dispatch = useDispatch();
  const { role, checkBox, show, handleClose, unSelectAll } = props;
  const {
    roles,
    roleEmployees,
    roleEmployeesNoCrew,
    apiStatus,
    userDetails,
    foremanSearchResults,
    employeeSearchResults
  } = useSelector((state) => state);
  const [selectCheck, setSelectCheck] = useState([]);
  const [changeReporting, setChangeReporting] = useState(false);
  const [offset, setOffset] = useState({ false: 0, true: 0 }); // false for foreman and true for all employees
  const [search, setSearch] = useState("");
  const [limit] = useState(50);
  const [scrollTop, setScrollTop] = useState(0);
  const [scrollHeight, setScrollHeight] = useState(0);
  const [clientHeight, setClientHeight] = useState(0);
  const { t } = useTranslation();

  const searchEmployee = (query) => {
    setSearch(query);
  };
  const clearSearchedEmployees = () => {
    dispatch(ForemanSearchActions.clearForemanSearchResults());
  };

  const filteredRoles = roles.filter((obj) => obj.roleName !== "");
  const isNotInLastRole =
    role.roleLevel < filteredRoles[filteredRoles.length - 1].roleLevel;
  const lastSecondRole = filteredRoles[filteredRoles.length - 2];
  const classes = useStyles();

  // Custom hook for infinite scroll
  const [isFetching, toggle] = useInfiniteScroll(
    clientHeight,
    scrollTop,
    scrollHeight
  );

  // Send scroll event result to parent component to send API calls for pagination
  useEffect(() => {
    if (isFetching && !apiStatus.isLoading) {
      setOffset((prevValue) => ({
        ...prevValue,
        [Boolean(changeReporting)]: offset[Boolean(changeReporting)] + limit
      }));
      toggle(isFetching);
      setScrollTop(0);
      setScrollHeight(0);
      setClientHeight(0);
    }
    // eslint-disable-next-line
  }, [isFetching]);

  function onOffsetChange() {
    if (offset[Boolean(changeReporting)] > 0) {
      if (
        (changeReporting || isNotInLastRole) &&
        roleEmployeesNoCrew.length - offset[Boolean(changeReporting)] >= 0
      ) {
        dispatch(
          RoleEmployeesWithoutCrewActions.loadEmployeesNotCrewDetails(
            offset[Boolean(changeReporting)],
            limit,
            "empName",
            "asc",
            isNotInLastRole,
            role
          )
        );
      } else if (
        !changeReporting &&
        roleEmployees[lastSecondRole._id].length -
          offset[Boolean(changeReporting)] >=
          0
      ) {
        dispatch(
          RoleEmployeesActions.loadRoleEmployees(
            lastSecondRole._id,
            offset[Boolean(changeReporting)],
            limit,
            "empName",
            "asc"
          )
        );
      }
    }
  }
  useEffect(onOffsetChange, [offset]);

  function initialLoadEmployees() {
    dispatch(
      RoleEmployeesWithoutCrewActions.loadEmployeesNotCrewDetails(
        0,
        limit,
        "empName",
        "asc",
        isNotInLastRole,
        role
      )
    );
    dispatch(
      RoleEmployeesActions.loadRoleEmployees(
        lastSecondRole._id,
        0,
        limit,
        "empName",
        "asc"
      )
    );
  }
  useEffect(initialLoadEmployees, []);

  function onSearch() {
    if (search.length > 0) {
      if (!changeReporting && !isNotInLastRole) {
        dispatch(
          ForemanSearchActions.searchForemanWithParam(
            lastSecondRole._id,
            search,
            0,
            limit,
            "empName",
            "asc",
            "role_employees"
          )
        );
      } else {
        dispatch(
          EmployeeSearchActions.searchEmployeesWithParam(
            userDetails.companyCode,
            search,
            0,
            limit,
            "empName",
            "asc",
            "app_employees",
            isNotInLastRole,
            role
          )
        );
      }
    }
  }
  useEffect(onSearch, [search]);

  let checkedEmp = [];
  if (checkBox.length > 0) {
    checkedEmp = roleEmployees[role._id].filter((obj) => {
      return checkBox.includes(obj._id);
    });
  }

  function handleCheck(id) {
    const index = selectCheck.indexOf(id);
    const copyArray = selectCheck.slice();

    if (index !== -1) {
      // remove _id if unchecked
      copyArray.splice(index, 1);
      setSelectCheck(copyArray);
    } else {
      // add checked _id
      copyArray.push(id);
      setSelectCheck(copyArray);
    }
  }

  function handleOnSave() {
    if (selectCheck.length > 0) {
      dispatch(
        EmployeesActions.updateEmployeesPersonnel(
          checkBox,
          selectCheck,
          role._id
        )
      );
    }
    setSelectCheck([]);
    unSelectAll();
  }

  function hangeChangeReporting() {
    setChangeReporting(!changeReporting);
    setSearch("");
  }

  return (
    <div className='assignToComponent'>
      <Dialog
        open={show}
        onClose={handleClose}
        className={classes.dialog}
        maxWidth='sm'
        variant='outlined'
        color='primary'
        fullWidth
      >
        <Grid>
          {apiStatus &&
            apiStatus.isLoading &&
            (apiStatus.reducer.includes("AssignToStatus") ||
              apiStatus.reducer.includes(lastSecondRole._id)) && (
              <LinearProgress className={classes.progressBar} />
            )}
        </Grid>
        <DialogContent>
          <Grid container>
            <Grid item xs={6}>
              <DialogTitle>{role.roleName}</DialogTitle>
              <Divider variant='middle' />
              <Grid
                item
                className={classes.listHeight}
                onScroll={(e) => e.stopPropagation()}
              >
                {checkedEmp.map((emp) => (
                  <Grid item key={emp._id}>
                    <Grid className={classes.selectedEmployees}>
                      {`${emp.empID} - ${emp.empName}`}
                    </Grid>
                  </Grid>
                ))}
              </Grid>

              <Grid container item xs={9} className={classes.buttonsAlign}>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => {
                    handleClose();
                    setSelectCheck([]);
                  }}
                >
                  {t("commonActions.cancel")}
                </Button>
                <Button
                  variant='contained'
                  color='primary'
                  disabled={!selectCheck.length}
                  onClick={() => {
                    handleOnSave();
                    handleClose();
                  }}
                >
                  {t("equipmentManager.assign_btn")}
                </Button>
              </Grid>
            </Grid>
            {!changeReporting && !isNotInLastRole && (
              <Grid item xs={6}>
                <DialogTitle>
                  {lastSecondRole.roleName}
                  <SearchAutocompleteComponent
                    handleSearch={searchEmployee}
                    searchResults={foremanSearchResults}
                    showOptions={false}
                    disableClearable
                    freeSolo
                    clear={clearSearchedEmployees}
                  />
                </DialogTitle>
                <Grid
                  item
                  className={classes.listHeight}
                  onScroll={(event) => {
                    event.persist();
                    setScrollTop(event.target.scrollTop);
                    setScrollHeight(event.target.scrollHeight);
                    setClientHeight(event.target.clientHeight);
                    event.stopPropagation();
                  }}
                >
                  {roleEmployees[lastSecondRole._id] &&
                  ((!roleEmployees[lastSecondRole._id].length &&
                    !search.length) ||
                    (search.length && !foremanSearchResults.length)) ? (
                    <Grid
                      item
                      key={lastSecondRole._id}
                      style={{ textAlign: "center" }}
                    >
                      {t("commonActions.no_records")}
                    </Grid>
                  ) : (
                    roleEmployees[lastSecondRole._id] &&
                    roleEmployees[lastSecondRole._id].length > 0 &&
                    (search.length
                      ? foremanSearchResults
                      : roleEmployees[lastSecondRole._id]
                    ).map((emp) => (
                      <Grid item key={emp._id}>
                        <Checkbox
                          type='checkbox'
                          onClick={() => handleCheck(emp._id)}
                          checked={selectCheck.includes(emp._id)}
                        />
                        {`${emp.empID} - ${emp.empName}`}
                      </Grid>
                    ))
                  )}
                </Grid>

                <Grid item>
                  <Button
                    variant='outlined'
                    color='primary'
                    onClick={() => hangeChangeReporting()}
                  >
                    {t("authorizerContainer.view")}{" "}
                    {t("authorizerContainer.all_emp_label")}
                  </Button>
                </Grid>
              </Grid>
            )}
            {(changeReporting || isNotInLastRole) && (
              <Grid item xs={6}>
                <DialogTitle>
                  {" "}
                  {isNotInLastRole
                    ? t("authorizerContainer.employees_label")
                    : t("authorizerContainer.all_emp_label")}
                  <SearchAutocompleteComponent
                    handleSearch={searchEmployee}
                    searchResults={employeeSearchResults}
                    showOptions={false}
                    disableClearable
                    freeSolo
                    clear={clearSearchedEmployees}
                    key='assign_to_search'
                  />
                </DialogTitle>
                <Grid
                  item
                  className={classes.listHeight}
                  onScroll={(event) => {
                    event.persist();
                    setScrollTop(event.target.scrollTop);
                    setScrollHeight(event.target.scrollHeight);
                    setClientHeight(event.target.clientHeight);
                    event.stopPropagation();
                  }}
                >
                  {(!roleEmployeesNoCrew.length && !search.length) ||
                  (search.length && !employeeSearchResults.length) ? (
                    <Grid
                      item
                      key={lastSecondRole._id}
                      style={{ textAlign: "center" }}
                    >
                      {t("commonActions.no_records")}
                    </Grid>
                  ) : (
                    roleEmployeesNoCrew.length &&
                    (search.length
                      ? employeeSearchResults
                      : roleEmployeesNoCrew
                    ).map((emp) => (
                      <Grid item key={emp._id}>
                        <Checkbox
                          type='checkbox'
                          checked={selectCheck.includes(emp._id)}
                          onClick={() => handleCheck(emp._id)}
                        />
                        {`${emp.empID} - ${emp.empName}`}
                      </Grid>
                    ))
                  )}
                </Grid>

                <Grid item>
                  {!isNotInLastRole && (
                    <Button
                      variant='outlined'
                      color='primary'
                      onClick={() => hangeChangeReporting()}
                    >
                      {t("authorizerContainer.view")} {lastSecondRole.roleName}
                    </Button>
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

AssignToComponent.propTypes = {
  role: PropTypes.shape({
    employees: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    roleName: PropTypes.string.isRequired,
    _id: PropTypes.string.isRequired,
    roleLevel: PropTypes.number.isRequired
  }).isRequired,
  checkBox: PropTypes.arrayOf(PropTypes.string).isRequired,
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  unSelectAll: PropTypes.func.isRequired
};

export default AssignToComponent;
