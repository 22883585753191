import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Fab, LinearProgress } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

import useInfiniteScroll from "../../../custom-hooks/useInfiniteScroll";
import * as EmployeeActions from "../../../redux/actions/EmployeeActions";
import * as EmployeeSearchActions from "../../../redux/actions/EmployeeSearchActions";
import useStyles from "../../../styles/components/AuthorizerEmployeesStyles";
import permissionsList from "../../../utillities/Permissions";
import SearchAutocompleteComponent from "../../UI/SearchAutocompleteComponent";
import TableHeaderWrapper from "../../UI/TableHeaderWrapper";
import AssignRoleComponent from "../roles/AssignRoleComponent";

import AllEmployeesTableWrapperComponent from "./AllEmployeesTableWrapperComponent";

// table headers config
const headCells = [
  {
    id: "empID",
    numeric: true,
    disablePadding: false,
    label: "authorizerContainer.emp_id",
    isSortable: true
  },
  {
    id: "empName",
    numeric: false,
    disablePadding: false,
    label: "authorizerContainer.name",
    isSortable: true
  },
  {
    id: "roleName",
    numeric: false,
    disablePadding: false,
    label: "authorizerContainer.role",
    isSortable: false
  },
  {
    id: "dropDown",
    numeric: true,
    disablePadding: false,
    label: "",
    isSortable: false
  }
];

const AllEmployeesComponent = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();

  const { employees, employeeSearchResults, userDetails, apiStatus, count } =
    useSelector((state) => state);

  const [rows, setRows] = useState([]); // rows of table
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("empName");
  const [selected, setSelected] = useState([]);
  const [offsetEmployees, setOffsetEmployees] = useState(0);
  const [limit] = useState(50);
  const [assignRoleShow, setAssignRoleShow] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedButtonAssign, setSelectedButtonAssign] = useState([]);
  const [scrollTop, setScrollTop] = useState(0);
  const [scrollHeight, setScrollHeight] = useState(0);
  const [clientHeight, setClientHeight] = useState(0);
  const scrollRef = useRef();
  const searchEmployee = (query) => {
    if (search !== query && query.length > 0) {
      dispatch(
        EmployeeSearchActions.searchEmployeesWithParam(
          userDetails.companyCode,
          query,
          0,
          limit,
          orderBy,
          order
        )
      );
    }
    setSearch(query);
  };
  const clearSearchedEmployees = () =>
    dispatch(EmployeeSearchActions.clearEmployees());

  function handleSorting() {
    setOffsetEmployees(0);
    if (!search.length) {
      if (scrollRef.current) scrollRef.current.scrollTo(0, 0);
      dispatch(EmployeeActions.loadEmployees(0, limit, orderBy, order));
    } else {
      dispatch(
        EmployeeSearchActions.searchEmployeesWithParam(
          userDetails.companyCode,
          search,
          0,
          limit,
          orderBy,
          order
        )
      );
    }
  }
  useEffect(handleSorting, [order]);

  useEffect(() => {
    // setting rows on employees change
    setRows(
      (search.length ? employeeSearchResults : employees).map((emp) => ({
        empID: parseInt(emp.empID, 10),
        _id: emp._id,
        empName: emp.empName,
        roleName: emp.role ? emp.role.roleName : ""
      }))
    );
  }, [employees, search, employeeSearchResults]);

  // Custom hook for infinite scroll
  const [isFetching, toggle] = useInfiniteScroll(
    clientHeight,
    scrollTop,
    scrollHeight
  );

  // Send scroll event result to parent component to send API calls for pagination
  useEffect(() => {
    if (isFetching && search.length === 0 && !apiStatus.isLoading) {
      if (employees.length - offsetEmployees >= 0) {
        dispatch(
          EmployeeActions.loadEmployees(
            offsetEmployees + limit,
            limit,
            orderBy,
            order
          )
        );
      }
      if (
        !employeeSearchResults.length &&
        !search.length &&
        isFetching &&
        !apiStatus.isLoading
      ) {
        setOffsetEmployees((o) => o + limit);
      }
      setScrollTop(0);
      setScrollHeight(0);
      setClientHeight(0);
      toggle(isFetching);
    }
    // eslint-disable-next-line
  }, [isFetching]);

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  };

  // handling checkboxes
  const handleSelectAllClick = (event) => {
    if (selected.length > 0) {
      setSelected([]);
      return;
    }
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, rowIndex, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };
  function unSelectAll() {
    setSelected([]);
    setSelectedButtonAssign([]);
  }
  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <div className={classes.paper} elevation={1}>
      <Toolbar className={classes.toolbar}>
        <Typography className={classes.title} variant='h4' id='tableTitle'>
          {t("authorizerContainer.all_emp_label")}
          <p className={classes.count}>
            {`1 - ${employees.length} of ${count.employees}`}
          </p>
        </Typography>
        <div className={classes.UserActions}>
          {selected.length > 0 && (
            <Fab
              variant='extended'
              color='primary'
              onClick={() => setAssignRoleShow(true)}
              className={classes.headerButton}
              disabled={
                !userDetails.isSuperAdmin &&
                !userDetails.role.permissions.includes(
                  permissionsList.AUTHORIZER_ASSIGN_ROLE.key
                )
              }
            >
              {t("authorizerContainer.assign_role_label")}
            </Fab>
          )}
          <SearchAutocompleteComponent
            handleSearch={searchEmployee}
            searchResults={employeeSearchResults}
            showOptions={false}
            disableClearable
            freeSolo
            clear={clearSearchedEmployees}
          />
        </div>
      </Toolbar>

      {assignRoleShow && (
        <AssignRoleComponent
          show={assignRoleShow}
          handleClose={() => setAssignRoleShow(false)}
          SelectedEmployees={selected.length ? selected : selectedButtonAssign}
          unSelectAll={unSelectAll}
        />
      )}

      {apiStatus &&
        apiStatus.isLoading &&
        (apiStatus.reducer.includes("allEmployees") ||
          apiStatus.reducer.includes("RoleChange")) && (
          <LinearProgress className={classes.progressBar} />
        )}

      <Paper
        className={classes.tableWrapper}
        ref={scrollRef}
        onScroll={(event) => {
          event.persist();
          setScrollTop(event.target.scrollTop);
          setScrollHeight(event.target.scrollHeight);
          setClientHeight(event.target.clientHeight);
        }}
      >
        <Table
          className={classes.table}
          stickyHeader
          aria-labelledby='tableTitle'
          aria-label='enhanced table'
          size='small'
        >
          <TableHeaderWrapper
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={count.employees}
            headCells={headCells}
          />

          {rows.length > 0 ? (
            <TableBody>
              {rows.map((row, index) => (
                <TableRow
                  hover
                  onClick={(event) => handleClick(event, index, row._id)}
                  role='checkbox'
                  aria-checked={isSelected(row._id)}
                  tabIndex={-1}
                  key={row._id}
                  selected={isSelected(row._id)}
                >
                  <AllEmployeesTableWrapperComponent
                    handleClick={handleClick}
                    isItemSelected={isSelected(row._id)}
                    row={row}
                    handleDropdownButton={() =>
                      setSelectedButtonAssign([row._id])
                    }
                    handleShow={() => setAssignRoleShow(true)}
                    selected={selected}
                    index={index}
                    shouldComponentRerender={`${apiStatus.isLoading}${selected.length}${row.autoFillLunchTime}`}
                    classes={classes}
                  />
                </TableRow>
              ))}
            </TableBody>
          ) : (
            search.length > 0 && (
              <TableRow>
                <TableCell
                  colSpan={headCells.length + 1}
                  style={{ textAlign: "center", border: "none" }}
                >
                  {t("commonActions.no_records")}
                </TableCell>
              </TableRow>
            )
          )}
        </Table>
      </Paper>
    </div>
  );
};

export default AllEmployeesComponent;
