import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
import PropTypes from "prop-types";

import { Divider } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Badge from "@material-ui/core/Badge";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Fab from "@material-ui/core/Fab";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import NotificationsIcon from "@material-ui/icons/Notifications";
import SyncIcon from "@material-ui/icons/Sync";
import SyncProblemIcon from "@material-ui/icons/SyncProblem";

import AssignCompanyModalComponent from "../../containers/AssignCompanyModalComponent";
import { SWITCH } from "../../redux/actions/actionTypes";
import * as AuthActions from "../../redux/actions/AuthActions";
import * as NotificationsActions from "../../redux/actions/NotificationsActions";
import { loadPermissions } from "../../redux/actions/PermissionsActions";
import * as SyncActions from "../../redux/actions/SyncActions";
import { ACTIVITY_LOGS_TIME_FORMAT } from "../../utillities/DateFormats";
import permissionsList from "../../utillities/Permissions";
import { regReplaceURI } from "../../utillities/regexConstants";
import ViewPointSyncStatus from "../../utillities/ViewPointSyncStatus";

import DrawerComponent from "./DrawerComponent";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  buttonChange: {
    backgroundColor: "white",
    marginLeft: 15
  },
  title: {
    lineHeight: 3.3
  },
  titleStyle: {
    display: "inline-flex",
    flex: "auto",
    textTransform: "capitalize"
  },
  userName: {
    lineHeight: 2.5,
    textDecorationLine: "underline"
  },
  fab: {
    margin: theme.spacing(2),
    height: 35,
    width: "max-content"
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex"
    },
    alignItems: "center"
  },
  exportButton: {
    marginRight: "14px"
  },
  notification: {
    marginRight: "6px"
  },
  drawerPaper: {
    height: "100% !important"
  },
  NotificationsGrid: {
    padding: "8px"
  },
  paperWidth: {
    minWidth: "300px",
    maxWidth: "300px",
    maxHeight: "200px",
    overflow: "scroll"
  },
  "@keyframes spin": {
    "0%": { transform: "rotate(0deg)" },
    "100%": { transform: "rotate(360deg)" }
  },
  spinningImg: {
    animation: "$spin 1s infinite linear",
    color: "#212734"
  },
  syncButton: {
    backgroundColor: "white",
    "&:disabled": {
      backgroundColor: "#48b350"
    }
  },
  syncFailedButton: {
    backgroundColor: "#ff5555",
    "&:hover": {
      backgroundColor: "#ff5555"
    }
  },
  syncIcon: {
    color: "#212734"
  }
}));

const HeaderComponent = (props) => {
  const { toolBarStyle, history, exportButtonHandler, showExportReportButton } =
    props;
  const dispatch = useDispatch();
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(undefined);
  const open = Boolean(anchorEl);
  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const [show, setShow] = useState(false);
  const {
    selectedCompany,
    userDetails,
    notifications,
    companies,
    toSwitch,
    syncStatus,
    apiStatus
  } = useSelector((state) => state);
  const [drawerState, setDrawerState] = useState({
    right: false
  });
  const [lastSyncVPstatus, setLastSyncVPstatus] = useState();
  const [notificationStatus, setNotificationStatus] = useState(false);
  const isUserInAuthorizerView =
    (userDetails.isSuperAdmin && toSwitch) ||
    (!toSwitch &&
      userDetails.role &&
      userDetails.role.permissions.includes(permissionsList.AUTHORIZER.key) &&
      !userDetails.isSuperAdmin);

  const isSyncInProgress =
    syncStatus.VPsyncStatus === ViewPointSyncStatus.VPinprogress.code;
  const isSyncFailed =
    syncStatus.VPsyncStatus === ViewPointSyncStatus.VPerror.code;

  const toggleDrawer = (side, isOpen) => {
    setDrawerState({ [side]: isOpen });
  };

  const handleClose = () => setShow(false);
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  function handleOnClick() {
    dispatch(AuthActions.logout());
    handleCloseMenu();
  }

  function handleSwitch() {
    dispatch({ type: SWITCH });
  }

  function loadNotifications() {
    if (isUserInAuthorizerView) {
      dispatch(NotificationsActions.loadNotifications());
      dispatch(SyncActions.getSyncStatus());
    }
  }
  useEffect(loadNotifications, []);

  useEffect(() => {
    if (
      notifications.map((notification) => notification.isRead).includes(false)
    ) {
      setNotificationStatus(true);
    } else {
      setNotificationStatus(false);
    }
  }, [notifications]);

  function loadPermissionsForLocalisation() {
    if (isUserInAuthorizerView) {
      dispatch(loadPermissions());
    }
  }
  useEffect(loadPermissionsForLocalisation, [i18n.language]);

  function markNotificationsAsRead() {
    const markRead = notifications
      .filter((notification) => notification.isRead === false)
      .map((noti) => noti._id);
    if (markRead.length)
      dispatch(NotificationsActions.updateNotificationsStatus(markRead));
  }

  function getSwitchToText() {
    if (
      userDetails &&
      userDetails.role &&
      history.location.pathname.includes(
        `/${userDetails.role.roleName.replace(regReplaceURI, "_")}`
      )
    ) {
      return t("header.Authorizer_label");
    }
    return `${userDetails.role.roleName}`;
  }

  function syncWithViewPoint() {
    dispatch(SyncActions.syncWithViewPoint());
  }

  function syncTooltipTitle() {
    if (isSyncFailed) return t("header.sync_failed");
    if (isSyncInProgress) return t("header.sync_in_progress");
    if (syncStatus.lastSyncedAt)
      return `${t("header.last_synced_at")} ${moment(
        syncStatus.lastSyncStartedAt
      ).format(ACTIVITY_LOGS_TIME_FORMAT)}`;
    return t("header.sync_viewpoint");
  }

  function onSyncStatusReload() {
    let interval;
    if (isSyncInProgress) {
      interval = setInterval(
        () => dispatch(SyncActions.getSyncStatus()),
        30000
      );
    }
    if (
      lastSyncVPstatus === ViewPointSyncStatus.VPinprogress.code &&
      syncStatus.VPsyncStatus === ViewPointSyncStatus.VPsuccess.code
    ) {
      dispatch(NotificationsActions.loadNotifications());
    }
    setLastSyncVPstatus(syncStatus.VPsyncStatus);
    return () => {
      clearInterval(interval);
    };
  }
  useEffect(onSyncStatusReload, [syncStatus]);

  return (
    <AppBar position='fixed' className={toolBarStyle || classes.appBar}>
      <Toolbar>
        <div className={classes.titleStyle}>
          {selectedCompany && selectedCompany.name ? (
            <Typography variant='h6' className={classes.title}>
              {t("header.title")} - {selectedCompany.name}
            </Typography>
          ) : (
            <Typography variant='h6' className={classes.title}>
              {t("header.title")} - {userDetails.companyName}
            </Typography>
          )}
          {companies.length > 1 && (
            <Fab
              className={classes.fab}
              variant='extended'
              onClick={() => setShow(true)}
            >
              {t("header.change_btn_label")}
            </Fab>
          )}
          <AssignCompanyModalComponent show={show} handleClose={handleClose} />
        </div>
        <div className={classes.sectionDesktop}>
          {isUserInAuthorizerView && (
            <>
              {showExportReportButton && (
                <Fab
                  variant='extended'
                  color='secondary'
                  size='medium'
                  onClick={exportButtonHandler}
                  disabled={apiStatus.isLoading}
                  className={classes.exportButton}
                >
                  {t("payroll.export_reports")}
                </Fab>
              )}
              <Tooltip title={syncTooltipTitle()}>
                <div>
                  <Fab
                    classes={{
                      root: isSyncFailed
                        ? classes.syncFailedButton
                        : classes.syncButton
                    }}
                    variant='round'
                    size='small'
                    onClick={() => syncWithViewPoint()}
                    disabled={isSyncInProgress}
                  >
                    {isSyncFailed ? (
                      <SyncProblemIcon className={classes.syncIcon} />
                    ) : (
                      <SyncIcon
                        className={isSyncInProgress ? classes.spinningImg : ""}
                      />
                    )}
                  </Fab>
                </div>
              </Tooltip>
              <Tooltip title={t("header.notifications_label")}>
                <IconButton
                  color='inherit'
                  className={classes.notification}
                  onClick={() => {
                    toggleDrawer("right", true);
                    markNotificationsAsRead();
                  }}
                >
                  <Badge
                    badgeContent={notificationStatus ? " " : 0}
                    color='secondary'
                  >
                    <NotificationsIcon />
                  </Badge>
                </IconButton>
              </Tooltip>
            </>
          )}

          <Typography
            className={classes.userName}
            aria-label='account of current user'
            aria-controls='menu-appbar'
            aria-haspopup='true'
            onClick={handleMenu}
          >
            {userDetails.empName}
            <ArrowDropDownIcon />
          </Typography>
          <Menu
            id='menu-appbar'
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right"
            }}
            keepMounted
            open={open}
            onClose={handleCloseMenu}
            getContentAnchorEl={undefined}
          >
            {userDetails &&
              userDetails.role &&
              userDetails.role.permissions.includes(
                permissionsList.AUTHORIZER.key
              ) &&
              userDetails.role.roleName && (
                <MenuItem onClick={handleSwitch}>
                  {`${t("header.switch_to_label")} ${getSwitchToText()}`}
                </MenuItem>
              )}
            <MenuItem onClick={handleOnClick}>
              {t("header.signOut_btn_label")}
            </MenuItem>
            <MenuItem
              onClick={() => {
                changeLanguage("es");
                handleCloseMenu();
              }}
            >
              {t("header.spanish")}
            </MenuItem>
            <MenuItem
              onClick={() => {
                changeLanguage("en");
                handleCloseMenu();
              }}
            >
              {t("header.english")}
            </MenuItem>
          </Menu>
        </div>
      </Toolbar>
      <DrawerComponent
        header={t("header.notifications_label")}
        openDrawer={drawerState}
        closeDrawer={() => toggleDrawer("right", false)}
        drawerPaper={classes.drawerPaper}
        handleScroll={() => false}
        actions={undefined}
        drawerAttributes={
          <>
            <Divider variant='middle' />
            {notifications.length > 0 &&
              notifications.map((notification) => (
                <ExpansionPanel key={notification._id}>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls='panel1a-content'
                    id='panel1a-header'
                  >
                    <Typography className={classes.heading}>
                      <b>{`${notification.count} `}</b>
                      {t(`header.${notification.messageCode}`)}
                      <div className='text-muted'>
                        {moment(notification.lastSync).fromNow()}
                      </div>
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography className={classes.paperWidth}>
                      {notification.changes.map((change, index) => (
                        <Grid container key={index}>
                          <Grid item xs={5}>
                            {change.code}
                          </Grid>
                          <Grid item xs={7}>
                            {change.name}
                          </Grid>
                        </Grid>
                      ))}
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              ))}
          </>
        }
      />
    </AppBar>
  );
};

HeaderComponent.propTypes = {
  toolBarStyle: PropTypes.string,
  history: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string
    })
  }).isRequired,
  exportButtonHandler: PropTypes.func,
  showExportReportButton: PropTypes.bool
};

HeaderComponent.defaultProps = {
  toolBarStyle: undefined,
  exportButtonHandler: () => {},
  showExportReportButton: false
};

export default withRouter(HeaderComponent);
