import axios from "../../axios-jwt";

import { ALL_CREW } from "./actionTypes";
import { apiFailed, apiStartLoader, apiStopLoader } from "./ApiStatusActions";

async function getCrew(offset, limit) {
  const resp = axios.get(
    `crew?orderBy=empID-asc&offset=${offset}&limit=${limit}`
  );
  return resp;
}

export function loadAllCrewMembersSuccess(crewMembers, offset) {
  return { type: ALL_CREW, crewMembers, offset };
}

export function loadAllCrewMembers(offset, limit) {
  return (dispatch) => {
    dispatch(apiStartLoader("crewMembers"));
    getCrew(offset, limit)
      .then((res) => {
        if (res.status === 200) {
          dispatch(loadAllCrewMembersSuccess(res.data, offset));
          dispatch(apiStopLoader("crewMembers"));
        }
        return undefined;
      })
      .catch((error) => {
        dispatch(apiFailed(error));
        dispatch(apiStopLoader("crewMembers"));
      });
  };
}
