import axios from "../../axios-jwt";

import {
  CLEAR_JOBS,
  CLEAR_JOBS_SEARCH_RESULTS,
  LOAD_COMPANIES_SUCCESS,
  LOAD_JOBS_SUCCESS,
  RESET_SELECTED_COMPANY,
  SEARCH_JOBS,
  SELECTED_COMPANY,
  UNASSIGN_JOB_SUCCESS
} from "./actionTypes";
import * as ApiStatusActions from "./ApiStatusActions";
import * as AuthActions from "./AuthActions";
import loadUserDetails from "./UserDetailsActions";

async function getCompanies() {
  return axios.get("/employees/me/companies");
}

async function getJobs(
  userDetails,
  selectedcompany,
  offset,
  limit,
  orderBy,
  order
) {
  if (selectedcompany) {
    return axios.get(
      `companies/${selectedcompany.code}/jobs?offset=${offset}&limit=${limit}&orderBy=${orderBy}-${order}`
    );
  }
  return axios.get(
    `companies/${userDetails.companyCode}/jobs?offset=${offset}&limit=${limit}&orderBy=${orderBy}-${order}`
  );
}

async function getSearchedJobs(
  userDetails,
  selectedcompany,
  search,
  offset,
  limit,
  orderBy,
  order
) {
  if (selectedcompany) {
    return axios.get(
      `companies/${selectedcompany.code}/jobs?search=${search}&offset=${offset}&limit=${limit}&orderBy=${orderBy}-${order}`
    );
  }
  return axios.get(
    `companies/${userDetails.companyCode}/jobs?search=${search}&offset=${offset}&limit=${limit}&orderBy=${orderBy}-${order}`
  );
}

async function getJobsWithAssignedJobs(
  userDetails,
  selectedcompany,
  empID,
  offset,
  limit,
  orderBy,
  order
) {
  if (selectedcompany) {
    return axios.get(
      `companies/${selectedcompany.code}/jobs?employee=${empID}&offset=${offset}&limit=${limit}&orderBy=${orderBy}-${order}`
    );
  }
  return axios.get(
    `companies/${userDetails.companyCode}/jobs?employee=${empID}&offset=${offset}&limit=${limit}&orderBy=${orderBy}-${order}`
  );
}

async function searchJobsWithAssignedJobs(
  userDetails,
  selectedcompany,
  search,
  empID,
  offset,
  limit,
  orderBy,
  order
) {
  if (selectedcompany) {
    return axios.get(
      `companies/${selectedcompany.code}/jobs?employee=${empID}&search=${search}&offset=${offset}&limit=${limit}&orderBy=${orderBy}-${order}`
    );
  }
  return axios.get(
    `companies/${userDetails.companyCode}/jobs?employee=${empID}&search=${search}&offset=${offset}&limit=${limit}&orderBy=${orderBy}-${order}`
  );
}

async function deleteJob(employee, jobs) {
  return axios.delete(`employeejobs?employee=${employee}&jobs=${jobs}`);
}

function loadCompaniesSuccess(companies) {
  return { type: LOAD_COMPANIES_SUCCESS, payload: companies };
}

function loadJobsSuccess(jobs, offset) {
  return { type: LOAD_JOBS_SUCCESS, jobs, offset };
}

export function loadCompanies() {
  return (dispatch) => {
    ApiStatusActions.apiStartLoader("Companies");
    return getCompanies()
      .then((res) => {
        if (res.status === 200) {
          dispatch(loadCompaniesSuccess(res.data));
          if (res.data.length === 0) {
            dispatch(AuthActions.logout());
            dispatch(
              ApiStatusActions.customFailedMessage("No companies are assgined.")
            );
          }
          ApiStatusActions.apiStopLoader("Companies");
        }
      })
      .catch((err) => {
        dispatch(AuthActions.logout());
        dispatch(ApiStatusActions.apiFailed(err));
        dispatch(ApiStatusActions.apiStopLoader("Companies"));
      });
  };
}

export function selectedCompanySuccess(selectedComp) {
  return {
    type: SELECTED_COMPANY,
    payload: selectedComp
  };
}

export function loadJobsSearchSuccess(jobsSearchResults) {
  return {
    type: SEARCH_JOBS,
    jobsSearchResults
  };
}

export function selectedCompany(selectedComp) {
  return (dispatch, getState) => {
    return (() => {
      dispatch(selectedCompanySuccess(selectedComp));
      if (getState().userDetails.companyCode !== selectedComp.code)
        dispatch(loadUserDetails());
    })();
  };
}

// get jobs in active jobs, assign jobs and manage jobs
export function getJobOfCompany(
  offset = 0,
  limit = 20,
  orderBy = "code",
  order = "asc",
  unassignJob,
  empID
) {
  return (dispatch, getState) => {
    dispatch(ApiStatusActions.apiStartLoader("activeJobs"));
    return (
      unassignJob
        ? getJobsWithAssignedJobs(
            getState().userDetails,
            getState().selectedCompany,
            empID,
            offset,
            limit,
            orderBy,
            order
          )
        : getJobs(
            getState().userDetails,
            getState().selectedCompany,
            offset,
            limit,
            orderBy,
            order
          )
    )
      .then((obj) => {
        dispatch(loadJobsSuccess(obj.data.jobs, offset));
        dispatch(ApiStatusActions.apiStopLoader("activeJobs"));
      })
      .catch(() => dispatch(ApiStatusActions.apiStopLoader("activeJobs")));
  };
}

// get searched jobs in active jobs, assign jobs and manage jobs
export function loadSearchedJobs(
  search,
  offset = 0,
  limit = 20,
  orderBy,
  order,
  unassignJob,
  empID
) {
  return (dispatch, getState) => {
    dispatch(ApiStatusActions.apiStartLoader("activeJobs"));
    return (
      unassignJob
        ? searchJobsWithAssignedJobs(
            getState().userDetails,
            getState().selectedCompany,
            search,
            empID,
            offset,
            limit,
            orderBy,
            order
          )
        : getSearchedJobs(
            getState().userDetails,
            getState().selectedCompany,
            search,
            offset,
            limit,
            orderBy,
            order
          )
    )
      .then((obj) => {
        dispatch(loadJobsSearchSuccess(obj.data.jobs));
        dispatch(ApiStatusActions.apiStopLoader("activeJobs"));
      })
      .catch(() => dispatch(ApiStatusActions.apiStopLoader("activeJobs")));
  };
}

export function unassignJob(empId, jobCode) {
  return (dispatch) => {
    dispatch(ApiStatusActions.apiStartLoader("unAssignJobs"));
    return deleteJob(empId, jobCode)
      .then((res) => {
        if (res.status === 200) {
          dispatch(ApiStatusActions.apiStopLoader("unAssignJobs"));
          dispatch({ type: UNASSIGN_JOB_SUCCESS, jobCode });
        }
      })
      .catch((err) => {
        ApiStatusActions.apiFailed(err);
        dispatch(ApiStatusActions.apiStopLoader("unAssignJobs"));
      });
  };
}

export function clearJobs() {
  return (dispatch) => {
    dispatch({ type: CLEAR_JOBS });
    dispatch({ type: CLEAR_JOBS_SEARCH_RESULTS });
  };
}

export function resetSelectedCompany() {
  return (dispatch) => {
    return dispatch({ type: RESET_SELECTED_COMPANY });
  };
}

export function clearJobResults() {
  return (dispatch) => {
    return dispatch({ type: CLEAR_JOBS_SEARCH_RESULTS });
  };
}
