import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  NavLink,
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
  withRouter
} from "react-router-dom";
import PropTypes from "prop-types";

import { Drawer, Fab } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import SupervisedUserCircleRoundedIcon from "@material-ui/icons/SupervisedUserCircleRounded";

import AllEmployeesComponent from "../components/Authorizer/employees/AllEmployeesComponent";
import PreviewReportsComponent from "../components/Authorizer/exportReports/PreviewReportsComponent";
import ActiveJobsComponent from "../components/Authorizer/jobs/ActiveJobsComponent";
import ReportsFilterDialog from "../components/Authorizer/ReportsFilterDialog";
import ManageRolesComponent from "../components/Authorizer/roles/ManageRolesComponent";
import SpecificRoleEmployeesComponent from "../components/Authorizer/roles/SpecificRoleEmployeesComponent";
import AllEquipmentsComponent from "../components/EquipmentManager/AllEquipmentsComponent";
import HeaderComponent from "../components/UI/HeaderComponent";
import SnackbarComponent from "../components/UI/SnackbarComponent";
import * as AuthorisersReportFilterActions from "../redux/actions/AuthorisersReportFilterActions";
import * as AuthorisersReportFilterLoadSearchActions from "../redux/actions/AuthorisersReportFilterLoadSearchActions";
import * as EmployeesActions from "../redux/actions/EmployeeActions";
import * as EquipmentsActions from "../redux/actions/EquipmentsActions";
import * as RolesActions from "../redux/actions/RolesActions";
import permissionsList from "../utillities/Permissions";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex"
  },
  drawer: {
    width: "18%",
    flexShrink: 0
  },
  drawerPaper: {
    width: "18%",
    height: "100% !important"
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  listItem: {
    justifyContent: "space-between",
    marginBottom: "5px"
  },
  roleName: {
    fontSize: "1rem",
    textOverflow: "ellipsis",
    overflow: "hidden"
  },
  roleCount: {
    fontSize: "80%",
    fontWeight: "400"
  },
  toolbar: theme.mixins.toolbar,
  appBar: {
    zIndex: theme.zIndex.drawer + 1
  },
  buttonStyle: {
    width: "80%"
  },
  buttonGrid: {
    justifyContent: "center"
  },
  rolesIcon: {
    marginRight: theme.spacing(1)
  },
  marginAbove: {
    marginTop: "24px"
  },
  backdrop: {
    zIndex: 10000
  }
}));

const AdminComponent = (props) => {
  const { history } = props;
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [exportReportDialog, setExportReportDialog] = useState(false);
  const location = useLocation();

  const isNotInPreviewReportsRoute = ["/admin/exportReports"].some((value) => {
    return value !== location.pathname;
  });

  const [modalShow, setModalShow] = useState(false);
  const {
    roles,
    employees,
    selectedCompany,
    apiStatus,
    companies,
    userDetails,
    companyJobs,
    count
  } = useSelector((state) => state);

  const match = useRouteMatch();
  const allEmployeesList = useRef();
  const equipmentsRef = useRef();
  const roleLevel = roles.filter((role) => role.roleName.length);

  useEffect(() => {
    if (selectedCompany.code === userDetails.companyCode) {
      dispatch(RolesActions.loadRoles());
      allEmployeesList.current = dispatch(
        EmployeesActions.loadEmployees(0, 50, "empName", "asc")
      );
      equipmentsRef.current = dispatch(
        EquipmentsActions.loadEquipments("orderBy=name-asc", 0, 50)
      );
      //Initially Populating job options (for filters) with default values (offset and limit)
      dispatch(AuthorisersReportFilterLoadSearchActions.getCompanyJobs());
      //Initially Populating personnel options (for filters) with default values (offset and limit)
      dispatch(
        AuthorisersReportFilterLoadSearchActions.getAssignedEmployeePersonnel()
      );
      //Resetting loaded options (for filters) of assignedTo(employeesByEquipment) & select_equipments(equipments) on company change
      dispatch(
        AuthorisersReportFilterLoadSearchActions.resetARFEquipmentsLoadedResults()
      );
      //Resetting Offsets on company change
      setDropdownOffset({
        companyJobs: 0,
        employeesByEquipment: 0,
        personnel: 0,
        equipments: 0
      });
    }
  }, [dispatch, selectedCompany, userDetails.companyCode]);

  const path = {
    [`${match.url}/jobs`]: 0,
    [`${match.url}/all_equipments`]: 1,
    [`${match.url}/all_employees`]: 2
  };
  roles.forEach((role, idx) => {
    if (role.roleName !== "") {
      path[`${match.url}/role_employees/${role._id}`] = idx + 3;
    }
  });

  const defaultSidebarValue = () => {
    if (path[history.location.pathname] >= 0) {
      setValue(path[history.location.pathname]);
    }
  };

  useEffect(
    defaultSidebarValue,
    [history.location.pathname, companies],
    selectedCompany
  );

  const handleTsDeskopPermissionCheck = () => {
    if (
      !userDetails.isSuperAdmin &&
      !userDetails.role.permissions.includes(permissionsList.TS_DESKTOP.key)
    ) {
      history.push("/");
    }
  };

  useEffect(handleTsDeskopPermissionCheck, [userDetails]);

  // ?---Reports Filters common current offsets and dropdownscroll func

  const [currentFilter, setCurrentFilter] = useState("");
  const [dropdownLimit, setDropdownLimit] = useState(20);
  const [dropdownOffset, setDropdownOffset] = useState({
    companyJobs: 0,
    employeesByEquipment: 0,
    personnel: 0,
    equipments: 0
  });

  const handleDropdownScroll = (trigger, dropdownType) => {
    if (trigger) {
      setDropdownOffset((o) => ({
        ...o,
        [dropdownType]: o[dropdownType] + dropdownLimit
      }));
      setDropdownLimit(dropdownLimit);
      setCurrentFilter(dropdownType);
    }
  };
  const applyPagination = () => {
    switch (currentFilter) {
      case "companyJobs":
        dispatch(
          AuthorisersReportFilterLoadSearchActions.getCompanyJobs(
            dropdownOffset["companyJobs"],
            dropdownLimit
          )
        );
        break;
      case "personnel":
        dispatch(
          AuthorisersReportFilterLoadSearchActions.getAssignedEmployeePersonnel(
            dropdownOffset["personnel"],
            dropdownLimit
          )
        );
        break;
      case "employeesByEquipment":
        dispatch(
          AuthorisersReportFilterLoadSearchActions.loadEmployeesByEquipment(
            dropdownOffset["employeesByEquipment"],
            dropdownLimit
          )
        );
        break;
      case "equipments":
        dispatch(
          AuthorisersReportFilterLoadSearchActions.loadEquipments(
            dropdownOffset["equipments"],
            dropdownLimit
          )
        );
        break;

      default:
        break;
    }
  };
  useEffect(applyPagination, [dropdownOffset]);

  // ? ----------------------------------------------------------------

  useEffect(() => {
    // Resetting/Clearing filter on route change or back press
    if (location.pathname !== "/admin/exportReports") {
      dispatch(AuthorisersReportFilterActions.resetFilter());
    }
  }, [location.pathname, dispatch]);

  return (
    companyJobs.length >= 0 && (
      <div className={classes.root}>
        <CssBaseline />
        {apiStatus &&
          apiStatus.reducer &&
          (apiStatus.reducer.includes("UserDetails") ||
            apiStatus.reducer.includes("Roles") ||
            apiStatus.reducer.includes("syncWithViewPoint")) && (
            <Backdrop open className={classes.backdrop}>
              <CircularProgress />
            </Backdrop>
          )}
        <HeaderComponent
          toolBarStyle={classes.appBar}
          exportButtonHandler={() => setExportReportDialog(true)}
          showExportReportButton={isNotInPreviewReportsRoute}
        />
        {!selectedCompany.code && userDetails.isSuperAdmin && (
          <Redirect to='/' />
        )}
        {isNotInPreviewReportsRoute && (
          <Drawer
            className={classes.drawer}
            variant='permanent'
            classes={{
              paper: classes.drawerPaper
            }}
          >
            <div className={classes.toolbar} />

            <List>
              <ListItem className={`${classes.marginAbove} text-muted`}>
                <span>{t("authorizerContainer.jobs")}</span>
              </ListItem>

              <ListItem
                button
                name='jobs'
                component={NavLink}
                to={`${match.url}/jobs`}
                selected={value === 0}
                onClick={() => setValue(0)}
              >
                <ListItemText
                  primary={t("authorizerContainer.active_jobs_title")}
                />
              </ListItem>

              <ListItem className='text-muted'>
                <span>{t("authorizerContainer.equipment")}</span>
              </ListItem>

              <ListItem
                button
                name='equipments'
                component={NavLink}
                to={`${match.url}/all_equipments`}
                selected={value === 1}
                onClick={() => setValue(1)}
              >
                <ListItemText
                  primary={t("authorizerContainer.all_equip_label")}
                />
                <small className='text-muted'>{count.equipment}</small>
              </ListItem>

              <ListItem className='text-muted'>
                <span>{t("authorizerContainer.employees")}</span>
              </ListItem>

              <ListItem
                button
                component={NavLink}
                to={`${match.url}/all_employees`}
                className={classes.listItem}
                selected={value === 2}
                onClick={() => setValue(2)}
              >
                <ListItemText
                  primary={t("authorizerContainer.all_emp_label")}
                />
                <small className='text-muted'>{count.employees}</small>
              </ListItem>

              {roles.map(
                (role, idx) =>
                  role.roleName !== "" && (
                    <ListItem
                      button
                      component={NavLink}
                      to={`${match.url}/role_employees/${role._id}`}
                      key={role._id}
                      className={classes.listItem}
                      selected={value === idx + 3}
                      onClick={() => setValue(idx + 3)}
                    >
                      <ListItemText
                        primary={
                          <Typography type='div' className={classes.roleName}>
                            {role.roleName}
                          </Typography>
                        }
                      />
                      <span className={`${classes.roleCount} text-muted`}>
                        {role.count}
                      </span>
                    </ListItem>
                  )
              )}
            </List>
            {(userDetails.isSuperAdmin ||
              userDetails.role.permissions.includes(
                permissionsList.AUTHORIZER_ROLE_SETUP.key
              )) && (
              <Grid container className={classes.buttonGrid}>
                <Fab
                  variant='extended'
                  color='primary'
                  size='large'
                  onClick={() => setModalShow(true)}
                  className={classes.buttonStyle}
                >
                  <SupervisedUserCircleRoundedIcon
                    className={classes.rolesIcon}
                  />
                  {t("authorizerContainer.manage_roles")}
                </Fab>
              </Grid>
            )}

            {modalShow && (
              <ManageRolesComponent
                modalShow={modalShow}
                onHide={() => setModalShow(false)}
              />
            )}
            {exportReportDialog && (
              <ReportsFilterDialog
                open={exportReportDialog}
                close={() => setExportReportDialog(false)}
                handleDropdownScroll={handleDropdownScroll}
              />
            )}
          </Drawer>
        )}

        {/* setting roles and adding hierarchy */}

        <main role='main' className={classes.content}>
          <div className={classes.toolbar} />
          <Switch>
            <Route
              path={`${match.url}/jobs`}
              key={`ActiveJobs_${selectedCompany.code}_${userDetails.companyCode}`}
            >
              {selectedCompany && <ActiveJobsComponent />}
            </Route>
            <Route path={`${match.url}/all_equipments`}>
              <AllEquipmentsComponent />
            </Route>
            <Route path={`${match.url}/all_employees`}>
              <AllEmployeesComponent employees={employees} />
            </Route>
            {roles.map(
              (role) =>
                role.roleName !== "" && (
                  <Route
                    path={`${match.url}/role_employees/${role._id}`}
                    key={role._id}
                  >
                    <SpecificRoleEmployeesComponent
                      role={role}
                      lastRole={roleLevel[roleLevel.length - 1]}
                      firstRole={roleLevel[0]}
                    />
                  </Route>
                )
            )}
            <Route path={`${match.url}/exportReports`}>
              <PreviewReportsComponent
                handleDropdownScroll={handleDropdownScroll}
              />
            </Route>
            <Redirect to={{ pathname: "/payroll" }} />
          </Switch>
        </main>
        {typeof apiStatus === "object" &&
          apiStatus.status &&
          (apiStatus.status.success || apiStatus.status.error) && (
            <>
              <SnackbarComponent />
            </>
          )}
      </div>
    )
  );
};

AdminComponent.propTypes = {
  history: PropTypes.shape().isRequired
};

export default withRouter(AdminComponent);
