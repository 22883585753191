import React, { useEffect, useMemo, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import clsx from "clsx";
import PropTypes from "prop-types";

import { FormControlLabel, Grid, Radio, RadioGroup } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import * as AuthorisersReportFilterActions from "../../redux/actions/AuthorisersReportFilterActions";
import * as AuthorisersReportFilterLoadSearchActions from "../../redux/actions/AuthorisersReportFilterLoadSearchActions";
import FilterDialogComponent from "../UI/FilterDialogComponent";
import MultiSelectComponent from "../UI/MultiSelectComponent";
import SimpleSelectorComponent from "../UI/SimpleSelectorComponent";

const useStyles = makeStyles(() => ({
  gridLabel: {
    fontSize: "1rem",
    marginBottom: "15px"
  },
  radioLabel: {
    fontSize: "1rem",
    marginBottom: "7px"
  },
  gridValue: {
    width: "360px",
    margin: "0 auto"
  },
  selectorValue: {
    marginBottom: "16px"
  }
}));

const ReportsFilterDialog = (props) => {
  const {
    open,
    close,
    isAModifyDialog = false,
    handleDropdownScroll,
    onModifyFilterSave = () => {}
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    authorisersReportFilter,
    authoriserReportFilterLoadSearch,
    selectedCompany,
    userDetails,
    apiStatus
  } = useSelector((state) => state);

  const [searchString, setSearchString] = useState({});

  const [localFilterValue, setLocalFilterValue] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    authorisersReportFilter
  );

  const EmployeesOptions = useMemo(
    () => [
      {
        _id: "allEmployees",
        name: t("authorizerContainer.employees_options.all_employees"),
        value: "allEmployees"
      },
      {
        _id: "selectedEmployees",
        name: t("authorizerContainer.employees_options.selected_employees"),
        value: "selectedEmployees"
      },
      {
        _id: "unassignedOnly",
        name: t("authorizerContainer.employees_options.unassigned_only"),
        value: "unassignedOnly"
      }
    ],
    [t]
  );

  const EquipmentsOptions = useMemo(
    () => [
      {
        _id: "allEquipments",
        name: t("authorizerContainer.equipments_options.all_equipments"),
        value: "allEquipments"
      },
      {
        _id: "selectedEquipments",
        name: t("authorizerContainer.equipments_options.selected_equipments"),
        value: "selectedEquipments"
      },
      {
        _id: "unassignedOnly",
        name: t("authorizerContainer.equipments_options.unassigned_only"),
        value: "unassignedOnly"
      }
    ],
    [t]
  );

  const clearList = (type) =>
    dispatch(
      AuthorisersReportFilterLoadSearchActions.clearARFSearchResults(type)
    );

  const searchCompanyJobs = (query) =>
    query &&
    dispatch(
      AuthorisersReportFilterLoadSearchActions.getSearchedCompanyJobs(query)
    );
  const searchPersonnel = (query) =>
    query &&
    dispatch(
      AuthorisersReportFilterLoadSearchActions.getSearchedPersonnel(query)
    );
  const searchEmployeesByEquipment = (query) =>
    query &&
    dispatch(
      AuthorisersReportFilterLoadSearchActions.getSearchedEmployeesByEquipment(
        query
      )
    );
  const searchEquipments = (query) =>
    query &&
    dispatch(
      AuthorisersReportFilterLoadSearchActions.getSearchedEquipments(query)
    );

  // -----------Common Functions---------------

  const dropdownOptions = (searchString, array, searchResults) => {
    let options = [];
    if (!searchString) options = array;
    else {
      if (searchResults.length) options = searchResults;
      else if (!searchResults.length && array.length) options = [];
    }
    return options;
  };

  const handleDropdownSearch = (type, query) => {
    if (!query) setSearchString({});
    else setSearchString((s) => ({ ...s, [type]: query }));

    switch (type) {
      case "companyJobs":
        if (query && query.length) searchCompanyJobs(query);
        else clearList("companyJobsSearchResults");
        break;
      case "personnel":
        if (query && query.length) searchPersonnel(query);
        else clearList("personnelSearchResults");
        break;
      case "employeesByEquipment":
        if (query && query.length) searchEmployeesByEquipment(query);
        else clearList("employeesByEqSearchResults");
        break;
      case "equipments":
        if (query && query.length) searchEquipments(query);
        else clearList("equipmentsSearchResults");
        break;
      default:
        break;
    }
  };

  const handleDropdownClose = (type, closed) => {
    if (!closed) return;
    setSearchString((s) => ({ ...s, [type]: "" }));

    switch (type) {
      case "companyJobs":
        clearList("companyJobsSearchResults");
        break;
      case "personnel":
        clearList("personnelSearchResults");
        break;
      case "employeesByEquipment":
        clearList("employeesByEqSearchResults");
        break;
      case "equipments":
        clearList("equipmentsSearchResults");
        break;
      default:
        break;
    }
  };

  //-----------Company Jobs ----------

  const jobOptions = () =>
    dropdownOptions(
      (searchString || {})["companyJobs"],
      authoriserReportFilterLoadSearch.companyJobs,
      authoriserReportFilterLoadSearch.companyJobsSearchResults
    ).map((option) => ({
      name: option.name,
      id: option.code
      // operationalUnits: option.operationalUnits
    }));

  const dropdownConfigJob = {
    label: `${t("authorizerContainer.job_label")}`,
    type: "companyJobs",
    searchType: "api", // To Decide if search needs to be done from frontend or with API
    data: jobOptions()
  };

  const jobFilterDropdown = () => (
    <MultiSelectComponent
      key={dropdownConfigJob.type}
      dropdownConfig={dropdownConfigJob}
      value={localFilterValue.jobFilter}
      onChange={(values) =>
        setLocalFilterValue({
          jobFilter: values
        })
      }
      optionLabel={(option) => `${option.id} - ${option.name}`}
      handleScroll={handleDropdownScroll}
      handleSearch={handleDropdownSearch}
      handleClose={handleDropdownClose}
      noLabelDivider
    />
  );

  //-------------Personnel/CrewOf -----------

  const crewOfOptions = () =>
    dropdownOptions(
      (searchString || {})["personnel"],
      authoriserReportFilterLoadSearch.personnel,
      authoriserReportFilterLoadSearch.personnelSearchResults
    ).map((option) => ({
      name: option.empName,
      id: option.empID,
      _id: option._id
    }));

  const dropdownConfigCrewOf = {
    label: `${t("authorizerContainer.employees_label")}`,
    type: "personnel",
    searchType: "api", // To Decide if search needs to be done from frontend or with API
    data: crewOfOptions()
  };

  /** crewOf is same as personnel and same is used here as selectEmployees */
  const crewOfFilterDropdown = () => (
    <MultiSelectComponent
      key={dropdownConfigCrewOf.type}
      dropdownConfig={dropdownConfigCrewOf}
      value={localFilterValue.selectedEmployees}
      onChange={(values) =>
        setLocalFilterValue({
          selectedEmployees: values
        })
      }
      optionLabel={(option) => `${option.id} - ${option.name}`}
      handleScroll={handleDropdownScroll}
      handleSearch={handleDropdownSearch}
      handleClose={handleDropdownClose}
      noLabelDivider
    />
  );

  //-------------Employees by equipments/Assigned To--------------
  const empByEqOptions = () =>
    dropdownOptions(
      (searchString || {})["employeesByEquipment"],
      authoriserReportFilterLoadSearch.employeesByEquipment,
      authoriserReportFilterLoadSearch.employeesByEqSearchResults
    ).map((option) => ({
      name: option.empName,
      id: option.empID,
      _id: option._id
    }));

  const dropdownConfigEmployeesByEquipment = {
    label: `${t("authorizerContainer.assigned_to")}`,
    type: "employeesByEquipment",
    searchType: "api", // To Decide if search needs to be done from frontend or with API
    data: empByEqOptions()
  };

  const empByEqFilterDropdown = () => (
    <MultiSelectComponent
      key={dropdownConfigEmployeesByEquipment.type}
      dropdownConfig={dropdownConfigEmployeesByEquipment}
      value={localFilterValue.assignedTo}
      onChange={(values) =>
        setLocalFilterValue({
          assignedTo: values
        })
      }
      optionLabel={(option) => `${option.id} - ${option.name}`}
      handleScroll={handleDropdownScroll}
      handleSearch={handleDropdownSearch}
      handleClose={handleDropdownClose}
      noLabelDivider
    />
  );

  //-----------Equipments --------
  const allEquipmentOptions = () =>
    dropdownOptions(
      (searchString || {})["equipments"],
      authoriserReportFilterLoadSearch.equipments,
      authoriserReportFilterLoadSearch.equipmentsSearchResults
    ).map((option) => ({
      name: option.name || option.code,
      id: option.code,
      _id: option._id
    }));

  const dropdownConfigAllEquipment = {
    label: `${t("authorizerContainer.equipments_label")}`,
    type: "equipments",
    searchType: "api", // To Decide if search needs to be done in the UI or  with API
    data: allEquipmentOptions()
  };

  const allEquipmentFilterDropdown = () => (
    <MultiSelectComponent
      key={dropdownConfigAllEquipment.type}
      dropdownConfig={dropdownConfigAllEquipment}
      value={localFilterValue.selectedEquipments}
      onChange={(values) =>
        setLocalFilterValue({
          selectedEquipments: values
        })
      }
      optionLabel={(option) => `${option.id} - ${option.name || option.id}`}
      handleScroll={handleDropdownScroll}
      handleSearch={handleDropdownSearch}
      handleClose={handleDropdownClose}
      noLabelDivider
    />
  );

  //----------------------------------------------------------------

  useEffect(
    () => {
      //Only fetch options initially when reports of equipment option is selected
      if (
        selectedCompany.code === userDetails.companyCode &&
        localFilterValue.reportsOf === "equipments" &&
        authoriserReportFilterLoadSearch.employeesByEquipment.length === 0 &&
        authoriserReportFilterLoadSearch.equipments.length === 0
      ) {
        //Initially Populating assigned_to options (for filters) with default values (offset and limit)
        dispatch(
          AuthorisersReportFilterLoadSearchActions.loadEmployeesByEquipment()
        );
        //Initially Populating select equipments options (for filters) with default values (offset and limit)
        dispatch(AuthorisersReportFilterLoadSearchActions.loadEquipments());
      }
    },
    // eslint-disable-next-line
    [
      dispatch,
      selectedCompany,
      userDetails.companyCode,
      localFilterValue.reportsOf
    ]
  );

  const isCurrentReportOfEmployeesSelected =
    localFilterValue.reportsOf === "employees";

  const isPreviewActionDisabled = () => {
    if (localFilterValue.employees === "selectedEmployees") {
      return !localFilterValue.selectedEmployees.length;
    } else if (localFilterValue.equipments === "selectedEquipments") {
      return !localFilterValue.selectedEquipments.length;
    }
    return apiStatus.isLoading;
  };

  const settingGlobalFilterState = () => {
    dispatch(
      AuthorisersReportFilterActions.overrideFilter({
        reportsOf: localFilterValue.reportsOf,
        employees: localFilterValue.employees,
        jobFilter: [...localFilterValue.jobFilter],
        selectedEmployees: [...localFilterValue.selectedEmployees],
        equipments: localFilterValue.equipments,
        assignedTo: [...localFilterValue.assignedTo],
        selectedEquipments: [...localFilterValue.selectedEquipments]
      })
    );
  };

  const applyFiltersAndRouteToPreview = () => {
    settingGlobalFilterState();
    history.push("/admin/exportReports");
    close();
  };

  const saveFilterAndPreview = () => {
    onModifyFilterSave(localFilterValue.reportsOf);
    settingGlobalFilterState();
    close();
  };

  return (
    <FilterDialogComponent
      open={open}
      close={close}
      title={
        isAModifyDialog
          ? t("authorizerContainer.modify_filters")
          : t("authorizerContainer.export_reports")
      }
      dialogAttributes={{
        cancelAction: close,
        cancelText: t("commonActions.cancel"),
        previewAction: isAModifyDialog
          ? saveFilterAndPreview
          : applyFiltersAndRouteToPreview,
        previewText: t(
          `commonActions.${isAModifyDialog ? "save" : "preview_reports"}`
        ),
        isPreviewActionDisabled: isPreviewActionDisabled()
      }}
    >
      <Grid container alignItems='center'>
        <Grid item xs={3} className={classes.radioLabel}>
          {t("authorizerContainer.reports_of")}
        </Grid>
        <Grid item xs={9}>
          <RadioGroup
            aria-label='reportsOf'
            name='reportsOf'
            onChange={(e) =>
              // resetting the states if radio button is changed in between form filling
              setLocalFilterValue({
                reportsOf: e.target.value,
                employees: e.target.value === "employees" ? "allEmployees" : "",
                jobFilter: [],
                selectedEmployees: [], //personnel
                equipments:
                  e.target.value === "equipments" ? "allEquipments" : "",
                assignedTo: [],
                selectedEquipments: []
              })
            }
            value={localFilterValue.reportsOf}
            row
            className={classes.gridValue}
          >
            <FormControlLabel
              value='employees'
              control={<Radio color='secondary' />}
              label={t("authorizerContainer.employees_label")}
              labelPlacement='End'
            />

            <FormControlLabel
              value='equipments'
              control={<Radio color='secondary' />}
              label={t("authorizerContainer.equipments_label")}
              labelPlacement='End'
            />
          </RadioGroup>
        </Grid>
        <Grid item xs={3} className={classes.gridLabel}>
          {isCurrentReportOfEmployeesSelected
            ? t("authorizerContainer.employees_label")
            : t("authorizerContainer.equipments_label")}
        </Grid>
        <Grid item xs={9}>
          <div className={clsx(classes.gridValue, classes.selectorValue)}>
            <SimpleSelectorComponent
              currentValue={
                isCurrentReportOfEmployeesSelected
                  ? localFilterValue.employees
                  : localFilterValue.equipments
              }
              options={
                isCurrentReportOfEmployeesSelected
                  ? EmployeesOptions
                  : EquipmentsOptions
              }
              onChange={(e) =>
                setLocalFilterValue(
                  isCurrentReportOfEmployeesSelected
                    ? {
                        employees: e.target.value,
                        jobFilter: [],
                        selectedEmployees: []
                      }
                    : {
                        equipments: e.target.value,
                        assignedTo: [],
                        selectedEquipments: []
                      }
                )
              }
            />
          </div>
        </Grid>
        {isCurrentReportOfEmployeesSelected
          ? (() => {
              switch (localFilterValue.employees) {
                case "allEmployees":
                  return (
                    <>
                      <Grid item xs={3} className={classes.gridLabel}>
                        {`${t("authorizerContainer.select")} ${t(
                          "authorizerContainer.job_label"
                        )}`}
                      </Grid>
                      <Grid item xs={9}>
                        <div className={classes.gridValue}>
                          {jobFilterDropdown()}
                        </div>
                      </Grid>
                    </>
                  );
                case "selectedEmployees":
                  return (
                    <>
                      <Grid item xs={3} className={classes.gridLabel}>
                        {`${t("authorizerContainer.select")} ${t(
                          "authorizerContainer.employees_label"
                        )}`}
                      </Grid>
                      <Grid item xs={9}>
                        <div className={classes.gridValue}>
                          {crewOfFilterDropdown()}
                        </div>
                      </Grid>
                    </>
                  );
                default:
                  return null;
              }
            })()
          : (() => {
              switch (localFilterValue.equipments) {
                case "allEquipments":
                  return (
                    <>
                      <Grid item xs={3} className={classes.gridLabel}>
                        {t("authorizerContainer.assigned_to")}
                      </Grid>
                      <Grid item xs={9}>
                        <div className={classes.gridValue}>
                          {empByEqFilterDropdown()}
                        </div>
                      </Grid>
                    </>
                  );
                case "selectedEquipments":
                  return (
                    <>
                      <Grid item xs={3} className={classes.gridLabel}>
                        {`${t("authorizerContainer.select")} ${t(
                          "authorizerContainer.equipments_label"
                        )}`}
                      </Grid>
                      <Grid item xs={9}>
                        <div className={classes.gridValue}>
                          {allEquipmentFilterDropdown()}
                        </div>
                      </Grid>
                    </>
                  );
                default:
                  return null;
              }
            })()}
      </Grid>
    </FilterDialogComponent>
  );
};
ReportsFilterDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  isAModifyDialog: PropTypes.bool,
  onModifyFilterSave: PropTypes.func,
  handleDropdownScroll: PropTypes.func.isRequired
};

export default ReportsFilterDialog;
