import axios from "../../axios-jwt";

import { GET_PENDING_APPROVALS_COUNT_SUCCESS } from "./actionTypes";
import { apiFailed, apiStartLoader, apiStopLoader } from "./ApiStatusActions";

async function getPendingApprovalsCount(payPeriodId) {
  const resp = axios.get(`/approval/count?payPeriod=${payPeriodId}`);
  return resp;
}

function loadPendingApprovalsCountSuccess(pendingApprovalsCount) {
  return { type: GET_PENDING_APPROVALS_COUNT_SUCCESS, pendingApprovalsCount };
}

export default function loadPendingApprovalsCount(payPeriodId) {
  return (dispatch) => {
    dispatch(apiStartLoader("PendingApprovalsCount"));
    return getPendingApprovalsCount(payPeriodId)
      .then((obj) => {
        if (obj.status === 200) {
          dispatch(loadPendingApprovalsCountSuccess(obj.data));
          dispatch(apiStopLoader("PendingApprovalsCount"));
        }
      })
      .catch((err) => {
        apiFailed(err);
        dispatch(apiStopLoader("PendingApprovalsCount"));
      });
  };
}
