import { GET_EMPLOYEES_NOT_CREW_MEMBERS } from "../actions/actionTypes";

export default function RoleEmployeesWithoutCrewReducer(state = [], action) {
  switch (action.type) {
    case GET_EMPLOYEES_NOT_CREW_MEMBERS: {
      return action.offset
        ? [...state, ...action.roleEmployeesNotCrew]
        : action.roleEmployeesNotCrew;
    }
    default:
      return state;
  }
}
