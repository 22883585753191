import axios from "../../axios-jwt";

import {
  CLEAR_FOREMAN_SEARCH_RESULTS,
  SEACRH_FOREMAN_ASSIGNTO
} from "./actionTypes";
import * as ApiStatusActions from "./ApiStatusActions";

async function searchEmployees(id, query, offset, limit, orderBy, order) {
  return axios.get(
    `roles/${id}/employees?search=${query}&offset=${offset}&limit=${limit}&orderBy=${orderBy}-${order}`
  );
}

export function searchEmployeesResults(foremanSearchResults, offset) {
  return { type: SEACRH_FOREMAN_ASSIGNTO, foremanSearchResults, offset };
}

export function clearForemanSearchResults() {
  return { type: CLEAR_FOREMAN_SEARCH_RESULTS };
}

export function searchForemanWithParam(
  id,
  queryParam,
  offset = 0,
  limit = 50,
  orderBy = "empName",
  order = "asc"
) {
  return (dispatch) => {
    dispatch(ApiStatusActions.apiStartLoader("AssignToStatus"));
    searchEmployees(id, queryParam, offset, limit, orderBy, order)
      .then((res) => {
        if (res.status === 200) {
          dispatch(searchEmployeesResults(res.data, offset));
          dispatch(ApiStatusActions.apiStopLoader("AssignToStatus"));
        }
      })
      .catch(() => dispatch(ApiStatusActions.apiStopLoader("AssignToStatus")));
  };
}
