import { orderBy } from "lodash";

import {
  CLEAR_SEARCH_RESULTS,
  SEARCH_EQUIPMENT,
  UPDATE_SEARCHED_EQUIPMENT
} from "../actions/actionTypes";

export default function EquipmentSearchReducer(state = [], action) {
  switch (action.type) {
    case SEARCH_EQUIPMENT:
      return orderBy(action.equipmentSearchResults, "name", "asc");
    case UPDATE_SEARCHED_EQUIPMENT: {
      const equipments = state.map((eq) => eq);
      const udpates = equipments.map((equipment) => {
        const udpatedEquipment = action.equipmentSearchResults.find(
          (update) => update._id === equipment._id
        );
        return udpatedEquipment
          ? { ...equipment, assignedTo: udpatedEquipment.assignedTo }
          : equipment;
      });
      return udpates;
    }
    case CLEAR_SEARCH_RESULTS: {
      // Clear search results from state so that upon searching the next time, previous results won't show up
      if (
        action.actionType === "equipmentSearchResults" &&
        action.clear &&
        state.length
      )
        return [];
      else return state;
    }
    default:
      return state;
  }
}
