import {
  LOAD_EMPLOYEES_REPORTS_PREVIEW,
  LOAD_EQUIPMENTS_REPORTS_PREVIEW
} from "../actions/actionTypes";

export default function AuthorisersReportsPreviewReducer(state = [], action) {
  switch (action.type) {
    case LOAD_EMPLOYEES_REPORTS_PREVIEW:
      return action.offset
        ? [...state, ...action.employeeReports]
        : action.employeeReports;
    case LOAD_EQUIPMENTS_REPORTS_PREVIEW:
      return action.offset
        ? [...state, ...action.equipmentReports]
        : action.equipmentReports;
    default:
      return state;
  }
}
