import axios from "../../axios-jwt";

import {
  FILTERED_EQUIMENT_REPORT,
  LOAD_BULK_EQUIPMENT_REPORTS,
  LOAD_EQUIPMENT_REPORT_BY_ID,
  LOAD_EQUIPMENT_REPORTS,
  SORT_EQUIPMENT_REPORTS
} from "./actionTypes";
import * as ApiStatusActions from "./ApiStatusActions";

async function getEquipmentReports(query, offset, limit) {
  const resp = axios.get(
    `equipmentreports?${query}&offset=${offset}&limit=${limit}`
  );
  return resp;
}

async function getEquipmentReportById(payPeriodId, equipmentId) {
  const resp = axios.get(
    `equipmentreports/?payperiod=${payPeriodId}&equipments=${equipmentId}`
  );
  return resp;
}

async function getFilteredEquipmentReports(payload, offset, limit) {
  const resp = axios.get(
    `equipmentreports/?${payload}&offset=${offset}&limit=${limit}`
  );
  return resp;
}

export function loadEquipmentReportsSuccess(equipmentReports, offset) {
  return { type: LOAD_EQUIPMENT_REPORTS, equipmentReports, offset };
}

export function loadEquipmentReportByIdSuccess(equipmentReport) {
  return { type: LOAD_EQUIPMENT_REPORT_BY_ID, equipmentReport };
}

export function loadBulkEquipmentReportsSuccess(equipmentReports) {
  return { type: LOAD_BULK_EQUIPMENT_REPORTS, equipmentReports };
}

export function loadSortedEquipmentReportsSuccess(equipmentReports, offset) {
  return { type: SORT_EQUIPMENT_REPORTS, equipmentReports, offset };
}

export function loadFilteredEquipmentReportsSuccess(equipmentReports, offset) {
  return { type: FILTERED_EQUIMENT_REPORT, equipmentReports, offset };
}

export function loadEquipmentReports(loadParams, offset, limit) {
  return (dispatch) => {
    dispatch(ApiStatusActions.apiStartLoader("equipmentReports"));
    getEquipmentReports(loadParams, offset, limit)
      .then((res) => {
        if (res.status === 200) {
          dispatch(loadEquipmentReportsSuccess(res.data, offset));
          dispatch(ApiStatusActions.apiStopLoader("equipmentReports"));
        }
        return undefined;
      })
      .catch((error) => {
        console.log(dispatch(ApiStatusActions.apiFailed(error)));
        dispatch(ApiStatusActions.apiStopLoader("equipmentReports"));
      });
  };
}

export function loadEquipmentReportById(payPeriodId, equipmentId) {
  return (dispatch) => {
    dispatch(ApiStatusActions.apiStartLoader("equipmentReports"));
    getEquipmentReportById(payPeriodId, equipmentId)
      .then((res) => {
        if (res.status === 200) {
          dispatch(loadEquipmentReportByIdSuccess(res.data));
          dispatch(ApiStatusActions.apiStopLoader("equipmentReports"));
        }
        return undefined;
      })
      .catch((error) => {
        console.log(dispatch(ApiStatusActions.apiFailed(error)));
        dispatch(ApiStatusActions.apiStopLoader("equipmentReports"));
      });
  };
}

export function loadBulkWeeklyReports(payPeriodId, equipmentIds) {
  return (dispatch) => {
    dispatch(ApiStatusActions.apiStartLoader("equipmentReports"));
    axios
      .get(
        `equipmentreports?payperiod=${payPeriodId}&equipments=${equipmentIds}`
      )
      .then((res) => {
        if (res.status === 200) {
          dispatch(loadBulkEquipmentReportsSuccess(res.data));
          dispatch(ApiStatusActions.apiStopLoader("equipmentReports"));
        }
        return undefined;
      })
      .catch((error) => {
        console.log(dispatch(ApiStatusActions.apiFailed(error)));
        dispatch(ApiStatusActions.apiStopLoader("equipmentReports"));
      });
  };
}

export function sortEquipmentReports(payPeriodId, sortParams, offset, limit) {
  return (dispatch) => {
    dispatch(ApiStatusActions.apiStartLoader("equipmentReports"));
    getFilteredEquipmentReports(sortParams, offset, limit)
      .then((res) => {
        if (res.status === 200) {
          dispatch(loadSortedEquipmentReportsSuccess(res.data));
          dispatch(ApiStatusActions.apiStopLoader("equipmentReports"));
        }
        return undefined;
      })
      .catch((error) => {
        console.log(dispatch(ApiStatusActions.apiFailed(error)));
        dispatch(ApiStatusActions.apiStopLoader("equipmentReports"));
      });
  };
}

export function filteredEquipmentReports(payload, offset, limit) {
  return (dispatch) => {
    dispatch(ApiStatusActions.apiStartLoader("equipmentReports"));
    getFilteredEquipmentReports(payload, offset, limit)
      .then((res) => {
        if (res.status === 200) {
          dispatch(loadFilteredEquipmentReportsSuccess(res.data, offset));
          dispatch(ApiStatusActions.apiStopLoader("equipmentReports"));
        }
        return undefined;
      })
      .catch((error) => {
        console.log(dispatch(ApiStatusActions.apiFailed(error)));
        dispatch(ApiStatusActions.apiStopLoader("equipmentReports"));
      });
  };
}
