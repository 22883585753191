import {
  LOAD_ROLES_SUCCESS,
  UPDATE_PERTICULAR_ROLE_SUCCESS
} from "../actions/actionTypes";

export default function RolesReducer(state = [], action) {
  switch (action.type) {
    case LOAD_ROLES_SUCCESS: {
      const { roles } = action;
      return [...roles].sort((a, b) => a.roleLevel - b.roleLevel);
    }
    case UPDATE_PERTICULAR_ROLE_SUCCESS: {
      const idx = state.map((r) => r._id === action.role._id).indexOf(true);
      const copyRoles = state.map((r) => r);
      if (idx >= 0) copyRoles[idx] = action.role;
      return copyRoles;
    }
    default:
      return state;
  }
}
