import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import {
  cloneDeep,
  differenceWith,
  findIndex,
  isEqual,
  mapKeys,
  omit,
  orderBy,
  pick,
  range,
  uniqBy
} from "lodash";
import moment from "moment";
import PropTypes from "prop-types";

import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import Zoom from "@material-ui/core/Zoom";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

import useInfiniteScroll from "../../custom-hooks/useInfiniteScroll";
import { customFailedMessage } from "../../redux/actions/ApiStatusActions";
import * as EquipmentLogsActions from "../../redux/actions/EquipmentLogsActions";
import * as userJobsSearchActions from "../../redux/actions/UserJobsSearchActions";
import tableStyles from "../../styles/components/DetailsTableStyles";
import { DATE_FORMAT, INPUT_DATE_FORMAT } from "../../utillities/DateFormats";
import ConfirmationDialogComponent from "../UI/ConfirmationDialogComponent";
import DropdownSearchComponent from "../UI/DropdownSearchComponent";
import HoverTooltip from "../UI/HoverTooltip";

const EquipmentEntryDetailsComponent = (props) => {
  const classes = tableStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { payPeriods, apiStatus, userJobsSearchResults } = useSelector(
    (state) => state
  );
  const {
    equipment,
    userJobs,
    payPeriodId,
    equipmentLogsForWeek,
    jobsInWeek,
    OULevelNamesForEquipmentLogs,
    costTypes,
    handleCollapse,
    rowIndex,
    jobOffset,
    setJobOffset,
    permissionDisableEquipmentEntry,
    isPayPeriodLocked
  } = props;
  const [rows, setRows] = useState(equipmentLogsForWeek);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedEntries, setSelectedEntries] = useState({});
  const [currentIndex, setCurrentIndex] = useState(0);
  const [focus, setFocus] = useState(false); // Bring focus to last row's date field after clicking on New Entry button
  const [confirmDialogShow, setConfirmDialogShow] = useState(false); // Dialog open/close state
  const [scrollTop, setScrollTop] = useState(0);
  const [scrollHeight, setScrollHeight] = useState(0);
  const [clientHeight, setClientHeight] = useState(0);
  const [selectedSearchJobs, setSelectedSearchJobs] = useState([]); // When a searched job is selected including it in jobs list
  const [search, setSearch] = useState("");
  const dropdownLimit = 20;
  const handleClose = () => setConfirmDialogShow(false); // Dialog close
  const updateEquipmentEntries = (type, updatedPayload, pID, eID) =>
    dispatch(
      EquipmentLogsActions.updateEquipmentLogs(type, updatedPayload, pID, eID)
    );

  // Get required properties from equipmentLog object
  const getRequiredFields = (obj, ...fieldsToBeOmitted) => {
    return cloneDeep(
      omit(
        pick(obj, [
          "_id",
          "equipmentId",
          "date",
          "jobCode",
          "OULevelValues",
          "costType",
          "operatedTime",
          "idleTime",
          "storeTime",
          "isDeleted",
          "uploaded",
          "hasAccess"
        ]),
        ...fieldsToBeOmitted
      )
    );
  };

  const [equipmentLogs, setEquipmentLogs] = useState(
    equipmentLogsForWeek.reduce(
      (prev, row) => ({
        ...prev,
        [row._id]: getRequiredFields(row)
      }),
      {}
    )
  );

  const loading =
    (apiStatus || {}).isLoading &&
    apiStatus.reducer.find((reducerText) => reducerText === "equipmentReports");
  // Custom hook for infinite scroll on Job dropdown
  const [isFetching, toggle] = useInfiniteScroll(
    clientHeight,
    scrollTop,
    scrollHeight
  );

  const getScrollValue = () => {
    if (isFetching && !search.length) {
      setJobOffset(jobOffset + dropdownLimit);
      toggle(isFetching);
      setScrollTop(0);
      setScrollHeight(0);
    }
  };

  useEffect(getScrollValue, [isFetching]);

  // Merge userJobs and jobsInWeek
  const mergedJobs = (empJobs, searchedJobs = []) =>
    uniqBy(
      orderBy(
        [...jobsInWeek, ...empJobs, ...searchedJobs, ...selectedSearchJobs],
        (job) => +job.code,
        "asc"
      ),
      "code"
    ) || [];

  const validJobs = (empJobs) => {
    // eslint-disable-next-line no-undef

    return (
      uniqBy(
        orderBy(
          [
            //filtering  jobsInWeek with hasAccess=true
            ...jobsInWeek.filter((x) => Boolean(x.hasAccess)),
            ...empJobs,
            ...selectedSearchJobs
          ],
          (job) => +job.code,
          "asc"
        ),
        "code"
      ) || []
    );
  };
  //with hasAccess = false
  const invalidJobs = jobsInWeek.filter((x) => Boolean(!x.hasAccess));

  const [jobs, setJobs] = useState(mergedJobs(userJobs));

  useEffect(() => {
    setJobs(mergedJobs(userJobs));
    // eslint-disable-next-line
  }, [userJobs]);

  const onSearchItemSelected = () => {
    setJobs(mergedJobs(userJobs));
  };
  useEffect(onSearchItemSelected, [selectedSearchJobs]);

  const handleJobSearch = (searchTerm, id) => {
    if (searchTerm.length) {
      dispatch(userJobsSearchActions.searchUserJobsWithParam(searchTerm, id));
    }
    setSearch(searchTerm);
  };

  // Text for ConfirmationDialogComponent
  const attributes = {
    title: `${t("confirmation_text")}`,
    contentText: `${t("confirmation_msg")}`,
    cancel: `${t("commonActions.cancel")}`,
    accept: `${t("commonActions.delete")}`
  };

  // Gets called when clicked on action menu
  // Stores the selected row object for which action menu is clicked
  const menuClick = (event, index) => {
    event.preventDefault();
    setCurrentIndex(index);
    const selectedEntry = equipmentLogs[index];
    setSelectedEntries((entries) => ({ ...entries, [index]: selectedEntry }));
    setAnchorEl(event.currentTarget);
  };

  // For passing selected action menu data to parent component prop from here
  // Trigger PUT request with initial object and isDeleted = true
  const deleteEntry = (data) => {
    const format = {
      ...omit(
        data,
        "isDeleted",
        "costType",
        "loggedBy",
        "companyCode",
        "createdAt",
        "updatedAt",
        "uploaded",
        "hasAccess",
        "__v"
      ),
      isDeleted: true,
      updated: undefined
    };

    const pId = (payPeriods || [])[
      findIndex(payPeriods || [], ["_id", payPeriodId])
    ]._id;
    if ((data || {})._id)
      updateEquipmentEntries("delete", [format], pId, equipment._id);
    else {
      // Delete entries from UI which are not yet saved
      let currentRows;
      if (Object.values(equipmentLogs).length) {
        const logs = omit(equipmentLogs, currentIndex);
        const eqLogKeyUpdates = mapKeys(logs, (value, key) => {
          const idx = (str) => +str.split("_")[1];
          return key.length === 5 && idx(key) > idx(currentIndex)
            ? `new_${idx(key) - 1}`
            : key;
        });
        setEquipmentLogs(eqLogKeyUpdates);
        currentRows = Object.values(eqLogKeyUpdates); //  If fields are edited, the remove from equipmentLogs
      } else {
        currentRows = rows.filter((r, idx) => idx !== currentIndex); // If no edits, remove from rows
      }
      setRows(currentRows);
    }
  };

  // Closes the action menu pop up
  const menuClose = (event, option) => {
    if (option.key === "delete") {
      setConfirmDialogShow(true);
    }
    setAnchorEl(null);
  };

  // Default object to create new row/entry
  const newRowObjectDefaults = {
    equipmentId: equipment._id,
    costType: (costTypes[0] || {}).name || "Equipment-Internal"
  };

  // Create a new row in the UI with default values
  const handleNewEntry = (event) => {
    event.persist();
    const key = event.target.accessKey;
    if (
      (event.keyCode === 9 && event.target.id.includes("_action")) ||
      event.type === "click"
    ) {
      // Check if the last cell has Tab key event
      if (+key === rows.length - 1 || event.type === "click") {
        setFocus(true);
        setRows((r) => [...r, newRowObjectDefaults]);
        setEquipmentLogs((r) => ({
          ...r,
          [`new_${rows.length}`]: newRowObjectDefaults
        }));
      }
    }
  };

  // Trigger PUT request with udpated equipmentLogs
  const handleSave = (event) => {
    // Check if one of the three time fields exists in the entry to consider the entry as valid
    const checkTimeFields = (oTime, iTime, sTime) =>
      !![oTime, iTime, sTime].filter(Boolean).length;

    const updates = Object.values(equipmentLogs)
      .filter((currentUpdates) => currentUpdates.updated)
      .map((update) => ({
        ...update,
        hasAccess: undefined, //omiting while sending it to BE
        // One of the three time fields (Operated, Idle or Store) is mandatory, other two can be set to 0 if not added/updated from input
        ...(() => {
          let { operatedTime, idleTime, storeTime } = update;
          [operatedTime, idleTime, storeTime] = [
            operatedTime,
            idleTime,
            storeTime
          ].map(Number);
          if (checkTimeFields(operatedTime, idleTime, storeTime)) {
            return {
              operatedTime: operatedTime || 0,
              idleTime: idleTime || 0,
              storeTime: storeTime || 0
            };
          }
        })(),
        updated: undefined,
        uploaded: undefined
      }));
    if (event && updates.filter((u) => u._id).length)
      updateEquipmentEntries("edit", updates, payPeriodId, equipment._id);
    else updateEquipmentEntries("create", updates, payPeriodId, equipment._id);
  };

  // Create headers and columns for OULevelValues based on OULevelNamesForEquipmentLogs in organization details
  const OUHeaders = OULevelNamesForEquipmentLogs.map((entry, index) => ({
    OUIndex: index,
    OUId: entry.name,
    numeric: false,
    disablePadding: true,
    label: entry.label
  })).filter((header) => header);

  // Table headers
  const headers = [
    {
      id: "date",
      numeric: false,
      disablePadding: true,
      label: `${t("payroll.date_label")}`
    },
    {
      id: "jobCode",
      numeric: false,
      disablePadding: true,
      label: `${t("payroll.job_label")}`
    },
    ...OUHeaders,
    {
      id: "costType",
      numeric: false,
      disablePadding: true,
      label: `${t("equipmentManager.cost_type")}`
    },
    {
      id: "operatedTime",
      numeric: false,
      disablePadding: true,
      label: `${t("equipmentManager.operated_time")}`
    },
    {
      id: "idleTime",
      numeric: false,
      disablePadding: true,
      label: `${t("equipmentManager.idle_Time")}`
    },
    {
      id: "storeTime",
      numeric: false,
      disablePadding: true,
      label: `${t("equipmentManager.store_time")}`
    },
    {
      id: "actions",
      numeric: false,
      disablePadding: true,
      label: ""
    }
  ];

  // Row action menu options
  const actionMenuOptions = [
    { key: "delete", label: `${t("commonActions.delete")}` }
  ];

  // Create options dynamically for OULevelValues dropdowns in table based on jobCode
  const setOperationalUnitsOptions = (jobCode, i, type, hasAccess) => {
    const jobs = hasAccess === false ? invalidJobs : validJobs(userJobs);

    const code = jobCode && jobCode[i]; // Get jobCode of curent row (initial or updated)
    const jobIndex = code
      ? findIndex(jobs, (currentJob) => currentJob.code === code)
      : 0; // Find index to look for job
    const job = (jobs && jobs[jobIndex]) || {}; // Get the job from jobs with the above jobIndex
    const operationalUnits = (
      (job.operationalUnits || [])[
        findIndex(job.operationalUnits, (unit) => unit.name === type)
      ] || {}
    ).items;
    return orderBy(operationalUnits, (ou) => +ou.code, "asc"); // Return operationalUnits' items from the job
  };

  // Compare OULevelValues coming from API and show them in the table only if the logged in user has access to them.
  // Otherwise show NA
  const getOperationalUnits = (jobCode, OUId, code, hasAccess) => {
    const jobs = hasAccess === false ? invalidJobs : validJobs(userJobs);
    const jobIndex = findIndex(
      jobs,
      (currentJob) => currentJob.code === jobCode
    );
    const job = jobs && jobs[jobIndex];
    const operationalUnits =
      jobIndex !== -1 &&
      (
        ((job || {}).operationalUnits || [])[
          findIndex(job.operationalUnits, (unit) => unit.name === OUId)
        ] || {}
      ).items;

    let OU;
    if ((operationalUnits || []).length)
      [OU] = operationalUnits.filter((ou) => ou.code === code);

    return { code: (OU || {}).code, name: (OU || {}).name };
  };

  // Format number fields to accept only two digits in integer and decimal
  const numberFieldFormat = (number) => {
    const a = new Intl.NumberFormat("en", {
      style: "decimal",
      useGrouping: false,
      maximumFractionDigits: 2
    })
      .formatToParts(number)
      .map(({ type, value }) => {
        switch (type) {
          case "integer":
            // eslint-disable-next-line no-case-declarations
            const int = Math.max(0, parseInt(value));
            return int > 24 ? 24 : int;
          case "decimal":
            return value;
          case "fraction":
            return value
              ? Math.max(0, parseInt(value)).toString().slice(0, 2)
              : 0;
          default:
            return value;
        }
      })
      .reduce((num, part) => num + part);
    return a;
  };

  const checkMandatoryFields = () => {
    const updates = Object.values(equipmentLogs).filter((log) =>
      // eslint-disable-next-line no-prototype-builtins
      log.hasOwnProperty("updated")
    );
    const checkFields =
      updates.length &&
      updates.filter(
        (update) =>
          update.date &&
          update.jobCode &&
          update.costType &&
          (update.OULevelValues || {})[((OUHeaders || [])[0] || {}).OUId] &&
          (!!+update.operatedTime || !!+update.idleTime || !!+update.storeTime)
      );
    return checkFields.length === updates.length && !apiStatus.isLoading;
  };

  // Accept input from only specific keys in textField type number
  const handleKeyDown = (event, keyCode) => {
    // 8 = delete/backspace
    // 9 = tab
    // 38 & 40 = arrow up/down
    // 37 & 39 = arrow left/right
    // 190 & 110 = .
    // range(48, 58, 1) = numeric keys
    if (
      ![
        8,
        9,
        37,
        38,
        39,
        40,
        190,
        110,
        ...range(48, 58, 1),
        ...range(96, 106, 1)
      ].includes(keyCode)
    ) {
      event.preventDefault();
    }
  };

  const getStartOrEndDateOfPayPeriod = (date) => {
    if (date === "startDate")
      return moment
        .utc(
          (payPeriods || [])[findIndex(payPeriods || [], ["_id", payPeriodId])]
            .startDate
        )
        .format(INPUT_DATE_FORMAT);
    return moment
      .utc(
        (payPeriods || [])[findIndex(payPeriods || [], ["_id", payPeriodId])]
          .endDate
      )
      .format(INPUT_DATE_FORMAT);
  };

  // Format payload based on input changes
  const handleUpdates = (type, index, data) => {
    const updatedData = (type, data, log) =>
      ({
        date: { [type]: moment(data, INPUT_DATE_FORMAT).format(DATE_FORMAT) },
        jobCode: { [type]: (data || {}).code, OULevelValues: undefined },
        costType: { [type]: (data || {}).name },
        operatedTime: { [type]: data },
        idleTime: { [type]: data },
        storeTime: { [type]: data }
      }[type] || {
        OULevelValues: {
          ...log.OULevelValues,
          ...{ [type]: (data || {}).code }
        }
      });

    setEquipmentLogs((logs) => ({
      ...logs,
      [index]: {
        ...logs[index],
        ...updatedData(type, data, logs[index]),
        updated: true
      }
    }));
  };

  const isDisabled = (entry) =>
    loading ||
    permissionDisableEquipmentEntry ||
    entry.uploaded ||
    isPayPeriodLocked ||
    entry.hasAccess === false;

  const computedTitle = (entry) => {
    // Main case: checking if payPeriod is Locked
    if (isPayPeriodLocked) {
      return t("rowTooltipMessages.payperiod_is_locked");
    }
    // Other cases:

    if (entry.hasAccess === false) {
      return t("rowTooltipMessages.no_job_access");
    }

    if (entry.uploaded) {
      return t("rowTooltipMessages.entry_already_uploaded");
    }

    return "";
  };

  return (
    <div key='equipmentEntryDetails'>
      {/* Equipment Entry Details Table */}
      {payPeriods.length && payPeriodId && headers.length && (
        <Paper className={classes.paper}>
          <Table
            className={classes.table}
            size='small'
            aria-label='equipment-entry-table'
            padding='none'
          >
            <TableHead>
              <TableRow>
                {headers.map((header, index) => (
                  <TableCell
                    key={index}
                    padding={header.disablePadding ? "none" : "default"}
                    className={clsx(classes.headerCell, {
                      [classes.fixedHeader]: !header.label
                    })}
                  >
                    {header.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length ? (
                rows.map((row, logIndex) => {
                  const index = row._id || `new_${logIndex}`;
                  const equipmentLog = (equipmentLogs || {})[index] || {};

                  return (
                    <HoverTooltip
                      key={index}
                      title={computedTitle(row)}
                      TransitionComponent={Zoom}
                    >
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={index}
                        onKeyUp={handleNewEntry}
                        className={
                          loading &&
                          !differenceWith(equipmentLogsForWeek, rows, !isEqual)
                            .length
                            ? classes.blur
                            : ""
                        }
                      >
                        {headers.map(
                          (header) =>
                            ({
                              date: (
                                <TableCell
                                  className={clsx(classes.eqDate, classes.cell)}
                                  key={`date_${index}`}
                                >
                                  <TextField
                                    id={`table_${rowIndex}_row_${index}_date`}
                                    name='date'
                                    fullWidth
                                    value={moment(
                                      equipmentLog.date,
                                      DATE_FORMAT
                                    ).format(INPUT_DATE_FORMAT)}
                                    className={classes.textField}
                                    InputProps={{
                                      classes: {
                                        input: classes.textField
                                      },
                                      disableUnderline: true,
                                      autoFocus: focus, // Will be set to true once New Entry button is clicked
                                      inputProps: {
                                        type: "date",
                                        min: getStartOrEndDateOfPayPeriod(
                                          "startDate"
                                        ),
                                        max: getStartOrEndDateOfPayPeriod(
                                          "endDate"
                                        )
                                      }
                                    }}
                                    onBlur={(event) => {
                                      if (
                                        moment(event.target.value).isBefore(
                                          getStartOrEndDateOfPayPeriod(
                                            "startDate"
                                          )
                                        ) ||
                                        moment(event.target.value).isAfter(
                                          getStartOrEndDateOfPayPeriod(
                                            "endDate"
                                          )
                                        )
                                      ) {
                                        handleUpdates("date", index, null);
                                        dispatch(
                                          customFailedMessage(
                                            t("equipmentManager.date_not_valid")
                                          )
                                        );
                                      }
                                    }}
                                    onChange={(event) => {
                                      event.persist();
                                      handleUpdates(
                                        "date",
                                        index,
                                        event.target.value
                                      );
                                    }}
                                    // disabled={isDisabled(row)}
                                  />
                                </TableCell>
                              ),
                              jobCode: (
                                <TableCell
                                  className={classes.cell}
                                  key={`jobCode_${index}`}
                                  onScroll={(event) => {
                                    event.persist();
                                    setClientHeight(event.target.clientHeight);
                                    setScrollTop(event.target.scrollTop);
                                    setScrollHeight(event.target.scrollHeight);
                                  }}
                                >
                                  {jobs.length && (
                                    <DropdownSearchComponent
                                      width={"100%"}
                                      options={
                                        isDisabled(row)
                                          ? invalidJobs
                                          : validJobs(userJobs)
                                      }
                                      searchOptions={
                                        search && search.length
                                          ? userJobsSearchResults
                                          : []
                                      }
                                      value={
                                        (jobs || [])[
                                          findIndex(
                                            jobs || [],
                                            (option) =>
                                              option.code ===
                                              equipmentLog.jobCode
                                          )
                                        ]
                                      }
                                      id={`table_${rowIndex}_row_${index}_userJobs`}
                                      handleChange={(change) => {
                                        if (
                                          change &&
                                          change.code &&
                                          !selectedSearchJobs.find(
                                            (job) => job.code === change.code
                                          )
                                        ) {
                                          setSelectedSearchJobs([
                                            ...selectedSearchJobs,
                                            change
                                          ]);
                                        }
                                        setSearch("");
                                        handleUpdates("jobCode", index, change);
                                        userJobsSearchActions.clearUserJobsResults(
                                          "userJobsSearchResults"
                                        );
                                      }}
                                      //   disabled={isDisabled(row)}
                                      handleJobSearch={handleJobSearch}
                                      onDropDownChange={() => setSearch("")}
                                    />
                                  )}
                                </TableCell>
                              ),
                              operatedTime: (
                                <TableCell
                                  className={clsx(classes.eqTime, classes.cell)}
                                  key={`operatedTime_${index}`}
                                >
                                  <TextField
                                    placeholder='00.00'
                                    fullWidth
                                    id={`table_${rowIndex}_row_${index}_operatedTime`}
                                    value={(
                                      equipmentLog.operatedTime || 0
                                    ).toLocaleString(undefined, {
                                      minimumFractionDigits: 2
                                    })}
                                    InputProps={{
                                      className: classes.textField,
                                      disableUnderline: true,
                                      inputProps: {
                                        type: "number",
                                        step: 0.25,
                                        min: 0,
                                        max: 24
                                      }
                                    }}
                                    onKeyDown={(event) => {
                                      const { keyCode } = event;
                                      handleKeyDown(event, keyCode);
                                    }}
                                    onInput={(event) => {
                                      event.target.value = numberFieldFormat(
                                        event.target.value
                                      );
                                    }}
                                    onChange={(event) => {
                                      event.persist();
                                      handleUpdates(
                                        "operatedTime",
                                        index,
                                        event.target.value
                                      );
                                    }}
                                    // disabled={isDisabled(row)}
                                  />
                                </TableCell>
                              ),
                              idleTime: (
                                <TableCell
                                  className={clsx(classes.eqTime, classes.cell)}
                                  key={`idleTime_${index}`}
                                >
                                  <TextField
                                    placeholder='00.00'
                                    fullWidth
                                    id={`table_${rowIndex}_row_${index}_idleTime`}
                                    value={(
                                      equipmentLog.idleTime || 0
                                    ).toLocaleString(undefined, {
                                      minimumFractionDigits: 2
                                    })}
                                    InputProps={{
                                      className: classes.textField,
                                      disableUnderline: true,
                                      inputProps: {
                                        type: "number",
                                        step: 0.25,
                                        min: 0,
                                        max: 24
                                      }
                                    }}
                                    onKeyDown={(event) => {
                                      const { keyCode } = event;
                                      handleKeyDown(event, keyCode);
                                    }}
                                    onInput={(event) => {
                                      event.target.value = numberFieldFormat(
                                        event.target.value
                                      );
                                    }}
                                    onChange={(event) => {
                                      event.persist();
                                      handleUpdates(
                                        "idleTime",
                                        index,
                                        event.target.value
                                      );
                                    }}
                                    // disabled={isDisabled(row)}
                                  />
                                </TableCell>
                              ),
                              storeTime: (
                                <TableCell
                                  className={clsx(
                                    classes.eqStoredTime,
                                    classes.cell
                                  )}
                                  key={`storeTime_${index}`}
                                >
                                  <TextField
                                    placeholder='00.00'
                                    fullWidth
                                    id={`table_${rowIndex}_row_${index}_storeTime`}
                                    value={(
                                      equipmentLog.storeTime || 0
                                    ).toLocaleString(undefined, {
                                      minimumFractionDigits: 2
                                    })}
                                    InputProps={{
                                      className: classes.textField,
                                      disableUnderline: true,
                                      inputProps: {
                                        type: "number",
                                        step: 0.25,
                                        min: 0,
                                        max: 24
                                      }
                                    }}
                                    onKeyDown={(event) => {
                                      const { keyCode } = event;
                                      handleKeyDown(event, keyCode);
                                    }}
                                    onInput={(event) => {
                                      event.target.value = numberFieldFormat(
                                        event.target.value
                                      );
                                    }}
                                    onChange={(event) => {
                                      event.persist();
                                      handleUpdates(
                                        "storeTime",
                                        index,
                                        event.target.value
                                      );
                                    }}
                                    // disabled={isDisabled(row)}
                                  />
                                </TableCell>
                              ),
                              costType: (
                                <TableCell
                                  className={clsx(
                                    classes.eqCostType,
                                    classes.cell
                                  )}
                                  key={`costType_${index}`}
                                >
                                  <DropdownSearchComponent
                                    options={costTypes}
                                    defaultValue={{
                                      code: "",
                                      name: row.costType
                                    }}
                                    value={{
                                      code: "",
                                      name: equipmentLog.costType
                                    }}
                                    id={`table_${rowIndex}_row_${index}_costType`}
                                    width={"100%"}
                                    handleChange={(change) => {
                                      handleUpdates("costType", index, change);
                                    }}
                                    // disabled={isDisabled(row)}
                                  />
                                </TableCell>
                              ),
                              actions: (
                                <TableCell
                                  className={clsx(
                                    classes.menu,
                                    classes.fixedColumn
                                  )}
                                  key={`actions_${index}`}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                  }}
                                >
                                  {/* Row actions */}
                                  {/* eslint-disable-next-line */}
                                  <Button
                                    id={`table_${rowIndex}_row_${index}_action`}
                                    aria-controls='actions-control'
                                    aria-haspopup='true'
                                    className={classes.menuButton}
                                    onClick={(e) => {
                                      menuClick(e, index);
                                    }}
                                    aria-label={index}
                                    accessKey={logIndex}
                                    disabled={isDisabled(row)}
                                  >
                                    <MoreHorizIcon />
                                  </Button>
                                </TableCell>
                              )
                            }[header.id] || (
                              <TableCell
                                className={classes.cell}
                                key={header.OUId}
                              >
                                {/* Dropdown/s for OULevelValues */}
                                {jobs.length && (
                                  <DropdownSearchComponent
                                    id={`table_${rowIndex}_row_${index}_${header.OUId}_${header.OUIndex}`}
                                    width={"100%"}
                                    options={setOperationalUnitsOptions(
                                      { [index]: equipmentLog.jobCode },
                                      index,
                                      header.OUId,
                                      row.hasAccess
                                    )}
                                    value={getOperationalUnits(
                                      equipmentLog.jobCode,
                                      header.OUId,
                                      (equipmentLog.OULevelValues || {})[
                                        header.OUId
                                      ],
                                      row.hasAccess
                                    )}
                                    handleChange={(change) => {
                                      handleUpdates(header.OUId, index, change);
                                    }}
                                    // disabled={isDisabled(row)}
                                  />
                                )}
                              </TableCell>
                            ))
                        )}
                      </TableRow>
                    </HoverTooltip>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={headers.length + 1}
                    className={classes.emptyCell}
                  >
                    {t("payroll.make_an_entry")}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            <caption>
              <div className={classes.caption}>
                <div className={classes.captionLeft}>
                  <Button
                    variant='contained'
                    color='secondary'
                    size='small'
                    className={clsx(classes.button, classes.newEntryButton)}
                    onClick={handleNewEntry}
                    disabled={
                      loading ||
                      permissionDisableEquipmentEntry ||
                      isPayPeriodLocked
                    }
                  >
                    {t("commonActions.new_entry")}
                  </Button>
                </div>
                <div className={classes.captionRight}>
                  <Button
                    variant='outlined'
                    color='secondary'
                    size='small'
                    className={classes.button}
                    onClick={() => {
                      handleCollapse();
                    }}
                  >
                    {t("commonActions.cancel")}
                  </Button>
                  <Button
                    variant='contained'
                    color='secondary'
                    size='small'
                    className={classes.button}
                    onClick={(event) => {
                      handleSave(event);
                    }}
                    disabled={!checkMandatoryFields()}
                  >
                    {t("commonActions.save")}
                  </Button>
                </div>
              </div>
            </caption>
          </Table>
        </Paper>
      )}

      {/* Action menu for rows */}
      <Menu
        id='actions-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={menuClose}
      >
        {actionMenuOptions.map((option) => (
          <MenuItem
            className={classes.menuItems}
            onClick={(e) => {
              menuClose(e, option, selectedEntries[currentIndex]);
            }}
            key={option.key}
            disabled={
              loading || permissionDisableEquipmentEntry || isPayPeriodLocked
            }
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>

      {/* Dialog for delete equipment entry action */}
      <ConfirmationDialogComponent
        open={confirmDialogShow}
        close={() => handleClose()}
        selectedData={selectedEntries[currentIndex]}
        dialogAttributes={attributes}
        handleDialog={deleteEntry}
      />
    </div>
  );
};

EquipmentEntryDetailsComponent.propTypes = {
  equipment: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string
  }).isRequired,
  userJobs: PropTypes.arrayOf(
    PropTypes.shape({
      operationalUnits: PropTypes.arrayOf(PropTypes.shape()).isRequired,
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired
    })
  ).isRequired,
  payPeriodId: PropTypes.string.isRequired,
  equipmentLogsForWeek: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      equipmentId: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      jobCode: PropTypes.string,
      OULevelValues: PropTypes.shape(),
      costType: PropTypes.string,
      operatedTime: PropTypes.number,
      idleTime: PropTypes.number,
      isDeleted: PropTypes.bool
    }).isRequired
  ).isRequired,
  jobsInWeek: PropTypes.arrayOf(
    PropTypes.shape({
      operationalUnits: PropTypes.arrayOf(PropTypes.shape()).isRequired,
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired
    })
  ).isRequired,
  OULevelNamesForEquipmentLogs: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  costTypes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  handleCollapse: PropTypes.func.isRequired,
  rowIndex: PropTypes.number.isRequired,
  jobOffset: PropTypes.number.isRequired,
  setJobOffset: PropTypes.func.isRequired,
  permissionDisableEquipmentEntry: PropTypes.bool.isRequired,
  isPayPeriodLocked: PropTypes.bool.isRequired
};

export default EquipmentEntryDetailsComponent;
