import axios from "../../axios-jwt";

import {
  GET_ASSIGNED_EMPLOYEES,
  GET_PERSONNEL_SUCCESS,
  UPDATE_PERSONNEL_SUCCESS
} from "./actionTypes";
import { apiFailed, apiStartLoader, apiStopLoader } from "./ApiStatusActions";

async function getPersonnel(id) {
  const resp = axios.get(`/employees/${id}/personnel`);
  return resp;
}

export async function getAssignedEmployees(offset, limit) {
  const resp = axios.get(
    `/employees?personnelAssigned=true&orderBy=empID-asc&offset=${offset}&limit=${limit}`
  );
  return resp;
}

export function loadPersonnelSuccess(personnel) {
  return { type: GET_PERSONNEL_SUCCESS, personnel };
}

export function loadPersonnel(id) {
  return (dispatch) => {
    dispatch(apiStartLoader("loadPersonnel"));
    return getPersonnel(id)
      .then((obj) => {
        if (obj.status === 200) {
          dispatch(loadPersonnelSuccess(obj.data));
          dispatch(apiStopLoader("loadPersonnel"));
        }
      })
      .catch((err) => {
        dispatch(apiStopLoader("loadPersonnel"));
        dispatch(apiFailed(err));
      });
  };
}

// update personnel while unassigning crew members
function loadUpdatePersonnel(id, unassigned) {
  const data = unassigned;
  const resp = axios({
    method: "DELETE",
    url: `/employees/${id}/personnel?_ids=${data.join()}`
  });
  return resp;
}

export function updatePersonnelSuccess(unassigned) {
  return { type: UPDATE_PERSONNEL_SUCCESS, unassigned };
}

export function updatePersonnel(id, unassigned) {
  return (dispatch) => {
    return loadUpdatePersonnel(id, unassigned).then((resp) => {
      if (resp.status === 200) {
        dispatch(updatePersonnelSuccess(unassigned));
      }
    });
  };
}

export function loadAssignedEmpPersonnelSuccess(assignedEmployees, offset) {
  return { type: GET_ASSIGNED_EMPLOYEES, assignedEmployees, offset };
}

export function assignedEmployeePersonnel(offset, limit) {
  return (dispatch) => {
    dispatch(apiStartLoader("personnel"));
    return getAssignedEmployees(offset, limit)
      .then((obj) => {
        if (obj.status === 200) {
          dispatch(loadAssignedEmpPersonnelSuccess(obj.data.employees, offset));
          dispatch(apiStopLoader("personnel"));
        }
      })
      .catch((err) => {
        if (err) {
          dispatch(apiFailed(err));
          dispatch(apiStopLoader("personnel"));
        }
      });
  };
}
