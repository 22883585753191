import {
  CLEAR_EQUIPMENT_INVALID_ENTRIES,
  EQUIPMENT_INVALID_ENTRIES
} from "../actions/actionTypes";

export default function EquipmentsInvalidEntries(state = [], action) {
  switch (action.type) {
    case EQUIPMENT_INVALID_ENTRIES: {
      return action.equipmentInvalidEntries;
    }
    case CLEAR_EQUIPMENT_INVALID_ENTRIES: {
      return [];
    }
    default:
      return state;
  }
}
