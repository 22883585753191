import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-xhr-backend";

// not like to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init
import EN from "./locale/en.json";
import ES from "./locale/es.json";

// the translations
const resources = {
  en: {
    translation: EN
  },
  es: {
    translation: ES
  }
};

i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    lng: "en",
    fallbackLng: "en",
    debug: false,
    react: {
      useSuspense: false //   <---- this will do the magic (A React component suspended while rendering, but no fallback UI was specified)
    },
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    }
  });

export default i18n;
