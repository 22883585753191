import { findIndex } from "lodash";

import {
  FILTERED_EQUIMENT_REPORT,
  LOAD_BULK_EQUIPMENT_REPORTS,
  LOAD_EQUIPMENT_REPORT_BY_ID,
  LOAD_EQUIPMENT_REPORTS,
  SORT_EQUIPMENT_REPORTS
} from "../actions/actionTypes";

export default function EquipmentReportsReducer(state = [], action) {
  switch (action.type) {
    case LOAD_EQUIPMENT_REPORTS:
      return action.offset
        ? [...state, ...action.equipmentReports]
        : action.equipmentReports;
    case LOAD_EQUIPMENT_REPORT_BY_ID: {
      const reports = state.map((r) => r);
      const currentReport = action.equipmentReport[0];
      const index = findIndex(
        reports,
        (data) => data.equipment._id === currentReport.equipment._id
      );

      if (index !== -1) {
        return [
          ...reports.slice(0, index),
          currentReport,
          ...reports.slice(index + 1)
        ];
      }
      return reports;
    }
    case LOAD_BULK_EQUIPMENT_REPORTS: {
      const reports = state.map((eq) => eq);
      const udpatedReports = reports.map((report) => {
        const udpatedReport = action.equipmentReports.find(
          (update) => update.equipment._id === report.equipment._id
        );
        return udpatedReport ? { ...report, ...udpatedReport } : report;
      });
      return udpatedReports;
    }
    case FILTERED_EQUIMENT_REPORT:
      return action.offset
        ? [...state, ...action.equipmentReports]
        : action.equipmentReports;
    case SORT_EQUIPMENT_REPORTS:
      return action.offset
        ? [...state, ...action.equipmentReports]
        : action.equipmentReports;
    default:
      return state;
  }
}
