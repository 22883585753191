import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Popover from "@material-ui/core/Popover";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ArrowBack from "@material-ui/icons/ArrowBack";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import ConditionalDateRangePicker from "./ConditionalDateRangePicker";
import CustomPayPeriodPicker from "./CustomPayPeriodPicker";

const useStyles = makeStyles(() => ({
  periodValue: {
    fontSize: "13px",
    cursor: "pointer",
    margin: "0 auto 12px auto",
    width: "360px"
  },
  subMenuContainer: {
    minWidth: "244px"
  },
  subMenuTitle: {
    fontWeight: 500,
    flexGrow: 1
  },
  arrowBack: { position: "absolute" }
}));

const SubMenuChild = ({ onBackClick, title, children }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <>
      <Box p={1.5} className={classes.subMenuContainer}>
        <Box display='flex' alignItems='center' my={1}>
          <IconButton
            aria-label='back'
            className={classes.arrowBack}
            onClick={onBackClick}
            size='small'
          >
            <ArrowBack />
          </IconButton>

          <Typography align='center' className={classes.subMenuTitle}>
            {`${t("payroll.select")} ${title}`}
          </Typography>
        </Box>
        {children}
      </Box>
    </>
  );
};

SubMenuChild.propTypes = {
  title: PropTypes.string.isRequired,
  onBackClick: PropTypes.func.isRequired,
  children: PropTypes.node
};

const CustomPeriodSelector = ({
  currentPayPeriodIndex,
  payPeriodsOptions,
  payPeriodOptionsScroll,
  periodValue,
  setPeriodValue
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [localPayPeriodIndex, setLocalPayPeriodIndex] = useState(
    null || currentPayPeriodIndex
  );

  const [staticAnchorEl, setStaticAnchorEl] = useState(undefined);
  const [anchorEl, setAnchorEl] = useState(undefined);
  const [subMenuAnchorEl, setSubMenuAnchorEl] = useState(undefined);
  const [currentMainOption, setCurrentMainOption] = useState({
    name: t("payroll.pay_period"),
    value: "pay-period"
  });

  useEffect(() => {
    if (subMenuAnchorEl) {
      handleCloseSubMenu();
    }
    // eslint-disable-next-line
  }, [periodValue]);

  const handleMenu = (event) => {
    setStaticAnchorEl(event.currentTarget);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMainMenu = () => {
    setAnchorEl(null);
  };

  const handleCloseSubMenu = () => {
    setSubMenuAnchorEl(null);
  };

  const handleOnClick = (event, value) => {
    setCurrentMainOption(value);
    handleCloseMainMenu();
    setSubMenuAnchorEl(event.currentTarget);
  };

  const handleBackClick = () => {
    handleCloseSubMenu();
    setAnchorEl(staticAnchorEl);
  };
  const mainOptions = [
    { name: t("payroll.pay_period"), value: "pay-period" },
    { name: t("payroll.custom"), value: "custom" }
  ];

  const getContent = (optionvalue) => {
    switch (optionvalue) {
      case "pay-period":
        return (
          <CustomPayPeriodPicker
            payPeriodsOptions={payPeriodsOptions}
            payPeriodOptionsScroll={payPeriodOptionsScroll}
            localPayPeriodIndex={localPayPeriodIndex}
            setPeriodValue={setPeriodValue}
            setLocalPayPeriodIndex={setLocalPayPeriodIndex}
          />
        );
      case "custom":
        return (
          <ConditionalDateRangePicker
            periodValue={periodValue}
            setPeriodValue={setPeriodValue}
            setLocalPayPeriodIndex={setLocalPayPeriodIndex}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Typography
        aria-label='period-selector'
        aria-controls='menu-period-selector'
        aria-haspopup='true'
        onClick={handleMenu}
        className={classes.periodValue}
      >
        {periodValue.label}
        <ArrowDropDownIcon />
      </Typography>
      <Menu
        id='menu-period-selector'
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom"
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMainMenu}
        getContentAnchorEl={undefined}
      >
        {mainOptions.map((opt) => (
          <MenuItem key={opt.name} onClick={(e) => handleOnClick(e, opt)}>
            {opt.name}
          </MenuItem>
        ))}
      </Menu>
      <Popover
        anchorEl={staticAnchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        open={Boolean(subMenuAnchorEl)}
        onClose={handleCloseSubMenu}
      >
        <SubMenuChild
          onBackClick={handleBackClick}
          title={currentMainOption.name}
        >
          {getContent(currentMainOption.value)}
        </SubMenuChild>
      </Popover>
    </>
  );
};

CustomPeriodSelector.propTypes = {
  payPeriodsOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  payPeriodOptionsScroll: PropTypes.func,
  periodValue: PropTypes.shape({
    startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
    endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
    isPayPeriod: PropTypes.bool,
    label: PropTypes.string
  }),
  setPeriodValue: PropTypes.func,
  currentPayPeriodIndex: PropTypes.number
};

export default CustomPeriodSelector;
