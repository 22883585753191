import {
  OVERRIDE_AUTHORISERS_FILTER,
  RESET_AUTHORISERS_FILTER
} from "./actionTypes";

// Define action creators
export const overrideFilter = (filterOverRideState) => ({
  type: OVERRIDE_AUTHORISERS_FILTER,
  filterOverRideState
});

export const resetFilter = () => ({
  type: RESET_AUTHORISERS_FILTER
});
