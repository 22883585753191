/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import { makeStyles } from "@material-ui/core/styles";

import * as EmployeeActions from "../../../redux/actions/EmployeeActions";
import * as EmployeeSearchActions from "../../../redux/actions/EmployeeSearchActions";
import * as RoleEmployeesActions from "../../../redux/actions/RoleEmployeesActions";
import * as RoleEmployeesWithoutCrewActions from "../../../redux/actions/RoleEmployeesWithoutCrewActions";
import * as RoleSearchActions from "../../../redux/actions/RoleSearchActions";
import DropdownSearchComponent from "../../UI/DropdownSearchComponent";
import SearchAutocompleteComponent from "../../UI/SearchAutocompleteComponent";

import SelectEmployeesTableComponent from "./SelectEmployeesTableComponent";

const useStyles = makeStyles(() => ({
  alignCenterItems: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "10px"
  }
}));

const AssignEmployeeComponent = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const {
    roleEmployees,
    roles,
    roleSearchResults,
    apiStatus,
    roleEmployeesNoCrew,
    userDetails,
    employeeSearchResults
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  // roleName as key and roleId as its value in dropDownOptions
  // for offset roleId as key and offset as value
  const validRoles = roles.filter(
    (role) => role.roleName.length > 0 && role.roleLevel !== 10
  );
  const dropDownOptions = {
    [`${t("authorizerContainer.all_emp_label")}`]: t(
      "authorizerContainer.all_emp_label"
    )
  };
  validRoles.forEach((role) => {
    dropDownOptions[role.roleName] = role._id;
  });
  const InitialOffset = { [`${t("authorizerContainer.all_emp_label")}`]: 0 };
  validRoles.forEach((role) => {
    InitialOffset[role._id] = 0;
  });

  const [selectedDropDown, setSelectedDropDown] = useState(
    t("authorizerContainer.all_emp_label")
  ); // selected role employees or all employees in dropdown
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [employees, setEmployees] = useState([]); // tableData
  const [offset, setOffset] = useState(InitialOffset);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("empName");
  const [limit] = useState(50);
  const [search, setSearch] = useState("");
  const { open, close, selected, setSelected } = props;

  const searchEmployee = (query) => {
    setSearch(query);
  };
  const clearSearchedEmployees = () => {
    dispatch(RoleSearchActions.clearEmployees());
    dispatch(EmployeeSearchActions.clearEmployees());
  };

  // initially when dropdown changes when roles employees are not present in the list
  function initialEmployeesLoad() {
    if (
      selectedDropDown === t("authorizerContainer.all_emp_label") &&
      offset[dropDownOptions[selectedDropDown]] === 0
    ) {
      dispatch(
        RoleEmployeesWithoutCrewActions.loadEmployeesNotCrewDetails(
          0,
          limit,
          orderBy,
          order
        )
      );
    } else if (
      selectedDropDown !== t("authorizerContainer.all_emp_label") &&
      (roleEmployees[dropDownOptions[selectedDropDown]] === undefined ||
        (offset[dropDownOptions[selectedDropDown]] === 0 &&
          roleEmployees[dropDownOptions[selectedDropDown]].length >= limit))
    ) {
      dispatch(
        RoleEmployeesActions.loadRoleEmployees(
          dropDownOptions[selectedDropDown],
          0,
          limit,
          orderBy,
          order
        )
      );
    }
  }
  useEffect(initialEmployeesLoad, [selectedDropDown]);

  // if offset changes
  function onOffsetChange() {
    if (
      selectedDropDown === t("authorizerContainer.all_emp_label") &&
      roleEmployeesNoCrew.length - offset[dropDownOptions[selectedDropDown]] >=
        0 &&
      offset[dropDownOptions[selectedDropDown]] > 0
    ) {
      dispatch(
        RoleEmployeesWithoutCrewActions.loadEmployeesNotCrewDetails(
          offset[dropDownOptions[selectedDropDown]],
          limit,
          orderBy,
          order
        )
      );
    } else if (
      roleEmployees[dropDownOptions[selectedDropDown]] &&
      roleEmployees[dropDownOptions[selectedDropDown]].length -
        offset[dropDownOptions[selectedDropDown]] >=
        0
    ) {
      dispatch(
        RoleEmployeesActions.loadRoleEmployees(
          dropDownOptions[selectedDropDown],
          offset[dropDownOptions[selectedDropDown]],
          limit,
          orderBy,
          order
        )
      );
    }
  }
  useEffect(onOffsetChange, [offset]);

  // employees to display
  function onPaginationEmployeesAdd() {
    if (search.length === 0) {
      if (selectedDropDown === t("authorizerContainer.all_emp_label")) {
        setEmployees(roleEmployeesNoCrew);
      } else {
        setEmployees(roleEmployees[dropDownOptions[selectedDropDown]]);
      }
    }
  }
  useEffect(onPaginationEmployeesAdd, [
    selectedDropDown,
    roleEmployees,
    search,
    roleEmployeesNoCrew
  ]);

  function onSearch() {
    if (search.length > 0) {
      if (
        Object.keys(roleEmployees).includes(dropDownOptions[selectedDropDown])
      ) {
        dispatch(
          RoleSearchActions.searchEmployeesWithParam(
            dropDownOptions[selectedDropDown],
            search,
            0,
            limit,
            orderBy,
            order
          )
        );
      } else {
        dispatch(
          EmployeeSearchActions.searchEmployeesWithParam(
            userDetails.companyCode,
            search,
            0,
            limit,
            orderBy,
            order,
            "app_employees"
          )
        );
      }
    }
  }
  useEffect(onSearch, [search]);

  function onRoleSearchResultsChange() {
    if (search.length) {
      setEmployees(roleSearchResults);
    }
  }
  useEffect(onRoleSearchResultsChange, [roleSearchResults]);

  function onAppEmployeesSearchResultsChange() {
    if (search.length) {
      setEmployees(employeeSearchResults);
    }
  }
  useEffect(onAppEmployeesSearchResultsChange, [employeeSearchResults]);

  function handleSorting() {
    setOffset((o) => ({ ...o, [dropDownOptions[selectedDropDown]]: 0 }));
    if (
      selectedDropDown === t("authorizerContainer.all_emp_label") &&
      search.length
    )
      dispatch(
        EmployeeSearchActions.searchEmployeesWithParam(
          userDetails.companyCode,
          search,
          0,
          limit,
          orderBy,
          order,
          "app_employees"
        )
      );
    else if (
      selectedDropDown === t("authorizerContainer.all_emp_label") &&
      !search.length
    )
      dispatch(
        RoleEmployeesWithoutCrewActions.loadEmployeesNotCrewDetails(
          0,
          limit,
          orderBy,
          order
        )
      );
    else if (
      selectedDropDown !== t("authorizerContainer.all_emp_label") &&
      search.length
    )
      dispatch(
        RoleSearchActions.searchEmployeesWithParam(
          dropDownOptions[selectedDropDown],
          search,
          0,
          limit,
          orderBy,
          order
        )
      );
    else
      dispatch(
        RoleEmployeesActions.loadRoleEmployees(
          dropDownOptions[selectedDropDown],
          0,
          limit,
          orderBy,
          order
        )
      );
  }
  useEffect(handleSorting, [order]);

  const handleScroll = (trigger) => {
    if (trigger && !apiStatus.isLoading) {
      setOffset((o) => ({
        ...o,
        [dropDownOptions[selectedDropDown]]:
          offset[dropDownOptions[selectedDropDown]] + limit
      }));
    }
  };

  function handleOnSave() {
    dispatch(
      EmployeeActions.assignJobsToEmployees(selectedEmployees, selected)
    );
    setSelected({});
  }

  return (
    <>
      {validRoles.length > 0 ? (
        <Dialog
          open={open}
          aria-labelledby='max-width-dialog-title'
          fullWidth
          maxWidth='md'
        >
          {selectedEmployees.length ? (
            <DialogTitle style={{ backgroundColor: "rgb(245, 245, 245)" }}>
              {`${selectedEmployees.length} ${t("assignEmployee.selected")}`}
            </DialogTitle>
          ) : (
            <DialogTitle id='max-width-dialog-title'>
              {t("assignEmployee.title")}
            </DialogTitle>
          )}
          <DialogContent>
            <Grid container className={classes.alignCenterItems}>
              <Grid item xs={6}>
                <DialogContentText>
                  <small>{t("assignEmployee.content_text")}</small>
                </DialogContentText>
              </Grid>
              <Grid item xs={3}>
                <DropdownSearchComponent
                  options={Object.keys(dropDownOptions).map(
                    (dropDownOption) => {
                      return { code: "", name: dropDownOption };
                    }
                  )}
                  width={220}
                  value={(() => {
                    return { code: "", name: selectedDropDown };
                  })()}
                  handleChange={(change) => {
                    setSelectedDropDown(change.name);
                    searchEmployee("");
                  }}
                  variant='outlined'
                />
              </Grid>
              <Grid item xs={3}>
                <SearchAutocompleteComponent
                  handleSearch={searchEmployee}
                  searchResults={roleSearchResults}
                  showOptions={false}
                  stopLoader
                  disableClearable
                  freeSolo
                  clear={clearSearchedEmployees}
                  key={`${selectedDropDown}_search`}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs>
                {apiStatus && apiStatus.isLoading && <LinearProgress />}
                <SelectEmployeesTableComponent
                  employees={employees}
                  selected={selectedEmployees}
                  setSelected={setSelectedEmployees}
                  handleScroll={handleScroll}
                  selectedDropDown={selectedDropDown}
                  setOrder={setOrder}
                  setOrderBy={setOrderBy}
                  order={order}
                  orderBy={orderBy}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => close()} color='primary'>
              {t("commonActions.cancel")}
            </Button>
            <Button
              disabled={!!(selectedEmployees && selectedEmployees.length === 0)}
              onClick={() => {
                handleOnSave();
                close();
              }}
              color='primary'
            >
              {t("commonActions.save")}
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        <Dialog
          open={open}
          aria-labelledby='max-width-dialog-title'
          maxWidth='md'
        >
          <DialogTitle id='max-width-dialog-title'>
            {t("assignEmployee.no_role_msg")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t("assignEmployee.create_role_msg")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => close()} color='primary'>
              {t("commonActions.close")}
            </Button>
            <Button
              onClick={() => {
                close();
              }}
              color='primary'
            >
              {t("commonActions.ok")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

AssignEmployeeComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  selected: PropTypes.shape().isRequired,
  setSelected: PropTypes.func.isRequired
};
export default AssignEmployeeComponent;
