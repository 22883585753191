import { LOAD_COUNT } from "../actions/actionTypes";

export default function CountReducer(state = [], action) {
  switch (action.type) {
    case LOAD_COUNT: {
      return { ...state, [action.reducer]: action.count };
    }
    default:
      return state;
  }
}
