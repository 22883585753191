import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { flatMap, flatten, intersectionWith, mapValues, uniqBy } from "lodash";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import useInput from "../../custom-hooks/useInput";
import * as AllCrewActions from "../../redux/actions/AllCrewActions";
import * as CrewActions from "../../redux/actions/CrewActions";
import * as PersonnelActions from "../../redux/actions/PersonnelActions";
import * as PersonnelSearchActions from "../../redux/actions/PersonnelSearchActions";
import * as TimeEntryReportFilterActions from "../../redux/actions/TimeEntryReportFilterActions";
import * as UserJobsSearchActions from "../../redux/actions/UserJobsSearchActions";
import { tableStyles } from "../../styles/components/WeeklyReportsTableStyles";
import CustomSwitch from "../UI/CustomSwitch";
import DrawerComponent from "../UI/DrawerComponent";
import GroupCheckboxComponent from "../UI/GroupCheckboxComponent";
import MultiSelectComponent from "../UI/MultiSelectComponent";

import MinMaxHourFields from "./MinMaxHourFields";

export const missingInfoKeyMapHelper = {
  job: "jobCode",
  clockin: "punchIn",
  clockout: "punchOut",
  phase: "phases",
  craft: "crafts"
};

const useStyles = makeStyles(() => ({
  untrustedBox: {
    width: "360px",
    margin: "0 auto 16px auto",
    display: "flex",
    gap: "16px",
    alignItems: "center"
  }
}));

const TimeEntryReportsFiltersComponent = (props) => {
  const { t } = useTranslation();
  const classes = tableStyles();
  const localClasses = useStyles();
  const dispatch = useDispatch();
  const {
    filterDrawerState,
    toggleDrawer,
    setFiltersState,
    organizationDetails,
    userJobs,
    jobOffset,
    dropdownLimit,
    setDropdownOffset,
    handleDropdownScroll,
    currentFilter
  } = props;

  const {
    roles,
    crewMembers,
    userDetails,
    userJobsSearchResults,
    crewSearchResults,
    personnel,
    personnelSearchResults,
    selectedCompany
  } = useSelector((state) => state);

  const [drawerState, setDrawerState] = useState(filterDrawerState);
  const [searchString, setSearchString] = useState({});
  const [query, setQuery] = useState("");
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const crewMembersRef = useRef();
  const personelEmpRef = useRef();
  const roleFilter = useInput();
  const jobFilter = useInput();
  const allCrewFilter = useInput();
  const crewFilter = useInput();
  const ouFilter = useInput();
  const hoursTypeFilter = useInput();
  const crewOfFilter = useInput();
  const [showOnlyUntrustedHours, setShowOnlyUntrustedHours] = useState(false);

  const minMaxInitialState = {
    minHours: "",
    maxHours: "",
    errorMessage: ""
  };
  const [minMaxState, setMinMaxState] = useState(minMaxInitialState);

  const missingInfoInitialState = {
    job: false,
    phase: false,
    craft: false,
    clockin: false,
    clockout: false
  };
  const missingInfoFilter = useInput(missingInfoInitialState);

  const roleLevel = userDetails && userDetails.role.roleLevel;

  const searchCrew = (query) =>
    query && dispatch(CrewActions.searchCrewWithParam(query));
  const clearCrewList = (type, clear) =>
    dispatch(CrewActions.clearCrewResults(type, clear));
  const searchPersonnel = (query) =>
    query && dispatch(PersonnelSearchActions.searchPersonnelWithParam(query));
  const clearPersonnelList = (type, clear) =>
    dispatch(PersonnelSearchActions.clearPersonnelResults(type, clear));
  const searchUserJobs = (query) =>
    query && dispatch(UserJobsSearchActions.searchUserJobsWithParam(query));
  const clearUserJobsList = (type, clear) =>
    dispatch(UserJobsSearchActions.clearUserJobsResults(type, clear));

  const loadDetails = () => {
    if (
      selectedCompany.code &&
      userDetails.companyCode === selectedCompany.code
    ) {
      crewMembersRef.current = dispatch(
        AllCrewActions.loadAllCrewMembers(0, dropdownLimit)
      );
      personelEmpRef.current = dispatch(
        PersonnelActions.assignedEmployeePersonnel(0, dropdownLimit)
      );
    }
  };

  useEffect(loadDetails, [selectedCompany, userDetails]);

  const roleOptions =
    roles &&
    roles
      .filter(
        (role) => role.roleName && role.roleLevel && role.roleLevel >= roleLevel
      )
      .map((r) => ({ name: r.roleName, id: r._id }));

  // Load options in filter dropdowns based on search and pagination conditions
  const dropdownOptions = (searchString, array, searchResults) => {
    let options = [];
    if (!searchString) options = array;
    else {
      if (searchResults.length) options = searchResults;
      else if (!searchResults.length && array.length) options = [];
    }
    return options;
  };

  const jobOptions = () =>
    dropdownOptions(
      (searchString || {})["userJobs"],
      userJobs,
      userJobsSearchResults
    ).map((option) => ({
      name: option.name,
      id: option.code,
      operationalUnits: option.operationalUnits
    }));

  const crewOptions = () =>
    dropdownOptions(
      (searchString || {})["crewMembers"],
      crewMembers,
      crewSearchResults
    ).map((option) => ({
      name: option.empName,
      id: option.empID,
      _id: option._id
    }));

  const crewOfOptions = () =>
    dropdownOptions(
      (searchString || {})["personnel"],
      personnel,
      personnelSearchResults
    ).map((option) => ({
      name: option.empName,
      id: option.empID,
      _id: option._id
    }));

  const crewMembersAssigned = (data) => {
    if (!data || !data.value || !data.value.length) return;

    const options = intersectionWith(
      personnel,
      data.value,
      (per, val) => per.empID === val.id
    ).map((emp) =>
      emp.personnel.map((personnel) => ({
        name: personnel.empName,
        id: personnel.empID,
        _id: personnel._id
      }))
    );

    const config = {
      label: `${t("payroll.employee")}`,
      type: "crewMembersAssigned",
      searchType: "ui", // To Decide if search needs to be done from frontend or with API
      data: uniqBy(flatten(options), "id") || []
    };

    return config;
  };

  const hoursTypeOptions = () =>
    ((organizationDetails || {}).hoursType || []).map((type, index) => ({
      name: type,
      id: index
    }));

  const ouFilterOptions = (data) => {
    if (!data || !data.value || !data.value.length || data.value.length > 1)
      return;
    const operationalUnits = data.value[0].operationalUnits.map((unit) => ({
      label: unit.name,
      type: `operationalUnit_${unit.name}`,
      searchType: "ui", // To Decide if search needs to be done from frontend or with API
      data: flatten(unit.items).map((m) => ({ name: m.name, id: m.code }))
    }));
    return operationalUnits || [];
  };

  const dropdownConfigRoles = {
    label: `${t("payroll.roles")}`,
    type: "roles", // Label of reducer
    searchType: "ui", // To Decide if search needs to be done from frontend or with API
    data: roleOptions
  };

  const dropdownConfigJob = {
    label: `${t("payroll.job_label")}`,
    type: "userJobs", // Label of reducer
    searchType: "api", // To Decide if search needs to be done from frontend or with API
    data: jobOptions()
  };

  const dropdownConfigCrew = {
    label: `${t("payroll.employee")}`,
    type: "crewMembers", // Label of reducer
    searchType: "api", // To Decide if search needs to be done from frontend or with API
    data: crewOptions()
  };

  const dropdownConfigCrewOf = {
    label: `${t("payroll.crew_Of")}`,
    type: "personnel", // Label of reducer
    searchType: "api", // To Decide if search needs to be done from frontend or with API
    data: crewOfOptions()
  };

  const dropdownConfigHoursType = {
    label: `${t("payroll.pay_type")}`,
    type: "hourTypes",
    searchType: "ui", // To Decide if search needs to be done from frontend or with API
    data: hoursTypeOptions()
  };

  const setData = () => {
    setQuery(""); // To make sure query is not set to undefined during re-render

    if (((roleFilter || {}).value || []).length) {
      const roles = roleFilter.value.map((role) => role.id).join(",");
      setQuery((qu) => qu.concat(`&roles=${roles}`));
    }

    if (((jobFilter || {}).value || []).length) {
      const jobCode = jobFilter.value.map((job) => job.id).join(",");
      setQuery((qu) => qu.concat(`&jobCode=${jobCode}`));
    }

    if (((crewOfFilter || {}).value || []).length) {
      allCrewFilter.onChange([]);
      const crewOfIds = crewOfFilter.value.map((cOf) => cOf._id).join(",");
      setQuery((qu) => qu.concat(`&crewOf=${crewOfIds}`));

      if (((crewFilter || {}).value || []).length) {
        const crewIds = crewFilter.value.map((cr) => cr._id).join(",");
        setQuery((qu) => qu.concat(`&crew=${crewIds}`));
      }
    } else if (((allCrewFilter || {}).value || []).length) {
      const crewIds = allCrewFilter.value.map((cr) => cr._id).join(",");
      setQuery((qu) => qu.concat(`&crew=${crewIds}`));
    }

    if (((hoursTypeFilter || {}).value || []).length) {
      const hourTypes = hoursTypeFilter.value
        .map((type) => type.name)
        .join(",");
      setQuery((qu) => qu.concat(`&hoursType=${hourTypes}`));
    }

    if (showOnlyUntrustedHours) {
      setQuery((qu) =>
        qu.concat(`&untrustedEntriesOnly=${showOnlyUntrustedHours}`)
      );
    }

    if (ouFilter.value && !!Object.keys(ouFilter.value).length) {
      const ouCodes = mapValues(ouFilter.value, (unit) =>
        unit.map((ou) => ou.id).join(",")
      );
      setQuery((qu) =>
        qu.concat(
          Object.keys(ouCodes).map((ou) => {
            if (ouCodes[ou] !== "") {
              return `&${ou}=${ouCodes[ou]}`;
            } else {
              return "";
            }
          })
        )
      );
    }

    const selectedMissingInfoFilterKeys = Object.keys(
      missingInfoFilter.value
    ).filter((k) => missingInfoFilter.value[k]);

    if (missingInfoFilter.value && !!selectedMissingInfoFilterKeys.length) {
      const missing_info_values = selectedMissingInfoFilterKeys
        .map((mk) => missingInfoKeyMapHelper[mk])
        .join(",");
      setQuery((qu) => qu.concat(`&missingInfo=${missing_info_values}`));
    }

    // Min-Max hours
    if (minMaxState.minHours !== "" && minMaxState.minHours >= 0) {
      setQuery((qu) => qu.concat(`&minimumHours=${minMaxState.minHours}`));
    }
    if (minMaxState.maxHours !== "" && minMaxState.maxHours >= 0) {
      setQuery((qu) => qu.concat(`&maximumHours=${minMaxState.maxHours}`));
    }
  };

  useEffect(setData, [
    roleFilter.value.length,
    jobFilter.value.length,
    crewOfFilter.value.length,
    crewFilter.value.length,
    allCrewFilter.value.length,
    hoursTypeFilter.value.length,
    flatMap(ouFilter.value).length,
    missingInfoFilter.value,
    minMaxState,
    showOnlyUntrustedHours
  ]);

  const settingGlobalFilterState = () => {
    dispatch(
      TimeEntryReportFilterActions.overrideFilter({
        roleFilter: [...roleFilter.value],
        jobFilter: [...jobFilter.value],
        crewOfFilter: [...crewOfFilter.value],
        crewFilter: [...crewFilter.value],
        allCrewFilter: [...allCrewFilter.value],
        hoursTypeFilter: [...hoursTypeFilter.value],
        ouFilter: [...flatMap(ouFilter.value)],
        ouFilterUnflattened: JSON.parse(JSON.stringify(ouFilter.value)),
        showOnlyUntrustedHours,
        minMaxHours: {
          minHours: minMaxState.minHours,
          maxHours: minMaxState.maxHours
        }
      })
    );
  };
  const applyFilters = () => {
    setDrawerState({ right: false });
    setIsFilterApplied(true);
    settingGlobalFilterState();
    setFiltersState({ right: false }, true, query);
  };

  const clearData = () => {
    setQuery("");
    setSearchString({});
    allCrewFilter.onChange([]);
    jobFilter.onChange([]);
    ouFilter.onChange([]);
    crewFilter.onChange([]);
    crewOfFilter.onChange([]);
    hoursTypeFilter.onChange([]);
    roleFilter.onChange([]);
    setMinMaxState(minMaxInitialState);
    setShowOnlyUntrustedHours(false);
    missingInfoFilter.onChange(missingInfoInitialState);
    clearCrewList("crewSearchResults", true);
    clearPersonnelList("personnelSearchResults", true);
    clearUserJobsList("userJobsSearchResults", true);
    dispatch(TimeEntryReportFilterActions.resetFilter());
    setIsFilterApplied(false);
  };

  const resetFiltersState = () => {
    clearData();
    /* Only resetting the offset value for currentFilter so that 
      For Ex: If I scroll&fetch for Employees, and then scroll&fetch for crewof(or any other)
      and then click on reset filter then offset value of only crewof is reset to 0 and not of employees
    */
    setDropdownOffset((o) => ({
      ...o,
      [currentFilter]: currentFilter === "userJobs" ? jobOffset : 0
    }));
    setDrawerState({ right: false });
    setFiltersState({ right: false }, false, "");
  };

  const resetFiltersStateAndOffset = () => {
    clearData();
    /* Here reseting all the offset value for currentFilter to 0, 
      so that if selectedCompany.code is changed the offset values get reset 
      to 0 and are not old values.
      There is an edge case: If i go to equipment tab and come back then i dont 
      want to make jobOffset to zero but want to preserve its value and hence we are not making that 0.
      (as resetFiltersStateAndOffset will run on mount as well)
    */
    setDropdownOffset({
      userJobs: jobOffset,
      crewMembers: 0,
      personnel: 0,
      organizationDetails: 0
    });
    setDrawerState({ right: false });
    setFiltersState({ right: false }, false, "");
  };

  useEffect(resetFiltersStateAndOffset, [selectedCompany.code]);

  useEffect(() => {
    setDrawerState(filterDrawerState);
    if (!isFilterApplied) clearData();
    // eslint-disable-next-line
  }, [filterDrawerState]);

  const disableDrawerActions = () => {
    const { minHours, maxHours } = minMaxState;
    const length = (filter) => ((filter || {}).value || []).length;
    const isMinOrMaxSet = () => minHours !== "" || maxHours !== "";
    const isMinMaxErrorSet = !!minMaxState.errorMessage;
    const checkFilterOptions = () =>
      !![
        length(allCrewFilter),
        length(jobFilter),
        length(ouFilter),
        length(crewFilter),
        length(crewOfFilter),
        length(hoursTypeFilter),
        length(roleFilter),
        isMinOrMaxSet(),
        showOnlyUntrustedHours,
        ...Object.values(missingInfoFilter.value)
      ].filter(Boolean).length;
    return checkFilterOptions() && !isMinMaxErrorSet;
  };

  const drawerActions = [
    {
      display: true,
      label: `${t("payroll.apply_btn_label")}`,
      action: applyFilters,
      position: "bottom",
      disabled: disableDrawerActions()
    },
    {
      display: true,
      label: `${t("payroll.reset_btn_label")}`,
      action: resetFiltersState,
      position: "top",
      disabled: disableDrawerActions()
    }
  ];

  const handleDropdownSearch = (type, query) => {
    if (!query) setSearchString({});
    else setSearchString((s) => ({ ...s, [type]: query }));

    switch (type) {
      case "crewMembers":
        if (query && query.length) searchCrew(query);
        else clearCrewList("crewSearchResults", true);
        break;
      case "personnel":
        if (query && query.length) searchPersonnel(query);
        else clearPersonnelList("personnelSearchResults", true);
        break;
      case "userJobs":
        if (query && query.length) searchUserJobs(query);
        else clearUserJobsList("userJobsSearchResults", true);
        break;
      default:
        break;
    }
  };

  const handleDropdownClose = (type, closed) => {
    if (!closed) return;
    setSearchString((s) => ({ ...s, [type]: "" }));

    switch (type) {
      case "crewMembers":
        clearCrewList("crewSearchResults", true);
        break;
      case "personnel":
        clearPersonnelList("personnelSearchResults", true);
        break;
      case "userJobs":
        clearUserJobsList("userJobsSearchResults", true);
        break;
      default:
        break;
    }
  };

  const roleFilterDropdown = () => (
    <MultiSelectComponent
      key={dropdownConfigRoles.type}
      dropdownConfig={dropdownConfigRoles}
      {...roleFilter}
      optionLabel={(option) => option.name}
      handleScroll={handleDropdownScroll}
      handleSearch={handleDropdownSearch}
      handleClose={handleDropdownClose}
    />
  );

  const crewOfFilterDropdown = () => (
    <MultiSelectComponent
      key={dropdownConfigCrewOf.type}
      dropdownConfig={dropdownConfigCrewOf}
      {...crewOfFilter}
      optionLabel={(option) => `${option.id} - ${option.name}`}
      handleScroll={handleDropdownScroll}
      handleSearch={handleDropdownSearch}
      handleClose={handleDropdownClose}
    />
  );

  const resetCrewFilterOnChange = () => {
    if (!crewOfFilter.value.length) {
      crewFilter.onChange([]);
    } else {
      const crewOf = crewMembersAssigned(crewOfFilter);
      const options = intersectionWith(
        crewOf.data,
        crewFilter.value,
        (cOf, cr) => cOf.id === cr.id
      );
      crewFilter.onChange(options);
    }
  };

  // Re-render the Employee filter on every CrewOf change
  useEffect(resetCrewFilterOnChange, [crewOfFilter.value]);

  const crewFilterDropdown = () => {
    const config = crewMembersAssigned(crewOfFilter) || {};

    return (
      crewOfFilter && (
        <MultiSelectComponent
          key={config.type}
          dropdownConfig={config}
          {...crewFilter}
          optionLabel={(option) => `${option.id} - ${option.name}`}
          handleScroll={handleDropdownScroll}
          handleSearch={handleDropdownSearch}
          handleClose={handleDropdownClose}
        />
      )
    );
  };

  const allCrewFilterDropdown = () => (
    <MultiSelectComponent
      key={dropdownConfigCrew.type}
      dropdownConfig={dropdownConfigCrew}
      {...allCrewFilter}
      optionLabel={(option) => `${option.id} - ${option.name}`}
      handleScroll={handleDropdownScroll}
      handleSearch={handleDropdownSearch}
      handleClose={handleDropdownClose}
    />
  );

  const jobFilterDropdown = () => (
    <MultiSelectComponent
      key={dropdownConfigJob.type}
      dropdownConfig={dropdownConfigJob}
      {...jobFilter}
      optionLabel={(option) => `${option.id} - ${option.name}`}
      handleScroll={handleDropdownScroll}
      handleSearch={handleDropdownSearch}
      handleClose={handleDropdownClose}
    />
  );

  const resetOperationalUnitsOnJobChange = () => {
    if (jobFilter && jobFilter.value) {
      if (jobFilter.value.length === 1) {
        userJobs.map(
          (j) => jobFilter.value[0].code !== j.code && ouFilter.onChange([])
        );
      } else if (jobFilter.value.length > 1 || jobFilter.value.length === 0) {
        ouFilter.onChange([]);
      }
    }
  };

  // Load the Operations Units if only one job is selected in userJobFilter
  useEffect(resetOperationalUnitsOnJobChange, [jobFilter.value.length]);

  const ouFilterDropdown = () => {
    const ouOptions = ouFilterOptions(jobFilter);

    return (
      jobFilter &&
      !!(ouOptions || []).length &&
      ouOptions.map((ou) => (
        <MultiSelectComponent
          key={ou.label}
          dropdownConfig={ou}
          onChange={(val) => {
            ouFilter.onChange({ ...ouFilter.value, [ou.label]: val });
          }}
          value={ouFilter.value[ou.label] || []}
          optionLabel={(option) => `${option.id} - ${option.name}`}
          handleScroll={handleDropdownScroll}
          handleSearch={handleDropdownSearch}
          handleClose={handleDropdownClose}
        />
      ))
    );
  };

  const hoursTypeFilterDropdown = () => (
    <MultiSelectComponent
      key={dropdownConfigHoursType.type}
      dropdownConfig={dropdownConfigHoursType}
      {...hoursTypeFilter}
      optionLabel={(option) => option.name}
      handleScroll={handleDropdownScroll}
      handleSearch={handleDropdownSearch}
      handleClose={handleDropdownClose}
    />
  );

  const handleCheckBox = (event) => {
    missingInfoFilter.onChange({
      ...missingInfoFilter.value,
      [event.target.name]: event.target.checked
    });
  };

  return (
    <DrawerComponent
      key={"weekly-report-filters"}
      header={t("payroll.filters")}
      openDrawer={drawerState}
      closeDrawer={toggleDrawer("right", false)}
      onKeyDown={toggleDrawer()}
      actions={drawerActions}
      drawerPaper={classes.drawer}
      handleScroll={() => false}
      drawerAttributes={
        <>
          {jobFilterDropdown()}
          {ouFilterDropdown()}
          <GroupCheckboxComponent
            label={t("payroll.missing_info")}
            groupState={missingInfoFilter.value}
            onChange={handleCheckBox}
          />
          <MinMaxHourFields
            label={t("payroll.total_hours")}
            minHours={minMaxState.minHours}
            setMinHours={(value) =>
              setMinMaxState((prev) => ({ ...prev, minHours: value }))
            }
            maxHours={minMaxState.maxHours}
            setMaxHours={(value) =>
              setMinMaxState((prev) => ({ ...prev, maxHours: value }))
            }
            error={minMaxState.errorMessage}
            setError={(value) =>
              setMinMaxState((prev) => ({
                ...prev,
                errorMessage: value
              }))
            }
          />
          {roleFilterDropdown()}
          {crewOfFilterDropdown()}
          {(() => {
            if (((crewOfFilter || {}).value || {}).length)
              return crewFilterDropdown();
            else return allCrewFilterDropdown();
          })()}
          {hoursTypeFilterDropdown()}
          <div className={localClasses.untrustedBox}>
            {t("payroll.show_only_untrusted_hours")}
            <CustomSwitch
              checked={showOnlyUntrustedHours}
              onChange={(e) => setShowOnlyUntrustedHours(e.target.checked)}
            />
          </div>
        </>
      }
    />
  );
};

TimeEntryReportsFiltersComponent.propTypes = {
  filterDrawerState: PropTypes.shape({
    right: PropTypes.bool
  }).isRequired,
  toggleDrawer: PropTypes.func.isRequired,
  setFiltersState: PropTypes.func.isRequired,
  organizationDetails: PropTypes.shape({
    timeEntriesRoleLevels: PropTypes.arrayOf(PropTypes.string).isRequired,
    OULevelNamesForTimeLogs: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    hoursType: PropTypes.arrayOf(PropTypes.string).isRequired,
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired
  }).isRequired,
  userJobs: PropTypes.arrayOf(
    PropTypes.shape({
      operationalUnits: PropTypes.arrayOf(PropTypes.shape()).isRequired,
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired
    })
  ).isRequired,
  jobOffset: PropTypes.number.isRequired,
  dropdownLimit: PropTypes.number.isRequired,
  setDropdownOffset: PropTypes.func.isRequired,
  handleDropdownScroll: PropTypes.func.isRequired,
  currentFilter: PropTypes.string.isRequired
};

export default TimeEntryReportsFiltersComponent;
