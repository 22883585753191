import axios from "../../axios-jwt";
import i18n from "../../i18n";

import {
  CLEAR_SEARCH_RESULTS,
  SEARCH_EQUIPMENT,
  UPDATE_SEARCHED_EQUIPMENT
} from "./actionTypes";
import {
  apiStartLoader,
  apiStopLoader,
  customFailedMessage
} from "./ApiStatusActions";

export async function searchEquipments(
  userCompanyCode,
  changedCompanyCode,
  query
) {
  let resp;
  if (changedCompanyCode) {
    resp = axios.get(
      `equipments?companyCode=${changedCompanyCode}&search=${query}&orderBy=code-asc`
    );
  } else
    resp = axios.get(
      `equipments?companyCode=${userCompanyCode}&search=${query}&orderBy=code-asc`
    );
  return resp;
}

export function searchEquipmentResults(equipmentSearchResults) {
  return { type: SEARCH_EQUIPMENT, equipmentSearchResults };
}

export function updateSearchedEquipmentsSuccess(equipmentSearchResults) {
  return { type: UPDATE_SEARCHED_EQUIPMENT, equipmentSearchResults };
}

export function searchEquipmentWithParam(queryParam) {
  return (dispatch, getState) => {
    dispatch(apiStartLoader("equipmentSearchResults"));
    searchEquipments(
      getState().userDetails.companyCode,
      getState().selectedCompany.code,
      queryParam
    )
      .then((res) => {
        if (res.status === 200) {
          dispatch(searchEquipmentResults(res.data));
          dispatch(apiStopLoader("equipmentSearchResults"));
        }
        return undefined;
      })
      .catch((error) => {
        if (error) dispatch(customFailedMessage(i18n.t("invalid_search_term")));
        dispatch(apiStopLoader("equipmentSearchResults"));
      });
  };
}

export function clearEquipmentResults(actionType, clear) {
  return { type: CLEAR_SEARCH_RESULTS, actionType, clear };
}
