/* eslint-disable no-case-declarations */
import { findIndex, unionBy } from "lodash";

import {
  FILTER_WEEKLY_REPORTS,
  LOAD_BULK_WEEKLY_REPORTS,
  LOAD_WEEKLY_REPORT_BY_ID,
  LOAD_WEEKLY_REPORTS,
  SORT_WEEKLY_REPORTS,
  UPDATE_TIMELOGS_FOR_WEEK
} from "../actions/actionTypes";

export default function WeeklyReportsReducer(state = [], action) {
  switch (action.type) {
    case LOAD_WEEKLY_REPORTS:
      return action.offset
        ? [...state, ...action.weeklyReports]
        : action.weeklyReports;
    case LOAD_WEEKLY_REPORT_BY_ID: {
      const reports = state.map((r) => r);
      const currentReport = action.weeklyReport[0];
      const index = findIndex(
        reports,
        (data) => currentReport && data.crew._id === currentReport.crew._id
      );
      if (index !== -1) {
        return [
          ...reports.slice(0, index),
          currentReport,
          ...reports.slice(index + 1)
        ];
      } else return reports;
    }
    case LOAD_BULK_WEEKLY_REPORTS:
      const reports = state.map((eq) => eq);
      const udpatedReports = reports.map((report) => {
        const udpatedReport = action.weeklyReports.find(
          (update) => update.crew._id === report.crew._id
        );
        return udpatedReport ? { ...report, ...udpatedReport } : report;
      });
      return udpatedReports;
    case SORT_WEEKLY_REPORTS:
      return action.offset
        ? [...state, ...action.weeklyReports]
        : action.weeklyReports;
    case FILTER_WEEKLY_REPORTS:
      return action.offset
        ? [...state, ...action.weeklyReports]
        : action.weeklyReports;
    case UPDATE_TIMELOGS_FOR_WEEK: {
      const weeklyReports = state.map((w) => w);
      const index = findIndex(
        weeklyReports,
        (data) => data.crew._id === action.crewId
      );
      const dataWithoutErrors = (logs) => logs.filter((log) => !log.error);

      return state.map((report, ind) => {
        if (ind === index) {
          return {
            ...report,
            timelogsForWeek: {
              // Update timelogsForWeek array in the report according to add, edit or delete actions
              create: unionBy(
                report.timelogsForWeek,
                dataWithoutErrors(action.timelogs),
                "_id"
              ),
              edit: unionBy(
                dataWithoutErrors(action.timelogs),
                report.timelogsForWeek,
                "_id"
              ),
              delete: report.timelogsForWeek.filter(
                (log) => log._id !== dataWithoutErrors(action.timelogs)[0]._id
              )
            }[action.actionType]
          };
        }

        return report;
      });
    }
    default:
      return state;
  }
}
