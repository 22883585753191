import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import lodash from "lodash";
import PropTypes from "prop-types";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TextField from "@material-ui/core/TextField";

import useInfiniteScroll from "../../../custom-hooks/useInfiniteScroll";
import * as CompaniesActions from "../../../redux/actions/CompaniesActions";
import ConfirmationDialogComponent from "../../UI/ConfirmationDialogComponent";

import OperationalUnitsTable from "./OperationalUnitsTable";

const useStyles = makeStyles((theme) => ({
  paper: {
    color: theme.palette.text.secondary,
    border: "1px solid rgba(224, 224, 224, 1)",
    minHeight: "76vh",
    maxHeight: "76vh",
    position: "relative",
    overflowY: "scroll"
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  UnassignButton: {
    width: "max-content"
  }
}));

const SelectJobComponent = ({
  setShowAssignEmpBtn,
  unassignJob,
  empID,
  selected,
  setSelected
}) => {
  const classes = useStyles();
  const {
    companyJobs,
    apiStatus,
    jobsSearchResults,
    organizationDetails,
    selectedCompany
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [selectedJob, setSelectedJob] = useState(companyJobs[0] || {}); // job selected in dropdown
  const [scrollTop, setScrollTop] = useState(0);
  const [scrollHeight, setScrollHeight] = useState(0);
  const [clientHeight, setClientHeight] = useState(0);
  const scrollRef = useRef();
  const [jobsOffest, setJobsOffset] = useState(0);
  const [search, setSearch] = useState("");
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("code");
  const [limit] = useState(20);

  const [unassignComfirmationDialog, setUnassignConfirmationDialog] =
    useState(false);
  const unassignDialogAttributes = {
    title: `${t("assignJob.unassign_job")}?`,
    contentText: t("assignJob.unassign_job_confirmation"),
    cancel: t("commonActions.cancel"),
    accept: t("assignJob.unassign_job_action")
  };

  const onSearchChange = () => {
    if (search.length) {
      dispatch(
        CompaniesActions.loadSearchedJobs(
          search,
          0,
          50,
          orderBy,
          order,
          unassignJob,
          empID
        )
      );
    } else {
      dispatch(CompaniesActions.clearJobResults());
    }
  };
  useEffect(onSearchChange, [search]);

  // Custom hook for infinite scroll
  const [isFetching, toggle] = useInfiniteScroll(
    clientHeight,
    scrollTop,
    scrollHeight
  );

  // Send scroll event result to parent component to send API calls for pagination
  useEffect(() => {
    if (!apiStatus.isLoading && isFetching && search.length === 0) {
      setJobsOffset(jobsOffest + limit);
      setScrollTop(0);
      setScrollHeight(0);
      setClientHeight(0);
    }
    if (isFetching) {
      toggle(isFetching);
    }
    // eslint-disable-next-line
  }, [isFetching]);

  function onJobOffsetChange() {
    if (jobsOffest)
      dispatch(
        CompaniesActions.getJobOfCompany(
          jobsOffest,
          limit,
          orderBy,
          order,
          unassignJob,
          empID
        )
      );
  }
  useEffect(onJobOffsetChange, [jobsOffest]);

  function onOrderChange() {
    if (search.length === 0) {
      if (scrollRef.current) scrollRef.current.scrollTo(0, 0);
      dispatch(
        CompaniesActions.getJobOfCompany(
          0,
          limit,
          orderBy,
          order,
          unassignJob,
          empID
        )
      );
      setJobsOffset(0);
    } else
      dispatch(
        CompaniesActions.loadSearchedJobs(
          search,
          0,
          50,
          orderBy,
          order,
          unassignJob,
          empID
        )
      );
  }
  useEffect(onOrderChange, [order]);

  const handleRequestSort = (property) => {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  };

  // on company change
  const onCompanyChange = () => {
    setSelected({});
    setSelectedJob(companyJobs[0]);
    setShowAssignEmpBtn(false);
  };
  useEffect(onCompanyChange, [selectedCompany]);

  function handleJobChange(e) {
    setSelectedJob(e);
  }

  function jobSet() {
    if (companyJobs.length && !search.length)
      setSelectedJob(
        (selectedJob &&
          selectedJob.code &&
          companyJobs.findIndex((job) => job.code === selectedJob.code) >= 0 &&
          selectedJob) ||
          companyJobs[0]
      );
    if (search.length && jobsSearchResults.length)
      setSelectedJob(jobsSearchResults[0]);
  }
  useEffect(jobSet, [companyJobs, jobsSearchResults]);

  // on Unassign job click
  function handleUnassignJob(compCode) {
    dispatch(CompaniesActions.unassignJob(empID, [compCode]));
    setSelected((prevSelected) => {
      const copyOfSelected = lodash.cloneDeep(prevSelected);
      delete copyOfSelected[`${compCode}`];
      return copyOfSelected;
    });
  }

  function getNumberOfSelectedOuValues(compCode) {
    const displaySelected =
      selected[compCode] &&
      selected[compCode].filter((items) => items.length > 0).length > 0;
    return (
      displaySelected && (
        <div className='text-muted'>
          {`${t("assignJob.selected")} - ${selected[compCode].map(
            (items, idx) =>
              `${items.length} ${organizationDetails.OULevelNamesForTimeLogs[idx].label}`
          )}`}
        </div>
      )
    );
  }

  const setSearchValue = (searchValue) => {
    setSearch(searchValue);
  };
  const searchHandler = useCallback(lodash.debounce(setSearchValue, 800), []);

  function EnhancedTableHead() {
    const headCells = [
      {
        id: "code",
        disablePadding: false,
        label: t("authorizerContainer.code")
      },
      {
        id: "name",
        disablePadding: false,
        label: t("authorizerContainer.jobs")
      },
      {
        id: "",
        disablePadding: false,
        label: ""
      }
    ];

    return (
      <TableHead>
        <TableRow>
          {headCells &&
            headCells.map((headCell) => (
              <TableCell
                key={headCell.id}
                padding={headCell.disablePadding ? "none" : "default"}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={order}
                  onClick={() => handleRequestSort(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </span>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            ))}
        </TableRow>
        <TableRow>
          <TableCell colSpan={3} style={{ padding: "unset", top: "57px" }}>
            <TextField
              id='filled-basic'
              label={t("commonActions.search_here_label")}
              onChange={(e) => searchHandler(e.currentTarget.value)}
              fullWidth
              variant='filled'
            />
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  return (
    <Grid container>
      <Grid item xs={4}>
        <Paper
          className={classes.paper}
          ref={scrollRef}
          onScroll={(event) => {
            event.persist();
            setScrollTop(event.target.scrollTop);
            setScrollHeight(event.target.scrollHeight);
            setClientHeight(event.target.clientHeight);
          }}
        >
          <Table
            size='medium'
            className={classes.table}
            aria-labelledby='tableTitle'
            aria-label='enhanced table'
            stickyHeader
          >
            {EnhancedTableHead()}
            <TableBody>
              {((!companyJobs.length && !search.length) ||
                (search.length && !jobsSearchResults.length)) &&
              !apiStatus.isLoading ? (
                <TableRow>
                  <TableCell
                    colSpan={3}
                    style={{ textAlign: "center", border: "none" }}
                  >
                    {t("commonActions.no_records_found")}
                  </TableCell>
                </TableRow>
              ) : (
                (search.length ? jobsSearchResults : companyJobs).map(
                  (comp) => (
                    <>
                      <TableRow
                        hover
                        onClick={() => handleJobChange(comp)}
                        role='checkbox'
                        tabIndex={-1}
                        key={comp._id}
                        style={{
                          backgroundColor:
                            comp._id === (selectedJob && selectedJob._id)
                              ? "#dbdbdb"
                              : "#fff",
                          position: "relative",
                          overflow: "scroll"
                        }}
                      >
                        <TableCell>{comp.code}</TableCell>
                        <TableCell>
                          {comp.name}
                          {getNumberOfSelectedOuValues(comp.code)}
                        </TableCell>
                        {unassignJob && comp.isAssigned ? (
                          <>
                            <TableCell>
                              <Button
                                variant='outlined'
                                size='small'
                                color='secondary'
                                className={classes.UnassignButton}
                                onClick={() => {
                                  setUnassignConfirmationDialog(true);
                                }} // handleUnassignJob(comp.code)
                              >
                                {t("assignJob.unassign_job")}
                              </Button>
                            </TableCell>
                          </>
                        ) : (
                          <TableCell />
                        )}
                      </TableRow>
                    </>
                  )
                )
              )}
            </TableBody>
          </Table>
        </Paper>
      </Grid>
      <Grid item xs={8} style={{ display: "flex" }}>
        {companyJobs.length > 0 && selectedJob && (
          <OperationalUnitsTable
            selectedJob={selectedJob}
            unassignJob={unassignJob}
            selected={selected}
            setSelected={setSelected}
          />
        )}
      </Grid>
      {unassignComfirmationDialog && (
        <ConfirmationDialogComponent
          open={unassignComfirmationDialog}
          close={() => setUnassignConfirmationDialog(false)}
          dialogAttributes={unassignDialogAttributes}
          selectedData={selectedJob.code}
          handleDialog={handleUnassignJob}
        />
      )}
    </Grid>
  );
};

SelectJobComponent.propTypes = {
  setShowAssignEmpBtn: PropTypes.func,
  unassignJob: PropTypes.bool,
  empID: PropTypes.string,
  selected: PropTypes.shape().isRequired,
  setSelected: PropTypes.func.isRequired
};

SelectJobComponent.defaultProps = {
  setShowAssignEmpBtn: () => {},
  unassignJob: false,
  empID: ""
};

export default SelectJobComponent;
