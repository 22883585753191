import {
  CLEAR_ARF_SEARCH_RESULTS,
  LOAD_ARF_SUCCESS,
  RESET_ARF_EQUIPMENTS_LOADED_RESULTS
} from "../actions/actionTypes";

const initialState = {
  companyJobs: [],
  companyJobsSearchResults: [],
  personnel: [], //select employees
  personnelSearchResults: [],
  employeesByEquipment: [], //assignedTo
  employeesByEqSearchResults: [],
  equipments: [], //select equipments
  equipmentsSearchResults: []
};

export default function AuthorisersReportFilterLoadSearchReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case LOAD_ARF_SUCCESS:
      return {
        ...state,
        [action.payload.key]: action.offset
          ? [...state[action.payload.key], ...action.payload.data]
          : action.payload.data
      };
    case CLEAR_ARF_SEARCH_RESULTS: {
      if (
        [
          "companyJobsSearchResults",
          "personnelSearchResults",
          "employeesByEqSearchResults",
          "equipmentsSearchResults"
        ].includes(action.actionType)
      )
        return {
          ...state,
          [action.actionType]: []
        };
      else return state;
    }
    case RESET_ARF_EQUIPMENTS_LOADED_RESULTS:
      return {
        ...state,
        employeesByEquipment: [], //assignedTo
        employeesByEqSearchResults: [],
        equipments: [], //select equipments
        equipmentsSearchResults: []
      };
    default:
      return state;
  }
}
