import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  NavLink,
  Redirect,
  Route,
  Switch,
  useRouteMatch,
  withRouter
} from "react-router-dom";
import i18n from "i18next";
import moment from "moment";
import PropTypes from "prop-types";

import Backdrop from "@material-ui/core/Backdrop";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fab from "@material-ui/core/Fab";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import LockIcon from "@material-ui/icons/Lock";

import EquipmentEntryReportsComponent from "../components/EquipmentManager/EquipmentEntryReportsComponent";
import ExportCSVComponent from "../components/TimeEntriesManager/ExportCSVComponent";
import PreviewTimeReportsComponent from "../components/TimeEntriesManager/ExportTimeReports/PreviewTimeReportsComponent";
import TimeEntryReportsComponent from "../components/TimeEntriesManager/TimeEntryReportsComponent";
import TimeEntryReportsFiltersDialog from "../components/TimeEntriesManager/TimeEntryReportsFiltersDialog";
import ConfirmationDialogComponent from "../components/UI/ConfirmationDialogComponent";
import HeaderComponent from "../components/UI/HeaderComponent";
import SnackbarComponent from "../components/UI/SnackbarComponent";
import useInfiniteScroll from "../custom-hooks/useInfiniteScroll";
import * as AllCrewActions from "../redux/actions/AllCrewActions";
import { lockUnlockPayPeriod } from "../redux/actions/exportCsvActions";
import { loadOrganizationDetails } from "../redux/actions/OrganizationActions";
import * as PayPeriodsActions from "../redux/actions/PayPeriodsActions";
import loadPendingApprovalsCount from "../redux/actions/PendingApprovalsCountActions";
import * as PersonnelActions from "../redux/actions/PersonnelActions";
import * as RolesActions from "../redux/actions/RolesActions";
import loadUserJobDetails from "../redux/actions/UserJobDetailsActions";
import permissionsList from "../utillities/Permissions";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "20px",
    maxHeight: "100vh",
    overflowY: "hidden"
  },
  tabs: {
    marginBottom: theme.spacing(2),
    borderBottom: "1px solid rgba(224, 224, 224, 1)"
  },
  tab: {
    textTransform: "capitalize",
    "&:hover": {
      textDecoration: "none",
      color: "none"
    }
  },
  tableRoot: {
    width: "100%",
    fontSize: "10px !important"
  },
  toolbar: theme.mixins.toolbar,
  payPeriodsDropdown: {
    fontSize: "0.8125rem"
  },
  exportButton: {
    marginLeft: "auto",
    marginRight: "24px"
  },
  exportReport: {
    marginLeft: "16px"
  },
  payPeriodLockButton: {
    marginRight: "16px"
  },
  backdrop: {
    zIndex: 10000
  },
  menuPaper: {
    maxHeight: "312px",
    overflowY: "scroll"
  },
  lockIcon: {
    marginLeft: "4px",
    color: "grey"
  },
  payPeriodText: {
    color: "grey"
  }
}));

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component='div'
      role='tabpanel'
      hidden={value !== index}
      id={index}
      aria-labelledby={index}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.string.isRequired,
  value: PropTypes.string
};

export const PayPeriodOptionItem = (props) => {
  const { option } = props;
  const classes = useStyles();
  return (
    <Grid container>
      <Grid
        item
        xs={11}
        className={option.isLocked ? classes.payPeriodText : ""}
      >
        {option.label}
      </Grid>
      <Grid item xs={1}>
        {option.isLocked && (
          <LockIcon
            color='secondary'
            className={classes.lockIcon}
            fontSize='small'
          />
        )}
      </Grid>
    </Grid>
  );
};

PayPeriodOptionItem.propTypes = {
  option: PropTypes.shape().isRequired
};

const PayrollHomeComponent = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { history } = props;
  const match = useRouteMatch();
  const exportReportClickedRef = useRef(false);
  const {
    companyName,
    payPeriods,
    organizationDetails,
    userJobs,
    userDetails,
    selectedCompany,
    pendingApprovalsCount,
    apiStatus
  } = useSelector((state) => state);

  const { payperiodTypeForEquipment, payperiodTypeForHumanTime } =
    organizationDetails;
  const initialValue =
    history.location.pathname.split("/")[
      history.location.pathname.split("/").length - 1
    ];
  const [value, setValue] = useState(
    initialValue.includes("EquipmentEntries")
      ? payperiodTypeForEquipment
      : payperiodTypeForHumanTime
  );
  const isInTimeEntriesTab = () => value === payperiodTypeForHumanTime;
  const isInEquipmentEntriesTab = () => value === payperiodTypeForEquipment;

  const [payPeriodIndex, setPayPeriodIndex] = useState(0);
  const [payPeriodsOptions, setPayPeriodsOptions] = useState([]);
  const [payPeriodId, setPayPeriodId] = useState(
    payPeriods.length ? payPeriods[0]._id : ""
  );
  const [openCSV, setOpenCSV] = useState(false);
  const [exportReportDialog, setExportReportDialog] = useState(false);
  const payPeriodsRef = useRef();
  const dateColumnsRef = useRef();
  const userJobsRef = useRef();

  // pagination for payPeriod
  const [payPeriodOffset, setPayPeriodOffset] = useState(0);
  const limit = 20;
  const [scrollTop, setScrollTop] = useState(0);
  const [scrollHeight, setScrollHeight] = useState(0);
  const [clientHeight, setClientHeight] = useState(0);

  // Custom hook for infinite scroll
  const [isFetching, toggle] = useInfiniteScroll(
    clientHeight,
    scrollTop,
    scrollHeight
  );

  // Send scroll event result to parent component to send API calls for pagination
  useEffect(() => {
    if (isFetching) {
      if (isFetching && !apiStatus.isLoading)
        setPayPeriodOffset((o) => o + limit);
      setScrollTop(0);
      setScrollHeight(0);
      setClientHeight(0);
    }
    if (isFetching) {
      toggle(isFetching);
    }
    // eslint-disable-next-line
  }, [isFetching]);

  const loadMorePayPeriods = () => {
    if (payPeriodOffset)
      payPeriodsRef.current = dispatch(
        PayPeriodsActions.loadPayPeriods(
          payPeriodOffset,
          limit,
          isInEquipmentEntriesTab()
            ? payperiodTypeForEquipment
            : payperiodTypeForHumanTime
        )
      );
  };
  useEffect(loadMorePayPeriods, [payPeriodOffset]);

  // job offset is synched and is same for all job dropdowns
  const [jobOffset, setJobOffset] = useState(0);
  const jobLimit = 20;

  // if equipment id exported reload equipment reports to know status.
  const [isEquipmentExported, setEquipmentExportedStatus] = useState(0);

  const [confirmationDialog, setConfirmationDialog] = useState(false);

  const dialogAttributesForLockConfirmation = {
    title: `${t("commonActions.lock_payperiod_title")}`,
    // contentText is based on tab
    contentText: isInEquipmentEntriesTab()
      ? `${t("equipmentManager.lock_confirmation")}`
      : `${t("payroll.lock_confirmation")}`,
    cancel: `${t("commonActions.cancel")}`,
    accept: `${t("equipmentManager.lock")}`
  };

  //This is common functionality for both TimeEntries and EquipmentEntries payperiods
  const dialogAttributesForUnlockConfirmation = {
    title: `${t("commonActions.unlock_payperiod_title")}`,
    contentText: `${t("commonActions.unlock_confirmation")} ${
      (payPeriodsOptions[payPeriodIndex] || {}).label
    } ?`,
    cancel: `${t("commonActions.cancel")}`,
    accept: `${t("commonActions.unlock")}`
  };

  const handleChange = (event, newValue) => {
    setValue(
      newValue === payperiodTypeForHumanTime
        ? payperiodTypeForHumanTime
        : payperiodTypeForEquipment
    );
  };

  const applyPaginationForJob = () => {
    if (jobOffset) dispatch(loadUserJobDetails(jobOffset, jobLimit));
  };

  // Call API for filter dropdown/s if jobOffset > 0
  useEffect(applyPaginationForJob, [jobOffset]);

  // ?---Other Filters common current offsets and dropdownscroll func

  const [currentFilter, setCurrentFilter] = useState("");
  const [dropdownLimit, setDropdownLimit] = useState(20);
  const [dropdownOffset, setDropdownOffset] = useState({
    userJobs: jobOffset,
    crewMembers: 0,
    personnel: 0,
    organizationDetails: 0
  });

  const applyPagination = () => {
    switch (currentFilter) {
      case "crewMembers":
        dispatch(
          AllCrewActions.loadAllCrewMembers(
            dropdownOffset["crewMembers"],
            dropdownLimit
          )
        );
        break;
      case "personnel":
        dispatch(
          PersonnelActions.assignedEmployeePersonnel(
            dropdownOffset["personnel"],
            dropdownLimit
          )
        );
        break;
      case "userJobs":
        if (dropdownOffset["userJobs"])
          setJobOffset(dropdownOffset["userJobs"]);
        break;
      default:
        break;
    }
  };

  useEffect(applyPagination, [dropdownOffset]);

  const handleDropdownScroll = (trigger, dropdownType) => {
    if (trigger) {
      setDropdownOffset((o) => ({
        ...o,
        [dropdownType]: o[dropdownType] + dropdownLimit
      }));
      setDropdownLimit(dropdownLimit);
      setCurrentFilter(dropdownType);
    } else {
      setDropdownOffset((o) => ({ ...o, [dropdownType]: jobOffset }));
      setDropdownLimit(0);
    }
  };

  // ? ----------------------------------------------------------------

  const loadDetails = () => {
    if (!Object.keys(organizationDetails).length)
      dispatch(loadOrganizationDetails());
    if (selectedCompany.code === userDetails.companyCode) {
      payPeriodsRef.current = dispatch(
        PayPeriodsActions.loadPayPeriods(
          0,
          limit,
          isInEquipmentEntriesTab()
            ? payperiodTypeForEquipment
            : payperiodTypeForHumanTime
        )
      );
      userJobsRef.current = dispatch(loadUserJobDetails(0, 20));
      dispatch(RolesActions.loadRoles());
    }
    if (
      !userDetails.isSuperAdmin &&
      !userDetails.role.permissions.includes(permissionsList.TS_DESKTOP.key)
    ) {
      history.push("/");
    }
  };

  useEffect(loadDetails, [userDetails, selectedCompany]);

  const getPayPeriodOptions = () => {
    if (payPeriods.length) {
      setPayPeriodsOptions(
        payPeriods.map((payPeriod) => {
          return {
            value: `${t("payroll.pay_period")} - ${moment
              .utc(payPeriod.startDate)
              .locale(i18n.language)
              .format("MMM D, YYYY")} - ${moment
              .utc(payPeriod.endDate)
              .locale(i18n.language)
              .format("MMM D, YYYY")}`,
            label: `${t("payroll.pay_period")} - ${moment
              .utc(payPeriod.startDate)
              .locale(i18n.language)
              .format("MMM D, YYYY")} - ${moment
              .utc(payPeriod.endDate)
              .locale(i18n.language)
              .format("MMM D, YYYY")}`,
            id: payPeriod._id,
            isLocked: isInTimeEntriesTab()
              ? payPeriod.isLocked
              : payPeriod.isLockedForEquipmentEntries
          };
        })
      );
      if ((payPeriods[payPeriodIndex] || {})._id !== payPeriodId) {
        if (!payPeriodOffset) {
          setPayPeriodIndex(0);
          setPayPeriodId(payPeriods[0]._id);
        } else {
          const payPeriodSelectedIndex = payPeriods.findIndex(
            (payPeriod) => payPeriod._id === payPeriodId
          );
          setPayPeriodId(
            payPeriods[payPeriodSelectedIndex >= 0 ? payPeriodSelectedIndex : 0]
              ._id
          );
          setPayPeriodIndex(
            payPeriodSelectedIndex >= 0 ? payPeriodSelectedIndex : 0
          );
        }
      }
    }
  };

  useEffect(getPayPeriodOptions, [payPeriods, i18n.language]);

  const getPendingApprovals = () => {
    if (payPeriodId) dispatch(loadPendingApprovalsCount(payPeriodId));
  };

  useEffect(getPendingApprovals, [payPeriodId]);

  const handleScroll = (event) => {
    event.stopPropagation();
    setScrollTop(event.target.scrollTop);
    setScrollHeight(event.target.scrollHeight);
    setClientHeight(event.target.clientHeight);
  };

  const handlePayPeriodsDropDown = (e) => {
    setPayPeriodIndex(e.target.value);
    setPayPeriodId(payPeriods[e.target.value]._id);
  };

  const payPeriodsHandler = () => {
    return (
      <Select
        value={payPeriodIndex}
        disableUnderline
        onChange={(e) => handlePayPeriodsDropDown(e)}
        inputProps={{
          className: classes.payPeriodsDropdown
        }}
        onScroll={handleScroll}
        MenuProps={{
          classes: { paper: classes.menuPaper },
          getContentAnchorEl: null
        }}
      >
        {payPeriodsOptions.map((o, idx) => (
          <MenuItem
            value={idx}
            key={o.id}
            className={classes.payPeriodsDropdown}
          >
            <PayPeriodOptionItem option={o} />
          </MenuItem>
        ))}
      </Select>
    );
  };

  const enumerateDaysBetweenDates = (startDate, endDate) => {
    const start = startDate;
    const dates = [];

    while (start.isSameOrBefore(endDate)) {
      dates.push(start.toISOString());
      start.add(1, "days");
    }
    return dates;
  };

  const fromDate =
    payPeriodsOptions.length &&
    moment((payPeriods[payPeriodIndex] || {}).startDate);
  const toDate =
    payPeriodsOptions.length &&
    moment((payPeriods[payPeriodIndex] || {}).endDate);
  dateColumnsRef.current =
    payPeriodsOptions.length && enumerateDaysBetweenDates(fromDate, toDate);

  const handleCallToRouter = () => {
    setPayPeriodOffset(0);
    payPeriodsRef.current = dispatch(
      PayPeriodsActions.loadPayPeriods(
        0,
        limit,
        isInEquipmentEntriesTab()
          ? payperiodTypeForEquipment
          : payperiodTypeForHumanTime
      )
    );
    setPayPeriodId("");
  };
  useEffect(handleCallToRouter, [value]);

  const onPathChange = () => {
    const val =
      history.location.pathname.split("/")[
        history.location.pathname.split("/").length - 1
      ];
    if (val.includes("EquipmentEntries")) setValue(payperiodTypeForEquipment);
    else if (val.includes("TimeEntries")) setValue(payperiodTypeForHumanTime);
    else setValue(false);
  };
  useEffect(onPathChange, [history.location.pathname]);

  const isValidPayPeroidType = (type) => {
    return !!(payPeriods.length && payPeriods[0].type === type);
  };

  const isCurrentPayperiodLocked = isInEquipmentEntriesTab()
    ? (payPeriods[payPeriodIndex] || {}).isLockedForEquipmentEntries
    : (payPeriods[payPeriodIndex] || {}).isLocked;

  // Unlocking equipment or human/labor payperiods based on current tab
  const handleUnlockPayPeriodAction = () => {
    isInEquipmentEntriesTab()
      ? dispatch(
          lockUnlockPayPeriod(
            payPeriodId,
            "equipment",
            false,
            payPeriodOffset + limit
          )
        )
      : dispatch(
          lockUnlockPayPeriod(
            payPeriodId,
            "human",
            false,
            payPeriodOffset + limit
          )
        );
  };

  // Currently we can only lock equipment payperiods manually
  const handleLockPayPeriodAction = () => {
    isInEquipmentEntriesTab()
      ? dispatch(
          lockUnlockPayPeriod(
            payPeriodId,
            "equipment",
            true,
            payPeriodOffset + limit
          )
        )
      : dispatch(
          lockUnlockPayPeriod(
            payPeriodId,
            "human",
            true,
            payPeriodOffset + limit
          )
        );
  };

  return (
    <div className={classes.root}>
      <HeaderComponent companyName={companyName} />
      {apiStatus &&
        apiStatus.reducer &&
        (apiStatus.reducer.includes("UserDetails") ||
          apiStatus.reducer.includes("exportCount") ||
          apiStatus.reducer.includes("PayPeriods")) && (
          <Backdrop open className={classes.backdrop}>
            <CircularProgress />
          </Backdrop>
        )}
      <div className={classes.toolbar} />
      <Tabs
        value={value}
        textColor='secondary'
        onChange={handleChange}
        aria-label='payroll-tabs'
        className={classes.tabs}
      >
        <Tab
          label={t("payroll.time_entries")}
          className={classes.tab}
          to={`${match.url}/TimeEntries`}
          component={NavLink}
          value={payperiodTypeForHumanTime || undefined}
        />
        <Tab
          label={t("payroll.equipment_entries")}
          className={classes.tab}
          to={`${match.url}/EquipmentEntries`}
          component={NavLink}
          value={payperiodTypeForEquipment || undefined}
        />
        <div className={classes.exportButton}>
          {(userDetails.isSuperAdmin ||
            (userDetails.role.permissions.includes(
              permissionsList.LABOR_UPLOAD.key
            ) &&
              isInTimeEntriesTab()) ||
            userDetails.isSuperAdmin ||
            (userDetails.role.permissions.includes(
              permissionsList.EQUIPMENT_UPLOAD.key
            ) &&
              isInEquipmentEntriesTab())) && (
            <Tooltip
              title={
                pendingApprovalsCount && isInTimeEntriesTab()
                  ? `${t(
                      "payroll.number_of_approvals_text"
                    )} ${pendingApprovalsCount}. ${t(
                      "payroll.approve_to_export"
                    )}`
                  : ""
              }
              arrow
            >
              <span>
                <Fab
                  variant='extended'
                  color='secondary'
                  size='medium'
                  disabled={
                    !payPeriods.length ||
                    !!(pendingApprovalsCount && isInTimeEntriesTab())
                  }
                  onClick={() => setOpenCSV(true)}
                >
                  {isInEquipmentEntriesTab()
                    ? t("payroll.equipment_export_csv")
                    : t("payroll.labor_export_csv")}
                </Fab>
              </span>
            </Tooltip>
          )}
          {userDetails.role.permissions.includes(
            permissionsList.GENERATE_LABOUR_REPORTS.key
          ) &&
            isInTimeEntriesTab() && (
              <Fab
                variant='extended'
                color='secondary'
                size='medium'
                onClick={() => setExportReportDialog(true)}
                disabled={apiStatus.isLoading}
                className={classes.exportReport}
              >
                {t("payroll.export_reports")}
              </Fab>
            )}
        </div>
      </Tabs>
      <Paper className={classes.paper}>
        <Switch>
          <Route path={`${match.url}/TimeEntries`}>
            <TabPanel
              key='first'
              value={value}
              index={payperiodTypeForHumanTime}
            >
              {payPeriodsOptions.length > 0 &&
                isInTimeEntriesTab() &&
                isValidPayPeroidType(payperiodTypeForHumanTime) && (
                  <TimeEntryReportsComponent
                    key='TimeEntryReportsComponent'
                    organizationDetails={organizationDetails}
                    isPayPeriodLocked={
                      (payPeriods[payPeriodIndex] || {}).isLocked
                    }
                    userJobs={userJobs}
                    setJobOffset={setJobOffset}
                    jobOffset={jobOffset}
                    payPeriodsOptions={payPeriodsOptions}
                    payPeriodsHandler={payPeriodsHandler}
                    payPeriodId={payPeriodId}
                    payPeriodIndex={payPeriodIndex}
                    dateRange={dateColumnsRef.current}
                    exportReportClickedRef={exportReportClickedRef}
                    handleDropdownScroll={handleDropdownScroll}
                    dropdownLimit={dropdownLimit}
                    setDropdownOffset={setDropdownOffset}
                    currentFilter={currentFilter}
                    setLockUnlockPayPeriodConfirmationDialog={
                      setConfirmationDialog
                    }
                  />
                )}
            </TabPanel>
          </Route>
          <Route path={`${match.url}/EquipmentEntries`}>
            <TabPanel
              key='second'
              value={value}
              index={payperiodTypeForEquipment}
            >
              {payPeriodsOptions.length > 0 &&
                isInEquipmentEntriesTab() &&
                isValidPayPeroidType(payperiodTypeForEquipment) && (
                  <EquipmentEntryReportsComponent
                    key='EquipmentEntryReportsComponent'
                    organizationDetails={organizationDetails}
                    isPayPeriodLocked={
                      (payPeriods[payPeriodIndex] || {})
                        .isLockedForEquipmentEntries
                    }
                    userJobs={userJobs}
                    jobOffset={jobOffset}
                    setJobOffset={setJobOffset}
                    payPeriodsOptions={payPeriodsOptions}
                    payPeriodsHandler={payPeriodsHandler}
                    payPeriodId={payPeriodId}
                    payPeriodIndex={payPeriodIndex}
                    dateRange={dateColumnsRef.current}
                    isEquipmentExported={isEquipmentExported}
                    setLockUnlockPayPeriodConfirmationDialog={
                      setConfirmationDialog
                    }
                  />
                )}
            </TabPanel>
          </Route>
          <Route path={`${match.url}/ExportTimeReports`}>
            <TabPanel key='third' value={"third"} index={"third"}>
              <PreviewTimeReportsComponent
                userJobs={userJobs}
                organizationDetails={organizationDetails}
                exportReportClickedRef={exportReportClickedRef}
                handleDropdownScroll={handleDropdownScroll}
                setDropdownOffset={setDropdownOffset}
                jobOffset={jobOffset}
                dropdownLimit={dropdownLimit}
                currentPayPeriodIndex={payPeriodIndex}
                payPeriodsOptions={payPeriodsOptions}
                payPeriodOptionsScroll={handleScroll}
              />
            </TabPanel>
          </Route>
          <Redirect to={{ pathname: `${match.url}/TimeEntries` }} />
        </Switch>
      </Paper>
      {/* Using this dialog for both locking and unlocking payperiods */}
      {confirmationDialog && (
        <ConfirmationDialogComponent
          open={confirmationDialog}
          close={() => setConfirmationDialog(false)}
          dialogAttributes={
            isCurrentPayperiodLocked
              ? dialogAttributesForUnlockConfirmation
              : dialogAttributesForLockConfirmation
          }
          handleDialog={() => {
            isCurrentPayperiodLocked
              ? handleUnlockPayPeriodAction()
              : handleLockPayPeriodAction();
          }}
        />
      )}
      {openCSV && (
        <ExportCSVComponent
          key={`payPeriodId_${value}`}
          open={openCSV}
          close={() => setOpenCSV(false)}
          value={value}
          payPeriodId={payPeriodId}
          userJobs={userJobs}
          jobOffset={jobOffset}
          setJobOffset={setJobOffset}
          payPeriodOffset={payPeriodOffset}
          setEquipmentExportedStatus={setEquipmentExportedStatus}
          isEquipmentExported={isEquipmentExported}
          isInTimeEntriesTab={isInTimeEntriesTab}
          isInEquipmentEntriesTab={isInEquipmentEntriesTab}
        />
      )}
      {exportReportDialog && (
        <TimeEntryReportsFiltersDialog
          open={exportReportDialog}
          close={() => setExportReportDialog(false)}
          userJobs={userJobs}
          organizationDetails={organizationDetails}
          exportReportClickedRef={exportReportClickedRef}
          handleDropdownScroll={handleDropdownScroll}
          currentPayPeriodIndex={payPeriodIndex}
          payPeriodsOptions={payPeriodsOptions}
          payPeriodOptionsScroll={handleScroll}
        />
      )}
      {apiStatus.status &&
        (apiStatus.status.success || apiStatus.status.error) && (
          <>
            <SnackbarComponent />
          </>
        )}
    </div>
  );
};

export default withRouter(PayrollHomeComponent);

PayrollHomeComponent.propTypes = {
  history: PropTypes.shape().isRequired
};
