import axios from "../../axios-jwt";

import { LOAD_EMPLOYEES_BY_EQUIPMENT } from "./actionTypes";
import { apiFailed, apiStartLoader, apiStopLoader } from "./ApiStatusActions";
import loadCount from "./CountActions";

export async function getEmployeesByEquipment(
  userDetails,
  selectedCompany,
  offset,
  limit
) {
  if (selectedCompany.code) {
    return axios.get(
      `employees?companyCode=${selectedCompany.code}&equipmentsAssigned=true&orderBy=empID-asc&offset=${offset}&limit=${limit}`
    );
  }
  return axios.get(
    `employees?companyCode=${userDetails.companyCode}&equipmentsAssigned=true&orderBy=empID-asc&offset=${offset}&limit=${limit}`
  );
}

export function loadEmployeesByEquipmentSuccess(employeesByEquipment, offset) {
  return { type: LOAD_EMPLOYEES_BY_EQUIPMENT, employeesByEquipment, offset };
}

export function loadEmployeesByEquipment(offset, limit, id) {
  return (dispatch, getState) => {
    dispatch(apiStartLoader(id || "employeesByEquipment"));
    return getEmployeesByEquipment(
      getState().userDetails,
      getState().selectedCompany,
      offset,
      limit
    )
      .then((obj) => {
        dispatch(loadCount("employeesByEquipment", obj.data.count));
        dispatch(loadEmployeesByEquipmentSuccess(obj.data.employees, offset));
        dispatch(apiStopLoader(id || "employeesByEquipment"));
      })
      .catch((error) => {
        apiFailed(error);
        dispatch(apiStopLoader(id || "employeesByEquipment"));
      });
  };
}
