import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import {
  cloneDeep,
  differenceWith,
  findIndex,
  flatten,
  isEqual,
  mapKeys,
  omit,
  orderBy,
  pick,
  replace,
  uniqBy
} from "lodash";
import moment from "moment";
import PropTypes from "prop-types";

import { Divider } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import Zoom from "@material-ui/core/Zoom";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

import useInfiniteScroll from "../../custom-hooks/useInfiniteScroll";
import { customFailedMessage } from "../../redux/actions/ApiStatusActions";
import * as TimelogsActions from "../../redux/actions/TimelogsActions";
import * as userJobsSearchActions from "../../redux/actions/UserJobsSearchActions";
import tableStyles from "../../styles/components/DetailsTableStyles";
import activityLogs from "../../utillities/ActivityLogs";
import {
  ACTIVITY_LOGS_TIME_FORMAT,
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  DATE_TIME_LONG_FORMAT,
  INPUT_DATE_FORMAT
} from "../../utillities/DateFormats";
import permissionsList from "../../utillities/Permissions";
import ConfirmationDialogComponent from "../UI/ConfirmationDialogComponent";
import DrawerComponent from "../UI/DrawerComponent";
import DropdownSearchComponent from "../UI/DropdownSearchComponent";
import HoverTooltip from "../UI/HoverTooltip";

import NotesToolTip from "./NotesToolTip";
import SplitHoursComponent from "./SplitHoursComponent";

const TimeEntryDetailsComponent = (props) => {
  const { t } = useTranslation();
  const classes = tableStyles();
  const dispatch = useDispatch();
  const {
    payPeriods,
    apiStatus,
    activityLogsTimeEntries,
    userDetails,
    userJobsSearchResults
  } = useSelector((state) => state);
  const {
    crew,
    userJobs,
    payPeriodId,
    timelogsForWeek,
    jobsInWeek,
    OULevelNamesForTimeLogs,
    hoursTypeOptions,
    approvals,
    handleCollapse,
    rowIndex,
    roleLevel,
    isPayrollUser,
    unUploadedJobs,
    isPayPeriodLocked,
    jobOffset,
    setJobOffset,
    filterQuery
  } = props;
  const [rows, setRows] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedEntries, setSelectedEntries] = useState({});
  const [currentIndex, setCurrentIndex] = useState(0);
  const [focus, setFocus] = useState(false); // Bring focus to last row's date field after clicking on New Entry button
  const [splitHoursShow, setSplitHoursShow] = useState(false);
  const [confirmDialogShow, setConfirmDialogShow] = useState(false); // Dialog confirmDialogShow/close state
  const [scrollTop, setScrollTop] = useState(0);
  const [scrollHeight, setScrollHeight] = useState(0);
  const [clientHeight, setClientHeight] = useState(0);
  const [search, setSearch] = useState("");
  const dropdownLimit = 20;
  const [drawerState, setDrawerState] = useState({
    right: false
  });
  // helps to refresh timelogs for new entries when only filtered timelogs to be shown.
  useEffect(() => {
    setRows(timelogsForWeek);
  }, [timelogsForWeek]);

  const handleClose = () => setConfirmDialogShow(false); // Dialog close
  const handleSplitHoursClickOpen = () => setSplitHoursShow(true);
  const handleSplitHoursClose = () => setSplitHoursShow(false);
  const updateTimelogEntries = (type, updatedPayload, pID, cID) =>
    dispatch(
      TimelogsActions.updateTimelogs(
        type,
        updatedPayload,
        pID,
        cID,
        filterQuery
      )
    );

  const disabledCell =
    userDetails &&
    !userDetails.isSuperAdmin &&
    !userDetails.role.permissions.includes(
      permissionsList.LABOR_BULK_ENTRY.key
    );

  // Get required properties from timelog object
  const getRequiredFields = (obj, ...fieldsToBeOmitted) => {
    return cloneDeep(
      omit(
        pick(obj, [
          "_id",
          "crewId",
          "dateOfEntry",
          "date",
          "jobCode",
          "OULevelValues",
          "punchIn",
          "punchOut",
          "manualPunchIn",
          "manualPunchOut",
          "actualPunchIn",
          "actualPunchOut",
          "hoursType",
          "hoursWorked",
          "isDeleted",
          "isTrustedInTime",
          "isTrustedOutTime",
          "inLocation",
          "outLocation",
          "activityLogs",
          "hasAccess"
        ]),
        "inLocation._id", // inLocation's _id is not needed in payload
        "outLocation._id", // outLocation's _id is not needed in payload
        ...fieldsToBeOmitted
      )
    );
  };

  const [timeLogs, setTimeLogs] = useState(
    timelogsForWeek.reduce(
      (prev, row) => ({
        ...prev,
        [row._id]: getRequiredFields(row)
      }),
      {}
    )
  );

  // Add/Modify AM-PM suffix for time fields
  const getTimeSuffix = (hour, id) => ({ [id]: hour >= 12 ? "PM" : "AM" });

  // AM-PM suffix for punchIn & punchOut in each row
  const [suffix, setSuffix] = useState(
    timelogsForWeek.reduce((prev, row, index) => {
      const punchInTime = ((row.punchIn || "").split(" ")[1] || "").split(
        ":"
      )[0];
      const punchOutTime = ((row.punchOut || "").split(" ")[1] || "").split(
        ":"
      )[0];
      const idx = row._id || `new_${index}`;
      return {
        ...prev,
        ...getTimeSuffix(punchInTime, `${rowIndex}${idx}punchIn`),
        ...getTimeSuffix(punchOutTime, `${rowIndex}${idx}punchOut`)
      };
    }, {})
  );

  // To know if a timeEntry is approved or not
  const isApproved = (jobCode) => {
    if (!jobCode || !approvals.length) return false;
    let status = false;
    approvals.forEach((approval) => {
      if (approval.jobCode === jobCode) {
        approval.approvalRoles.forEach((role) => {
          if (role <= userDetails.role.roleLevel) {
            status = true;
          }
        });
      }
    });
    return status && !isPayrollUser;
  };

  const loading =
    (apiStatus || {}).isLoading && apiStatus.reducer.includes("weeklyReports");
  const activityLogsLoading =
    (apiStatus || {}).isLoading &&
    apiStatus.reducer.includes("activityLogsTimeEntries");

  const isDisabled = (entry) =>
    loading ||
    isApproved(entry.jobCode) ||
    disabledCell ||
    (entry.jobCode && !unUploadedJobs.includes(entry.jobCode) && entry._id) ||
    isPayPeriodLocked ||
    entry.hasAccess === false;

  // Custom hook for infinite scroll on Job dropdown
  const [isFetching, toggle] = useInfiniteScroll(
    clientHeight,
    scrollTop,
    scrollHeight
  );

  const getScrollValue = () => {
    if (isFetching && !search.length) {
      setJobOffset(jobOffset + dropdownLimit);
      toggle(isFetching);
      setScrollTop(0);
      setScrollHeight(0);
    }
  };

  useEffect(getScrollValue, [isFetching]);

  const [selectedSearchJobs, setSelectedSearchJobs] = useState([]);

  // Merge userJobs and jobsInWeek
  const mergedJobs = (empJobs) => {
    // eslint-disable-next-line no-undef
    return (
      uniqBy(
        orderBy(
          [...jobsInWeek, ...empJobs, ...selectedSearchJobs],
          (job) => +job.code,
          "asc"
        ),
        "code"
      ) || []
    );
  };

  const validJobs = (empJobs) => {
    // eslint-disable-next-line no-undef

    return (
      uniqBy(
        orderBy(
          [
            //filtering  jobsInWeek with hasAccess=true
            ...jobsInWeek.filter((x) => Boolean(x.hasAccess)),
            ...empJobs,
            ...selectedSearchJobs
          ],
          (job) => +job.code,
          "asc"
        ),
        "code"
      ) || []
    );
  };
  //with hasAccess = false
  const invalidJobs = jobsInWeek.filter((x) => Boolean(!x.hasAccess));

  const [jobs, setJobs] = useState(mergedJobs(userJobs));

  useEffect(() => {
    setJobs(mergedJobs(userJobs));
    // eslint-disable-next-line
  }, [userJobs]);

  const onSearchItemSelected = () => {
    setJobs(mergedJobs(userJobs));
  };
  useEffect(onSearchItemSelected, [selectedSearchJobs]);

  const handleJobSearch = (searchTerm, id) => {
    if (searchTerm.length) {
      dispatch(userJobsSearchActions.searchUserJobsWithParam(searchTerm, id));
    }
    setSearch(searchTerm);
  };

  // Text for ConfirmationDialogComponent
  const attributes = {
    title: t("confirmation_text"),
    contentText: t("confirmation_msg"),
    cancel: t("commonActions.cancel"),
    accept: t("commonActions.delete")
  };

  // Gets called when clicked on action menu
  // Stores the selected row object for which action menu is clicked
  const menuClick = (event, index) => {
    event.preventDefault();
    setCurrentIndex(index);
    const selectedEntry = timeLogs[index];
    setSelectedEntries((entries) => ({ ...entries, [index]: selectedEntry }));
    setAnchorEl(event.currentTarget);
  };

  // For passing selected action menu data to parent component prop from here
  // Trigger PUT request with initial object and isDeleted = true
  const deleteEntry = (data) => {
    if (!data) return;

    const copyData = cloneDeep(data || {});
    const format = {
      _id: copyData._id,
      crewId: copyData.crewId,
      dateOfEntry: copyData.dateOfEntry
        ? moment(copyData.dateOfEntry).toISOString()
        : null,
      date: copyData.date,
      isDeleted: true,
      activityLogs: [],
      updated: undefined
    };
    format.activityLogs.push({
      code: activityLogs.DELETED.code,
      timestamp: new Date().toISOString(),
      updates: []
    });

    const pId = (payPeriods || [])[
      findIndex(payPeriods || [], ["_id", payPeriodId])
    ]._id;
    if (data._id) updateTimelogEntries("delete", [format], pId, crew._id);
    else {
      // Delete entries from UI which are not yet saved
      let currentRows;
      if (Object.values(timeLogs).length) {
        const logs = omit(timeLogs, currentIndex);
        const timeLogKeyUpdates = mapKeys(logs, (value, key) => {
          const idx = (str) => +str.split("_")[1];
          return key.length === 5 && idx(key) > idx(currentIndex)
            ? `new_${idx(key) - 1}`
            : key;
        });
        setTimeLogs(timeLogKeyUpdates);
        currentRows = Object.values(timeLogKeyUpdates); //  If fields are edited, then remove from timeLogs
      } else {
        currentRows = rows.filter((r, idx) => idx !== currentIndex); // If no edits, remove from rows
      }
      setRows(currentRows);
    }
  };

  // Activity logs state handler
  const toggleDrawer = (side, isOpen) => {
    setDrawerState({ [side]: isOpen });
  };

  const getActivityName = (selectedCode) => {
    return Object.values(activityLogs)
      .map((log) => {
        if (log.code === selectedCode) {
          return log.activity;
        }
        return false;
      })
      .find((val) => val !== false);
  };

  // Closes the action menu pop up
  const menuClose = (event, option) => {
    if (option.key === "delete") {
      setConfirmDialogShow(true);
    }
    if (option.key === "split_time") {
      handleSplitHoursClickOpen();
    }
    if (option.key === "view_logs") {
      dispatch(
        TimelogsActions.getActivityLogs(selectedEntries[currentIndex]._id)
      );
      toggleDrawer("right", true);
    }
    setAnchorEl(null);
  };

  // Default object to create new row/timelog
  const newRowObjectDefaults = {
    crewId: crew && crew._id,
    isTrustedInTime: false,
    isTrustedOutTime: false,
    activityLogs: [
      {
        code: activityLogs.CREATED.code,
        timestamp: new Date().toISOString(),
        updates: []
      }
    ]
  };

  // Create a new empty row in the UI
  const handleNewEntry = (event) => {
    event.persist();
    const key = event.target.accessKey;
    if (
      (event.keyCode === 9 && event.target.id.includes("_action")) ||
      event.type === "click"
    ) {
      // Check if the last cell has Tab key event
      if (+key === rows.length - 1 || event.type === "click") {
        setFocus(true);
        setRows((r) => [...r, newRowObjectDefaults]);
        setTimeLogs((r) => ({
          ...r,
          [`new_${rows.length}`]: newRowObjectDefaults
        }));
        setSuffix((suffixes) => ({
          ...suffixes,
          ...{ [`${rowIndex}${rows.length}punchIn`]: "AM" },
          ...{ [`${rowIndex}${rows.length}punchOut`]: "AM" }
        }));
      }
    }
  };

  // Displays hyperlink for punchIn if coordinates are available
  // and opens a google map in new tab otherwise display a text
  const clockInLocation = (data) => {
    return data.inLocation === undefined ? (
      <p className={classes.label}>{t("payroll.no_gps_msg")}</p>
    ) : (
      <a
        className={classes.links}
        href={`https://maps.google.com/?q=${data.inLocation.coordinates[0]},${data.inLocation.coordinates[1]}`}
        target='_blank'
        rel='noopener noreferrer'
      >
        {t("payroll.clock_in_loc_label")}
      </a>
    );
  };

  // Displays hyperlink for punchOut if coordinates are available
  // and opens a google map in new tab otherwise display a text
  const clockOutLocation = (data) => {
    return data.outLocation === undefined ? (
      <p className={classes.label}>{t("payroll.no_gps_msg")}</p>
    ) : (
      <a
        className={classes.links}
        href={`https://maps.google.com/?q=${data.outLocation.coordinates[0]},${data.outLocation.coordinates[1]}`}
        target='_blank'
        rel='noopener noreferrer'
      >
        {t("payroll.clock_out_loc_label")}
      </a>
    );
  };

  // Trigger PUT request with udpated timelogs
  const handleSave = (event) => {
    let pIn;
    let pOut;
    const updates = Object.values(timeLogs)
      .filter((currentUpdates) => currentUpdates.updated)
      .map((update) => ({
        ...update,
        hasAccess: undefined, //omiting while sending it to BE,
        hoursWorked:
          update.actualPunchIn || update.actualPunchOut
            ? undefined
            : update.hoursWorked || undefined,
        punchIn: (pIn = (update.punchIn || "").includes(":")
          ? moment(update.punchIn, DATE_TIME_LONG_FORMAT).format(
              DATE_TIME_FORMAT
            )
          : undefined),
        punchOut: (pOut = (update.punchOut || "").includes(":")
          ? moment(update.punchOut, DATE_TIME_LONG_FORMAT).format(
              DATE_TIME_FORMAT
            )
          : undefined),
        manualPunchIn: pIn,
        manualPunchOut: pOut,
        isTrustedInTime: !!(
          timelogsForWeek.length &&
          (timelogsForWeek.find((log) => log._id === update._id) || {})
            .punchIn === pIn &&
          update.isTrustedInTime
        ),
        isTrustedOutTime: !!(
          timelogsForWeek.length &&
          (timelogsForWeek.find((log) => log._id === update._id) || {})
            .punchOut === pOut &&
          update.isTrustedOutTime
        ),
        actualPunchIn: undefined,
        actualPunchOut: undefined,
        updated: undefined
      }));
    if (event && updates.filter((u) => u._id).length)
      updateTimelogEntries("edit", updates, payPeriodId, crew._id);
    else updateTimelogEntries("create", updates, payPeriodId, crew._id);
  };

  // Create headers and columns for OULevelValues based on OULevelNamesForTimeLogs in organization details
  const OUHeaders = OULevelNamesForTimeLogs.map((entry, index) => ({
    OUIndex: index,
    OUId: entry.name,
    numeric: false,
    disablePadding: true,
    label: entry.label
  })).filter((header) => header);

  // Table headers
  const headers = [
    {
      id: "dateOfEntry",
      numeric: false,
      disablePadding: true,
      label: `${t("payroll.date_label")}`
    },
    {
      id: "jobCode",
      numeric: false,
      disablePadding: true,
      label: `${t("payroll.job_label")}`
    },
    ...OUHeaders,
    {
      id: "hoursType",
      numeric: false,
      disablePadding: true,
      label: `${t("payroll.pay_type")}`
    },
    {
      id: "punchIn",
      numeric: false,
      disablePadding: true,
      label: `${t("payroll.clock_in_label")}`
    },
    {
      id: "punchOut",
      numeric: false,
      disablePadding: true,
      label: `${t("payroll.clock_out_label")}`
    },
    {
      id: "hoursWorked",
      numeric: false,
      disablePadding: true,
      label: `${t("payroll.total_time_label")}`
    },
    {
      id: "inLocation",
      numeric: false,
      disablePadding: true,
      label: `${t("payroll.clock_in_loc_label")}`
    },
    {
      id: "outLocation",
      numeric: false,
      disablePadding: true,
      label: `${t("payroll.clock_out_loc_label")}`
    },
    {
      id: "note",
      numeric: false,
      disablePadding: true,
      label: `${t("payroll.note_label")}`
    },
    {
      id: "actions",
      numeric: false,
      disablePadding: true,
      label: ""
    }
  ];

  // Row action menu options
  const actionMenuOptions = [
    {
      key: "view_logs",
      label: `${t("payroll.view_activity_logs_label")}`,
      disabled: !(selectedEntries[currentIndex] || {})._id
    },
    {
      key: "split_time",
      label: `${t("payroll.split_time_title")}`,
      disabled:
        !(selectedEntries[currentIndex] || {})._id ||
        isDisabled(selectedEntries[currentIndex] || {})
    },
    {
      key: "delete",
      label: `${t("commonActions.delete")}`,
      disabled:
        isDisabled(selectedEntries[currentIndex] || {}) &&
        (selectedEntries[currentIndex] || {})._id
    }
  ];

  // Create options dynamically for OULevelValues dropdowns in table based on jobCode
  const setOperationalUnitsOptions = (jobCode, i, type, hasAccess) => {
    const jobs = hasAccess === false ? invalidJobs : validJobs(userJobs);
    const code = jobCode && jobCode[i]; // Get jobCode of curent row (initial or updated)
    const jobIndex = code
      ? findIndex(jobs, (currentJob) => currentJob.code === code)
      : 0; // Find index to look for job
    const job = (jobs && jobs[jobIndex]) || {}; // Get the job from jobs with the above jobIndex
    const operationalUnits = (
      (job.operationalUnits || [])[
        findIndex(job.operationalUnits, (unit) => unit.name === type)
      ] || {}
    ).items;
    return orderBy(operationalUnits, (ou) => +ou.code, "asc"); // Return operationalUnits' items from the job
  };

  // Compare OULevelValues coming from API and show them in the table only if the logged in user has access to them.
  // Otherwise show NA
  const getOperationalUnits = (jobCode, OUId, code, hasAccess) => {
    const jobs = hasAccess === false ? invalidJobs : validJobs(userJobs);
    const jobIndex = findIndex(
      jobs,
      (currentJob) => currentJob.code === jobCode
    );
    const job = jobs && jobs[jobIndex];
    const operationalUnits =
      jobIndex !== -1 &&
      (
        (job || {}).operationalUnits[
          findIndex(job.operationalUnits, (unit) => unit.name === OUId)
        ] || {}
      ).items;

    let OU;
    if ((operationalUnits || []).length)
      [OU] = operationalUnits.filter((ou) => ou.code === code);

    return { code: (OU || {}).code, name: (OU || {}).name };
  };

  // Format punchIn and punchOut to match the format of NumberInput component
  const formatTimeFields = (field) => {
    if (field && field.match(/[a-z]/)) return;
    return field
      ? moment((field || "").split(" ")[1], "HH:mm").format("hh:mm")
      : "00:00";
  };

  // Check if punchOut time is less than punchIn, if yes, increase date in punchOut by 1
  const checkDateOverlap = (punchIn, punchOut, dateOfEntry, date) => {
    const pIn = moment.utc(punchIn, DATE_TIME_LONG_FORMAT, true);
    const pOut = moment.utc(punchOut, DATE_TIME_LONG_FORMAT, true);
    const pOValue = punchOut.split(" ").splice(1, 2).join(" ");
    const incresedDate = `${moment(dateOfEntry)
      .add(1, "day")
      .format(DATE_FORMAT)} ${
      moment(punchOut, DATE_TIME_LONG_FORMAT)
        .format(DATE_TIME_FORMAT)
        .split(" ")[1]
    }`;
    const originalDate = `${date} ${pOValue}`;
    return pIn && pOut && pOut.isBefore(pIn) ? incresedDate : originalDate;
  };

  // Set empty craft value as pre-selected in OU dropdown
  const setEmptyOperationalUnitIndex = (defaultJobCode, changedJobCode) => {
    const jobCode = changedJobCode || defaultJobCode;
    const jobIndex = findIndex(
      jobs,
      (currentJob) => currentJob.code === jobCode
    );
    const job = (jobs || [])[jobIndex] || {};

    const emptyOperationalUnit = OULevelNamesForTimeLogs.map((ou, index) => {
      const matchingjob = (
        (job.operationalUnits || []).find(
          (o) => o.name === ou.name && (o.items[0] || {}).code === "NA"
        ) || {}
      ).name;
      return matchingjob ? { index, name: matchingjob } : undefined;
    }).filter((o) => o)[0];

    return emptyOperationalUnit;
  };

  // Function to determine new entry and save buttons to be disabled according to jobs and approvals
  const TimeEntryActionsDisable = () => {
    const approvalLength =
      approvals &&
      flatten(
        approvals.map((i) => i.approvalRoles.map((role) => role <= roleLevel))
      ).filter((jobsApproved) => jobsApproved === true).length;
    return jobs.length <= approvalLength && timelogsForWeek.length;
  };

  // Index of updated field in activityLogs
  const findFieldIndex = (field, updates) => {
    const index = updates.findIndex((update) => update.fieldName === field);
    return index >= 0 ? index : updates.length;
  };

  // activityLog when entry is newly created
  const createActivityLogForNewEntry = () => {
    return [
      {
        code: activityLogs.CREATED.code,
        timestamp: new Date().toISOString(),
        updates: []
      }
    ];
  };

  // To add UPDATED code in activityLogs on initial update
  const onInitialUpdateActivityLog = (
    fieldName,
    previousValue,
    currentValue
  ) => {
    return [
      {
        code: activityLogs.UPDATED.code,
        timestamp: new Date().toISOString(),
        updates: [{ fieldName, previousValue, currentValue }]
      }
    ];
  };

  // On update after Update Activity code is created (Activity logs in an entry for same time stamp)
  const updatedActivityLogs = (
    log,
    fieldName,
    previousValue = undefined,
    currentValue
  ) => {
    if ((log || {}).activityLogs) {
      const copyActivityLogs = cloneDeep((log || {}).activityLogs);
      const fieldIndex = findFieldIndex(fieldName, copyActivityLogs[0].updates);
      copyActivityLogs[0].updates.splice(fieldIndex, 1);
      copyActivityLogs[0].updates.unshift({
        fieldName,
        previousValue,
        currentValue
      });
      return copyActivityLogs;
    }
    return onInitialUpdateActivityLog(fieldName, previousValue, currentValue);
  };

  const getStartOrEndDateOfPayPeriod = (date = "") => {
    if (date === "startDate")
      return moment
        .utc(
          (payPeriods || [])[findIndex(payPeriods || [], ["_id", payPeriodId])]
            .startDate
        )
        .format(INPUT_DATE_FORMAT);
    if (date === "endDate")
      return moment
        .utc(
          (payPeriods || [])[findIndex(payPeriods || [], ["_id", payPeriodId])]
            .endDate
        )
        .format(INPUT_DATE_FORMAT);
    return "";
  };

  const checkMandatoryFields = () => {
    const updates = Object.values(timeLogs).filter((log) =>
      // eslint-disable-next-line no-prototype-builtins
      log.hasOwnProperty("updated")
    );
    const checkFields =
      updates.length &&
      updates.filter((update) => update.date && update.dateOfEntry);
    return checkFields.length === updates.length;
  };

  // Format input changes to set the payload
  const handleUpdates = (type, typeIndex, index, initial, update) => {
    const updatedData = (type, update, log, index) =>
      ({
        dateOfEntry: {
          [type]: moment(update, INPUT_DATE_FORMAT).toISOString(),
          date: moment(update, INPUT_DATE_FORMAT).format(DATE_FORMAT),
          punchIn: (log || {}).punchIn
            ? replace(
                (log || {}).punchIn,
                (log || {}).punchIn.split(" ")[0],
                moment(update).format(DATE_FORMAT)
              )
            : undefined,
          punchOut: (log || {}).punchOut
            ? replace(
                (log || {}).punchOut,
                (log || {}).punchOut.split(" ")[0],
                moment(update).format(DATE_FORMAT)
              )
            : undefined,
          activityLogs: (() => {
            if (index.includes("new")) return createActivityLogForNewEntry();
            return (
              type === "dateOfEntry" &&
              updatedActivityLogs(
                log,
                "Date",
                initial,
                moment(update, INPUT_DATE_FORMAT).format(DATE_FORMAT),
                update
              )
            );
          })()
        },
        jobCode: {
          [type]: (update || {}).code || "",
          OULevelValues: setEmptyOperationalUnitIndex(
            (log || {}).code,
            (update || {}).code || "",
            index
          )
            ? {
                [setEmptyOperationalUnitIndex(
                  (log || {}).jobCode,
                  (update || {}).code || "",
                  index
                ).name]: "NA"
              }
            : {},
          activityLogs: (() => {
            if (index.includes("new")) return createActivityLogForNewEntry();
            return (
              type === "jobCode" &&
              updatedActivityLogs(
                log,
                "Job Code",
                initial && initial.length
                  ? initial.concat(
                      "-",
                      (
                        jobs[
                          findIndex(jobs, (option) => option.code === initial)
                        ] || {}
                      ).name
                    )
                  : undefined,
                ((update || {}).code || "").concat("-", (update || {}).name)
              )
            );
          })()
        },
        punchIn: {
          punchIn: update,
          manualPunchIn: update,
          activityLogs: (() => {
            if (index.includes("new")) return createActivityLogForNewEntry();
            return (
              type === "punchIn" &&
              updatedActivityLogs(
                log,
                "Clock In",
                (initial.length &&
                  moment(initial, "DD-MM-YYYY HH:mm").format(
                    "DD-MM-YYYY hh:mm A"
                  )) ||
                  undefined,
                update
              )
            );
          })()
        },
        punchOut: {
          punchOut: update,
          manualPunchOut: update,
          activityLogs: (() => {
            if (index.includes("new")) return createActivityLogForNewEntry();
            return (
              type === "punchOut" &&
              updatedActivityLogs(
                log,
                "Clock Out",
                (initial.length &&
                  moment(initial, "DD-MM-YYYY HH:mm").format(
                    "DD-MM-YYYY hh:mm A"
                  )) ||
                  undefined,
                update
              )
            );
          })()
        },
        hoursType: {
          [type]: (update || {}).name,
          activityLogs: (() => {
            if (index.includes("new")) return createActivityLogForNewEntry();
            return (
              type === "hoursType" &&
              updatedActivityLogs(log, "Pay Type", initial, (update || {}).name)
            );
          })()
        },
        operatedTime: { [type]: update },
        idleTime: { [type]: update }
      }[type] || {
        OULevelValues: {
          ...(log || {}).OULevelValues,
          ...{
            [type]: (update || {}).code || ""
          }
        },
        activityLogs: (() => {
          if (index.includes("new")) return createActivityLogForNewEntry();
          return updatedActivityLogs(
            log,
            OULevelNamesForTimeLogs[typeIndex].label,
            initial[type]
              ? initial[type].concat(
                  "-",
                  getOperationalUnits(
                    timelogsForWeek.find((timeLog) => timeLog._id === log._id)
                      .jobCode,
                    type,
                    initial[type]
                  ).name
                )
              : undefined,
            ((update || {}).code || "").concat("-", (update || {}).name)
          );
        })()
      });

    setTimeLogs((logs) => ({
      ...logs,
      [index]: {
        ...logs[index],
        ...updatedData(type, update, logs[index], index),
        updated: true
      }
    }));
  };

  const getJobsCallBack = useCallback(
    (timeLog) => () =>
      (jobs || [])[
        findIndex(jobs || [], (option) => option.code === timeLog.jobCode)
      ],
    [jobs]
  );

  const computedTitle = (entry) => {
    // Main case: checking if payPeriod is Locked
    if (isPayPeriodLocked) {
      return t("rowTooltipMessages.payperiod_is_locked");
    }
    // Other cases:

    if (entry.hasAccess === false) {
      return t("rowTooltipMessages.no_job_access");
    }

    if (entry.jobCode && !unUploadedJobs.includes(entry.jobCode) && entry._id) {
      return t("rowTooltipMessages.entry_already_uploaded");
    }

    if (isApproved(entry.jobCode))
      return t("rowTooltipMessages.entry_already_approved");

    return "";
  };

  return (
    <div>
      {/* Timelog Entry Details Table */}
      {payPeriods.length && payPeriodId && rows && (
        <Paper className={classes.paper}>
          <Table
            className={classes.table}
            size='small'
            aria-label='time-entry-table'
            padding='none'
          >
            <TableHead>
              <TableRow>
                {headers.map((headCell, index) => (
                  <TableCell
                    key={index}
                    padding={headCell.disablePadding ? "none" : "default"}
                    className={clsx(classes.headerCell, {
                      [classes.fixedHeader]: headCell.id === "actions"
                    })}
                  >
                    {headCell.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length ? (
                rows.map((row, logIndex) => {
                  const index = row._id || `new_${logIndex}`;
                  const timeLog = (timeLogs || {})[index] || {};

                  return (
                    <HoverTooltip
                      key={index}
                      title={computedTitle(row)}
                      TransitionComponent={Zoom}
                    >
                      <TableRow
                        hover
                        tabIndex={-1}
                        onKeyUp={handleNewEntry}
                        className={
                          loading &&
                          !differenceWith(timelogsForWeek, rows, !isEqual)
                            .length
                            ? classes.blur
                            : ""
                        }
                      >
                        {headers.map(
                          (header) =>
                            ({
                              dateOfEntry: (
                                <TableCell
                                  className={classes.cell}
                                  key={`dateOfEntry_${index}`}
                                >
                                  <TextField
                                    id={`table_${rowIndex}_row_${index}_dateOfEntry`}
                                    name='dateOfEntry'
                                    fullWidth
                                    value={moment(
                                      timeLog.date,
                                      DATE_FORMAT
                                    ).format(INPUT_DATE_FORMAT)}
                                    disabled={isDisabled(row)}
                                    className={classes.textField}
                                    InputProps={{
                                      classes: {
                                        input: classes.textField
                                      },
                                      disableUnderline: true,
                                      autoFocus: focus, // Will be set to true once New Entry button is clicked
                                      inputProps: {
                                        type: "date",
                                        min: getStartOrEndDateOfPayPeriod(
                                          "startDate"
                                        ),
                                        max: getStartOrEndDateOfPayPeriod(
                                          "endDate"
                                        )
                                      }
                                    }}
                                    onBlur={(event) => {
                                      if (
                                        moment(event.target.value).isBefore(
                                          getStartOrEndDateOfPayPeriod(
                                            "startDate"
                                          )
                                        ) ||
                                        moment(event.target.value).isAfter(
                                          getStartOrEndDateOfPayPeriod(
                                            "endDate"
                                          )
                                        )
                                      ) {
                                        handleUpdates(
                                          "dateOfEntry",
                                          "",
                                          index,
                                          row.date || "",
                                          null
                                        );
                                        dispatch(
                                          customFailedMessage(
                                            t("equipmentManager.date_not_valid")
                                          )
                                        );
                                      }
                                    }}
                                    onChange={(event) => {
                                      event.persist();
                                      handleUpdates(
                                        "dateOfEntry",
                                        "",
                                        index,
                                        row.date || "",
                                        event.target.value
                                      );
                                    }}
                                  />
                                </TableCell>
                              ),
                              jobCode: (
                                <TableCell
                                  className={classes.cell}
                                  key={`jobCode_${index}`}
                                  onScroll={(event) => {
                                    event.persist();
                                    setClientHeight(event.target.clientHeight);
                                    setScrollTop(event.target.scrollTop);
                                    setScrollHeight(event.target.scrollHeight);
                                  }}
                                >
                                  {jobs.length && (
                                    <DropdownSearchComponent
                                      options={
                                        isDisabled(row)
                                          ? invalidJobs
                                          : validJobs(userJobs)
                                      }
                                      searchOptions={
                                        search && search.length
                                          ? userJobsSearchResults
                                          : []
                                      }
                                      width='100%'
                                      value={getJobsCallBack(timeLog)()}
                                      id={`table_${rowIndex}_row_${index}_userJobs`}
                                      disabled={isDisabled(row)}
                                      handleChange={(change) => {
                                        if (
                                          change &&
                                          change.code &&
                                          !selectedSearchJobs.find(
                                            (job) => job.code === change.code
                                          )
                                        ) {
                                          setSelectedSearchJobs([
                                            ...selectedSearchJobs,
                                            change
                                          ]);
                                        }
                                        setSearch("");
                                        handleUpdates(
                                          "jobCode",
                                          "",
                                          index,
                                          row.jobCode || "",
                                          change
                                        );
                                      }}
                                      handleJobSearch={handleJobSearch}
                                      onDropDownChange={() => setSearch("")}
                                    />
                                  )}
                                </TableCell>
                              ),
                              punchIn: (
                                <TableCell
                                  className={
                                    !row.isTrustedInTime
                                      ? classes.colorCell
                                      : classes.cell
                                  }
                                  key={`punchIn_${index}`}
                                >
                                  <NumberFormat
                                    id={`table_${rowIndex}_row_${index}_punchIn`}
                                    format={`##:## ${
                                      (suffix || {})[
                                        `${rowIndex}${index}punchIn`
                                      ] || "AM"
                                    }`}
                                    decimalScale={2}
                                    decimalSeparator=':'
                                    mask={["H", "H", "m", "m", "A"]}
                                    value={formatTimeFields(timeLog.punchIn)}
                                    disabled={isDisabled(row)}
                                    inputMode='numeric'
                                    className={classes.label}
                                    InputProps={{
                                      className:
                                        timeLog.punchIn ||
                                        (timeLog.punchIn && timeLog.punchOut) ||
                                        row.hoursWorked
                                          ? classes.textField
                                          : classes.invalidValue,
                                      disableUnderline: true
                                    }}
                                    customInput={TextField}
                                    onValueChange={(val) => {
                                      const punchIn = `${timeLog.date || ""} ${
                                        val.formattedValue
                                      }`;
                                      const punchInPunchOutTimeDifference =
                                        moment(
                                          timeLog.punchOut,
                                          DATE_TIME_LONG_FORMAT
                                        ).diff(
                                          moment(
                                            punchIn,
                                            DATE_TIME_LONG_FORMAT
                                          ),
                                          "hours",
                                          true
                                        );
                                      if (
                                        punchIn &&
                                        timeLog.punchOut &&
                                        punchInPunchOutTimeDifference >= 24
                                      ) {
                                        // if punchOut is greater than punchIn by 24 hours or more --- move punchIn to previous day date
                                        handleUpdates(
                                          "punchOut",
                                          "",
                                          index,
                                          row.punchOut || "",
                                          moment(
                                            timeLog.punchOut,
                                            DATE_TIME_FORMAT
                                          )
                                            .subtract(1, "day")
                                            .format(DATE_TIME_LONG_FORMAT)
                                        );
                                      } else if (
                                        punchIn &&
                                        timeLog.punchOut &&
                                        punchInPunchOutTimeDifference < 0
                                      ) {
                                        // if punchOut is entered first and then punchIn is entered (if punchIn > punchOut --- move punchOut to next date )
                                        handleUpdates(
                                          "punchOut",
                                          "",
                                          index,
                                          row.punchOut || "",
                                          moment(
                                            timeLog.punchOut,
                                            DATE_TIME_FORMAT
                                          )
                                            .add(1, "day")
                                            .format(DATE_TIME_LONG_FORMAT)
                                        );
                                      }
                                      handleUpdates(
                                        "punchIn",
                                        "",
                                        index,
                                        row.punchIn || "",
                                        punchIn
                                      );
                                    }}
                                    onChange={(event) => {
                                      event.persist();
                                      const value =
                                        ((event || {}).target || {}).value ||
                                        "";
                                      setSuffix((suffixes) => ({
                                        ...suffixes,
                                        ...getTimeSuffix(
                                          value.split(":")[0],
                                          `${rowIndex}${index}punchIn`
                                        )
                                      }));
                                    }}
                                    suffix={
                                      (suffix || {})[
                                        `${rowIndex}${index}punchIn`
                                      ]
                                    }
                                  />
                                </TableCell>
                              ),
                              punchOut: (
                                <TableCell
                                  className={
                                    !row.isTrustedOutTime
                                      ? classes.colorCell
                                      : classes.cell
                                  }
                                  key={`punchOut_${index}`}
                                >
                                  <NumberFormat
                                    id={`table_${rowIndex}_row_${index}_punchOut`}
                                    format={`##:## ${
                                      (suffix || {})[
                                        `${rowIndex}${index}punchOut`
                                      ] || "AM"
                                    }`}
                                    decimalScale={2}
                                    decimalSeparator=':'
                                    mask={["H", "H", "m", "m", "A"]}
                                    value={formatTimeFields(timeLog.punchOut)}
                                    disabled={isDisabled(row)}
                                    inputMode='numeric'
                                    className={classes.label}
                                    InputProps={{
                                      className:
                                        timeLog.punchOut ||
                                        (timeLog.punchIn && timeLog.punchOut) ||
                                        row.hoursWorked
                                          ? classes.textField
                                          : classes.invalidValue,
                                      disableUnderline: true
                                    }}
                                    customInput={TextField}
                                    onValueChange={(val) => {
                                      const punchOut = timeLog.punchIn
                                        ? checkDateOverlap(
                                            moment(
                                              timeLog.punchIn,
                                              DATE_TIME_LONG_FORMAT,
                                              true
                                            ).isValid()
                                              ? timeLog.punchIn
                                              : moment(
                                                  timeLog.punchIn,
                                                  DATE_TIME_FORMAT
                                                ).format(DATE_TIME_LONG_FORMAT),
                                            `${timeLog.date} ${val.formattedValue}`,
                                            timeLog.dateOfEntry,
                                            timeLog.date
                                          )
                                        : `${timeLog.date} ${val.formattedValue}`;
                                      handleUpdates(
                                        "punchOut",
                                        "",
                                        index,
                                        row.punchOut || "",
                                        punchOut
                                      );
                                    }}
                                    onChange={(event) => {
                                      event.persist();
                                      const value =
                                        ((event || {}).target || {}).value ||
                                        "";
                                      setSuffix((suffixes) => ({
                                        ...suffixes,
                                        ...getTimeSuffix(
                                          value.split(":")[0],
                                          `${rowIndex}${index}punchOut`
                                        )
                                      }));
                                    }}
                                    suffix={
                                      (suffix || {})[
                                        `${rowIndex}${index}punchOut`
                                      ]
                                    }
                                  />
                                </TableCell>
                              ),
                              hoursType: (
                                <TableCell
                                  className={classes.cell}
                                  key={`hoursType${index}`}
                                >
                                  <DropdownSearchComponent
                                    options={hoursTypeOptions.map((h) => ({
                                      name: h.name
                                    }))}
                                    defaultValue={{
                                      code: "",
                                      name: row.hoursType
                                    }}
                                    disabled={isDisabled(row)}
                                    value={{
                                      code: "",
                                      name: timeLog.hoursType
                                    }}
                                    id={`table_${rowIndex}_row_${index}_hoursType`}
                                    width='100%'
                                    handleChange={(change) => {
                                      handleUpdates(
                                        "hoursType",
                                        "",
                                        index,
                                        row.hoursType || "",
                                        change
                                      );
                                    }}
                                  />
                                </TableCell>
                              ),
                              hoursWorked: (
                                <TableCell
                                  className={clsx(
                                    classes.cell,
                                    loading || isApproved(row.jobCode)
                                      ? classes.disabledCell
                                      : ""
                                  )}
                                  key={`hoursWorked_${index}`}
                                >
                                  {row.hoursWorked
                                    ? row.hoursWorked.toLocaleString(
                                        undefined,
                                        {
                                          minimumFractionDigits: 2
                                        }
                                      )
                                    : ""}
                                </TableCell>
                              ),
                              inLocation: (
                                <TableCell
                                  className={clsx(
                                    classes.cell,
                                    classes.location,
                                    loading || isApproved(row.jobCode)
                                      ? classes.disabledCell
                                      : ""
                                  )}
                                  key={`clockInLocation_${index}`}
                                >
                                  {clockInLocation(row)}
                                </TableCell>
                              ),
                              outLocation: (
                                <TableCell
                                  className={clsx(
                                    classes.cell,
                                    classes.location,
                                    loading || isApproved(row.jobCode)
                                      ? classes.disabledCell
                                      : ""
                                  )}
                                  key={`clockOutLocation_${index}`}
                                >
                                  {clockOutLocation(row)}
                                </TableCell>
                              ),
                              note: (
                                <TableCell
                                  className={clsx(classes.cell, classes.note)}
                                  key={`note_${index}`}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                  }}
                                >
                                  {row.notes && row.notes[0] ? (
                                    <NotesToolTip note={row.notes[0]} />
                                  ) : (
                                    "-"
                                  )}
                                </TableCell>
                              ),
                              actions: (
                                <TableCell
                                  className={clsx(
                                    classes.menu,
                                    classes.fixedColumn
                                  )}
                                  key={`actions_${index}`}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                  }}
                                >
                                  {/* Row actions */}
                                  {/* eslint-disable-next-line */}
                                  <Button
                                    id={`table_${rowIndex}_row_${index}_action`}
                                    aria-controls='actions-control'
                                    aria-haspopup='true'
                                    className={classes.menuButton}
                                    onClick={(e) => {
                                      menuClick(e, index);
                                    }}
                                    aria-label={index}
                                    accessKey={logIndex}
                                  >
                                    <MoreHorizIcon />
                                  </Button>
                                </TableCell>
                              )
                            }[header.id] || (
                              <TableCell
                                className={classes.cell}
                                key={header.OUId}
                              >
                                {/* Dropdowns for OULevelValues */}
                                {jobs.length && (
                                  <DropdownSearchComponent
                                    id={`table_${rowIndex}_row_${index}_${header.OUId}_${header.OUIndex}`}
                                    width='100%'
                                    options={setOperationalUnitsOptions(
                                      { [index]: timeLog.jobCode },
                                      index,
                                      header.OUId,
                                      row.hasAccess
                                    )}
                                    disabled={isDisabled(row)}
                                    value={getOperationalUnits(
                                      timeLog.jobCode,
                                      header.OUId,
                                      (timeLog.OULevelValues || {})[
                                        header.OUId
                                      ],
                                      row.hasAccess
                                    )}
                                    handleChange={(change) => {
                                      handleUpdates(
                                        header.OUId,
                                        header.OUIndex,
                                        index,
                                        row.OULevelValues || {},
                                        change
                                      );
                                    }}
                                  />
                                )}
                              </TableCell>
                            ))
                        )}
                      </TableRow>
                    </HoverTooltip>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={headers.length + 1}
                    className={classes.emptyCell}
                  >
                    {t("payroll.make_an_entry")}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            <caption>
              <div className={classes.caption}>
                <div className={classes.captionLeft}>
                  <Button
                    variant='contained'
                    color='secondary'
                    size='small'
                    className={clsx(classes.button, classes.newEntryButton)}
                    onClick={handleNewEntry}
                    disabled={
                      loading ||
                      disabledCell ||
                      TimeEntryActionsDisable() ||
                      isPayPeriodLocked
                    }
                  >
                    {t("commonActions.new_entry")}
                  </Button>
                </div>
                <div className={classes.captionRight}>
                  <Button
                    variant='outlined'
                    color='secondary'
                    size='small'
                    className={classes.button}
                    onClick={() => {
                      handleCollapse();
                    }}
                  >
                    {t("commonActions.cancel")}
                  </Button>
                  <Button
                    variant='contained'
                    color='secondary'
                    size='small'
                    className={classes.button}
                    onClick={(event) => {
                      event.stopPropagation();
                      handleSave(event);
                    }}
                    disabled={
                      !checkMandatoryFields() ||
                      disabledCell ||
                      TimeEntryActionsDisable() ||
                      isPayPeriodLocked ||
                      apiStatus.isLoading
                    }
                  >
                    {t("commonActions.save")}
                  </Button>
                </div>
              </div>
            </caption>
          </Table>
        </Paper>
      )}

      {/* Action menu for rows */}
      <Menu
        id='actions-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={menuClose}
      >
        {actionMenuOptions.map((option) => (
          <MenuItem
            className={classes.menuItems}
            onClick={(e) => {
              menuClose(e, option, selectedEntries[currentIndex]);
            }}
            key={option.key}
            disabled={option.disabled}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>

      {/* Dialog for delete time entry action */}
      <ConfirmationDialogComponent
        open={confirmDialogShow}
        close={() => handleClose()}
        selectedData={selectedEntries[currentIndex]}
        dialogAttributes={attributes}
        handleDialog={deleteEntry}
      />

      {/* Dialog for split hours in time entry action */}
      {splitHoursShow && (
        <SplitHoursComponent
          open={splitHoursShow}
          Close={handleSplitHoursClose}
          selectedTimeLog={selectedEntries[currentIndex]}
          crewMember={crew.empName}
          payPeriodId={payPeriodId}
          OULevelNamesForTimeLogs={OULevelNamesForTimeLogs}
          getOperationalUnits={getOperationalUnits}
          jobs={jobs}
          setJobs={setJobs}
          userJobsOffset={jobOffset}
          setJobOffset={setJobOffset}
          handleJobSearch={handleJobSearch}
        />
      )}

      {/* Drawer for Activity Logs */}
      {drawerState.right && (
        <DrawerComponent
          header={t("payroll.activity_logs_title")}
          openDrawer={drawerState}
          closeDrawer={() => toggleDrawer("right", false)}
          drawerPaper={classes.drawerPaper}
          handleScroll={() => false}
          actions={undefined}
          drawerAttributes={
            <>
              <Divider variant='middle' />
              {activityLogsLoading && (
                <Paper
                  elevation={0}
                  className={classes.paperWidth}
                  style={{ textAlign: "center" }}
                >
                  <CircularProgress />
                </Paper>
              )}
              {!activityLogsLoading &&
                activityLogsTimeEntries &&
                activityLogsTimeEntries.map((activityLog) => (
                  <>
                    <Divider
                      variant='middle'
                      className={classes.dividerStyles}
                    />
                    <Paper className={classes.paperWidth} elevation={0}>
                      <Grid container className={classes.activityLogGrid}>
                        <Grid item xs={12} className={classes.activityLogGrid}>
                          <small className='text-muted'>
                            {moment(activityLog.timestamp).format(
                              ACTIVITY_LOGS_TIME_FORMAT
                            )}
                          </small>
                        </Grid>
                        <Grid item xs={12} className={classes.activityLogGrid}>
                          <b>{`${activityLog.modifiedBy.empName}`} </b>
                          {t(
                            `payroll.${getActivityName(
                              activityLog.code
                            )}_time_entry`
                          )}
                        </Grid>
                        {activityLog.updates.map(
                          (field) =>
                            field.currentValue && (
                              <Grid
                                key={field._id}
                                item
                                container
                                className={classes.activityLogGrid}
                              >
                                <Grid item xs={4}>
                                  {field.fieldName}
                                </Grid>
                                <Grid xs={1}>{": "} </Grid>
                                <Grid item xs={7}>
                                  {`${
                                    field.previousValue
                                      ? field.previousValue
                                      : "NA"
                                  } to 
                                ${
                                  field.currentValue ? field.currentValue : "NA"
                                }`}
                                </Grid>
                              </Grid>
                            )
                        )}
                      </Grid>
                    </Paper>
                  </>
                ))}
            </>
          }
        />
      )}
    </div>
  );
};

TimeEntryDetailsComponent.propTypes = {
  crew: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    empName: PropTypes.string.isRequired
  }).isRequired,
  userJobs: PropTypes.arrayOf(
    PropTypes.shape({
      operationalUnits: PropTypes.arrayOf(PropTypes.shape()).isRequired,
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired
    })
  ).isRequired,
  payPeriodId: PropTypes.string.isRequired,
  isPayPeriodLocked: PropTypes.bool.isRequired,
  approvals: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  timelogsForWeek: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      crewId: PropTypes.string.isRequired,
      dateOfEntry: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      jobCode: PropTypes.string,
      OULevelValues: PropTypes.shape(),
      isDeleted: PropTypes.bool,
      punchIn: PropTypes.string,
      punchOut: PropTypes.string,
      isTrustedInTime: PropTypes.bool.isRequired,
      isTrustedOutTime: PropTypes.bool.isRequired
    }).isRequired
  ).isRequired,
  jobsInWeek: PropTypes.arrayOf(
    PropTypes.shape({
      operationalUnits: PropTypes.arrayOf(PropTypes.shape()).isRequired,
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired
    })
  ).isRequired,
  OULevelNamesForTimeLogs: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  hoursTypeOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  handleCollapse: PropTypes.func.isRequired,
  rowIndex: PropTypes.number.isRequired,
  isPayrollUser: PropTypes.bool.isRequired,
  roleLevel: PropTypes.number.isRequired,
  unUploadedJobs: PropTypes.arrayOf(PropTypes.string).isRequired,
  jobOffset: PropTypes.number.isRequired,
  setJobOffset: PropTypes.func.isRequired,
  filterQuery: PropTypes.string.isRequired
};

export default TimeEntryDetailsComponent;
