import {
  CLEAR_SEARCH_RESULTS,
  SEACRH_EMPLOYEES_BY_JOB
} from "../actions/actionTypes";

export default function EmployeeByJobSearchReducer(state = [], action) {
  switch (action.type) {
    case SEACRH_EMPLOYEES_BY_JOB: {
      return action.employeeByJobSearchResults;
    }
    case CLEAR_SEARCH_RESULTS: {
      // Clear search results from state so that upon searching the next time, previous results won't show up
      if (
        action.actionType === "employeeByJobSearchResults" &&
        action.clear &&
        state.length
      )
        return [];
      else return state;
    }
    default:
      return state;
  }
}
