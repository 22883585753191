import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import clsx from "clsx";
import { get, includes, range } from "lodash";
import PropTypes from "prop-types";

import Button from "@material-ui/core/Button";
import Collapse from "@material-ui/core/Collapse";
import Fab from "@material-ui/core/Fab";
import IconButton from "@material-ui/core/IconButton";
import LinearProgress from "@material-ui/core/LinearProgress";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { ReactComponent as ExpandAllIcon } from "../../../assets/img/dropdown1.svg";
import { ReactComponent as CollapseAllIcon } from "../../../assets/img/dropdown2.svg";
import * as AuthorisersReportsPreviewActions from "../../../redux/actions/AuthorisersReportsPreviewActions";
import * as ExportCsvActions from "../../../redux/actions/exportCsvActions";
import {
  tableStyles,
  toolbarStyles
} from "../../../styles/components/WeeklyReportsTableStyles";
import ReportsFilterDialog from "../ReportsFilterDialog";

import PreviewReportsDetailsComponent from "./PreviewReportsDetailsComponent";

const useStyles = makeStyles({
  toolBar: {
    padding: 0,
    marginBottom: "5px"
  },
  lastFab: {
    marginRight: 0
  },
  headerCell: {
    padding: "8px !important"
  },
  fixedHeader: {
    maxWidth: "90px"
  },
  noBorder: {
    border: "none"
  },
  paper: {
    padding: "24px"
  },
  actionsCenterCell: {
    justifyContent: "center",
    margin: 0
  },
  cellOverflow: {
    whiteSpace: "nowrap",
    maxWidth: "400px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    pointerEvents: "none"
  },
  lastColumnPaddedCell: {
    padding: "12px 8px !important"
  }
});

const calculateQuery = (authorisersReportFilter) => {
  const {
    reportsOf,
    employees,
    jobFilter,
    selectedEmployees,
    equipments,
    assignedTo,
    selectedEquipments
  } = authorisersReportFilter;

  const scenarios = {
    employees: {
      allEmployees:
        jobFilter.length > 0
          ? `&jobs=${jobFilter.map((job) => job.id).join(",")}`
          : "",
      selectedEmployees:
        selectedEmployees.length > 0
          ? `&ids=${selectedEmployees.map((emp) => emp._id).join(",")}`
          : "",
      unassignedOnly: "&unassigned=true"
    },
    equipments: {
      allEquipments:
        assignedTo.length > 0
          ? `&assignedTo=${assignedTo
              .map((assigned) => assigned._id)
              .join(",")}`
          : "",
      selectedEquipments:
        selectedEquipments.length > 0
          ? `&ids=${selectedEquipments.map((equip) => equip._id).join(",")}`
          : "",
      unassignedOnly: "&unassigned=true"
    }
  };

  return (
    scenarios[reportsOf][reportsOf === "employees" ? employees : equipments] ||
    ""
  );
};

const PreviewReportsComponent = (props) => {
  const toolbarClasses = toolbarStyles();
  const classes = tableStyles();
  const localClasses = useStyles();
  const { t } = useTranslation();
  const { handleDropdownScroll } = props;
  const history = useHistory();
  const [expanded, setExpanded] = useState({});
  const [showModFilter, setShowModFilter] = useState(false);
  const dispatch = useDispatch();
  const { apiStatus, authorisersReportFilter, authorisersReportsPreview } =
    useSelector((state) => state);

  const disableOnLoad =
    apiStatus &&
    apiStatus.isLoading &&
    (apiStatus.reducer.includes("loadEmployeeReportsPreview") ||
      apiStatus.reducer.includes("loadEquipmentReportsPreview"));

  const isReportOfEmployees = useMemo(
    () => authorisersReportFilter.reportsOf === "employees",
    [authorisersReportFilter.reportsOf]
  );

  const headers = [
    ...(isReportOfEmployees
      ? [
          {
            id: "empID",
            type: "string",
            numeric: false,
            disablePadding: true,
            label: t("authorizerContainer.emp_id"),
            sortable: true,
            sortId: "empID",
            classes: classes.cell
          },
          {
            id: "empName",
            type: "string",
            disablePadding: true,
            label: t("authorizerContainer.name"),
            sortable: true,
            sortId: "empName",
            classes: classes.cell
          },
          {
            id: "role.roleName",
            type: "string",
            disablePadding: true,
            label: t("authorizerContainer.role"),
            sortable: false,
            classes: classes.cell
          },

          ...(authorisersReportFilter.employees !== "unassignedOnly"
            ? [
                {
                  id: "jobs",
                  type: "jobsArray",
                  disablePadding: true,
                  label: t("authorizerContainer.jobs_capitalized"),
                  sortable: false,
                  mapKey: "name",
                  classes: clsx(classes.cell, localClasses.cellOverflow)
                },
                {
                  id: "actions",
                  type: "actions",
                  disablePadding: true,
                  label: "",
                  sortable: false,
                  classes: clsx(classes.cell, classes.fixedColumn)
                }
              ]
            : [
                {
                  id: "assignedCrew",
                  type: "assignedCrew",
                  disablePadding: true,
                  label: t("authorizerContainer.assigned_crew"),
                  sortable: false,
                  classes: clsx(classes.cell, localClasses.lastColumnPaddedCell)
                }
              ])
        ]
      : [
          {
            id: "code", //equipID
            type: "string",
            numeric: false,
            disablePadding: true,
            label: t("authorizerContainer.id"),
            sortable: true,
            sortId: "code",
            classes: classes.cell
          },
          {
            id: "name", //equipName
            type: "string",
            disablePadding: true,
            label: t("authorizerContainer.name"),
            sortable: true,
            sortId: "name",
            classes: classes.cell
          },
          {
            id: "assignedTo",
            type: "assignedToArray",
            disablePadding: true,
            label: t("authorizerContainer.assigned_to"),
            sortable: false,
            mapKey: "empName",
            classes: clsx(classes.cell, localClasses.lastColumnPaddedCell)
          }
        ])
  ];

  const [order, setOrder] = useState({
    [isReportOfEmployees ? "empName" : "name"]: "asc"
  });
  const [sortBy, setSortBy] = useState(
    isReportOfEmployees ? "empName" : "name"
  );
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(50);
  const [paginationLimit, setPaginationLimit] = useState(50);
  const [query, setQuery] = useState("");

  const onModifyFilterSave = (reportsOf) => {
    const isEmployeeReportInModFilter = reportsOf === "employees";
    setExpanded({});
    setOffset(0);
    setSortBy(isEmployeeReportInModFilter ? "empName" : "name");
    setOrder({ [isEmployeeReportInModFilter ? "empName" : "name"]: "asc" });
  };

  const loadPreviewReports = () => {
    const calculatedQuery = calculateQuery(authorisersReportFilter);
    const newQuery = `${calculatedQuery}&orderBy=${sortBy}-${order[sortBy]}`;
    if (isReportOfEmployees) {
      dispatch(
        AuthorisersReportsPreviewActions.loadEmployeeReportsPreview(
          newQuery,
          offset,
          limit
        )
      );
    } else {
      //isReportOfEquipments
      dispatch(
        AuthorisersReportsPreviewActions.loadEquipmentReportsPreview(
          newQuery,
          offset,
          limit
        )
      );
    }
    setQuery(newQuery);
  };

  useEffect(loadPreviewReports, [
    authorisersReportFilter,
    offset,
    limit,
    sortBy,
    order
  ]);

  const handleSort = (event, sort) => {
    if (event) {
      setOrder((o) => ({
        ...o,
        [sort]: order[sort] === "asc" ? "desc" : "asc"
      }));
      setSortBy(sort);
      setOffset(0);
    }
  };

  const handleExpandClick = (e, index) => {
    // eslint-disable-next-line no-return-assign
    setExpanded((exp) => ({
      ...exp,
      [index]: (expanded[index] = !expanded[index])
    }));
  };

  const tableHeader = (
    <TableHead key='reports-table-header'>
      <TableRow key='reports-table-header-row'>
        {headers.map((header) => (
          <TableCell
            key={header.id || header.label}
            padding={header.disablePadding ? "none" : "default"}
            className={clsx(classes.headerCell, localClasses.headerCell, {
              [classes.fixedHeader]: !header.label,
              [localClasses.fixedHeader]: !header.label
            })}
            sortDirection={
              header.sortable && sortBy === header.sortId
                ? order[sortBy]
                : false
            }
            scope='tr'
          >
            {header.sortable ? (
              <TableSortLabel
                className={classes.sortHeader}
                active={sortBy === header.sortId}
                direction={sortBy === header.sortId ? order[sortBy] : "asc"}
                onClick={(event) => handleSort(event, header.sortId)}
              >
                {header && header.label ? header.label : header.sortId}
              </TableSortLabel>
            ) : header && header.label ? (
              header.label
            ) : (
              <Button
                color='secondary'
                size='small'
                aria-label='expand-all'
                className={classes.button}
                disabled={disableOnLoad}
                onClick={() => {
                  if (
                    includes(expanded, false) &&
                    expanded.length === authorisersReportsPreview.length
                  ) {
                    setExpanded((exp) => ({
                      ...exp,
                      // eslint-disable-next-line no-return-assign
                      ...authorisersReportsPreview.map((e, i) => (e[i] = !e[i]))
                    }));
                  } else if (includes(expanded, true)) {
                    setExpanded((exp, i) => ({
                      ...exp,
                      ...authorisersReportsPreview.map((e) => Boolean(e[i]))
                    }));
                  } else {
                    setExpanded((exp, i) => ({
                      ...exp,
                      ...authorisersReportsPreview.map((e) => !e[i])
                    }));
                  }
                }}
                endIcon={
                  includes(expanded, true) ? (
                    <CollapseAllIcon
                      fill={disableOnLoad ? "rgba(0, 0, 0, 0.26)" : "#3a5998"}
                    />
                  ) : (
                    <ExpandAllIcon
                      fill={disableOnLoad ? "rgba(0, 0, 0, 0.26)" : "#3a5998"}
                    />
                  )
                }
              >
                {includes(expanded, true)
                  ? t("payroll.collapse_all_btn_label")
                  : t("payroll.expand_all_btn_label")}
              </Button>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );

  const exportXls = async () => {
    const selectedReportTypeURl = isReportOfEmployees
      ? `authoriser/employeereports/csv?${query}`
      : `authoriser/equipmentreports/csv?${query}`;

    dispatch(ExportCsvActions.exportPreviewReportsCSV(selectedReportTypeURl))
      .then(() => {
        // Redirecting to admin jobs tab
        history.push("/admin/jobs");
      })
      .catch((error) => console.log(error));
  };

  return (
    <React.Fragment key='Reports'>
      {/* ---Toolbar--- */}
      <Paper className={localClasses.paper}>
        <Toolbar
          className={clsx(toolbarClasses.root, localClasses.toolBar)}
          key='reports-table-toolbar'
        >
          <Typography className={toolbarClasses.previewtitle} variant='h5'>
            {t("authorizerContainer.reports_preview")}
          </Typography>
          <div className={toolbarClasses.buttons}>
            <Fab
              variant='extended'
              aria-label='modify-filter'
              size='medium'
              className={`${toolbarClasses.filter} ${toolbarClasses.fab}`}
              disabled={apiStatus.isLoading}
              onClick={() => setShowModFilter(true)}
            >
              {t("authorizerContainer.modify_filters")}
            </Fab>
            <Fab
              variant='extended'
              color='secondary'
              aria-label='export'
              size='medium'
              className={clsx(toolbarClasses.fab, localClasses.lastFab)}
              disabled={apiStatus.isLoading}
              onClick={exportXls}
            >
              {t("authorizerContainer.export_xls")}
            </Fab>
          </div>
        </Toolbar>

        {disableOnLoad && <LinearProgress />}

        {/* ---MainTable--- */}
        <div className={clsx(classes.tableWrapper)}>
          <Table
            className={classes.table}
            size='small'
            stickyHeader
            aria-label='preview-reports-table'
            key='preview-reports-table'
            padding='none'
          >
            {/* ---Header--- */}
            {tableHeader}
            <TableBody>
              {authorisersReportsPreview.length ? (
                authorisersReportsPreview.map((row, index) => (
                  <>
                    <TableRow hover tabIndex={-1} key={row._id || index}>
                      {row &&
                        headers.map((header) => (
                          <TableCell className={header.classes} key={header.id}>
                            {
                              {
                                string: get(row, header.id) || "-",

                                jobsArray:
                                  Array.isArray(get(row, header.id)) &&
                                  get(row, header.id).length > 0
                                    ? get(row, header.id)
                                        .map((log) => log[header.mapKey])
                                        .join(", ")
                                    : "-",

                                //this is not technically comming from API
                                assignedCrew: "Not assigned",

                                assignedToArray:
                                  Array.isArray(get(row, header.id)) &&
                                  get(row, header.id).length > 0
                                    ? get(row, header.id)
                                        .map((log) => log[header.mapKey])
                                        .join(", ")
                                    : "Unassigned",

                                actions: (
                                  <div
                                    className={clsx(
                                      classes.actionsCell,
                                      localClasses.actionsCenterCell
                                    )}
                                  >
                                    <IconButton
                                      className={clsx(classes.expand, {
                                        [classes.expandOpen]: expanded[index]
                                      })}
                                      disabled={disableOnLoad}
                                      onClick={(event) => {
                                        event.preventDefault();
                                        event.stopPropagation();
                                        handleExpandClick(event, index);
                                      }}
                                      aria-expanded={expanded}
                                      aria-label='expand-row'
                                    >
                                      <ExpandMoreIcon />
                                    </IconButton>
                                  </div>
                                )
                              }[header.type]
                            }
                          </TableCell>
                        ))}
                    </TableRow>

                    {/* Expansion Cell */}
                    <TableRow key={`expansion-row-${row._id}`}>
                      <TableCell
                        colSpan={headers.length + 1}
                        key='expansion-cell'
                      >
                        <Collapse
                          key={`collapse${index}`}
                          in={expanded[index]}
                          unmountOnExit
                        >
                          <PreviewReportsDetailsComponent
                            details={row.personnel}
                          />
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </>
                ))
              ) : (
                <TableRow key='empty-row'>
                  <TableCell
                    colSpan={headers.length + 1}
                    className={classes.emptyCell}
                    key='empty-cell'
                  >
                    {apiStatus && !apiStatus.isLoading
                      ? t("commonActions.no_records")
                      : t("commonActions.loading_records")}
                  </TableCell>
                </TableRow>
              )}

              {/* Pagination Row */}
              <TableRow key='pagination-row'>
                <TableCell
                  colSpan={headers.length - 1}
                  key='empty-cells'
                  className={localClasses.noBorder}
                />
                <TableCell
                  className={clsx(
                    localClasses.noBorder,
                    classes.paginationCell
                  )}
                  key='pagination-cell'
                >
                  <Select
                    key='pagination-menu'
                    labelId='pagination-limit'
                    id='pagination-limit'
                    value={paginationLimit}
                    disableUnderline
                    onChange={(event) => {
                      setLimit(event.target.value);
                      setPaginationLimit(event.target.value);
                      setOffset(0);
                    }}
                    inputProps={{
                      className: classes.paginationDropdown
                    }}
                  >
                    <MenuItem key={25} value={25}>
                      25
                    </MenuItem>
                    {range(50, 250, 50).map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                  <Button
                    key='show-more'
                    variant='outlined'
                    color='secondary'
                    size='small'
                    className={clsx(classes.button, classes.showMoreButton)}
                    disabled={disableOnLoad}
                    onClick={() => {
                      setLimit(paginationLimit);
                      setOffset((l) => l + paginationLimit);
                    }}
                  >
                    {t("authorizerContainer.show_more")}
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </Paper>
      {showModFilter && (
        <ReportsFilterDialog
          open={showModFilter}
          close={() => setShowModFilter(false)}
          handleDropdownScroll={handleDropdownScroll}
          onModifyFilterSave={onModifyFilterSave}
          isAModifyDialog
        />
      )}
    </React.Fragment>
  );
};
PreviewReportsComponent.propTypes = {
  handleDropdownScroll: PropTypes.bool
};
export default PreviewReportsComponent;
