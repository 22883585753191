import axios from "../../axios-jwt";

import { GET_PERMISSIONS_SUCCESS } from "./actionTypes";

async function getPermissions() {
  const resp = axios.get("/permissions");
  return resp;
}

export function loadPermissionsSuccess(permissions) {
  return { type: GET_PERMISSIONS_SUCCESS, permissions };
}

export function loadPermissions() {
  return (dispatch) => {
    return getPermissions().then((obj) => {
      if (obj.status === 200) {
        dispatch(loadPermissionsSuccess(obj.data));
      }
    });
  };
}
