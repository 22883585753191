import { GET_PERMISSIONS_SUCCESS } from "../actions/actionTypes";

export default function PermissionsReducer(state = [], action) {
  switch (action.type) {
    case GET_PERMISSIONS_SUCCESS:
      return action.permissions;
    default:
      return state;
  }
}
