import lodash from "lodash";

import { ACTIVITY_LOGS_TIMEENTRY } from "../actions/actionTypes";

export default function ActivityLogsTimeEntriesReducer(state = [], action) {
  switch (action.type) {
    case ACTIVITY_LOGS_TIMEENTRY: {
      return lodash.orderBy(
        action.activityLogsTimeEntries,
        ["timestamp"],
        "desc"
      );
    }
    default:
      return state;
  }
}
