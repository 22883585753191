import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import moment from "moment";
import PropTypes from "prop-types";

import { FormHelperText, TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  rangePickerContainer: {
    width: "260px",
    margin: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    flexDirection: "column",
    gap: "10px"
  },
  fieldsFlex: {
    display: "flex",
    gap: "16px"
  },
  actionButton: {
    borderRadius: "50px"
  }
}));

function ConditionalDateRangePicker({
  periodValue,
  setPeriodValue,
  setLocalPayPeriodIndex
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [dateFilter, setDateFilter] = useState({
    startDate: moment.utc(
      periodValue.isPayPeriod ? null : periodValue.startDate
    ),
    endDate: moment.utc(periodValue.isPayPeriod ? null : periodValue.endDate)
  });

  const { startDate, endDate } = dateFilter;
  const [confirmed, setConfirmed] = useState(false);
  const initialErrorState = { status: false, message: "" };
  const [dateError, setDateError] = useState(initialErrorState);

  const setStartDate = (date) =>
    setDateFilter((prev) => ({ ...prev, startDate: date }));
  const setEndDate = (date) =>
    setDateFilter((prev) => ({ ...prev, endDate: date }));

  const save = () => {
    setConfirmed(true);
    //newEndDateStartOfDay will set the time to start of day so for same date  newEndDateStartOfDay.isAfter(moment.utc()) results false
    const newEndDateStartOfDay = endDate.clone().startOf("day");

    if (
      endDate &&
      startDate &&
      (startDate.isAfter(moment.utc(endDate)) ||
        startDate.isAfter(moment.utc()) ||
        newEndDateStartOfDay.isAfter(moment.utc()) ||
        startDate.isBefore(
          moment.utc().subtract(2, "years").subtract(1, "day")
        ))
    ) {
      setDateError({
        status: true,
        message: t("payroll.error_msg_invalid_date_entry")
      });
    } else if (!endDate.isValid() || !startDate.isValid()) {
      setDateError({
        status: true,
        message: t("payroll.error_msg_require_date_entry")
      });
    } else {
      setDateError(initialErrorState);

      const momentStart = moment
        .utc(startDate.toISOString())
        .locale(i18n.language)
        .format("MMM DD, YYYY");

      const momentEnd = moment
        .utc(endDate.toISOString())
        .locale(i18n.language)
        .format("MMM DD, YYYY");

      setPeriodValue({
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        label: `${momentStart} - ${momentEnd}`,
        isPayPeriod: false
      });
      setLocalPayPeriodIndex(null);
    }
  };

  const handleStartDateChange = (event) => {
    setConfirmed(false);
    const newStartDate = moment.utc(event.target.value);

    // Check if the input value is a valid date
    if (!newStartDate.isValid()) {
      setDateError(true);
      return;
    }

    setStartDate(newStartDate);
  };

  const handleEndDateChange = (event) => {
    setConfirmed(false);
    const inputDate = event.target.value;

    const newEndDate = moment.utc(inputDate);
    if (!newEndDate.isValid()) {
      // Invalid date format
      setDateError(true);
      return;
    }
    newEndDate.set({ hour: 23, minute: 59, second: 0, millisecond: 0 });

    setEndDate(newEndDate);
  };

  const formatDate = (date) => {
    if (!date || !moment.utc(date).isValid()) {
      return null;
    }

    return moment.utc(date).format("YYYY-MM-DD");
  };

  const isEndDateDisabled = !startDate;
  const minDate = moment.utc().subtract(2, "years");
  const maxDate = moment.utc();

  return (
    <div className={classes.rangePickerContainer}>
      <div className={classes.fieldsFlex}>
        <TextField
          label={t("commonActions.from")}
          type='date'
          InputLabelProps={{
            shrink: true
          }}
          InputProps={{
            disableUnderline: true,
            inputProps: {
              max: formatDate(maxDate),
              min: formatDate(minDate),
              defaultValue: formatDate(startDate)
            }
          }}
          onChange={handleStartDateChange}
        />
        <TextField
          label={t("commonActions.to")}
          type='date'
          InputLabelProps={{
            shrink: true
          }}
          disabled={isEndDateDisabled}
          InputProps={{
            disableUnderline: true,
            inputProps: {
              max: formatDate(maxDate),
              min: formatDate(startDate || minDate),
              defaultValue: formatDate(endDate),
              disabled: isEndDateDisabled
            }
          }}
          onChange={handleEndDateChange}
        />
      </div>
      {confirmed && dateError.status && (
        <FormHelperText error>{dateError.message}</FormHelperText>
      )}
      <Button
        variant='contained'
        color='secondary'
        size='small'
        disabled={!(startDate && endDate)}
        className={classes.actionButton}
        onClick={save}
      >
        {t("commonActions.save")}
      </Button>
    </div>
  );
}

ConditionalDateRangePicker.propTypes = {
  periodValue: PropTypes.shape({
    startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
    endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
    isPayPeriod: PropTypes.bool,
    label: PropTypes.string
  }),
  setPeriodValue: PropTypes.func,
  setLocalPayPeriodIndex: PropTypes.func
};
export default ConditionalDateRangePicker;
