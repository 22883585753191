import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#e5e5e5",
      main: "#212734",
      dark: "#5e6d8a",
      contrastText: "#ffffff"
    },
    secondary: {
      light: "#90a7d5",
      main: "#3a5998",
      dark: "#314c81",
      contrastText: "#ffffff"
    },
    success: {
      main: "#13A869"
    },
    warning: {
      main: "#A74949"
    }
  }
});

export default theme;
