/* eslint-disable no-case-declarations */
import { findIndex, orderBy } from "lodash";

import {
  CREATE_TIME_LOG,
  EDIT_TIME_LOGS,
  LOAD_TIME_LOGS,
  SPLIT_TIME_LOGS
} from "../actions/actionTypes";

export default function TimelogsReducer(state = [], action) {
  switch (action.type) {
    case CREATE_TIME_LOG:
      const allTimelogs = state.map((timelog) => timelog);
      allTimelogs.push(action.timelog);
      return allTimelogs;
    case LOAD_TIME_LOGS:
      const filteredData = orderBy(
        action.timelogs.filter((i) => i.isDeleted !== true),
        ["dateOfEntry"],
        ["asc"]
      );
      // Save records only if isDelete = false and sort them by dateOfEntry
      return filteredData;
    case EDIT_TIME_LOGS:
      const index = state
        .map((timelog) => timelog._id === action.timelogs[0]._id)
        .indexOf(true);

      const timelogs = state.map((timelog) => timelog);
      const updated = action.timelogs[0];

      if (index !== -1) {
        timelogs[index] = updated;
      } else if (updated.isDeleted === false) {
        timelogs.push(updated);
      }

      return timelogs;
    case SPLIT_TIME_LOGS:
      const timelogsArray = state.map((timelog) => timelog);
      const index1 = findIndex(timelogsArray, ["_id", action.timelogs[0]._id]);
      const index2 = findIndex(timelogsArray, ["_id", action.timelogs[1]._id]);

      if (index1 !== -1) {
        // eslint-disable-next-line prefer-destructuring
        timelogsArray[index1] = action.timelogs[0];
        timelogsArray.push(action.timelogs[1]);
      } else {
        // eslint-disable-next-line prefer-destructuring
        timelogsArray[index2] = action.timelogs[1];
        timelogsArray.push(action.timelogs[0]);
      }
      return timelogsArray;

    default:
      return state;
  }
}
