import axios from "../../axios-jwt";

import { LOAD_LABOUR_TIME_REPORTS_PREVIEW } from "./actionTypes";
import * as ApiStatusActions from "./ApiStatusActions";

async function getLabourTimeReports(query, offset, limit) {
  const resp = axios.get(
    `labourtimereports?${query}&offset=${offset}&limit=${limit}`
  );
  return resp;
}

export function loadLabourTimeReportsPreviewSuccess(labourTimeReports, offset) {
  return { type: LOAD_LABOUR_TIME_REPORTS_PREVIEW, labourTimeReports, offset };
}

export function loadLabourTimeReportsPreview(loadParams, offset, limit) {
  return (dispatch) => {
    dispatch(ApiStatusActions.apiStartLoader("labourTimeReportsPreview"));
    getLabourTimeReports(loadParams, offset, limit)
      .then((res) => {
        if (res.status === 200) {
          dispatch(loadLabourTimeReportsPreviewSuccess(res.data, offset));
          dispatch(ApiStatusActions.apiStopLoader("labourTimeReportsPreview"));
        }
        return undefined;
      })
      .catch((error) => {
        console.log(dispatch(ApiStatusActions.apiFailed(error)));
        dispatch(ApiStatusActions.apiStopLoader("labourTimeReportsPreview"));
      });
  };
}
