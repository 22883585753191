import axios from "../../axios-jwt";

import {
  CHANGE_SPECIFIC_ROLE_ROLESEARCH,
  CLEAR_SEARCH_RESULTS,
  SEARCH_ROLE_EMPLOYEES,
  UNASSIGN_CREW_PERSONNEL_TO_ROLESEARCH,
  UPDATE_CREW_PERSONNEL_TO_ROLESEARCH
} from "./actionTypes";
import * as ApiStatusActions from "./ApiStatusActions";

async function searchEmployees(id, query, offset, limit, orderBy, order) {
  return axios.get(
    `roles/${id}/employees?search=${query}&offset=${offset}&limit=${limit}&orderBy=${orderBy}-${order}`
  );
}

export function searchEmployeesResults(employeeSearchResults, offset) {
  return { type: SEARCH_ROLE_EMPLOYEES, employeeSearchResults, offset };
}

export function clearEmployeeResults() {
  return { type: CLEAR_SEARCH_RESULTS };
}

export function searchEmployeesWithParam(
  id,
  queryParam,
  offset,
  limit,
  orderBy = "empName",
  order = "asc"
) {
  return (dispatch) => {
    dispatch(ApiStatusActions.apiStartLoader("RoleSearchResults"));
    searchEmployees(id, queryParam, offset, limit, orderBy, order)
      .then((res) => {
        if (res.status === 200) {
          dispatch(searchEmployeesResults(res.data, offset));
          // dispatch(EmployeesActions.filterEmployees(res.data));
          dispatch(ApiStatusActions.apiStopLoader("RoleSearchResults"));
        }
      })
      .catch(() =>
        dispatch(ApiStatusActions.apiStopLoader("RoleSearchResults"))
      );
  };
}

// change roles from specific role
export function UpdateSpecificRoleEmployees(
  SelectedEmployee,
  previousRole,
  selectedRole
) {
  return (dispatch) => {
    dispatch({
      type: CHANGE_SPECIFIC_ROLE_ROLESEARCH,
      SelectedEmployee,
      previousRole,
      selectedRole
    });
  };
}

// when assigned to clicked from crew members
export function UpdateCrewPersonnelTo(
  personnelToArray,
  SelectedEmployees,
  roleId
) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_CREW_PERSONNEL_TO_ROLESEARCH,
      personnelToArray,
      SelectedEmployees,
      roleId
    });
  };
}

// when role user is unassigned from crew members or last level
export function UnassignPersonnelTo(id, unassigned, roleId) {
  return (dispatch) => {
    dispatch({
      type: UNASSIGN_CREW_PERSONNEL_TO_ROLESEARCH,
      id,
      unassigned,
      roleId
    });
  };
}

export function clearEmployees() {
  return (dispatch) => {
    return dispatch(clearEmployeeResults());
  };
}
