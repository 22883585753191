import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { compose } from "redux";

import { Tooltip } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import * as AuthActions from "../redux/actions/AuthActions";
import * as CompaniesActions from "../redux/actions/CompaniesActions";
import permissionsList from "../utillities/Permissions";

const useStyles = makeStyles(() => ({
  gridPadding: {
    padding: "10px"
  },
  companyOptions: {
    fontSize: "20px",
    textTransform: "capitalize"
  },
  button: {
    float: "right"
  },
  headerIcon: {
    float: "right",
    cursor: "pointer"
  }
}));

const AssignCompanyModalComponent = (props) => {
  const { t } = useTranslation();

  const [companyName, setCompanyName] = useState("");

  const { show, history, handleClose } = props;
  const { companies, companyJobs, userDetails, toSwitch, selectedCompany } =
    useSelector((state) => state);
  const classes = useStyles();
  const dispatch = useDispatch();
  const checkUserIsInAuthorizerView =
    (userDetails.isSuperAdmin && toSwitch) ||
    (!toSwitch &&
      userDetails.role &&
      userDetails.role.permissions.includes(permissionsList.AUTHORIZER.key) &&
      !userDetails.isSuperAdmin);

  function handleSelectCompany(selectedComp) {
    dispatch(CompaniesActions.selectedCompany(selectedComp));
    setCompanyName(selectedComp.name);
    handleClose(); // close the select company modal
  }

  const onCompanyChange = () => {
    if (companyName && companyJobs && checkUserIsInAuthorizerView) {
      history.push("/admin/jobs");
    }
  };

  useEffect(onCompanyChange, [companyName]);

  return (
    companies.length > 1 && (
      <Dialog
        onClose={handleClose}
        open={show}
        maxWidth='sm'
        fullWidth
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle id='company_selection_dialog' onClose={handleClose}>
          {t("selectCompany.title")}
          {history.location.pathname !== "/" ? (
            <CloseIcon className={classes.headerIcon} onClick={handleClose} />
          ) : (
            <>
              <Tooltip
                title={t("header.signOut_btn_label")}
                className={classes.headerIcon}
                arrow
              >
                <span>
                  <ExitToAppIcon
                    className={classes.headerIcon}
                    onClick={() => dispatch(AuthActions.logout())}
                  />
                </span>
              </Tooltip>
            </>
          )}
        </DialogTitle>
        <DialogContent dividers>
          {companies.map((company) => (
            <Grid
              container
              className='row-margin'
              key={company._id}
              alignItems='center'
            >
              <Grid item className={classes.gridPadding} xs={12}>
                <span className={classes.companyOptions}>{company.name}</span>
                <Button
                  variant='contained'
                  color='primary'
                  className={classes.button}
                  disabled={company.code === selectedCompany.code}
                  onClick={() => handleSelectCompany(company)}
                >
                  {t("selectCompany.select_btn_label")}
                </Button>
              </Grid>
            </Grid>
          ))}
        </DialogContent>
      </Dialog>
    )
  );
};

AssignCompanyModalComponent.propTypes = {
  show: PropTypes.bool.isRequired,
  history: PropTypes.shape().isRequired,
  handleClose: PropTypes.func.isRequired
};

export default compose(withRouter)(AssignCompanyModalComponent);
