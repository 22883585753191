import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { findLastIndex, get, includes, range, uniqBy } from "lodash";
import moment from "moment";
import PropTypes from "prop-types";

import { LinearProgress } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Collapse from "@material-ui/core/Collapse";
import Fab from "@material-ui/core/Fab";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FilterListIcon from "@material-ui/icons/FilterList";

import { ReactComponent as ExpandAllIcon } from "../../assets/img/dropdown1.svg";
import { ReactComponent as CollapseAllIcon } from "../../assets/img/dropdown2.svg";
import { CLEAR_EQUIPMENT_INVALID_ENTRIES } from "../../redux/actions/actionTypes";
import * as EquipmentReportsActions from "../../redux/actions/EquipmentReportsActions";
import * as EquipmentSearchActions from "../../redux/actions/EquipmentSearchActions";
import {
  tableStyles,
  toolbarStyles
} from "../../styles/components/WeeklyReportsTableStyles";
import permissionsList from "../../utillities/Permissions";
import ConfirmationDialogComponent from "../UI/ConfirmationDialogComponent";
import SearchAutocompleteComponent from "../UI/SearchAutocompleteComponent";

import EquipmentBulkEntryComponent from "./EquipmentBulkEntryComponent";
import EquipmentEntryDetailsComponent from "./EquipmentEntryDetailsComponent";
import EquipmentEntryReportsFiltersComponent from "./EquipmentEntryReportsFiltersComponent";

const EquipmentEntryReportsComponent = (props) => {
  const { t, i18n } = useTranslation();
  const classes = tableStyles();
  const toolbarClasses = toolbarStyles();
  const dispatch = useDispatch();
  const {
    organizationDetails,
    userJobs,
    payPeriodsHandler,
    payPeriodId,
    dateRange,
    jobOffset,
    setJobOffset,
    payPeriodIndex,
    payPeriodsOptions,
    isEquipmentExported,
    isPayPeriodLocked,
    setLockUnlockPayPeriodConfirmationDialog
  } = props;
  const {
    apiStatus,
    equipmentReports,
    equipmentSearchResults,
    userDetails,
    payPeriods,
    equipmentInvalidEntries,
    selectedCompany
  } = useSelector((state) => state);
  const [selected, setSelected] = useState([]);
  const [expanded, setExpanded] = useState({});
  const [order, setOrder] = useState({ name: "asc" });
  const [sortBy, setSortBy] = useState("name");
  const [isSortApplied, setIsSortApplied] = useState(false);
  const [filterQuery, setFilterQuery] = useState("");
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(50);
  const [paginationLimit, setPaginationLimit] = useState(50);
  const [bulkEntryShow, setBulkEntryShow] = useState(false);
  const [equipmentExportReload, setEquipmentExportReload] =
    useState(isEquipmentExported);
  const [filterDrawerState, setFilterDrawerState] = useState({
    right: false
  });
  const permissionDisableEquipmentEntry =
    !userDetails.isSuperAdmin &&
    !userDetails.role.permissions.includes(permissionsList.EQUIPMENT_ENTRY.key);
  const prevPayperiodRef = useRef(payPeriodId);
  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const dialogAttributes = {
    contentText: `Equipments in which hours are not saved due to validation issues are ${equipmentInvalidEntries
      .map((equip) => equip.code)
      .join(", ")}. Please check and retry.`,
    cancel: `${t("commonActions.cancel")}`
  };
  const disableButtonsOnLoad =
    apiStatus &&
    apiStatus.isLoading &&
    apiStatus.reducer.includes("equipmentReports");

  const sortReports = (query, sortOffset, sortLimit) =>
    dispatch(
      EquipmentReportsActions.sortEquipmentReports(
        payPeriodId,
        query,
        sortOffset,
        sortLimit
      )
    );
  const searchEquipment = (query) =>
    query && dispatch(EquipmentSearchActions.searchEquipmentWithParam(query));
  const clearEquipmentList = (type, clear) =>
    dispatch(EquipmentSearchActions.clearEquipmentResults(type, clear));
  const getEquipmentSortByTotalHours = (query, limitTotalHours) =>
    dispatch(
      EquipmentReportsActions.filteredEquipmentReports(
        query,
        0,
        limitTotalHours
      )
    );

  const loadWeeklyReports = (setOffsetToZero, setQuery = null) => {
    if (!payPeriodId) return;
    if (selectedCompany.code === userDetails.companyCode) {
      // Reset offset to 0 if payperiod is changed
      const currentOffset =
        prevPayperiodRef.current !== payPeriodId || setOffsetToZero
          ? 0
          : offset;
      const query =
        filterQuery.length || (setQuery || "").length
          ? `payperiod=${payPeriodId}${
              setQuery !== null ? setQuery : filterQuery
            }&orderBy=${sortBy}-${order[sortBy]}`
          : `payperiod=${payPeriodId}&orderBy=${sortBy}-${order[sortBy]}`;
      switch (true) {
        case isSortApplied:
          sortReports(query, currentOffset, limit);
          setIsSortApplied(false);
          break;
        case isFilterApplied && Object.keys(filterQuery).length:
          if (sortBy === "totalhours")
            getEquipmentSortByTotalHours(query, currentOffset + limit);
          else
            dispatch(
              EquipmentReportsActions.filteredEquipmentReports(
                query,
                currentOffset,
                limit
              )
            );
          break;
        default:
          if (sortBy === "totalhours")
            getEquipmentSortByTotalHours(query, currentOffset + limit);
          else
            dispatch(
              EquipmentReportsActions.loadEquipmentReports(
                query,
                currentOffset,
                limit
              )
            );
          break;
      }
    }
    prevPayperiodRef.current = payPeriodId;
  };

  useEffect(loadWeeklyReports, [payPeriodId, offset, limit, sortBy, order]);

  const onPayPeriodChange = () => {
    setSelected([]);
  };
  useEffect(onPayPeriodChange, [payPeriodId]);

  const loadEntriesAfterExport = () => {
    if (
      equipmentExportReload !== isEquipmentExported &&
      !apiStatus.reducer.includes("exportCSVfile")
    ) {
      if (!offset) loadWeeklyReports(true);
      else setOffset(0);
      setEquipmentExportReload(isEquipmentExported);
    }
  };
  useEffect(loadEntriesAfterExport, [
    apiStatus.reducer.includes("exportCSVfile")
  ]);

  const invalidEntriesEquipment = () => {
    if (equipmentInvalidEntries.length) {
      setConfirmationDialog(true);
      setSelected([...equipmentInvalidEntries.map((equip) => equip._id)]);
    }
  };
  useEffect(invalidEntriesEquipment, [equipmentInvalidEntries]);

  const toggleDrawer = (side, isOpen) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setFilterDrawerState({ [side]: isOpen });
  };

  const setFiltersState = (drawerState, filterApplied, query) => {
    setSelected([]);
    setExpanded([]);
    setFilterDrawerState(drawerState);
    setIsFilterApplied(filterApplied);
    setFilterQuery(query);
    if (query !== filterQuery && offset === 0) loadWeeklyReports(false, query);
    if (query !== filterQuery && offset > 0) setOffset(0);

    if (!query.length) setOffset(0);
  };

  const headers = uniqBy(
    [
      {
        id: "equipment.code",
        type: "string",
        numeric: false,
        disablePadding: true,
        label: "ID",
        sortable: true,
        classes: classes.cell
      },
      {
        id: "equipment.name",
        type: "string",
        numeric: false,
        disablePadding: true,
        label: `${t("equipmentManager.title")}`,
        sortable: true,
        classes: clsx(classes.cell, classes.loggedByCell)
      },
      ...dateRange.map((date, index) => ({
        id: moment.utc(date).format("DD-MM-YYYY"),
        date: moment.utc(date).format("DD-MM-YYYY"),
        key: "hoursWorkedByJobs",
        index,
        numeric: false,
        disablePadding: true,
        label: moment.utc(date).locale(i18n.language).format("ddd MM/DD"),
        sortable: false,
        classes: classes.cell
      })),
      {
        id: "totalHoursOfWeek",
        type: "number",
        numeric: false,
        disablePadding: true,
        label: `${t("payroll.total_hours_job_label")}`,
        sortable: false,
        classes: classes.cell
      },
      {
        id: "totalHoursForAllJobs.totalhours",
        type: "number",
        numeric: false,
        disablePadding: true,
        label: `${t("payroll.total_hours_all_label")}`,
        sortable: true,
        classes: clsx(classes.cell, classes.totalHoursAllJobs)
      },
      {
        id: "loggedBy",
        type: "array",
        mapKey: "empName",
        numeric: false,
        disablePadding: true,
        label: `${t("payroll.logged_by_label")}`,
        sortable: false,
        classes: clsx(classes.cell, classes.loggedByCell)
      },
      {
        id: "actions",
        type: "actions",
        numeric: false,
        disablePadding: true,
        label: "",
        sortable: false,
        classes: clsx(classes.cell, classes.fixedColumn)
      }
    ],
    "id"
  );

  const handleEquipmentListItemClick = (event, option) => {
    if (option) {
      const query = `payperiod=${payPeriodId}&equipments=${option._id}`;
      dispatch(EquipmentReportsActions.loadEquipmentReports(query, 0, 1));
    } else {
      const query = `payperiod=${payPeriodId}`;
      dispatch(EquipmentReportsActions.loadEquipmentReports(query, 0, limit));
    }
    clearEquipmentList("equipmentSearchResults", true);
    setExpanded({});
  };

  const handleExpandClick = (event, index) => {
    // eslint-disable-next-line no-return-assign
    setExpanded((exp) => ({
      ...exp,
      [index]: (expanded[index] = !expanded[index])
    }));
  };

  const handleSelectAllClick = (event) => {
    if (selected.length > 0) {
      setSelected([]);
      return;
    }
    if (event.target.checked) {
      const newSelecteds =
        equipmentReports && equipmentReports.map((n) => n.equipment._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleSort = (event, sort) => {
    if (event) {
      setIsSortApplied(true);
      setOrder((o) => ({
        ...o,
        [sort.split(".")[1]]:
          order[sort.split(".")[1]] === "asc" ? "desc" : "asc"
      }));
      setSortBy(sort.split(".")[1]);
      setOffset(0);
    }
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  useEffect(() => {
    /* Closing the shared dialog for payperiod 
    locking-unlocking on component unmounting */
    return () => setLockUnlockPayPeriodConfirmationDialog(false);
    // eslint-disable-next-line
  }, []);

  const tableToolbar = () => {
    return (
      <Toolbar className={toolbarClasses.root} key='equipment-table-toolbar'>
        <Typography
          className={toolbarClasses.title}
          variant='h6'
          id='tableTitle'
        >
          {payPeriodsHandler()}

          {payPeriods.length &&
            (isPayPeriodLocked
              ? userDetails.role.permissions.includes(
                  permissionsList.EQUIPMENT_PAYPERIOD_UNLOCK.key
                ) && (
                  <Fab
                    variant='extended'
                    color='secondary'
                    size='medium'
                    onClick={() =>
                      setLockUnlockPayPeriodConfirmationDialog(true)
                    }
                    className={toolbarClasses.payPeriodLockButton}
                  >
                    {t("commonActions.unlock_payperiod")}
                  </Fab>
                )
              : userDetails.role.permissions.includes(
                  permissionsList.EQUIPMENT_PAYPERIOD_LOCK.key
                ) && (
                  <Fab
                    variant='extended'
                    color='secondary'
                    size='medium'
                    onClick={() =>
                      setLockUnlockPayPeriodConfirmationDialog(true)
                    }
                    className={toolbarClasses.payPeriodLockButton}
                  >
                    {t("equipmentManager.lock_payperiod")}
                  </Fab>
                ))}
        </Typography>

        <div className={toolbarClasses.buttons}>
          {selected.length > 0 && (
            <Fab
              variant='extended'
              color='secondary'
              aria-label='bulk-entry'
              className={toolbarClasses.fab}
              onClick={() => {
                setBulkEntryShow(true);
              }}
              disabled={permissionDisableEquipmentEntry || isPayPeriodLocked}
            >
              {t("payroll.bulk_entry_title")}
            </Fab>
          )}
          <Fab
            onClick={toggleDrawer("right", true)}
            variant='extended'
            aria-label='filter'
            className={clsx(toolbarClasses.filter, toolbarClasses.fab)}
          >
            <FilterListIcon />
            {t("payroll.filter_btn_label")}
          </Fab>

          {/* Search Equipment */}
          <SearchAutocompleteComponent
            handleSearch={searchEquipment}
            searchResults={equipmentSearchResults}
            setOptionLabel={(option) => `${option.code} - ${option.name}`}
            showOptions
            handleListItem={handleEquipmentListItemClick}
            clear={() =>
              clearEquipmentList(
                "equipmentSearchResults",
                !equipmentSearchResults.length
              )
            }
            disableClearable={false}
            freeSolo={false}
            key={payPeriodId}
          />
        </div>
      </Toolbar>
    );
  };

  const tableHeader = (
    <TableHead key='equipment-table-header'>
      <TableRow>
        <TableCell padding='checkbox' className={classes.cell} scope='tr'>
          <Checkbox
            color='secondary'
            indeterminate={
              selected.length > 0 && selected.length < equipmentReports.length
            }
            checked={
              selected.length === equipmentReports.length &&
              selected.length !== 0
            }
            onClick={handleSelectAllClick}
            inputProps={{ "aria-label": "select all rows" }}
          />
        </TableCell>
        {headers.length > 5 &&
          headers.map((header) => (
            <TableCell
              key={header.id || header.label}
              padding={header.disablePadding ? "none" : "default"}
              className={clsx(classes.headerCell, {
                [classes.fixedHeader]: !header.label
              })}
              sortDirection={
                header.sortable && sortBy === header.id.split(".")[1]
                  ? order[sortBy]
                  : false
              }
              scope='tr'
            >
              {header.sortable ? (
                <TableSortLabel
                  className={classes.sortHeader}
                  active={sortBy === header.id}
                  direction={order[sortBy]}
                  onClick={(event) => handleSort(event, header.id)}
                >
                  {header && header.label ? header.label : header.id}
                </TableSortLabel>
              ) : header && header.label ? (
                header.label
              ) : (
                <Button
                  color='secondary'
                  size='small'
                  aria-label='expand-all'
                  className={classes.button}
                  disabled={disableButtonsOnLoad}
                  onClick={() => {
                    if (
                      includes(expanded, false) &&
                      expanded.length === equipmentReports.length
                    ) {
                      setExpanded((exp) => ({
                        ...exp,
                        // eslint-disable-next-line no-return-assign
                        ...equipmentReports.map((e, i) => (e[i] = !e[i]))
                      }));
                    } else if (includes(expanded, true)) {
                      setExpanded((exp, i) => ({
                        ...exp,
                        ...equipmentReports.map((e) => Boolean(e[i]))
                      }));
                    } else {
                      setExpanded((exp, i) => ({
                        ...exp,
                        ...equipmentReports.map((e) => !e[i])
                      }));
                    }
                  }}
                  endIcon={
                    includes(expanded, true) ? (
                      <CollapseAllIcon
                        fill={
                          disableButtonsOnLoad
                            ? "rgba(0, 0, 0, 0.26)"
                            : "#3a5998"
                        }
                      />
                    ) : (
                      <ExpandAllIcon
                        fill={
                          disableButtonsOnLoad
                            ? "rgba(0, 0, 0, 0.26)"
                            : "#3a5998"
                        }
                      />
                    )
                  }
                >
                  {includes(expanded, true)
                    ? t("payroll.collapse_all_btn_label")
                    : t("payroll.expand_all_btn_label")}
                </Button>
              )}
            </TableCell>
          ))}
      </TableRow>
    </TableHead>
  );

  return (
    <React.Fragment key='EquipmentReports'>
      {payPeriodId && dateRange.length && (
        <React.Fragment key='EquipmentReportsPaper'>
          {/* Table Toolbar */}
          {tableToolbar()}
          {apiStatus &&
            apiStatus.isLoading &&
            apiStatus.reducer.includes("equipmentReports") && (
              <LinearProgress key='loader' />
            )}
          {/* Table Starts Here */}
          <div className={classes.tableWrapper} key='equipment-reports-table'>
            <Table
              className={classes.table}
              size='small'
              stickyHeader
              aria-label='equipment-reports-table'
              padding='none'
              key='weekly-table'
            >
              {/* Table Column Headers */}
              {tableHeader}

              <TableBody key='weekly-table-body'>
                {/* Table Rows */}
                {equipmentReports.length ? (
                  equipmentReports.map((row, index) => {
                    const isItemSelected = isSelected(
                      row.equipment._id ? row.equipment._id : null
                    );
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <>
                        <TableRow
                          hover
                          role='checkbox'
                          onClick={(event) =>
                            handleClick(event, row.equipment._id)
                          }
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.equipment._id || index}
                          selected={isItemSelected}
                        >
                          <TableCell
                            padding='checkbox'
                            className={classes.cell}
                          >
                            <Checkbox
                              color='secondary'
                              checked={isItemSelected}
                              inputProps={{ "aria-labelledby": labelId }}
                            />
                          </TableCell>
                          {row &&
                            headers.map((header) =>
                              (() => {
                                if (header.date) {
                                  return (
                                    <TableCell
                                      className={classes.cell}
                                      key={header.date}
                                    >
                                      {(() => {
                                        const day =
                                          row.daysInWeek[
                                            findLastIndex(
                                              row.daysInWeek || [],
                                              (day) =>
                                                day.dayOfWeek === header.date
                                            )
                                          ];
                                        const {
                                          totalOperatingHoursOfDay,
                                          totalIdleHoursOfDay,
                                          totalStoreTimeOfDay
                                        } = day || {};
                                        const localeString = (num) =>
                                          (num || 0).toLocaleString(undefined, {
                                            minimumFractionDigits: 2
                                          });
                                        return totalOperatingHoursOfDay ||
                                          totalIdleHoursOfDay ||
                                          totalStoreTimeOfDay
                                          ? `${localeString(
                                              totalOperatingHoursOfDay
                                            )}/${localeString(
                                              totalIdleHoursOfDay
                                            )}/${localeString(
                                              totalStoreTimeOfDay
                                            )}`
                                          : "-";
                                      })()}
                                    </TableCell>
                                  );
                                }
                                const rowValueOfHeaderId =
                                  typeof row[header.id.split(".")[0]] !==
                                  "object"
                                    ? row[header.id.split(".")[0]]
                                    : get(row, header.id);
                                return (
                                  <TableCell
                                    className={header.classes}
                                    key={header.id}
                                  >
                                    {
                                      {
                                        string: rowValueOfHeaderId || "-",
                                        number:
                                          (
                                            rowValueOfHeaderId || ""
                                          ).toLocaleString(undefined, {
                                            minimumFractionDigits: 2
                                          }) || "-",
                                        array:
                                          (((row || {})[header.id] || [])
                                            .length &&
                                            rowValueOfHeaderId
                                              .map((log) => log[header.mapKey])
                                              .join(", ")) ||
                                          "-",
                                        actions: (
                                          <div className={classes.actionsCell}>
                                            <Button
                                              variant='text'
                                              color='secondary'
                                              size='small'
                                              aria-label='approve'
                                              className={classes.button}
                                              disabled
                                            >
                                              {row.equipmentLogsForWeek
                                                .length &&
                                              row.equipmentLogsForWeek.every(
                                                (equip) =>
                                                  equip.uploaded === true
                                              )
                                                ? `${t("payroll.uploaded")}`
                                                : `${t(
                                                    "payroll.not_uploaded"
                                                  )}`}
                                            </Button>
                                            <IconButton
                                              className={clsx(classes.expand, {
                                                [classes.expandOpen]:
                                                  expanded[index]
                                              })}
                                              onClick={(event) => {
                                                event.preventDefault();
                                                event.stopPropagation();
                                                handleExpandClick(event, index);
                                              }}
                                              disabled={disableButtonsOnLoad}
                                              aria-expanded={expanded}
                                              aria-label='expand-row'
                                            >
                                              <ExpandMoreIcon />
                                            </IconButton>
                                          </div>
                                        )
                                      }[header.type]
                                    }
                                  </TableCell>
                                );
                              })()
                            )}
                        </TableRow>

                        {/* Expansion Cell */}
                        <TableRow key='expansion-row'>
                          <TableCell
                            colSpan={headers.length + 1}
                            key='expansion-cell'
                          >
                            <Collapse
                              key={`collapse${index}`}
                              in={expanded[index]}
                              unmountOnExit
                            >
                              <EquipmentEntryDetailsComponent
                                key={JSON.stringify(row.equipmentLogsForWeek)}
                                rowIndex={index}
                                payPeriodId={payPeriodId}
                                userJobs={userJobs}
                                equipment={get(row, "equipment")}
                                equipmentLogsForWeek={row.equipmentLogsForWeek}
                                jobsInWeek={row.jobsInWeek}
                                OULevelNamesForEquipmentLogs={
                                  organizationDetails.OULevelNamesForEquipmentLogs
                                }
                                costTypes={organizationDetails.costType.map(
                                  (cost) => ({ name: cost })
                                )}
                                jobOffset={jobOffset}
                                setJobOffset={setJobOffset}
                                handleCollapse={() => {
                                  setExpanded((exp) => ({
                                    ...exp,
                                    [index]: false
                                  }));
                                }}
                                permissionDisableEquipmentEntry={
                                  permissionDisableEquipmentEntry
                                }
                                isPayPeriodLocked={isPayPeriodLocked}
                              />
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })
                ) : (
                  <TableRow key='empty-row'>
                    <TableCell
                      colSpan={headers.length + 1}
                      className={classes.emptyCell}
                      key='empty-cell'
                    >
                      {apiStatus && !apiStatus.isLoading
                        ? t("commonActions.no_records")
                        : t("commonActions.loading_records")}
                    </TableCell>
                  </TableRow>
                )}

                {/* Pagination Row */}
                <TableRow key='pagination-row'>
                  <TableCell colSpan={headers.length} key='empty-cells' />
                  <TableCell
                    className={classes.paginationCell}
                    key='pagination-cell'
                  >
                    <Select
                      labelId='pagination-limit'
                      id='pagination-limit'
                      key='pagination-menu'
                      value={paginationLimit}
                      disableUnderline
                      onChange={(event) => {
                        setLimit(event.target.value);
                        setPaginationLimit(event.target.value);
                        setSelected([]);
                        setOffset(0);
                      }}
                      inputProps={{
                        className: classes.paginationDropdown
                      }}
                    >
                      <MenuItem key={25} value={25}>
                        25
                      </MenuItem>
                      {range(50, 250, 50).map((item) => (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                    <Button
                      key='show-more'
                      variant='outlined'
                      color='secondary'
                      size='small'
                      className={clsx(classes.button, classes.showMoreButton)}
                      onClick={() => {
                        setLimit(paginationLimit);
                        setOffset((l) => l + paginationLimit);
                      }}
                      disabled={disableButtonsOnLoad}
                    >
                      {t("payroll.show_more")}
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            {/* Table Ends Here */}
          </div>
        </React.Fragment>
      )}

      <EquipmentEntryReportsFiltersComponent
        filterDrawerState={filterDrawerState}
        toggleDrawer={toggleDrawer}
        setFiltersState={setFiltersState}
        organizationDetails={organizationDetails}
        userJobs={userJobs}
        jobOffset={jobOffset}
        setJobOffset={setJobOffset}
        payPeriodId={payPeriodId}
      />

      {bulkEntryShow && userJobs.length > 0 ? (
        <EquipmentBulkEntryComponent
          key='bulk-entry'
          open={bulkEntryShow}
          Close={() => setBulkEntryShow(false)}
          rows={equipmentReports}
          payPeriodIndex={payPeriodIndex}
          userJobs={userJobs}
          PayPeriodSelected={payPeriodsOptions}
          selected={selected}
          setSelected={setSelected}
          jobOffset={jobOffset}
          setJobOffset={setJobOffset}
          NumberOfDays={headers.filter((header) => header.date).length}
        />
      ) : null}

      {confirmationDialog && !apiStatus.isLoading && (
        <ConfirmationDialogComponent
          open={confirmationDialog}
          close={() => {
            dispatch({ type: CLEAR_EQUIPMENT_INVALID_ENTRIES });
            setConfirmationDialog(false);
          }}
          dialogAttributes={dialogAttributes}
        />
      )}
    </React.Fragment>
  );
};

EquipmentEntryReportsComponent.propTypes = {
  organizationDetails: PropTypes.shape({
    timeEntriesRoleLevels: PropTypes.arrayOf(PropTypes.string).isRequired,
    OULevelNamesForEquipmentLogs: PropTypes.arrayOf(
      PropTypes.shape().isRequired
    ).isRequired,
    hoursType: PropTypes.arrayOf(PropTypes.string).isRequired,
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    costType: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired
      })
    ).isRequired
  }).isRequired,
  userJobs: PropTypes.arrayOf(
    PropTypes.shape({
      operationalUnits: PropTypes.arrayOf(PropTypes.shape()).isRequired,
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired
    })
  ).isRequired,
  payPeriodsHandler: PropTypes.func.isRequired,
  payPeriodId: PropTypes.string.isRequired,
  payPeriodIndex: PropTypes.number.isRequired,
  dateRange: PropTypes.arrayOf(PropTypes.string).isRequired,
  jobOffset: PropTypes.number.isRequired,
  setJobOffset: PropTypes.func.isRequired,
  payPeriodsOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isEquipmentExported: PropTypes.number.isRequired,
  isPayPeriodLocked: PropTypes.bool.isRequired,
  setLockUnlockPayPeriodConfirmationDialog: PropTypes.func.isRequired
};

export default EquipmentEntryReportsComponent;
