import { LOAD_EMPLOYEES_BY_EQUIPMENT } from "../actions/actionTypes";

export default function EmployeeByEquipmentReducer(state = [], action) {
  switch (action.type) {
    case LOAD_EMPLOYEES_BY_EQUIPMENT:
      return action.offset
        ? [...state, ...action.employeesByEquipment]
        : action.employeesByEquipment;
    default:
      return state;
  }
}
