import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import useInfiniteScroll from "../../custom-hooks/useInfiniteScroll";
import * as employeesByEquipmentActions from "../../redux/actions/EmployeesByEquipmentActions";
import * as empByEqipSearchActions from "../../redux/actions/EmployeesByEquipmentSearchActions";
import * as ExportCsvActions from "../../redux/actions/exportCsvActions";
import loadUserJobDetails from "../../redux/actions/UserJobDetailsActions";
import * as userJobsSearchActions from "../../redux/actions/UserJobsSearchActions";
import ConfirmationDialogComponent from "../UI/ConfirmationDialogComponent";
import DropdownSearchComponent from "../UI/DropdownSearchComponent";

const useStyles = makeStyles(() => ({
  payPeriodGrid: {
    display: "flex",
    alignItems: "center",
    marginBottom: "8px"
  },
  jobGrid: {
    display: "flex",
    alignItems: "center",
    marginTop: "8px"
  }
}));

const ExportCSVComponent = (props) => {
  const {
    open,
    close,
    payPeriodId,
    userJobs,
    jobOffset,
    setJobOffset,
    setEquipmentExportedStatus,
    isEquipmentExported,
    isInEquipmentEntriesTab,
    payPeriodOffset
  } = props;

  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    apiStatus,
    userJobsSearchResults,
    employeesByEquipment,
    empByEqSearchResults,
    count
  } = useSelector((state) => state);
  const [selectedJob, setSelectedJob] = useState();
  const [selectedGroup, setSelectedGroup] = useState();
  const limit = 20;
  const [groupOffset, setGroupOffset] = useState(0);
  const [scrollTop, setScrollTop] = useState(0);
  const [scrollHeight, setScrollHeight] = useState(0);
  const [clientHeight, setClientHeight] = useState(0);
  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const [search, setSearch] = useState("");

  const [dialogAttributes, setDialogAttributes] = useState({
    title: `${t("payroll.export_csv")}`,
    contentText: `${t("exportConfirmationText")} ?`,
    cancel: `${t("commonActions.cancel")}`,
    accept: `${t("payroll.export_csv")}`
  });

  const onEquipmentExportCount = () => {
    if (
      count.exportCount === 0 &&
      confirmationDialog &&
      isInEquipmentEntriesTab()
    ) {
      setDialogAttributes({
        contentText: t("export_error_equipment"),
        cancel: `${t("commonActions.cancel")}`
      });
    }
    if (count.exportCount > 0 && isInEquipmentEntriesTab())
      setDialogAttributes((oldState) => ({
        ...oldState,
        contentText: `${t("exportConfirmationText")} ${
          count.exportCount
        } equipments ?`,
        accept: `${t("payroll.export_csv")}`
      }));
  };
  useEffect(onEquipmentExportCount, [count]);

  // Custom hook for infinite scroll
  const [isFetching, toggle] = useInfiniteScroll(
    clientHeight,
    scrollTop,
    scrollHeight
  );
  // Send scroll event result to parent component to send API calls for pagination
  useEffect(() => {
    if (isFetching && !apiStatus.isLoading && !search.length) {
      if (!isInEquipmentEntriesTab()) {
        dispatch(loadUserJobDetails(jobOffset + limit, limit));
        setJobOffset(jobOffset + limit);
      } else {
        dispatch(
          employeesByEquipmentActions.loadEmployeesByEquipment(
            groupOffset + limit,
            limit,
            "exportCSV_groupSelection"
          )
        );
        setGroupOffset(groupOffset + limit);
      }
      toggle(isFetching);
      setScrollTop(0);
      setScrollHeight(0);
      setClientHeight(0);
    }
    // eslint-disable-next-line
  }, [isFetching]);

  const loadEmployeesForGroupDropdown = () => {
    if (isInEquipmentEntriesTab())
      dispatch(
        employeesByEquipmentActions.loadEmployeesByEquipment(
          0,
          limit,
          "exportCSV_groupSelection"
        )
      );
  };
  useEffect(loadEmployeesForGroupDropdown, []);

  const handleJobSearch = (searchTerm, id) => {
    if (searchTerm.length) {
      if (!isInEquipmentEntriesTab())
        dispatch(userJobsSearchActions.searchUserJobsWithParam(searchTerm, id));
      else
        dispatch(
          empByEqipSearchActions.searchEmployeesByEquipmentWithParam(
            searchTerm,
            "exportCSV_groupSelection"
          )
        );
    }
    setSearch(searchTerm);
  };

  return (
    <>
      <Dialog open={open} onClose={close} maxWidth='sm' fullWidth>
        <DialogTitle>{t("payroll.export_csv")}</DialogTitle>
        <DialogContent dividers>
          <Grid container className={classes.jobGrid}>
            {!isInEquipmentEntriesTab() ? (
              <Grid item xs={3}>
                {t("payroll.job_label")}
                <small className='text-muted'>{`(${t(
                  "payroll.optional"
                )})`}</small>
              </Grid>
            ) : (
              <Grid item xs={3}>
                {t("payroll.select_group")}
                <small className='text-muted'>{`(${t(
                  "payroll.optional"
                )})`}</small>
              </Grid>
            )}
            <Grid
              item
              xs={7}
              onScroll={(event) => {
                event.persist();
                setScrollTop(event.target.scrollTop);
                setScrollHeight(event.target.scrollHeight);
                setClientHeight(event.target.clientHeight);
              }}
            >
              {!isInEquipmentEntriesTab() ? (
                <DropdownSearchComponent
                  options={userJobs}
                  searchOptions={
                    search && search.length ? userJobsSearchResults : []
                  }
                  disableUnderline={false}
                  id='exportCSV_userJobs'
                  value={selectedJob}
                  handleChange={(change) => {
                    setSelectedJob(change);
                    userJobsSearchActions.clearUserJobsResults(
                      "userJobsSearchResults"
                    );
                  }}
                  placeholderText={t("assignJob.job_table_title")}
                  handleJobSearch={handleJobSearch}
                  onDropDownChange={() => setSearch("")}
                />
              ) : (
                <DropdownSearchComponent
                  options={employeesByEquipment.map((emp) => ({
                    _id: emp._id,
                    code: emp.empID,
                    name: emp.empName
                  }))}
                  searchOptions={
                    search && search.length
                      ? empByEqSearchResults.map((emp) => ({
                          _id: emp._id,
                          code: emp.empID,
                          name: emp.empName
                        }))
                      : []
                  }
                  disableUnderline={false}
                  value={selectedGroup}
                  id='exportCSV_groupSelection'
                  handleChange={(change) => {
                    setSelectedGroup(change);
                    empByEqipSearchActions.clearEmployeesByEquipmentResults(
                      "empByEqSearchResults",
                      true
                    );
                  }}
                  placeholderText={t("payroll.select_group")}
                  handleJobSearch={handleJobSearch}
                  onDropDownChange={() => setSearch("")}
                />
              )}
            </Grid>
            <Grid item xs={2}>
              <Button
                disabled={
                  !Object.keys(selectedJob || selectedGroup || {}).length
                }
                onClick={() => {
                  setSelectedJob();
                  setSelectedGroup();
                }}
                style={{ float: "right" }}
              >
                {t("payroll.reset_btn_label")}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color='primary' onClick={close}>
            {t("commonActions.cancel")}
          </Button>
          <Button
            color='primary'
            onClick={() => {
              setConfirmationDialog(true);
              if (isInEquipmentEntriesTab() > 0)
                dispatch(
                  ExportCsvActions.exportEquipmentCount(
                    payPeriodId,
                    selectedGroup
                  )
                );
            }}
          >
            {t("commonActions.save")}
          </Button>
        </DialogActions>
      </Dialog>
      {confirmationDialog && !apiStatus.reducer.includes("exportCount") && (
        <ConfirmationDialogComponent
          open={confirmationDialog}
          close={() => setConfirmationDialog(false)}
          dialogAttributes={dialogAttributes}
          handleDialog={() => {
            dispatch(
              ExportCsvActions.exportCSV(
                payPeriodId,
                isInEquipmentEntriesTab(),
                isInEquipmentEntriesTab() ? selectedGroup : selectedJob,
                payPeriodOffset
              )
            );
            setEquipmentExportedStatus(isEquipmentExported + 1);
            close();
          }}
        />
      )}
    </>
  );
};

ExportCSVComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  userJobs: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  payPeriodId: PropTypes.string.isRequired,
  jobOffset: PropTypes.number.isRequired,
  setJobOffset: PropTypes.func.isRequired,
  isEquipmentExported: PropTypes.number.isRequired,
  setEquipmentExportedStatus: PropTypes.func.isRequired,
  isInEquipmentEntriesTab: PropTypes.func.isRequired,
  payPeriodOffset: PropTypes.number.isRequired
};

export default ExportCSVComponent;
