import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import Fab from "@material-ui/core/Fab";
import LinearProgress from "@material-ui/core/LinearProgress";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";

import permissionsList from "../../../utillities/Permissions";
import AssignEmployeeComponent from "../employees/AssignEmployeeComponent";

import SelectJobComponent from "./SelectJobComponent";

const useStyles = makeStyles(() => ({
  title: {
    padding: "20px 0 20px 8px",
    display: "flow-root",
    minHeight: "76px"
  },
  divPaper: {
    height: "100% !important"
  },
  floatRight: {
    display: "flex",
    float: "right"
  },
  floatLeft: {
    float: "left"
  },
  assignEmployeesButton: {
    marginRight: "16px"
  }
}));

const ActiveJobsComponent = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { userDetails, apiStatus } = useSelector((state) => state);
  const [open, setOpen] = useState(false);
  const [getShowAssignEmpBtn, setShowAssignEmpBtn] = useState(false);
  const [selected, setSelected] = useState({});

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("code");
  const [limit] = useState(20);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (
      Object.keys(selected)
        .map(
          (jobCode) => selected[jobCode].filter((ou) => ou.length > 0).length
        )
        .findIndex((len) => len > 0) >= 0
    )
      setShowAssignEmpBtn(true);
    else setShowAssignEmpBtn(false);
  }, [selected]);

  return (
    <div>
      <div className={classes.title}>
        <Typography className={classes.floatLeft} variant='h5'>
          {t("authorizerContainer.active_jobs_title")}
        </Typography>

        <div className={classes.floatRight}>
          {getShowAssignEmpBtn && (
            <>
              <Fab
                variant='extended'
                color='primary'
                size='medium'
                onClick={() => setOpen(true)}
                disabled={
                  !userDetails.isSuperAdmin &&
                  !userDetails.role.permissions.includes(
                    permissionsList.ASSIGN_JOBS.key
                  )
                }
                className={classes.assignEmployeesButton}
              >
                {t("assignEmployee.title")}
              </Fab>
              {open && (
                <AssignEmployeeComponent
                  open={open}
                  close={() => handleClose()}
                  selected={selected}
                  setSelected={setSelected}
                />
              )}
            </>
          )}
        </div>
      </div>
      <div>
        {apiStatus &&
          apiStatus.isLoading &&
          (apiStatus.reducer.includes("activeJobs") ||
            apiStatus.reducer.includes("RoleChange")) && <LinearProgress />}
      </div>
      <div style={{ display: "flex" }}>
        <SelectJobComponent
          setShowAssignEmpBtn={setShowAssignEmpBtn}
          selected={selected}
          setSelected={setSelected}
          order={order}
          orderBy={orderBy}
          limit={limit}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
        />
      </div>
    </div>
  );
};

export default ActiveJobsComponent;
