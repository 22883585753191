import lodash from "lodash";

import {
  API_FAILED,
  API_START_LOADER,
  API_STOP_LOADER,
  API_SUCCESS,
  SET_API_STATUS
} from "../actions/actionTypes";

export default function ApiStatusReducer(state = { isLoading: false }, action) {
  switch (action.type) {
    case API_FAILED: {
      return { ...state, status: action.status };
    }
    case API_SUCCESS: {
      return { ...state, status: action.status };
    }
    case SET_API_STATUS: {
      return { ...state, status: {} };
    }
    case API_START_LOADER: {
      const reducerArray = [
        ...lodash.cloneDeep(state.reducer ? state.reducer : []),
        action.reducer
      ];
      return {
        ...state,
        reducer: reducerArray,
        isLoading: !!reducerArray.length
      };
    }
    case API_STOP_LOADER: {
      const reducerArray =
        (state.reducer &&
          state.reducer.filter(
            (loaderText) => loaderText !== action.reducer
          )) ||
        [];
      return {
        ...state,
        reducer: reducerArray,
        isLoading: !!reducerArray.length
      };
    }
    default:
      return state;
  }
}
