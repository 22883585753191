import axios from "../../axios-jwt";
import BASE_PATH from "../../config";
import DBService from "../../services/DBService";

import { LOGIN, LOGIN_FAILED, LOGOUT, USER_LOGOUT } from "./actionTypes";
import { apiFailed, apiStartLoader, apiStopLoader } from "./ApiStatusActions";
import * as CompaniesActions from "./CompaniesActions";
import * as OrganizationActions from "./OrganizationActions";
import loadUserDetails from "./UserDetailsActions";

export function loginSuccess() {
  return { type: LOGIN };
}

export function logout() {
  return (dispatch) => {
    dispatch({ type: LOGOUT });
    dispatch({ type: USER_LOGOUT });
  };
}

export function loginFailed() {
  return { type: LOGIN_FAILED };
}

export function login(username, password) {
  return (dispatch, getState) => {
    dispatch(apiStartLoader("Login"));
    return axios
      .post(
        `${BASE_PATH}auth/login`,
        {
          username,
          password
        },
        { Host: "ac.example.com" }
      )
      .then((response) => {
        if (response.statusCode) {
          dispatch(loginFailed());
        }
        DBService.adder("jwt", response.data.jwt);
        DBService.adder("refreshToken", response.data.refreshToken);
        dispatch(loginSuccess());
        dispatch(CompaniesActions.loadCompanies());
      })
      .then(() => {
        if (getState().selectedCompany.code) dispatch(loadUserDetails());
        dispatch(OrganizationActions.loadOrganizationDetails());
        dispatch(apiStopLoader("Login"));
      })
      .catch((err) => {
        dispatch(apiFailed(err));
        dispatch(apiStopLoader("Login"));
      });
  };
}

export function checkAuth() {
  return (dispatch) => {
    return new Promise((resolve) => {
      DBService.get("jwt")
        .then((val) => {
          if (val === undefined) {
            dispatch(logout());
            resolve(true);
          } else {
            dispatch(loginSuccess());
            resolve(true);
          }
        })
        .catch(() => {
          dispatch(logout());
        });
    });
  };
}
