import {
  CLEAR_FOREMAN_SEARCH_RESULTS,
  SEACRH_FOREMAN_ASSIGNTO
} from "../actions/actionTypes";

export default function ForemanSearchReducer(state = [], action) {
  switch (action.type) {
    case SEACRH_FOREMAN_ASSIGNTO: {
      return action.foremanSearchResults;
    }
    case CLEAR_FOREMAN_SEARCH_RESULTS: {
      return state;
    }
    default:
      return state;
  }
}
