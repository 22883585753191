import axios from "../../axios-jwt";
import i18n from "../../i18n";

import * as ApiStatusActions from "./ApiStatusActions";
import loadCount from "./CountActions";
import { loadPayPeriods } from "./PayPeriodsActions";

// This is used for downoading data exported of weeklyreports of labour or equipmentreports
async function downloadExportedFile(payPeriod, tabSelected, selectedField) {
  const resp = axios({
    url: (() => {
      if (tabSelected && (selectedField || {})._id)
        return `equipmentreports/csv?payperiod=${payPeriod}&assignedTo=${selectedField._id}`;
      if (tabSelected) return `equipmentreports/csv?payperiod=${payPeriod}`;
      if (!tabSelected && (selectedField || {}).code)
        return `/weeklyreports/csv?payperiod=${payPeriod}&jobCode=${selectedField.code}`;
      return `/weeklyreports/csv?payperiod=${payPeriod}`;
    })(),
    method: "GET",
    responseType: "blob" // important
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      response.headers["content-disposition"].split("filename=")[1]
    );
    document.body.appendChild(link);
    link.click();
  });
  return resp;
}

// This is used for exporting data of weeklyreports of labour or equipmentreports
export function exportCSV(payPeriod, tabSelected, selectedField, limit) {
  return (dispatch, getState) => {
    dispatch(ApiStatusActions.apiStartLoader("exportCSVfile"));
    return downloadExportedFile(payPeriod, tabSelected, selectedField)
      .then(() => {
        dispatch(ApiStatusActions.apiSuccess(i18n.t("export_success")));
        if (!tabSelected)
          dispatch(
            loadPayPeriods(
              0,
              limit + 20,
              getState().organizationDetails.payperiodTypeForHumanTime
            )
          );
        dispatch(ApiStatusActions.apiStopLoader("exportCSVfile"));
      })
      .catch((err) => {
        dispatch(ApiStatusActions.apiStopLoader("exportCSVfile"));
        if (err) {
          dispatch(ApiStatusActions.apiFailed(err));
        }
      });
  };
}

async function getEquipmentCount(payPeriod, selectedField) {
  let resp;
  if (selectedField && selectedField._id) {
    resp = axios.get(
      `/equipmentreports/exportcount?payperiod=${payPeriod}&assignedTo=${selectedField._id}`
    );
  } else
    resp = axios.get(`/equipmentreports/exportcount?payperiod=${payPeriod}`);
  return resp;
}

export function exportEquipmentCount(payPeriod, selectedField) {
  return (dispatch) => {
    dispatch(ApiStatusActions.apiStartLoader("exportCount"));
    return getEquipmentCount(payPeriod, selectedField)
      .then((res) => {
        dispatch(loadCount("exportCount", res.data.count));
        dispatch(ApiStatusActions.apiStopLoader("exportCount"));
      })
      .catch((err) => {
        dispatch(ApiStatusActions.apiFailed(err));
        dispatch(ApiStatusActions.apiStopLoader("exportCount"));
      });
  };
}

export function lockUnlockPayPeriod(
  payPeriodId,
  type = "equipment",
  lockAction = true,
  limit
) {
  const data =
    type === "equipment"
      ? { isLockedForEquipmentEntries: lockAction }
      : { isLocked: lockAction };

  return async (dispatch, getState) => {
    dispatch(ApiStatusActions.apiStartLoader("exportCount"));
    try {
      const res = await axios.put(`/payperiods/${payPeriodId}`, data);
      if (res.status === 200) {
        dispatch(
          loadPayPeriods(
            0,
            limit,
            type === "equipment"
              ? getState().organizationDetails.payperiodTypeForEquipment
              : getState().organizationDetails.payperiodTypeForHumanTime
          )
        );
        lockAction
          ? dispatch(
              ApiStatusActions.apiSuccess(i18n.t("payperiod_locked_success"))
            )
          : dispatch(
              ApiStatusActions.apiSuccess(i18n.t("payperiod_unlocked_success"))
            );
      }
      dispatch(ApiStatusActions.apiStopLoader("exportCount"));
    } catch (err) {
      dispatch(ApiStatusActions.apiFailed(err));
      dispatch(ApiStatusActions.apiStopLoader("exportCount"));
    }
  };
}

// This is used for downoading data exported when user click export xls in preview reports pages
async function downloadPreviewReportsExportedFile(urlWithQuery) {
  try {
    const response = await axios({
      url: urlWithQuery,
      method: "GET",
      responseType: "blob" // important
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      response.headers["content-disposition"].split("filename=")[1]
    );
    document.body.appendChild(link);
    link.click();
  } catch (error) {
    throw new Error(error);
  }
}

// This is used for exporting preview reports
export function exportPreviewReportsCSV(urlWithQuery) {
  return async (dispatch) => {
    dispatch(ApiStatusActions.apiStartLoader("exportCSVfile"));

    try {
      await downloadPreviewReportsExportedFile(urlWithQuery);

      dispatch(ApiStatusActions.apiSuccess(i18n.t("export_success")));
    } catch (error) {
      console.log(dispatch(ApiStatusActions.apiFailed(error)));
      throw error;
    } finally {
      dispatch(ApiStatusActions.apiStopLoader("exportCSVfile"));
    }
  };
}
