export default {
  TS_MOBILE: {
    label: "TS Mobile",
    key: "TS_MOBILE"
  },
  TS_DESKTOP: {
    label: "TS Desktop",
    key: "TS_DESKTOP"
  },
  AUTHORIZER: {
    label: "Authorizer",
    key: "AUTHORIZER"
  },
  AUTHORIZER_ROLE_SETUP: {
    label: "Authorizer - Role setup",
    key: "AUTHORIZER_ROLE_SETUP"
  },
  AUTHORIZER_ASSIGN_PERSONNEL: {
    label: "Authorizer - Assign Personnel",
    key: "AUTHORIZER_ASSIGN_PERSONNEL"
  },
  ASSIGN_JOBS: {
    label: "Assign jobs",
    key: "ASSIGN_JOBS"
  },
  LABOR_APPROVAL: {
    id: 9,
    label: "Labor approval",
    key: "LABOR_APPROVAL"
  },
  LABOR_BULK_ENTRY: {
    label: "Labor bulk entry",
    key: "LABOR_BULK_ENTRY"
  },
  LABOR_UPLOAD: {
    label: "Labor upload",
    key: "LABOR_UPLOAD"
  },
  EQUIPMENT_ENTRY: {
    label: "Equipment entry",
    key: "EQUIPMENT_ENTRY"
  },
  EQUIPMENT_ASSIGN: {
    label: "Equipment assign",
    key: "EQUIPMENT_ASSIGN"
  },
  EQUIPMENT_UNASSIGN: {
    label: "Equipment unassign",
    key: "EQUIPMENT_UNASSIGN"
  },
  EQUIPMENT_UPLOAD: {
    label: "Equipment upload",
    key: "EQUIPMENT_UPLOAD"
  },
  EQUIPMENT_PAYPERIOD_LOCK: {
    label: "Lock payperiod - Equipment",
    key: "EQUIPMENT_PAYPERIOD_LOCK"
  },
  EQUIPMENT_PAYPERIOD_UNLOCK: {
    label: "Unlock payperiod - Equipment",
    key: "EQUIPMENT_PAYPERIOD_UNLOCK"
  },
  LABOUR_ENTRY_PAYPERIOD_LOCK: {
    label: "Lock payperiod - Labour",
    key: "LABOUR_ENTRY_PAYPERIOD_LOCK"
  },
  LABOUR_ENTRY_PAYPERIOD_UNLOCK: {
    label: "Unlock payperiod - Labour",
    key: "LABOUR_ENTRY_PAYPERIOD_UNLOCK"
  },
  LABOR_ENTRY_KIOSK: {
    label: "Labor entry - kiosk",
    key: "KIOSK_LABOR_ENTRY"
  },
  AUTHORIZER_ASSIGN_ROLE: {
    label: "Authorizer - Assign Roles",
    key: "AUTHORIZER_ASSIGN_ROLE"
  },
  GENERATE_LABOUR_REPORTS: {
    label: "generate_labour_reports_label",
    key: "GENERATE_LABOUR_REPORTS"
  }
};
