import { SWITCH } from "../actions/actionTypes";

export default function LoginStatusReducer(state = true, action) {
  switch (action.type) {
    case SWITCH:
      return !state;
    default:
      return state;
  }
}
