// filter by first Name
export const regFirstName = (search) => {
  return new RegExp(`^${search}`, "g");
};

// filter by last name
export const regLastName = (search) => {
  return new RegExp(`(\\w)+(\\s)${search}`);
};

// filter by first letter of firstname and first letter of lastname
export const regFirstAndLastNameChar = (search) => {
  return new RegExp(
    `^${search[0]}+([a-zA-Z0-9_])+(\\s)${search[1]}+([a-zA-Z0-9_])`
  );
};

// replace characters in user role name for URI
export const regReplaceURI = new RegExp(/[/|(|)|#| |?|-|_|.|!|~|*|`|"]/g);
