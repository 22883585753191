const BASE_PATH = () => {
  let path;
  switch (window.location && window.location.hostname) {
    case "localhost":
      path =
        "http://ac.timeshiftdev-env.eba-czmkvyrc.us-east-1.elasticbeanstalk.com/api/v2/";
      break;
    case "actimeshift.com":
      path = "https://ac.api.actimeshift.com/api/v2/";
      break;
    case "web-stage.actimeshift.com":
      path = "https://ac.api-stage.actimeshift.com/api/v2/";
      break;
    case "timeshift-dev.s3-website-us-east-1.amazonaws.com":
      path =
        "http://ac.timeshiftdev-env.eba-czmkvyrc.us-east-1.elasticbeanstalk.com/api/v2/";
      break;
    default:
      // Return prod env by default
      path = "https://ac.api.actimeshift.com/api/v2/";
      break;
  }
  return path;
};

export default BASE_PATH();
