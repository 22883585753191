// add activity to en and es whenever updated.
// define activity here, description for activity should be given in en.js and es.js files.
export default {
  UPDATED: {
    code: "101",
    activity: "updated"
  },
  CREATED: {
    code: "102",
    activity: "created"
  },
  DELETED: {
    code: "103",
    activity: "deleted"
  },
  CREATED_AUTO_LUNCH: {
    code: "104",
    activity: "created_auto_lunch"
  }
};
