import lodash from "lodash";

import {
  CHANGE_SPECIFIC_ROLE,
  EDIT_ASSIGN_ALL_JOBS,
  EDIT_AUTO_LUNCH,
  EDIT_REGISTER_FINGER_PRINT,
  GET_ROLE_EMPLOYEES,
  UNASSIGN_CREW_PERSONNEL_TO,
  UPDATE_CREW_PERSONNEL_TO
} from "../actions/actionTypes";

export default function RoleEmployeesReducer(state = {}, action) {
  switch (action.type) {
    case GET_ROLE_EMPLOYEES: {
      if (action.offset)
        return {
          ...state,
          [action.id]: [...state[action.id], ...action.employees]
        };
      return { ...state, [action.id]: action.employees };
    }
    case CHANGE_SPECIFIC_ROLE: {
      const copyEmployees = lodash.cloneDeep(state);
      copyEmployees[action.previousRole] = copyEmployees[
        action.previousRole
      ].filter((emp) =>
        emp._id === action.SelectedEmployee[0]
          ? emp.role._id === action.selectedRole._id
          : true
      );
      return copyEmployees;
    }
    case UPDATE_CREW_PERSONNEL_TO: {
      const copyEmployees = lodash.cloneDeep(state);
      copyEmployees[action.roleId].forEach((emp, idx) => {
        const SelectedEmployeesIndex = action.SelectedEmployees.findIndex(
          (id) => id === emp._id
        );
        if (SelectedEmployeesIndex >= 0) {
          copyEmployees[action.roleId][idx].personnelTo.push(
            ...action.personnelToArray
          );
          copyEmployees[action.roleId][idx].personnelTo = lodash.uniqBy(
            copyEmployees[action.roleId][idx].personnelTo,
            "_id"
          );
        }
      });
      return copyEmployees;
    }
    case UNASSIGN_CREW_PERSONNEL_TO: {
      const copyEmployees = lodash.cloneDeep(state);
      copyEmployees[action.roleId].forEach((emp, idx) => {
        if (emp._id === action.id) {
          copyEmployees[action.roleId][idx].personnelTo = copyEmployees[
            action.roleId
          ][idx].personnelTo.filter(
            (per) => !action.unassigned.includes(per._id)
          );
        }
      });
      return copyEmployees;
    }
    case EDIT_AUTO_LUNCH: {
      const copyEmployees = lodash.cloneDeep(state);
      copyEmployees[action.roleId].forEach((emp, idx) => {
        if (emp._id === action.empId) {
          copyEmployees[action.roleId][idx].autoFillLunchTime =
            action.autoLunchBoolean;
        }
      });
      return copyEmployees;
    }
    case EDIT_REGISTER_FINGER_PRINT: {
      const copyEmployees = lodash.cloneDeep(state);
      copyEmployees[action.roleId].forEach((emp, idx) => {
        if (emp._id === action.empId) {
          copyEmployees[action.roleId][idx].canRegisterFingerPrint =
            action.fingerPrintBoolean;
        }
      });
      return copyEmployees;
    }
    case EDIT_ASSIGN_ALL_JOBS: {
      const copyEmployees = lodash.cloneDeep(state);
      copyEmployees[action.roleId].forEach((emp, idx) => {
        if (emp._id === action.empId) {
          copyEmployees[action.roleId][idx].hasAllJobs =
            action.assignAllJobsBoolean;
        }
      });
      return copyEmployees;
    }
    default:
      return state;
  }
}
