import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import moment from "moment";
import PropTypes from "prop-types";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

import useInfiniteScroll from "../../custom-hooks/useInfiniteScroll";
import { splitTimelogs } from "../../redux/actions/TimelogsActions";
import * as userJobsSearchActions from "../../redux/actions/UserJobsSearchActions";
import activityLogs from "../../utillities/ActivityLogs";
import {
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  DATE_TIME_LONG_FORMAT
} from "../../utillities/DateFormats";
import DropdownSearchComponent from "../UI/DropdownSearchComponent";

const useStyles = makeStyles((theme) => ({
  select: {
    marginBottom: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  paper: {
    minHeight: "400px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly"
  },
  gridTextfield: {
    flexBasis: "48%",
    maxWidth: "48%"
  },
  outerTextFieldsGrid: {
    justifyContent: "space-between"
  }
}));

const SplitTimeComponent = (props) => {
  const { t } = useTranslation();

  const classes = useStyles();
  const { userJobsSearchResults } = useSelector((state) => state);
  const {
    Close,
    open,
    selectedTimeLog,
    crewMember,
    payPeriodId,
    OULevelNamesForTimeLogs,
    jobs,
    userJobsOffset,
    setJobs,
    setJobOffset,
    getOperationalUnits
  } = props;
  const [OUUpdate, setOUUpdate] = useState({});
  const [jobUpdated, setJobUpdated] = useState(false);
  const [rightHours, setRightHours] = useState("0.00");
  const [scrollHeight, setScrollHeight] = useState(0);
  const [clientHeight, setClientHeight] = useState(0);
  const [search, setSearch] = useState("");
  const [variableJob, setVariableJob] = useState(
    jobs.find((job) => selectedTimeLog.jobCode === job.code)
  );
  const clockIn = selectedTimeLog.punchIn
    ? moment(selectedTimeLog.punchIn, DATE_TIME_FORMAT)
    : null;
  const clockOut = selectedTimeLog.punchOut
    ? moment(selectedTimeLog.punchOut, DATE_TIME_FORMAT)
    : null;
  const hoursWorked =
    (clockIn && clockOut && clockOut.diff(clockIn, "hours", true)) ||
    (!clockIn && !clockOut && selectedTimeLog.hoursWorked) ||
    "0.00";
  const [leftHours, setLeftHours] = useState(hoursWorked);
  const [scrollTop, setScrollTop] = useState(0);
  const [splitTime, setSplitTime] = useState(clockOut);
  const dropdownLimit = 20;
  const dispatch = useDispatch();

  // Custom hook for infinite scroll on Job dropdown
  const [isFetching, toggle] = useInfiniteScroll(
    clientHeight,
    scrollTop,
    scrollHeight
  );

  const handleJobSearch = (searchTerm, id) => {
    if (searchTerm.length) {
      dispatch(userJobsSearchActions.searchUserJobsWithParam(searchTerm, id));
    }
    setSearch(searchTerm);
  };

  const getScrollValue = () => {
    if (isFetching && !search.length) {
      setJobOffset(userJobsOffset + dropdownLimit);
      toggle(isFetching);
      setScrollTop(0);
      setScrollHeight(0);
    }
  };

  useEffect(getScrollValue, [isFetching]);

  // handle time change when leftHours or rightHours changes
  const changeTime = () => {
    if (splitTime !== null && clockIn) {
      setSplitTime(clockIn.add(leftHours, "hours"));
    }
  };
  useEffect(changeTime, [leftHours]);

  function isDateValidForTime(momentDate) {
    return (
      momentDate.isSameOrAfter(clockIn, DATE_TIME_FORMAT) &&
      momentDate.isSameOrBefore(clockOut, DATE_TIME_FORMAT)
    );
  }

  function handleTimeChange(e) {
    if (clockIn && clockOut) {
      const clockInDateWithEditedTime = moment(
        `${clockIn.format(DATE_FORMAT)} ${e.target.value}`,
        DATE_TIME_FORMAT
      );
      const clockOutDateWithEditedTime = moment(
        `${clockOut.format(DATE_FORMAT)} ${e.target.value}`,
        DATE_TIME_FORMAT
      );
      const isClockInValidDate = isDateValidForTime(clockInDateWithEditedTime);
      const isClockOutValidDate = isDateValidForTime(
        clockOutDateWithEditedTime
      );

      if (isClockInValidDate || isClockOutValidDate) {
        const actualTimeWithDate = isClockInValidDate
          ? clockInDateWithEditedTime
          : clockOutDateWithEditedTime;
        setSplitTime(
          isClockInValidDate
            ? clockInDateWithEditedTime
            : clockOutDateWithEditedTime
        );
        const leftHoursMoment = actualTimeWithDate.diff(clockIn, "hours", true);
        setLeftHours(
          parseFloat(leftHoursMoment).toLocaleString(undefined, {
            maximumFractionDigits: 2
          })
        );
        setRightHours(
          parseFloat(hoursWorked - leftHoursMoment).toLocaleString(undefined, {
            maximumFractionDigits: 2
          })
        );
      }
    }
  }

  function handleLeftHoursChange(e) {
    if (e.target.value >= 0 && e.target.value <= hoursWorked) {
      setLeftHours(
        parseFloat(e.target.value).toLocaleString(undefined, {
          maximumFractionDigits: 2
        })
      );
      setRightHours(
        parseFloat(hoursWorked - e.target.value).toLocaleString(undefined, {
          maximumFractionDigits: 2
        })
      );
    }
  }

  function handleRightHoursChange(e) {
    if (e.target.value >= 0 && e.target.value <= hoursWorked) {
      setRightHours(
        parseFloat(e.target.value).toLocaleString(undefined, {
          maximumFractionDigits: 2
        })
      );
      setLeftHours(
        parseFloat(hoursWorked - e.target.value).toLocaleString(undefined, {
          maximumFractionDigits: 2
        })
      );
    }
  }

  function handleOnSave() {
    if (rightHours > 0) {
      let initialSplitObj = _.cloneDeep(selectedTimeLog);
      initialSplitObj.punchOut = splitTime
        ? splitTime.format(DATE_TIME_FORMAT)
        : undefined;

      initialSplitObj.hoursWorked = leftHours;
      initialSplitObj.isTrustedOutTime =
        selectedTimeLog.isTrustedOutTime && selectedTimeLog.isTrustedInTime;
      initialSplitObj.activityLogs = [];
      initialSplitObj.activityLogs.push({
        code: activityLogs.UPDATED.code,
        timestamp: new Date().toISOString(),
        updates: [
          {
            fieldName: "Clock Out",
            previousValue:
              selectedTimeLog.punchOut &&
              moment(selectedTimeLog.punchOut, DATE_TIME_FORMAT).format(
                DATE_TIME_LONG_FORMAT
              ),
            currentValue:
              initialSplitObj.punchOut &&
              moment(initialSplitObj.punchOut, DATE_TIME_FORMAT).format(
                DATE_TIME_LONG_FORMAT
              )
          },
          {
            fieldName: "Hours Worked",
            previousValue: selectedTimeLog.hoursWorked.toString(),
            currentValue: initialSplitObj.hoursWorked.toString()
          }
        ]
      });
      const initialSplitObject = _.pick(initialSplitObj, [
        "_id",
        "isTrustedInTime",
        "isTrustedOutTime",
        "punchIn",
        "punchOut",
        "jobCode",
        "date",
        "OULevelValues",
        "dateOfEntry",
        "crewId",
        "hoursWorked",
        "inLocation",
        "activityLogs"
      ]);
      const ouValues = {};
      Object.keys(OUUpdate).forEach((ou) => {
        ouValues[ou] = OUUpdate[ou].code;
      });
      if (Object.keys(ouValues).length && initialSplitObj.OULevelValues) {
        Object.keys(initialSplitObj.OULevelValues).forEach((ou) => {
          if (!OUUpdate[ou]) {
            ouValues[ou] = initialSplitObj.OULevelValues[ou];
          }
        });
      }

      let newSplitObj = {
        isTrustedInTime:
          selectedTimeLog.isTrustedOutTime && selectedTimeLog.isTrustedInTime,
        isTrustedOutTime:
          selectedTimeLog.isTrustedOutTime && selectedTimeLog.isTrustedInTime,
        punchOut: selectedTimeLog.punchOut,
        punchIn: initialSplitObject.punchOut,
        date: selectedTimeLog.date,
        jobCode: variableJob && variableJob.code,
        OULevelValues:
          Object.keys(OUUpdate).length > 0
            ? ouValues
            : jobUpdated
            ? ouValues
            : initialSplitObj.OULevelValues,
        hoursType: initialSplitObj.hoursType
          ? initialSplitObj.hoursType
          : "Regular",
        dateOfEntry: initialSplitObj.dateOfEntry,
        crewId: selectedTimeLog.crewId,
        hoursWorked: rightHours,
        outLocation: _.cloneDeep(selectedTimeLog.outLocation),
        activityLogs: [
          {
            code: activityLogs.CREATED.code,
            timestamp: new Date().toISOString()
          }
        ]
      };

      initialSplitObj = _.pick(
        initialSplitObj,
        Object.keys(initialSplitObj).filter(
          (ObjKey) => initialSplitObj[ObjKey] !== undefined
        )
      );
      newSplitObj = _.pick(
        newSplitObj,
        Object.keys(newSplitObj).filter(
          (ObjKey) => newSplitObj[ObjKey] !== undefined
        )
      );

      if (selectedTimeLog.inLocation) {
        delete initialSplitObject.inLocation._id;
      }
      if (selectedTimeLog.outLocation) {
        delete newSplitObj.outLocation._id;
      }

      const payload = [initialSplitObject, newSplitObj];
      dispatch(splitTimelogs(payload, payPeriodId, selectedTimeLog.crewId));
      Close();
    } else {
      Close();
    }
  }

  return (
    <Dialog onClose={Close} open={open} maxWidth='md' fullWidth>
      <DialogTitle id='customized-dialog-title' disableTypography>
        <h3>
          {" "}
          {`${t("payroll.split_hours_for")} ${crewMember} - ${moment(
            selectedTimeLog.dateOfEntry
          ).format("MMM D, YYYY")}`}
        </h3>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={1}>
          <Grid xs={5} item className={classes.paper} elevation={0}>
            <Grid item>
              <DropdownSearchComponent
                variant='outlined'
                value={(() => {
                  let label;
                  if (selectedTimeLog.jobCode)
                    label =
                      jobs[
                        _.findIndex(
                          jobs,
                          (option) => option.code === selectedTimeLog.jobCode
                        )
                      ];
                  else label = { code: "", name: "" };
                  return label;
                })()}
                disabled
              />
            </Grid>

            {OULevelNamesForTimeLogs.map((ou) => (
              <Grid item key={ou.name}>
                <DropdownSearchComponent
                  variant='outlined'
                  value={(() => {
                    let label;
                    if (
                      selectedTimeLog.jobCode &&
                      selectedTimeLog.OULevelValues &&
                      selectedTimeLog.OULevelValues[ou.name]
                    ) {
                      label = getOperationalUnits(
                        selectedTimeLog.jobCode,
                        ou.name,
                        selectedTimeLog.OULevelValues[ou.name]
                      );
                    } else label = { code: "", name: "" };
                    return label;
                  })()}
                  disabled
                />
              </Grid>
            ))}
            <Grid item container className={classes.outerTextFieldsGrid}>
              <Grid item xs={5} className={classes.gridTextfield}>
                <TextField
                  placeholder={t("payroll.clock_in_label")}
                  variant='outlined'
                  value={clockIn && clockIn.format("HH:mm")}
                  InputLabelProps={{
                    shrink: true
                  }}
                  type='time'
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={5} className={classes.gridTextfield}>
                <TextField
                  variant='outlined'
                  value={splitTime ? splitTime.format("HH:mm") : null}
                  onChange={(e) => handleTimeChange(e)}
                  inputProps={{
                    step: "900"
                  }}
                  fullWidth
                  disabled={splitTime === null}
                  type='time'
                />
              </Grid>
            </Grid>
            <Grid item container>
              <Grid xs={12} item>
                <TextField
                  variant='outlined'
                  value={leftHours}
                  type='number'
                  onChange={(e) => handleLeftHoursChange(e)}
                  inputProps={{
                    maxLength: 5,
                    step: 0.25
                  }}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={2} elevation={0} container />

          <Grid xs={5} item elevation={0} className={classes.paper}>
            <Grid
              item
              onScroll={(event) => {
                event.persist();
                setClientHeight(event.target.clientHeight);
                setScrollTop(event.target.scrollTop);
                setScrollHeight(event.target.scrollHeight);
              }}
            >
              <DropdownSearchComponent
                options={jobs}
                searchOptions={
                  search && search.length ? userJobsSearchResults : []
                }
                variant='outlined'
                id={`splitHours_${selectedTimeLog._id}_userJobs`}
                value={
                  (jobs || [])[
                    _.findIndex(
                      jobs || [],
                      (option) =>
                        option.code ===
                        (variableJob && variableJob.code
                          ? variableJob.code
                          : selectedTimeLog.jobCode)
                    )
                  ]
                }
                handleChange={(change) => {
                  if (
                    change &&
                    change.code &&
                    !jobs.find((job) => job.code === change.code)
                  ) {
                    setJobs([...jobs, change]);
                  }
                  userJobsSearchActions.clearUserJobsResults(
                    "userJobsSearchResults"
                  );
                  setVariableJob(change);
                  setOUUpdate({});
                  setJobUpdated(true);
                }}
                handleJobSearch={handleJobSearch}
                onDropDownChange={() => setSearch("")}
              />
            </Grid>

            {OULevelNamesForTimeLogs.map((ou) => (
              <Grid item key={ou.name}>
                <DropdownSearchComponent
                  variant='outlined'
                  options={
                    (variableJob &&
                      variableJob.name &&
                      variableJob.operationalUnits[
                        variableJob.operationalUnits.findIndex(
                          (op) => op.name === ou.name
                        )
                      ] &&
                      variableJob.operationalUnits[
                        variableJob.operationalUnits.findIndex(
                          (op) => op.name === ou.name
                        )
                      ].items) ||
                    []
                  }
                  value={(() => {
                    let label;
                    if (OUUpdate[ou.name]) {
                      label = OUUpdate[ou.name];
                    } else if (jobUpdated) {
                      label = { code: "", name: "" };
                    } else if (
                      selectedTimeLog.jobCode &&
                      selectedTimeLog.OULevelValues &&
                      selectedTimeLog.OULevelValues[ou.name]
                    ) {
                      label = getOperationalUnits(
                        selectedTimeLog.jobCode,
                        ou.name,
                        selectedTimeLog.OULevelValues[ou.name]
                      );
                    } else label = { code: "", name: "" };
                    return label;
                  })()}
                  handleChange={(change) => {
                    setOUUpdate((OU) => ({
                      ...OU,
                      [ou.name]: { code: change.code, name: change.name }
                    }));
                  }}
                />
              </Grid>
            ))}

            <Grid item container className={classes.outerTextFieldsGrid}>
              <Grid item xs={5} className={classes.gridTextfield}>
                <form noValidate>
                  <TextField
                    placeholder='Split'
                    variant='outlined'
                    value={splitTime ? splitTime.format("HH:mm") : null}
                    onChange={(e) => handleTimeChange(e)}
                    disabled={splitTime === null}
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{
                      step: "900"
                    }}
                    fullWidth
                    type='time'
                  />
                </form>
              </Grid>
              <Grid item xs={5} className={classes.gridTextfield}>
                <TextField
                  placeholder={t("payroll.clock_out_label")}
                  variant='outlined'
                  value={clockOut && clockOut.format("HH:mm")}
                  InputLabelProps={{
                    shrink: true
                  }}
                  type='time'
                  fullWidth
                  disabled
                />
              </Grid>
            </Grid>
            <Grid item container>
              <Grid xs={12} item>
                <TextField
                  variant='outlined'
                  value={rightHours}
                  type='number'
                  onChange={(e) => handleRightHoursChange(e)}
                  inputProps={{
                    maxLength: 5,
                    step: 0.25
                  }}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color='primary' onClick={Close}>
          {t("commonActions.cancel")}
        </Button>
        {selectedTimeLog.hoursWorked !== undefined &&
          selectedTimeLog.hoursWorked > 0 && (
            <Button color='primary' onClick={() => handleOnSave()}>
              {t("commonActions.save_changes")}
            </Button>
          )}
      </DialogActions>
    </Dialog>
  );
};

SplitTimeComponent.propTypes = {
  Close: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedTimeLog: PropTypes.shape({
    _id: PropTypes.string,
    companyCode: PropTypes.string,
    OULevelValues: PropTypes.shape({}),
    hoursWorked: PropTypes.number,
    punchIn: PropTypes.string,
    punchOut: PropTypes.string,
    dateOfEntry: PropTypes.string,
    date: PropTypes.string,
    crewId: PropTypes.string.isRequired,
    jobCode: PropTypes.string,
    outLocation: PropTypes.shape({}),
    inLocation: PropTypes.shape({}),
    isTrustedOutTime: PropTypes.bool.isRequired,
    isTrustedInTime: PropTypes.bool.isRequired
  }).isRequired,
  crewMember: PropTypes.string.isRequired,
  payPeriodId: PropTypes.string.isRequired,
  OULevelNamesForTimeLogs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  userJobsOffset: PropTypes.number.isRequired,
  setJobOffset: PropTypes.func.isRequired,
  jobs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setJobs: PropTypes.func.isRequired,
  getOperationalUnits: PropTypes.func.isRequired
};

export default SplitTimeComponent;
