import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { debounce, uniqBy } from "lodash";
import PropTypes from "prop-types";

import CircularProgress from "@material-ui/core/CircularProgress";
import { lighten, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

const dropdownStyles = makeStyles((theme) => ({
  root: {},
  listbox: { maxHeight: "300px !important" },
  value: {
    fontSize: "0.8125rem"
  },
  invalidValue: {
    color: "#f5494f",
    fontSize: "0.8125rem"
  },
  icon: { position: "relative" },
  highlight:
    theme.palette.type === "dark"
      ? {
          color: theme.palette.primary.main,
          backgroundColor: lighten(theme.palette.primary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.primary.dark
        }
}));

const DropdownSearchComponent = ({
  id,
  options,
  searchOptions,
  value,
  defaultValue,
  handleChange,
  width,
  variant,
  disabled,
  disableUnderline,
  placeholderText,
  handleJobSearch,
  onDropDownChange
}) => {
  const { apiStatus } = useSelector((state) => state);
  const [inputBoxValue, setInputBoxValue] = useState(undefined);
  const classes = dropdownStyles();
  const { t } = useTranslation();

  const loading =
    ((apiStatus || {}).isLoading &&
      apiStatus.reducer.find((reducerText) => reducerText.includes(id))) ||
    (apiStatus.reducer.find((reducerText) =>
      reducerText.includes("userJobsList")
    ) &&
      id &&
      id.includes("userJobs"));
  const onInputChange = () => {
    if (
      inputBoxValue &&
      id &&
      (id.includes("userJobs") || id.includes("groupSelection"))
    ) {
      handleJobSearch(inputBoxValue, id);
    }
  };
  useEffect(onInputChange, [inputBoxValue]);

  const setSearch = (searchValue) => {
    if (options.length) setInputBoxValue(searchValue);
  };

  const searchHandler = useCallback(debounce(setSearch, 800), []);

  return (
    <Autocomplete
      noOptionsText={t("authorizerContainer.no_results_found")}
      style={{ width }}
      loading={loading}
      loadingText={t("commonActions.loading")}
      classes={{
        root: classes.root,
        option: classes.value,
        endAdornment: classes.icon,
        listbox: classes.listbox
      }}
      size='small'
      // If true, the popup will ignore the blur event if the input is filled. Helpful to inspect the popup markup with browser tools.
      // debug
      // If true, the input can't be cleared.
      disableClearable
      // If true, the first option is automatically highlighted.
      autoHighlight
      // If true, the selected option becomes the value of the input when the Autocomplete loses focus unless the user chooses a different option or changes the character string in the input.
      autoSelect
      options={
        inputBoxValue &&
        inputBoxValue.length &&
        ((id || []).includes("userJobs") ||
          (id || []).includes("groupSelection"))
          ? searchOptions
          : options
      }
      disabled={disabled}
      getOptionLabel={(option) => {
        let label;
        if (option.code && option.name)
          label = `${option.code} - ${option.name}`;
        else if (option.code && !option.name) label = option.code;
        else if (!option.code && option.name) label = option.name;
        else if (!option.code && !option.name && defaultValue)
          label = defaultValue.name || `${t("payroll.na_label")}`;
        else
          label = placeholderText.length
            ? placeholderText
            : `${t("payroll.na_label")}`;
        return label;
      }}
      id={id}
      filterOptions={(optionsToFilter) => {
        if (!inputBoxValue) return optionsToFilter;
        const codeSearchResults = optionsToFilter.filter((op) =>
          (op.code || "").toUpperCase().includes(inputBoxValue.toUpperCase())
        );
        const nameSearchResults = optionsToFilter.filter((op) =>
          (op.name || "").toUpperCase().includes(inputBoxValue.toUpperCase())
        );
        return uniqBy([...codeSearchResults, ...nameSearchResults], "code");
      }}
      getOptionDisabled={(option) =>
        id.includes("userJobs") &&
        // eslint-disable-next-line no-prototype-builtins
        (option.hasOwnProperty("hasAccess")
          ? !option.hasAccess // Checking if jobsInWeek have access if not disable them
          : !option.isActive)
      }
      value={value || defaultValue}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            fullWidth
            variant={variant && variant}
            InputProps={{
              ...params.InputProps,
              className:
                params.inputProps.value === "NA"
                  ? classes.invalidValue
                  : classes.value,
              disableUnderline,
              disabled,
              endAdornment: loading ? (
                <CircularProgress color='secondary' size={20} />
              ) : (
                params.InputProps.endAdornment
              )
            }}
            onChange={(event) => {
              event.persist();
              if (
                id &&
                (id.includes("userJobs") || id.includes("groupSelection"))
              ) {
                searchHandler(event.target.value);
                if (event.target.value === "") onDropDownChange();
              } else {
                setInputBoxValue(event.target.value);
              }
            }}
          />
        );
      }}
      onChange={(event, newValue) => {
        event.persist();
        handleChange(newValue);
      }}
      onClose={(event, reason) => {
        event.persist();
        if (reason === "blur") onDropDownChange();
        setInputBoxValue("");
      }}
    />
  );
};

DropdownSearchComponent.propTypes = {
  id: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape()),
  defaultValue: PropTypes.shape(),
  placeholderText: PropTypes.string,
  value: PropTypes.shape(),
  handleChange: PropTypes.func,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  disabled: PropTypes.bool,
  variant: PropTypes.string,
  disableUnderline: PropTypes.bool,
  searchOptions: PropTypes.arrayOf(PropTypes.shape()),
  handleJobSearch: PropTypes.func,
  onDropDownChange: PropTypes.func
};

DropdownSearchComponent.defaultProps = {
  id: "",
  width: "",
  value: {},
  options: [],
  disabled: false,
  disableUnderline: true,
  variant: "standard",
  placeholderText: "",
  searchOptions: [],
  handleJobSearch: () => {},
  onDropDownChange: () => {},
  handleChange: () => {}
};

export default DropdownSearchComponent;
