import { GET_PENDING_APPROVALS_COUNT_SUCCESS } from "../actions/actionTypes";

export default function PendingApprovalsCountReducer(state = [], action) {
  switch (action.type) {
    case GET_PENDING_APPROVALS_COUNT_SUCCESS:
      return action.pendingApprovalsCount.employeesWithPendingApprovals;
    default:
      return state;
  }
}
