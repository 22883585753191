/* eslint-disable react/no-array-index-key */
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { findIndex } from "lodash";
import moment from "moment";

import { Divider, Fab, LinearProgress } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import FilterListIcon from "@material-ui/icons/FilterList";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

import useInfiniteScroll from "../../custom-hooks/useInfiniteScroll";
import useInput from "../../custom-hooks/useInput";
import loadCount from "../../redux/actions/CountActions";
import * as EmployeesByEquipmentActions from "../../redux/actions/EmployeesByEquipmentActions";
import * as EmployeesByEquipmentSearchActions from "../../redux/actions/EmployeesByEquipmentSearchActions";
import * as EquipmentsActions from "../../redux/actions/EquipmentsActions";
import * as EquipmentSearchActions from "../../redux/actions/EquipmentSearchActions";
import permissionsList from "../../utillities/Permissions";
import DrawerComponent from "../UI/DrawerComponent";
import MultiSelectComponent from "../UI/MultiSelectComponent";
import SearchAutocompleteComponent from "../UI/SearchAutocompleteComponent";

import AssignEquipmentDialogComponent from "./AssignEquipmentDialogComponent";
import UnassignEquipmentDialogComponent from "./UnassignEquipmentDialogComponent";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%"
  },
  paper: {
    height: "100%",
    marginBottom: theme.spacing(2),
    overflowY: "hidden"
  },
  table: {
    maxHeight: "90vh"
  },
  subTitle: {
    display: "flex",
    alignItems: "baseline"
  },
  count: {
    fontSize: "0.875rem",
    lineFeight: "1.43",
    float: "right",
    marginLeft: "14px"
  },
  tableWrapper: {
    top: "154px",
    position: "fixed",
    maxHeight: "80vh",
    overflowY: "auto",
    width: "78%"
  },
  toolbar: {
    position: "fixed",
    width: "78%",
    justifyContent: "space-between",
    zIndex: 40,
    backgroundColor: "#fff"
  },
  progressBar: {
    width: "99%",
    zIndex: "40",
    top: "62px"
  },
  buttonHidden: {
    visibility: "hidden"
  },
  assignEquipmentButton: {
    marginRight: "16px"
  },
  assignedToCell: {
    textTransform: "capitalize"
  },
  activityLogGrid: {
    padding: "8px"
  },
  drawerPaper: {
    height: "100% !important",
    width: "400px"
  },
  actionsCell: {
    paddingRight: 0
  },
  userActions: {
    display: "flex",
    float: "right"
  },
  filter: {
    backgroundColor: "white",
    marginRight: "16px",
    textTransform: "none"
  },
  loader: {
    marginLeft: "40px"
  },
  dividerStyles: {
    marginBottom: "2px"
  }
}));

const AllEquipmentsComponent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const {
    roles,
    equipments,
    equipmentSearchResults,
    equipmentActivityLogs,
    apiStatus,
    userDetails,
    employeesByEquipment,
    empByEqSearchResults,
    count
  } = useSelector((state) => state);
  const [rows, setRows] = useState(equipments || []);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [selected, setSelected] = useState([]);
  const [showAssignDialog, setShowAssignDialog] = useState(false);
  const [search, setSearch] = useState("");
  const [searchString, setSearchString] = useState("");
  const [showUnassignDialog, setShowUnassignDialog] = useState(false);
  const [offset, setOffset] = useState(0);
  const [activityLogsOffset, setActivityLogsOffset] = useState(0);
  const [dropdownOffset, setDropdownOffset] = useState(0);
  const [selectedEquip, setSelectedEquip] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [order, setOrder] = useState({ name: "asc" });
  const [sortBy, setSortBy] = useState("name");
  const [sorted, setSorted] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  const [scrollHeight, setScrollHeight] = useState(0);
  const [clientHeight, setClientHeight] = useState(0);
  const scrollRef = useRef();
  const [filterQuery, setFilterQuery] = useState("");
  const [filtered, setFiltered] = useState(false);
  const [drawerState, setDrawerState] = useState({
    activityLogs: { right: false },
    filters: { right: false }
  });
  const [currentDrawer, setCurrentDrawer] = useState("filters");
  const assignedToFilter = useInput();

  // Pagination limits
  const limit = 50;
  const activityLogsLimit = 20;
  const dropdownLimit = 20;

  const sortedEquipment = (query, sortOffset, sortLimit) =>
    dispatch(EquipmentsActions.sortEquipment(query, sortOffset, sortLimit));
  const filteredEquipment = (query, filterOffset, filterLimit) =>
    dispatch(
      EquipmentsActions.filterEquipment(query, filterOffset, filterLimit)
    );
  const searchEquipment = (query) => {
    if (query) dispatch(EquipmentSearchActions.searchEquipmentWithParam(query));
    setSearch(query);
  };
  const clearEquipmentList = (type, clear) =>
    dispatch(EquipmentSearchActions.clearEquipmentResults(type, clear));
  const getEmployeesByEquipment = (empOffset, empLimit) =>
    dispatch(
      EmployeesByEquipmentActions.loadEmployeesByEquipment(empOffset, empLimit)
    );
  const searchEmployeeByEquipment = (query) =>
    query &&
    dispatch(
      EmployeesByEquipmentSearchActions.searchEmployeesByEquipmentWithParam(
        query
      )
    );
  const clearEmployeeByEqList = (type, clear) =>
    dispatch(
      EmployeesByEquipmentSearchActions.clearEmployeesByEquipmentResults(
        type,
        clear
      )
    );

  const assignPermission =
    userDetails.isSuperAdmin ||
    (userDetails.role &&
      userDetails.role.permissions &&
      userDetails.role.permissions.includes(
        permissionsList.EQUIPMENT_ASSIGN.key
      ));
  const unassignPermission =
    userDetails.isSuperAdmin ||
    (!userDetails.isSuperAdmin &&
      userDetails.role.permissions.includes(
        permissionsList.EQUIPMENT_UNASSIGN.key
      ));
  const loading =
    (apiStatus || {}).isLoading &&
    (apiStatus.reducer || "").includes("equipment");
  const historyLoading =
    (apiStatus || {}).isLoading &&
    apiStatus.reducer.includes("activityLogsEquipment");

  const manageRows = () => {
    let array = [];
    if (!search.length) array = equipments;
    else {
      if (equipments.length && !equipmentSearchResults.length) array = [];
      else if (equipmentSearchResults.length && equipments.length)
        array = equipmentSearchResults;
    }
    setRows(array);
  };

  useEffect(manageRows, [
    equipments.length,
    equipmentSearchResults.length,
    search,
    filtered,
    loading,
    showAssignDialog,
    showUnassignDialog
  ]);

  // Assigned To filter options
  const empByEqOptions = () => {
    const options = () => {
      let array = [];
      if (!searchString) array = employeesByEquipment;
      if (searchString) {
        if (empByEqSearchResults.length) array = empByEqSearchResults;
        else if (!empByEqSearchResults.length && employeesByEquipment.length)
          array = [];
      }
      return array;
    };

    return options().map((emp) => ({
      name: emp.empName,
      id: emp.empID,
      _id: emp._id
    }));
  };

  const dropdownConfigAssignedTo = {
    label: `${t("unAssignComp.assigned_to")}`,
    type: "employeesByEquipment", // Label of reducer
    searchType: "api", // To Decide if search needs to be done in the UI or  with API
    data: empByEqOptions()
  };

  const applyFilters = () => {
    setOffset(0);
    const assignedTo = ((assignedToFilter || {}).value || [])
      .map((emp) => emp._id)
      .join(",");
    const query = `assignedTo=${assignedTo}&`;
    setFiltered(true);
    setFilterQuery(query);
    setDrawerState((drawer) => ({
      ...drawer,
      [currentDrawer]: { right: false }
    }));
  };

  const resetFilters = () => {
    setFiltered(false);
    setFilterQuery("");
    setSearchString("");
    setOffset(0);
    setDropdownOffset(0);
    assignedToFilter.onChange([]);
    clearEmployeeByEqList("empByEqSearchResults", true);
    dispatch(loadCount("equipment_filters", 0));
    setDrawerState((drawer) => ({
      ...drawer,
      [currentDrawer]: { right: false }
    }));
  };

  const drawerActions = [
    {
      display: true,
      label: `${t("payroll.apply_btn_label")}`,
      action: applyFilters,
      position: "bottom",
      disabled: !!assignedToFilter.value.length
    },
    {
      display: true,
      label: `${t("payroll.reset_btn_label")}`,
      action: resetFilters,
      position: "top",
      disabled: !!assignedToFilter.value.length
    }
  ];

  // Custom hook for infinite scroll
  const [isFetching, toggle] = useInfiniteScroll(
    clientHeight,
    scrollTop,
    scrollHeight
  );

  // Send scroll event result to parent component to send API calls for pagination
  useEffect(() => {
    if (isFetching && search.length === 0) {
      if (sorted) return;
      if (!equipmentSearchResults.length && isFetching && !loading)
        setOffset((o) => o + limit);
      setScrollTop(0);
      setScrollHeight(0);
      setClientHeight(0);
    }
    if (isFetching) {
      toggle(isFetching);
    }
    // eslint-disable-next-line
  }, [isFetching]);

  // Custom hook for infinite scroll on Activity Logs Drawer
  const handleScroll = (type, trigger) => {
    if (trigger && type === "activityLogs") {
      if (activityLogsOffset) setDropdownOffset(activityLogsLimit);
      setActivityLogsOffset((o) => o + activityLogsLimit);
    } else {
      setActivityLogsOffset(0);
    }

    if (trigger && type === "filters") {
      if (!dropdownOffset) setDropdownOffset(dropdownLimit);
      if (dropdownOffset < count.employeesByEquipment)
        setDropdownOffset((o) => o + dropdownLimit);
    }
  };

  // API call for activityLogs if activityLogsOffset > 0
  useEffect(() => {
    if (activityLogsOffset)
      dispatch(
        EquipmentsActions.loadEquipmentActivityLogs(
          selectedEquip._id,
          activityLogsOffset,
          activityLogsLimit
        )
      );
    // eslint-disable-next-line
  }, [activityLogsOffset]);

  // API call for filter dropdown if dropdownOffset > 0
  useEffect(() => {
    getEmployeesByEquipment(dropdownOffset, dropdownLimit);
    // eslint-disable-next-line
  }, [dropdownOffset]);

  const handleDropdownSearch = (type, query) => {
    if (query) {
      setSearchString(query);
      searchEmployeeByEquipment(query);
    } else {
      setSearchString("");
      clearEmployeeByEqList("empByEqSearchResults", true);
    }
  };

  const handleDropdownClose = (type, closed) => {
    if (!closed && type !== "employeesByEquipment") return;
    clearEmployeeByEqList("empByEqSearchResults", true);
  };

  const headers = [
    {
      id: "code",
      numeric: true,
      disablePadding: false,
      label: "ID",
      sortable: true
    },
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: `${t("equipmentManager.title")}`,
      sortable: true
    },
    {
      id: "assignedTo",
      numeric: false,
      disablePadding: false,
      label: `${t("unAssignComp.assigned_to")}`,
      sortable: false
    },
    {
      id: "actions",
      numeric: true,
      disablePadding: false,
      label: "",
      sortable: false
    }
  ];

  const DATE_TIME_FORMAT = "MMM DD, YYYY HH:mm";

  // Initial and paginated rendering
  const getEquipment = () => {
    const query = `${filterQuery || ""}orderBy=${sortBy}-${order[sortBy]}`;
    if (!offset) scrollRef.current.scrollTo(0, 0);
    if (sorted) {
      sortedEquipment(query, 0, limit);
      if (scrollRef.current) scrollRef.current.scrollTo(0, 0);
      setOffset(0);
      setSorted(false);
    } else if (filtered && filterQuery.length) {
      filteredEquipment(query, offset, limit);
    } else {
      dispatch(EquipmentsActions.loadEquipments(query, offset, limit));
      dispatch(loadCount("equipment_filters", 0));
    }
  };

  useEffect(getEquipment, [
    offset,
    sortBy,
    order,
    filterQuery,
    showUnassignDialog
  ]);

  const handleMenuClick = (event, row, index) => {
    event.stopPropagation();
    setMenuAnchorEl(event.currentTarget);
    setSelectedEquip(row);
    setSelectedIndex(index);
  };

  const handleMenuClose = (event) => {
    event.stopPropagation();
    setMenuAnchorEl(null);
  };

  const handleClose = () => setShowAssignDialog(false);
  const handleShow = () => setShowAssignDialog(true);

  const handleUnassignDialogShow = () => setShowUnassignDialog(true);

  const handleUnassignDialogClose = () => setShowUnassignDialog(false);

  const handleSelectAllClick = (event) => {
    if (selected.length > 0) {
      setSelected([]);
      return;
    }
    if (event.target.checked) {
      setSelected(rows);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, row) => {
    event.persist();

    const selectedIndex = findIndex(selected, (s) => s._id === row._id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, row);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const unSelectAll = () => {
    setSelected([]);
  };

  const isSelected = (row) =>
    findIndex(selected, (s) => s._id === row._id) !== -1;

  // View history
  const toggleDrawer = (component, side, isOpen) => {
    setCurrentDrawer(component);
    setDrawerState((drawer) => ({
      ...drawer,
      [component]: { [side]: isOpen }
    }));
    if (component === "filters") {
      if (!employeesByEquipment.length)
        getEmployeesByEquipment(dropdownOffset, dropdownLimit);
      clearEmployeeByEqList("empByEqSearchResults", true);
    }
  };

  const handleSort = (event, sort) => {
    if (event) {
      setOrder((o) => ({
        ...o,
        [sort]: order[sort] === "asc" ? "desc" : "asc"
      }));
      setSortBy(sort);
      setOffset(0);
      setSorted(true);
    }
  };

  const actionMenu = (index) => {
    return (
      <Menu
        id={`${index}_action_menu`}
        anchorEl={menuAnchorEl}
        keepMounted
        open={Boolean(menuAnchorEl)}
        onClose={handleMenuClose}
        elevation={1}
      >
        {assignPermission && (
          <MenuItem
            id={`${index}_action_menu_item_assign_to`}
            onClick={(event) => {
              handleShow();
              handleMenuClose(event);
            }}
          >
            {t("equipmentManager.assign_to")}
          </MenuItem>
        )}
        <MenuItem
          id={`${index}_action_menu_item_view_history`}
          onClick={(event) => {
            dispatch(
              EquipmentsActions.loadEquipmentActivityLogs(
                selectedEquip._id,
                activityLogsOffset,
                activityLogsLimit
              )
            );
            toggleDrawer("activityLogs", "right", true);
            handleMenuClose(event);
          }}
        >
          {t("equipmentManager.view_history")}
        </MenuItem>
        {unassignPermission &&
          !!(
            selectedEquip &&
            selectedEquip.assignedTo &&
            selectedEquip.assignedTo.length
          ) && (
            <MenuItem
              id={`${index}_action_menu_item_delete`}
              onClick={(event) => {
                handleUnassignDialogShow();
                handleMenuClose(event);
              }}
            >
              {t("equipmentManager.unassign_label")}
            </MenuItem>
          )}
      </Menu>
    );
  };

  const activityLogsComponent = () => (
    <>
      <Divider variant='middle' />
      {equipmentActivityLogs &&
        equipmentActivityLogs.map((activityLog) => (
          <>
            <Divider variant='middle' className={classes.dividerStyles} />
            <Paper
              style={{ minWidth: "350px", maxWidth: "350px" }}
              elevation={0}
            >
              <Grid container className={classes.activityLogGrid}>
                <Grid item xs={12} className={classes.activityLogGrid}>
                  <small className='text-muted'>
                    {moment(activityLog.timeStamp).format(DATE_TIME_FORMAT)}
                  </small>
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  className={classes.activityLogGrid}
                >
                  {activityLog.activityCode === 201 ? (
                    <small className='text-muted'>
                      {t("unAssignComp.assigned_to")}
                    </small>
                  ) : (
                    <small className='text-muted'>
                      {t("unAssignComp.returned_from")}
                    </small>
                  )}
                  <Grid xs={12} className={classes.assignedToCell}>
                    {activityLog.employees
                      .map((emp) => emp.empName)
                      .join(", ")
                      .toLowerCase()}
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </>
        ))}
    </>
  );

  const filtersComponent = () => {
    return (
      <MultiSelectComponent
        key={dropdownConfigAssignedTo.type}
        dropdownConfig={dropdownConfigAssignedTo}
        {...assignedToFilter}
        optionLabel={(option) => `${option.id} - ${option.name}`}
        handleScroll={handleScroll}
        handleSearch={handleDropdownSearch}
        handleClose={handleDropdownClose}
      />
    );
  };

  // Reuse drawer component for filters and activityLogs
  const drawerComponent = () => {
    const drawerHeader =
      currentDrawer === "filters"
        ? t("payroll.filters")
        : t("equipmentManager.equip_history");
    const actions = currentDrawer === "filters" ? drawerActions : [];

    const component = () => {
      if (currentDrawer === "filters") return filtersComponent();
      return activityLogsComponent();
    };

    return (
      <DrawerComponent
        header={
          <div>
            <span>{drawerHeader}</span>
            {historyLoading && (
              <CircularProgress size={20} className={classes.loader} />
            )}
          </div>
        }
        openDrawer={drawerState[currentDrawer]}
        actions={actions}
        closeDrawer={() => {
          toggleDrawer(currentDrawer, "right", false);
          setActivityLogsOffset(0);
        }}
        drawerPaper={classes.drawerPaper}
        handleScroll={(trigger) => handleScroll(currentDrawer, trigger)}
        drawerAttributes={component()}
      />
    );
  };

  const tableRows = (data) => {
    if (!(data || []).length)
      if (loading)
        return (
          <TableRow>
            <TableCell
              colSpan={headers.length + 1}
              style={{ textAlign: "center", border: "none" }}
            >
              {t("commonActions.loading_records")}
            </TableCell>
          </TableRow>
        );
      else
        return (
          <TableRow>
            <TableCell
              colSpan={headers.length + 1}
              style={{ textAlign: "center", border: "none" }}
            >
              {t("commonActions.no_records_found")}
            </TableCell>
          </TableRow>
        );

    return data.map((row, index) => {
      const isItemSelected = isSelected(row);
      return (
        <TableRow
          hover
          onClick={(event) => handleClick(event, row)}
          role='checkbox'
          aria-checked={isItemSelected}
          tabIndex={-1}
          key={row._id}
          selected={isItemSelected}
        >
          <TableCell padding='checkbox' key={`${index}_checkbox`}>
            <Checkbox
              checked={isItemSelected}
              inputProps={{
                "aria-labelledby": `table-checkbox-${index}`
              }}
            />
          </TableCell>
          <TableCell align='left' key={`${index}_code`}>
            {row.code}
          </TableCell>
          <TableCell align='left' key={`${index}_name`}>
            {row.name}
          </TableCell>

          {row.assignedTo && row.assignedTo.length ? (
            <TableCell
              align='left'
              className={classes.assignedToCell}
              key={`${index}_assigned_to`}
            >
              {row.assignedTo
                .map((e) => e.empName)
                .join(", ")
                .toLowerCase()}
            </TableCell>
          ) : (
            <TableCell align='left'>-</TableCell>
          )}

          {/* eslint-disable-next-line react/no-array-index-key */}
          <TableCell key={`${index}_action`} className={classes.actionsCell}>
            <Button
              id={row._id}
              aria-controls='actions-control'
              aria-haspopup='true'
              onClick={(event) => handleMenuClick(event, row, index)}
              aria-label={index}
              style={{ float: "right" }}
              className={selected.length > 0 ? classes.buttonHidden : null}
            >
              <MoreHorizIcon />
            </Button>
          </TableCell>
        </TableRow>
      );
    });
  };

  return (
    <div className={classes.root}>
      <Toolbar className={classes.toolbar}>
        <Typography className={classes.title} variant='h4' id='tableTitle'>
          <div className={classes.subTitle}>
            <p>{t("equipmentManager.title")}</p>
            <p className={classes.count}>{`1 - ${equipments.length} of ${
              count.equipment_filters || count.equipment
            }`}</p>
          </div>
        </Typography>
        <div className={classes.userActions}>
          {selected.length > 0 && (
            <Fab
              variant='extended'
              color='primary'
              onClick={handleShow}
              className={classes.assignEquipmentButton}
              disabled={!assignPermission}
            >
              {t("equipmentManager.assign_to")}
            </Fab>
          )}

          <Fab
            onClick={() => toggleDrawer("filters", "right", true)}
            variant='extended'
            aria-label='filter'
            className={classes.filter}
          >
            <FilterListIcon />
            {t("payroll.filter_btn_label")}
          </Fab>

          <SearchAutocompleteComponent
            handleSearch={searchEquipment}
            searchResults={equipmentSearchResults}
            showOptions={false}
            disableClearable
            freeSolo
            clear={() =>
              clearEquipmentList(
                "equipmentSearchResults",
                Boolean(equipmentSearchResults.length)
              )
            }
            key='all_equipment_serach'
          />
        </div>
      </Toolbar>

      {loading && <LinearProgress className={classes.progressBar} />}

      {/* Equipment table */}
      <Paper
        className={classes.tableWrapper}
        ref={scrollRef}
        onScroll={(event) => {
          event.persist();
          setScrollTop(event.target.scrollTop);
          setScrollHeight(event.target.scrollHeight);
          setClientHeight(event.target.clientHeight);
        }}
      >
        <Table
          className={classes.table}
          aria-labelledby='tableTitle'
          aria-label='enhanced table'
          size='small'
          stickyHeader
        >
          <TableHead>
            <TableRow>
              <TableCell padding='checkbox' scope='tr'>
                <Checkbox
                  color='secondary'
                  indeterminate={
                    selected.length &&
                    equipments.length &&
                    selected.length < count.equipment
                  }
                  checked={
                    selected.length &&
                    equipments.length &&
                    selected.length === count.equipment
                  }
                  onChange={handleSelectAllClick}
                  inputProps={{ "aria-label": "select all rows" }}
                />
              </TableCell>
              {headers.map((header) => (
                <TableCell key={header.id} align='left'>
                  {header.sortable ? (
                    <TableSortLabel
                      active={sortBy === header.id}
                      direction={order[sortBy]}
                      onClick={(event) => handleSort(event, header.id)}
                    >
                      {header && header.label ? header.label : header.id}
                    </TableSortLabel>
                  ) : (
                    header.label
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody key={JSON.stringify(rows)}>{tableRows(rows)}</TableBody>
        </Table>
      </Paper>

      {/* Action menu for each row */}
      {menuAnchorEl && actionMenu(selectedIndex, selectedEquip)}

      {/* Assign Equipment dialog */}
      {showAssignDialog && (
        <AssignEquipmentDialogComponent
          role={roles[findIndex(roles, (r) => r.roleLevel === 9)]}
          roles={roles}
          selected={selected.length ? selected : [selectedEquip]}
          unSelectAll={unSelectAll}
          show={showAssignDialog}
          handleClose={handleClose}
        />
      )}

      {/* Un-Assign Equipment dialog */}
      <UnassignEquipmentDialogComponent
        key={((selectedEquip || {}).assignedTo || []).length}
        unassignShow={showUnassignDialog}
        handleUnassignClose={handleUnassignDialogClose}
        selectedEquipment={selectedEquip}
      />
      {/* Drawer for Filters && View History */}
      {drawerComponent()}
    </div>
  );
};
export default AllEquipmentsComponent;
