import React, { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Switch from "@material-ui/core/Switch";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

import * as roleEmployeeActions from "../../../redux/actions/RoleEmployeesActions";
import permissionsList from "../../../utillities/Permissions";

const SpecificRoleTableWrapperComponent = (props) => {
  const { t } = useTranslation();

  const {
    handleClick,
    handleDropdownButton,
    handleShow,
    selected,
    classes,
    row,
    index,
    role,
    isItemSelected,
    isLastRole,
    handleViewCrewShow,
    handleUnassignShow,
    setUnassignEmployee,
    handleChangeRoleShow,
    handleChangeRole,
    setManageJobsShow,
    labelId,
    headCells,
    isFirstRole
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const { userDetails } = useSelector((state) => state);
  const dispatch = useDispatch();

  const handleMenuClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const handleSwitch = (e, param) => {
    e.stopPropagation();
    if (param === "autoLunch")
      dispatch(
        roleEmployeeActions.autoLunch(
          row.roleId,
          row._id,
          !row.autoFillLunchTime
        )
      );
    if (param === "registerFingerprint")
      dispatch(
        roleEmployeeActions.registerFingerPrint(
          row.roleId,
          row._id,
          !row.canRegisterFingerPrint
        )
      );
    if (param === "assignAllJobs")
      dispatch(
        roleEmployeeActions.assignAllJobs(row.roleId, row._id, !row.hasAllJobs)
      );
  };

  const renderPersonnelTo = (rowSelected) => {
    if (!isFirstRole) {
      return rowSelected.personnelTo === "" ? (
        <TableCell align='left'>-</TableCell>
      ) : (
        <TableCell align='left'>
          {rowSelected.personnelTo.map((e) => e.empName).join(", ")}
        </TableCell>
      );
    }
    return <></>;
  };

  return (
    <TableRow
      hover
      onClick={(event) => handleClick(event, index, row._id)}
      role='checkbox'
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row._id}
      selected={isItemSelected}
    >
      <TableCell padding='checkbox'>
        <Checkbox
          checked={isItemSelected}
          inputProps={{ "aria-labelledby": labelId }}
        />
      </TableCell>
      <TableCell component='th' id={labelId} scope='row'>
        {row.empID}
      </TableCell>
      <TableCell align='left'>{row.empName}</TableCell>

      {renderPersonnelTo(row)}

      {!isLastRole && (
        <TableCell
          onClick={(e) => {
            handleSwitch(e, "autoLunch");
          }}
          align='left'
        >
          <Switch checked={row.autoFillLunchTime} />
        </TableCell>
      )}

      {headCells.find((hc) => hc.id === "registerFingerprint") &&
        !role.permissions.includes(permissionsList.LABOR_ENTRY_KIOSK.key) && (
          <TableCell align='left'>
            <Switch
              onClick={(e) => {
                handleSwitch(e, "registerFingerprint");
              }}
              checked={row.canRegisterFingerPrint}
            />
          </TableCell>
        )}

      {headCells.find((hc) => hc.id === "assignAllJobs") &&
        role.permissions.includes(permissionsList.LABOR_UPLOAD.key) && (
          <TableCell align='left'>
            <Switch
              onClick={(e) => {
                handleSwitch(e, "assignAllJobs");
              }}
              checked={row.hasAllJobs}
            />
          </TableCell>
        )}

      <TableCell>
        {(userDetails.isSuperAdmin ||
          userDetails.role.permissions.includes(
            permissionsList.AUTHORIZER_ROLE_SETUP.key
          ) ||
          userDetails.role.permissions.includes(
            permissionsList.AUTHORIZER_ASSIGN_PERSONNEL.key
          ) ||
          !isLastRole) && (
          <Button
            id='actions'
            aria-controls='actions-control'
            aria-haspopup='true'
            onClick={handleMenuClick}
            aria-label={index}
            style={{ float: "right" }}
            className={selected.length > 0 ? classes.buttonHidden : null}
          >
            <MoreHorizIcon />
          </Button>
        )}

        <Menu
          id='actions-menu'
          anchorEl={anchorEl}
          onClick={(e) => e.stopPropagation()}
          keepMounted
          key={row._id}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          elevation={1}
        >
          {(userDetails.isSuperAdmin ||
            userDetails.role.permissions.includes(
              permissionsList.AUTHORIZER_ROLE_SETUP.key
            )) && (
            <MenuItem
              key='1'
              onClick={(e) => {
                handleChangeRoleShow();
                handleChangeRole(row._id);
                handleMenuClose(e);
              }}
            >
              {t("permissions.change_role")}
            </MenuItem>
          )}
          {!isLastRole && (
            <div>
              <MenuItem
                key='2'
                onClick={(e) => {
                  handleViewCrewShow(e);
                  handleDropdownButton(row);
                  handleMenuClose(e);
                }}
              >
                {t("authorizerContainer.view_group")}
              </MenuItem>
            </div>
          )}
          <MenuItem
            key='5'
            onClick={(e) => {
              setManageJobsShow(true);
              handleChangeRole(row._id);
              handleMenuClose(e);
            }}
            disabled={
              (!userDetails.isSuperAdmin &&
                !userDetails.role.permissions.includes(
                  permissionsList.ASSIGN_JOBS.key
                )) ||
              row.hasAllJobs
            }
          >
            {t("authorizerContainer.manage_jobs")}
          </MenuItem>
          {!isFirstRole &&
            (userDetails.isSuperAdmin ||
              userDetails.role.permissions.includes(
                permissionsList.AUTHORIZER_ASSIGN_PERSONNEL.key
              )) && (
              <div>
                {row.personnelTo.length > 0 && (
                  <MenuItem
                    key='3'
                    onClick={(e) => {
                      handleUnassignShow(e);
                      setUnassignEmployee(row);
                      handleMenuClose(e);
                    }}
                  >
                    {t("equipmentManager.unassign_label")}
                  </MenuItem>
                )}

                <MenuItem
                  key='4'
                  onClick={(e) => {
                    handleShow(e);
                    handleDropdownButton(row._id);
                    handleMenuClose(e);
                  }}
                >
                  {t("equipmentManager.assign_to")}
                </MenuItem>
              </div>
            )}
        </Menu>
      </TableCell>
    </TableRow>
  );
};

SpecificRoleTableWrapperComponent.propTypes = {
  handleClick: PropTypes.func.isRequired,
  setManageJobsShow: PropTypes.func.isRequired,
  row: PropTypes.shape().isRequired,
  isLastRole: PropTypes.bool.isRequired,
  isFirstRole: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  isItemSelected: PropTypes.bool.isRequired,
  handleViewCrewShow: PropTypes.func.isRequired,
  handleUnassignShow: PropTypes.func.isRequired,
  handleChangeRoleShow: PropTypes.func.isRequired,
  handleDropdownButton: PropTypes.func.isRequired,
  labelId: PropTypes.string.isRequired,
  handleShow: PropTypes.func.isRequired,
  setUnassignEmployee: PropTypes.func.isRequired,
  handleChangeRole: PropTypes.func.isRequired,
  selected: PropTypes.arrayOf(PropTypes.string).isRequired,
  role: PropTypes.shape().isRequired,
  classes: PropTypes.shape({
    buttonHidden: PropTypes.string
  }).isRequired,
  headCells: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

function shouldRowRerender(prevProps, nextProps) {
  // rerender component only when shouldComponentRerender string changes from previous props in next props
  if (prevProps.shouldComponentRerender === nextProps.shouldComponentRerender)
    return true;
  return false;
}

export default memo(SpecificRoleTableWrapperComponent, shouldRowRerender);
