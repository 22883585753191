import { LOAD_COMPANIES_SUCCESS } from "../actions/actionTypes";

export default function CompaniesReducer(state = [], action) {
  switch (action.type) {
    case LOAD_COMPANIES_SUCCESS: {
      return action.payload;
    }
    default:
      return state;
  }
}
