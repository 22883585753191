import { uniq } from "lodash";

import axios from "../../axios-jwt";
import i18n from "../../i18n";

import {
  APPROVE_BULK_TIMESHEET,
  APPROVE_CREW_TIMESHEET,
  LIST_APPROVED_TIMESHEET
} from "./actionTypes";
import {
  apiFailed,
  apiStartLoader,
  apiStopLoader,
  apiSuccess,
  customFailedMessage
} from "./ApiStatusActions";
import loadPendingApprovalsCount from "./PendingApprovalsCountActions";
import * as WeeklyReportsActions from "./WeeklyReportsActions";

async function getApprovalDetails(payPeriodId, crewID) {
  const resp = axios.get(`approval?payPeriod=${payPeriodId}&crewID=${crewID}`);
  return resp;
}

async function approveTimesheet(payload) {
  const resp = axios.post("approval", payload);
  return resp;
}

async function approveAllTimesheet(payload) {
  const resp = axios.put("approval", payload);
  return resp;
}

async function unapproveTimesheetEmployee(payPeriodId, crewID) {
  const resp = axios.delete(
    `approval?payPeriod=${payPeriodId}&crewIds=${crewID}`
  );
  return resp;
}

export const approveBulkTimesheetSuccess = (approvedTimesheet) => {
  return { type: APPROVE_BULK_TIMESHEET, approvedTimesheet };
};

export const approveCrewTimesheetSuccess = (approvedTimesheet) => {
  return { type: APPROVE_CREW_TIMESHEET, approvedTimesheet };
};

export const getApprovedTimesheetSuccess = (listTimesheet) => {
  return { type: LIST_APPROVED_TIMESHEET, listTimesheet };
};

export function approveBulkTimesheet(payPeriodId, payload, filterQuery) {
  return (dispatch) => {
    if (payload.length === 0) {
      dispatch(customFailedMessage(i18n.t("payroll.empty_approvals")));
    } else {
      dispatch(apiStartLoader("weeklyReports"));
      approveAllTimesheet(payload)
        .then((res) => {
          if (res.status === 200) {
            const error = uniq(res.data.map((l) => l.error)).join("||");
            const success = res.data.filter((d) => !d.error).length;
            if (!success && error.length) {
              throw new Error(error); //skip next then block
            } else {
              dispatch(apiSuccess(i18n.t("payroll.approved_successfully")));
            }
            dispatch(apiStopLoader("weeklyReports"));
          }
        })
        .then(() => {
          dispatch(
            WeeklyReportsActions.loadBulkWeeklyReports(
              payPeriodId,
              uniq(payload.map((crew) => crew.crewID)).join(","),
              filterQuery
            )
          );
          dispatch(loadPendingApprovalsCount(payPeriodId));
        })
        .catch((error) => {
          dispatch(apiFailed(error));
          dispatch(apiStopLoader("weeklyReports"));
        });
    }
  };
}

export function unapproveTimesheet(payPeriodId, crewId, filterQuery) {
  return (dispatch) => {
    dispatch(apiStartLoader("weeklyReports"));
    unapproveTimesheetEmployee(payPeriodId, crewId)
      .then((res) => {
        if (res.status === 200) {
          dispatch(apiStopLoader("weeklyReports"));
          dispatch(apiSuccess(i18n.t("payroll.unapproved_successfully")));
        }
      })
      .then(() => {
        dispatch(
          WeeklyReportsActions.loadBulkWeeklyReports(
            payPeriodId,
            crewId,
            filterQuery
          )
        );
        dispatch(loadPendingApprovalsCount(payPeriodId));
      })
      .catch((error) => {
        dispatch(apiFailed(error));
        dispatch(apiStopLoader("weeklyReports"));
      });
  };
}

export function approveCrewTimesheet(payload) {
  return (dispatch) => {
    approveTimesheet(payload)
      .then((res) => {
        if (res.status === 200) {
          dispatch(apiSuccess(i18n.t("payroll.approved_successfully")));
          dispatch(approveCrewTimesheetSuccess(res.data));
        }
      })
      .catch((error) => {
        dispatch(apiFailed(error));
      });
  };
}

export function approvedTimesheetDetails(payPeriodId, crewId) {
  return async (dispatch) => {
    try {
      const obj = await getApprovalDetails(payPeriodId, crewId);
      return dispatch(getApprovedTimesheetSuccess(obj.data));
    } catch (error) {
      return dispatch(apiFailed(error));
    }
  };
}
