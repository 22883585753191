import { combineReducers } from "redux";

import { USER_LOGOUT } from "../actions/actionTypes";

import activityLogsTimeEntries from "./ActivityLogsTimeEntries";
import crewMembers from "./AllCrewReducer";
import apiStatus from "./ApiStatusReducer";
import approvedTimesheet from "./ApprovalReducer";
import authoriserReportFilterLoadSearch from "./AuthorisersReportFilterLoadSearchReducer";
import authorisersReportFilter from "./AuthorisersReportFilterReducer";
import authorisersReportsPreview from "./AuthorisersReportsPreviewReducer";
import companies from "./CompaniesReducer";
import count from "./CountReducer";
import crewSearchResults from "./CrewReducer";
import employeeByJobSearchResults from "./EmployeeByJobSearchReducer";
import employeeByJobs from "./EmployeeByJobsReducer";
import employeesByEquipment from "./EmployeesByEquipmentReducer";
import empByEqSearchResults from "./EmployeesByEquipmentSearchReducer";
import employeeSearchResults from "./EmployeeSearchReducer";
import employees from "./EmployeesReducer";
import equipmentActivityLogs from "./EquipmentLogReducer";
import equipmentReports from "./EquipmentReportsReducer";
import equipmentSearchResults from "./EquipmentSearchReducer";
import equipmentInvalidEntries from "./EquipmentsInvalidEntriesReducer";
import equipments from "./EquipmentsReducer";
import foremanSearchResults from "./ForemanSearchReducer";
import foremen from "./ForemenReducer";
import companyJobs from "./JobsReducer";
import jobsSearchResults from "./JobsSearchReducer";
import labourTimeReportsPreview from "./LabourTimeReportsPreviewReducer";
import isAuth from "./LoginStatusReducer";
import notifications from "./NotificationsReducer";
import organizationDetails from "./OrganizationsReducer";
import payPeriods from "./PayPeriodsReducer";
import pendingApprovalsCount from "./PendingApprovalsCountReducer";
import permissions from "./PermissionsReducer";
import personnel from "./PersonnelReducer";
import personnelSearchResults from "./PersonnelSearchReducer";
import roleEmployees from "./RoleEmployeesReducer";
import roleEmployeesNoCrew from "./RoleEmployeesWithoutCrewReducer";
import roleSearchResults from "./RoleSearchReducer";
import roles from "./RolesReducer";
import toSwitch from "./RoleSwitchReducer";
import selectedCompany from "./SelectedCompanyReducer";
import syncStatus from "./SyncStatusReducer";
import timeEntryEmpSearchResults from "./TimeEntryEmployeeSearchReducer";
import timeEntryReportFilter from "./TimeEntryReportFilterReducer";
import timelogs from "./TimelogsReducer";
import updateEquipment from "./UnassignEquipmentReducer";
import userDetails from "./UserDetailsReducer";
import userJobs from "./UserJobDetailsReducer";
import userJobsSearchResults from "./UserJobsSearchReducer";
import weeklyReports from "./WeeklyReportsReducer";

const appReducer = combineReducers({
  isAuth,
  userDetails,
  userJobs,
  userJobsSearchResults,
  roles,
  roleEmployees,
  roleSearchResults,
  roleEmployeesNoCrew,
  employees,
  employeeSearchResults,
  employeeByJobs,
  employeeByJobSearchResults,
  employeesByEquipment,
  empByEqSearchResults,
  personnel,
  personnelSearchResults,
  crewMembers,
  crewSearchResults,
  equipments,
  equipmentSearchResults,
  updateEquipment,
  equipmentActivityLogs,
  equipmentReports,
  equipmentInvalidEntries,
  weeklyReports,
  timelogs,
  companies,
  selectedCompany,
  companyJobs,
  permissions,
  activityLogsTimeEntries,
  apiStatus,
  payPeriods,
  organizationDetails,
  foremen,
  approvedTimesheet,
  toSwitch,
  notifications,
  jobsSearchResults,
  timeEntryEmpSearchResults,
  timeEntryReportFilter,
  authorisersReportFilter,
  foremanSearchResults,
  count,
  pendingApprovalsCount,
  syncStatus,
  labourTimeReportsPreview,
  authoriserReportFilterLoadSearch,
  authorisersReportsPreview
});

const rootReducer = (state, action) => {
  if (action.type === USER_LOGOUT) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
