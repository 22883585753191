import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { flatMap, flatten, intersectionWith, uniqBy } from "lodash";
import PropTypes from "prop-types";

import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

import useInput from "../../custom-hooks/useInput";
import * as CrewActions from "../../redux/actions/CrewActions";
import * as PersonnelSearchActions from "../../redux/actions/PersonnelSearchActions";
import * as TimeEntryReportFilterActions from "../../redux/actions/TimeEntryReportFilterActions";
import * as UserJobsSearchActions from "../../redux/actions/UserJobsSearchActions";
import CustomSwitch from "../UI/CustomSwitch";
import FilterDialogComponent from "../UI/FilterDialogComponent";
import MultiSelectComponent from "../UI/MultiSelectComponent";

import CustomPeriodSelector from "./DatePeriodSelector/CustomPeriodSelector";
import MinMaxHourFields from "./MinMaxHourFields";

const useStyles = makeStyles(() => ({
  gridLabel: {
    marginBottom: "15px"
  },
  ouLablesText: {
    textTransform: "capitalize"
  },
  untrustedBox: {
    display: "flex",
    gap: "16px",
    alignItems: "center"
  }
}));

const TimeEntryReportsFiltersDialog = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const {
    open,
    close,
    userJobs,
    organizationDetails,
    exportReportClickedRef,
    handleDropdownScroll,
    isAModifyDialog = false,
    currentPayPeriodIndex,
    payPeriodsOptions,
    payPeriodOptionsScroll,
    onModifyFilterSave = () => {}
  } = props;
  const {
    roles,
    crewMembers,
    userDetails,
    userJobsSearchResults,
    crewSearchResults,
    personnel,
    personnelSearchResults,
    timeEntryReportFilter,
    payPeriods
  } = useSelector((state) => state);

  const [searchString, setSearchString] = useState({});

  const roleFilter = useInput(timeEntryReportFilter.roleFilter);
  const jobFilter = useInput(timeEntryReportFilter.jobFilter);
  const allCrewFilter = useInput(timeEntryReportFilter.allCrewFilter);
  const crewFilter = useInput(timeEntryReportFilter.crewFilter);
  const ouFilter = useInput(timeEntryReportFilter.ouFilterUnflattened);
  const hoursTypeFilter = useInput(timeEntryReportFilter.hoursTypeFilter);
  const crewOfFilter = useInput(timeEntryReportFilter.crewOfFilter);
  const roleLevel = userDetails && userDetails.role.roleLevel;
  const [showOnlyUntrustedHours, setShowOnlyUntrustedHours] = useState(
    timeEntryReportFilter.showOnlyUntrustedHours
  );

  const [periodValue, setPeriodValue] = useState(
    isAModifyDialog
      ? { ...timeEntryReportFilter.dateFilter }
      : {
          startDate: payPeriods[currentPayPeriodIndex].startDate,
          endDate: payPeriods[currentPayPeriodIndex].endDate,
          label: (payPeriodsOptions[currentPayPeriodIndex] || {}).label,
          isPayPeriod: true
        }
  );

  const [minMaxState, setMinMaxState] = useState({
    ...timeEntryReportFilter.minMaxHours,
    errorMessage: ""
  });

  // Resetting the minimum and maximum hours if custom periodValue is selected
  useEffect(() => {
    if (!periodValue.isPayPeriod) {
      setMinMaxState({
        minHours: "",
        maxHours: "",
        errorMessage: ""
      });
    }
  }, [periodValue.isPayPeriod]);

  const searchCrew = (query) =>
    query && dispatch(CrewActions.searchCrewWithParam(query));
  const clearCrewList = (type, clear) =>
    dispatch(CrewActions.clearCrewResults(type, clear));
  const searchPersonnel = (query) =>
    query && dispatch(PersonnelSearchActions.searchPersonnelWithParam(query));
  const clearPersonnelList = (type, clear) =>
    dispatch(PersonnelSearchActions.clearPersonnelResults(type, clear));
  const searchUserJobs = (query) =>
    query && dispatch(UserJobsSearchActions.searchUserJobsWithParam(query));
  const clearUserJobsList = (type, clear) =>
    dispatch(UserJobsSearchActions.clearUserJobsResults(type, clear));

  const roleOptions =
    roles &&
    roles
      .filter(
        (role) => role.roleName && role.roleLevel && role.roleLevel >= roleLevel
      )
      .map((r) => ({ name: r.roleName, id: r._id }));

  // Load options in filter dropdowns based on search and pagination conditions
  const dropdownOptions = (searchString, array, searchResults) => {
    let options = [];
    if (!searchString) options = array;
    else {
      if (searchResults.length) options = searchResults;
      else if (!searchResults.length && array.length) options = [];
    }
    return options;
  };
  const jobOptions = () =>
    dropdownOptions(
      (searchString || {})["userJobs"],
      userJobs,
      userJobsSearchResults
    ).map((option) => ({
      name: option.name,
      id: option.code,
      operationalUnits: option.operationalUnits
    }));

  const crewOptions = () =>
    dropdownOptions(
      (searchString || {})["crewMembers"],
      crewMembers,
      crewSearchResults
    ).map((option) => ({
      name: option.empName,
      id: option.empID,
      _id: option._id
    }));

  const crewOfOptions = () =>
    dropdownOptions(
      (searchString || {})["personnel"],
      personnel,
      personnelSearchResults
    ).map((option) => ({
      name: option.empName,
      id: option.empID,
      _id: option._id
    }));

  const crewMembersAssigned = (data) => {
    if (!data || !data.value || !data.value.length) return;

    const options = intersectionWith(
      personnel,
      data.value,
      (per, val) => per.empID === val.id
    ).map((emp) =>
      emp.personnel.map((personnel) => ({
        name: personnel.empName,
        id: personnel.empID,
        _id: personnel._id
      }))
    );

    const config = {
      label: `${t("payroll.employee")}`,
      type: "crewMembersAssigned",
      searchType: "ui", // To Decide if search needs to be done from frontend or with API
      data: uniqBy(flatten(options), "id") || []
    };

    return config;
  };

  const hoursTypeOptions = () =>
    ((organizationDetails || {}).hoursType || []).map((type, index) => ({
      name: type,
      id: index
    }));

  const ouFilterOptions = (data) => {
    if (!data || !data.value || !data.value.length || data.value.length > 1)
      return;
    const operationalUnits = data.value[0].operationalUnits.map((unit) => ({
      label: unit.name,
      type: `operationalUnit_${unit.name}`,
      searchType: "ui", // To Decide if search needs to be done from frontend or with API
      data: flatten(unit.items).map((m) => ({ name: m.name, id: m.code }))
    }));
    return operationalUnits || [];
  };

  const dropdownConfigRoles = {
    label: `${t("payroll.roles")}`,
    type: "roles", // Label of reducer
    searchType: "ui", // To Decide if search needs to be done from frontend or with API
    data: roleOptions
  };

  const dropdownConfigJob = {
    label: `${t("payroll.job_label")}`,
    type: "userJobs", // Label of reducer
    searchType: "api", // To Decide if search needs to be done from frontend or with API
    data: jobOptions()
  };

  const dropdownConfigCrew = {
    label: `${t("payroll.employee")}`,
    type: "crewMembers", // Label of reducer
    searchType: "api", // To Decide if search needs to be done from frontend or with API
    data: crewOptions()
  };

  const dropdownConfigCrewOf = {
    label: `${t("payroll.crew_Of")}`,
    type: "personnel", // Label of reducer
    searchType: "api", // To Decide if search needs to be done from frontend or with API
    data: crewOfOptions()
  };

  const dropdownConfigHoursType = {
    label: `${t("payroll.hours_Type")}`,
    type: "hourTypes",
    searchType: "ui", // To Decide if search needs to be done from frontend or with API
    data: hoursTypeOptions()
  };
  const settingGlobalFilterState = () => {
    dispatch(
      TimeEntryReportFilterActions.overrideFilter({
        roleFilter: [...roleFilter.value],
        jobFilter: [...jobFilter.value],
        crewOfFilter: [...crewOfFilter.value],
        crewFilter: [...crewFilter.value],
        allCrewFilter: [...allCrewFilter.value],
        hoursTypeFilter: [...hoursTypeFilter.value],
        ouFilter: [...flatMap(ouFilter.value)],
        ouFilterUnflattened: JSON.parse(JSON.stringify(ouFilter.value)),
        showOnlyUntrustedHours,
        dateFilter: { ...periodValue },
        minMaxHours: {
          minHours: minMaxState.minHours,
          maxHours: minMaxState.maxHours
        }
      })
    );
  };
  const applyFiltersAndRouteToPreview = () => {
    /* Making exportReportClicked.current to true before routing
      so that TimeEntryReportFilterActions.resetFilter() is not 
      dispatched on TimeEntryReportsComponent unmount */
    exportReportClickedRef.current = true;
    settingGlobalFilterState();
    history.push(`${match.url}/ExportTimeReports`);
    close();
  };

  const saveFilterAndPreview = () => {
    onModifyFilterSave();
    settingGlobalFilterState();
    close();
  };

  const handleDropdownSearch = (type, query) => {
    if (!query) setSearchString({});
    else setSearchString((s) => ({ ...s, [type]: query }));

    switch (type) {
      case "crewMembers":
        if (query && query.length) searchCrew(query);
        else clearCrewList("crewSearchResults", true);
        break;
      case "personnel":
        if (query && query.length) searchPersonnel(query);
        else clearPersonnelList("personnelSearchResults", true);
        break;
      case "userJobs":
        if (query && query.length) searchUserJobs(query);
        else clearUserJobsList("userJobsSearchResults", true);
        break;
      default:
        break;
    }
  };

  const handleDropdownClose = (type, closed) => {
    if (!closed) return;
    setSearchString((s) => ({ ...s, [type]: "" }));

    switch (type) {
      case "crewMembers":
        clearCrewList("crewSearchResults", true);
        break;
      case "personnel":
        clearPersonnelList("personnelSearchResults", true);
        break;
      case "userJobs":
        clearUserJobsList("userJobsSearchResults", true);
        break;
      default:
        break;
    }
  };

  const roleFilterDropdown = () => (
    <MultiSelectComponent
      key={dropdownConfigRoles.type}
      dropdownConfig={dropdownConfigRoles}
      {...roleFilter}
      optionLabel={(option) => option.name}
      handleScroll={handleDropdownScroll}
      handleSearch={handleDropdownSearch}
      handleClose={handleDropdownClose}
      noLabelDivider
    />
  );

  const crewOfFilterDropdown = () => (
    <MultiSelectComponent
      key={dropdownConfigCrewOf.type}
      dropdownConfig={dropdownConfigCrewOf}
      {...crewOfFilter}
      optionLabel={(option) => `${option.id} - ${option.name}`}
      handleScroll={handleDropdownScroll}
      handleSearch={handleDropdownSearch}
      handleClose={handleDropdownClose}
      noLabelDivider
    />
  );

  const resetCrewFilterOnChange = () => {
    if (!crewOfFilter.value.length) {
      crewFilter.onChange([]);
    } else {
      const crewOf = crewMembersAssigned(crewOfFilter);
      const options = intersectionWith(
        crewOf.data,
        crewFilter.value,
        (cOf, cr) => cOf.id === cr.id
      );
      crewFilter.onChange(options);
    }
  };

  // Re-render the Employee filter on every CrewOf change
  useEffect(resetCrewFilterOnChange, [crewOfFilter.value]);

  const crewFilterDropdown = () => {
    const config = crewMembersAssigned(crewOfFilter) || {};

    return (
      crewOfFilter && (
        <MultiSelectComponent
          key={config.type}
          dropdownConfig={config}
          {...crewFilter}
          optionLabel={(option) => `${option.id} - ${option.name}`}
          handleScroll={handleDropdownScroll}
          handleSearch={handleDropdownSearch}
          handleClose={handleDropdownClose}
          noLabelDivider
        />
      )
    );
  };

  const allCrewFilterDropdown = () => (
    <MultiSelectComponent
      key={dropdownConfigCrew.type}
      dropdownConfig={dropdownConfigCrew}
      {...allCrewFilter}
      optionLabel={(option) => `${option.id} - ${option.name}`}
      handleScroll={handleDropdownScroll}
      handleSearch={handleDropdownSearch}
      handleClose={handleDropdownClose}
      noLabelDivider
    />
  );

  const jobFilterDropdown = () => (
    <MultiSelectComponent
      key={dropdownConfigJob.type}
      dropdownConfig={dropdownConfigJob}
      {...jobFilter}
      optionLabel={(option) => `${option.id} - ${option.name}`}
      handleScroll={handleDropdownScroll}
      handleSearch={handleDropdownSearch}
      handleClose={handleDropdownClose}
      noLabelDivider
    />
  );

  const resetOperationalUnitsOnJobChange = () => {
    if (jobFilter && jobFilter.value) {
      if (jobFilter.value.length === 1) {
        userJobs.map((j) => jobFilter.value[0].code !== j.code);
      } else if (jobFilter.value.length > 1 || jobFilter.value.length === 0) {
        ouFilter.onChange([]);
      }
    }
  };

  // Load the Operations Units if only one job is selected in userJobFilter
  useEffect(resetOperationalUnitsOnJobChange, [jobFilter.value.length]);

  const ouFilterDropdown = () => {
    const ouOptions = ouFilterOptions(jobFilter);
    return (
      jobFilter &&
      !!(ouOptions || []).length &&
      ouOptions.map((ou) => (
        <>
          <Grid
            item
            xs={3}
            className={`${classes.gridLabel} ${classes.ouLablesText}`}
          >
            {`${t("payroll.select")} ${t(`payroll.${ou.label}`)}`}
          </Grid>
          <Grid item xs={9}>
            <MultiSelectComponent
              key={ou.label}
              dropdownConfig={ou}
              onChange={(val) => {
                ouFilter.onChange({ ...ouFilter.value, [ou.label]: val });
              }}
              value={ouFilter.value[ou.label] || []}
              optionLabel={(option) => `${option.id} - ${option.name}`}
              handleScroll={handleDropdownScroll}
              handleSearch={handleDropdownSearch}
              handleClose={handleDropdownClose}
              noLabelDivider
            />
          </Grid>
        </>
      ))
    );
  };

  const hoursTypeFilterDropdown = () => (
    <MultiSelectComponent
      key={dropdownConfigHoursType.type}
      dropdownConfig={dropdownConfigHoursType}
      {...hoursTypeFilter}
      optionLabel={(option) => option.name}
      handleScroll={handleDropdownScroll}
      handleSearch={handleDropdownSearch}
      handleClose={handleDropdownClose}
      noLabelDivider
    />
  );

  return (
    <FilterDialogComponent
      open={open}
      close={close}
      title={
        isAModifyDialog
          ? t("payroll.modify_filters")
          : t("payroll.export_reports")
      }
      dialogAttributes={{
        cancelAction: close,
        cancelText: t("commonActions.cancel"),
        previewAction: isAModifyDialog
          ? saveFilterAndPreview
          : applyFiltersAndRouteToPreview,
        previewText: t(
          `commonActions.${isAModifyDialog ? "save" : "preview_reports"}`
        ),
        isPreviewActionDisabled: minMaxState.errorMessage
      }}
    >
      <Grid container alignItems='center'>
        <Grid item xs={3} className={classes.gridLabel}>
          {`${t("payroll.select")} ${t("payroll.date_label")}`}
        </Grid>
        <Grid item xs={9}>
          <CustomPeriodSelector
            currentPayPeriodIndex={currentPayPeriodIndex}
            payPeriodsOptions={payPeriodsOptions}
            payPeriodOptionsScroll={payPeriodOptionsScroll}
            periodValue={periodValue}
            setPeriodValue={setPeriodValue}
          />
        </Grid>
        {periodValue.isPayPeriod && (
          <>
            <Grid item xs={3} className={classes.gridLabel}>
              {t("payroll.total_hours")}
            </Grid>

            <MinMaxHourFields
              label={""}
              minHours={minMaxState.minHours}
              setMinHours={(value) =>
                setMinMaxState((prev) => ({ ...prev, minHours: value }))
              }
              maxHours={minMaxState.maxHours}
              setMaxHours={(value) =>
                setMinMaxState((prev) => ({ ...prev, maxHours: value }))
              }
              error={minMaxState.errorMessage}
              setError={(value) =>
                setMinMaxState((prev) => ({
                  ...prev,
                  errorMessage: value
                }))
              }
            />
          </>
        )}

        <Grid item xs={3} className={classes.gridLabel}>
          {`${t("payroll.select")} ${t("payroll.job_label")}`}
        </Grid>
        <Grid item xs={9}>
          {jobFilterDropdown()}
        </Grid>
        {ouFilterDropdown()}

        <Grid item xs={3} className={classes.gridLabel}>
          {t("payroll.roles")}
        </Grid>
        <Grid item xs={9}>
          {roleFilterDropdown()}
        </Grid>

        <Grid item xs={3} className={classes.gridLabel}>
          {t("payroll.crew_Of")}
        </Grid>
        <Grid item xs={9}>
          {crewOfFilterDropdown()}
        </Grid>

        <Grid item xs={3} className={classes.gridLabel}>
          {t("payroll.employee")}
        </Grid>
        <Grid item xs={9}>
          {(() => {
            if (((crewOfFilter || {}).value || {}).length)
              return crewFilterDropdown();
            else return allCrewFilterDropdown();
          })()}
        </Grid>

        <Grid item xs={3} className={classes.gridLabel}>
          {t("payroll.pay_type")}
        </Grid>
        <Grid item xs={9}>
          {hoursTypeFilterDropdown()}
        </Grid>

        <div className={classes.untrustedBox}>
          {t("payroll.show_only_untrusted_hours")}
          <CustomSwitch
            checked={showOnlyUntrustedHours}
            onChange={(e) => setShowOnlyUntrustedHours(e.target.checked)}
          />
        </div>
      </Grid>
    </FilterDialogComponent>
  );
};

TimeEntryReportsFiltersDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  userJobs: PropTypes.arrayOf(
    PropTypes.shape({
      operationalUnits: PropTypes.arrayOf(PropTypes.shape()).isRequired,
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired
    })
  ).isRequired,
  organizationDetails: PropTypes.shape({
    timeEntriesRoleLevels: PropTypes.arrayOf(PropTypes.string).isRequired,
    OULevelNamesForTimeLogs: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    hoursType: PropTypes.arrayOf(PropTypes.string).isRequired,
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired
  }).isRequired,
  exportReportClickedRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.bool })
  ]),
  handleDropdownScroll: PropTypes.func.isRequired,
  isAModifyDialog: PropTypes.bool,
  currentPayPeriodIndex: PropTypes.number,
  payPeriodsOptions: PropTypes.arrayOf(PropTypes.shape()),
  payPeriodOptionsScroll: PropTypes.func,
  onModifyFilterSave: PropTypes.func
};

export default TimeEntryReportsFiltersDialog;
