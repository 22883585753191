export default function CrewSearchReducer(state = [], action) {
  switch (action.type) {
    case "ALL_CREW": {
      return action.offset
        ? [...state, ...action.crewMembers]
        : action.crewMembers;
    }
    default:
      return state;
  }
}
