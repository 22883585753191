import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { compose } from "redux";

import {
  Backdrop,
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup
} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";

import version from "../../package.json";
import SnackbarComponent from "../components/UI/SnackbarComponent";
import { customFailedMessage } from "../redux/actions/ApiStatusActions";
import * as AuthActions from "../redux/actions/AuthActions";
import * as CompaniesActions from "../redux/actions/CompaniesActions";
import permissionsList from "../utillities/Permissions";
import { regReplaceURI } from "../utillities/regexConstants";

import AssignCompanyModalComponent from "./AssignCompanyModalComponent";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  backdrop: {
    zIndex: 10000
  }
}));

const SignInComponent = (props) => {
  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng.target.value);
  };
  const { history } = props;
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const classes = useStyles();
  const [show, setShow] = useState(false);

  const handlePermissionsShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const {
    isAuth,
    apiStatus,
    userDetails,
    toSwitch,
    companies,
    selectedCompany
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  const login = (user, pass) => dispatch(AuthActions.login(user, pass));

  function handleOnLoginRouting() {
    if (isAuth) {
      const { isSuperAdmin } = userDetails;
      const hasAuthorizerPermission =
        userDetails &&
        userDetails.role &&
        userDetails.role.permissions.includes(permissionsList.AUTHORIZER.key);
      const hasDesktopPermission =
        userDetails &&
        userDetails.role &&
        userDetails.role.permissions.includes(permissionsList.TS_DESKTOP.key);
      if (companies.length) {
        if (
          (companies.length > 1 && !selectedCompany.code) ||
          (selectedCompany.code !== userDetails.companyCode &&
            selectedCompany.code &&
            !isSuperAdmin)
        ) {
          handlePermissionsShow();
        } else if (companies.length === 1 && !selectedCompany.code) {
          dispatch(CompaniesActions.selectedCompany(companies[0]));
        } else if (userDetails || isSuperAdmin) {
          // is superAdmin or has autherizer permission
          if (
            (isSuperAdmin && toSwitch) ||
            (!toSwitch && hasAuthorizerPermission && !isSuperAdmin)
          ) {
            history.push("/admin/jobs");
          } else if (hasDesktopPermission || (isSuperAdmin && !toSwitch)) {
            history.push(
              `/${userDetails.role.roleName.replace(regReplaceURI, "_")}`
            );
          } else if (
            !hasDesktopPermission ||
            (!isSuperAdmin && !toSwitch && !hasAuthorizerPermission)
          ) {
            handlePermissionsShow();
            if (companies.length === 1) dispatch(AuthActions.logout());
            dispatch(customFailedMessage(t("signIn.no_permission")));
            dispatch(CompaniesActions.resetSelectedCompany());
          }
        }
      }
    }
  }

  useEffect(handleOnLoginRouting, [
    isAuth,
    companies,
    userDetails,
    selectedCompany
  ]);

  useEffect(() => {
    if ((apiStatus && apiStatus.status && apiStatus.status.error) || isAuth) {
      setUsername("");
      setPassword("");
    }
  }, [apiStatus, isAuth]);

  function handleChange(e, type) {
    if (type === "username") {
      setUsername(e.target.value);
    } else {
      setPassword(e.target.value);
    }
  }

  const formSubmit = (e) => {
    e.preventDefault();
    login(username, password);
  };

  return (
    <Container component='main' maxWidth='xs'>
      {apiStatus &&
        apiStatus.reducer &&
        (apiStatus.reducer.includes("Login") ||
          apiStatus.reducer.includes("Companies") ||
          apiStatus.reducer.includes("UserDetails")) && (
          <Backdrop open className={classes.backdrop}>
            <CircularProgress />
          </Backdrop>
        )}
      <CssBaseline />
      {!isAuth && (
        <>
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component='h1' variant='h5'>
              {/* using react-i18next */}
              {t("signIn.signIn_label")}
            </Typography>
            {`${t("signIn.version")} ${version.version}`}
            <form className={classes.form} onSubmit={formSubmit}>
              <TextField
                variant='outlined'
                margin='normal'
                required
                fullWidth
                label={t("signIn.username_label")}
                autoComplete='Username'
                autoFocus
                value={username}
                onChange={(e) => handleChange(e, "username")}
              />
              <TextField
                variant='outlined'
                margin='normal'
                required
                fullWidth
                name='password'
                label={t("signIn.password_label")}
                type='password'
                id='password'
                autoComplete='current-password'
                value={password}
                onChange={(e) => handleChange(e, "password")}
              />
              <Button
                type='submit'
                fullWidth
                variant='contained'
                color='primary'
                className={classes.submit}
              >
                {t("signIn.signIn_label")}
              </Button>
            </form>
          </div>
          <RadioGroup
            aria-label='position'
            name='position'
            onChange={changeLanguage}
            defaultValue='en'
            value={i18n.language}
            row
          >
            <FormControlLabel
              value='en'
              control={<Radio color='primary' />}
              label={t("header.english")}
              labelPlacement='start'
            />

            <FormControlLabel
              value='es'
              control={<Radio color='primary' />}
              label={t("header.spanish")}
              labelPlacement='start'
            />
          </RadioGroup>
        </>
      )}
      {apiStatus.status && apiStatus.status.error && (
        <>
          <SnackbarComponent />
        </>
      )}
      <AssignCompanyModalComponent show={show} handleClose={handleClose} />
    </Container>
  );
};

SignInComponent.propTypes = {
  history: PropTypes.shape().isRequired
};

export default compose(withRouter)(SignInComponent);
