import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import { CircularProgress, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";

import * as PersonnelActions from "../../../redux/actions/PersonnelActions";

const useStyles = makeStyles(() => ({
  dialog: {
    maxHeight: "80%"
  },
  gridCaption: {
    height: "50%",
    display: "flex"
  },
  gridPadding: {
    padding: "8px",
    justifyContent: "space-evenly"
  },
  deleteButton: {
    color: "#c4302f",
    align: "center"
  }
}));

const ViewCrewMembers = (props) => {
  const {
    viewCrewShow,
    handleViewCrewClose,
    selectedEmployee,
    roleName,
    role
  } = props;
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { personnel, apiStatus } = useSelector((state) => state);
  const loadPersonnel = useRef();
  useEffect(() => {
    loadPersonnel.current = dispatch(
      PersonnelActions.loadPersonnel(selectedEmployee[0]._id)
    );
  }, [dispatch, loadPersonnel, selectedEmployee]);

  const [unassignPersonnel, setUnassignPersonnel] = useState([]);
  const classes = useStyles();

  const updatePersonnel = (Employee, unassigned, roleId) =>
    dispatch(PersonnelActions.updatePersonnel(Employee, unassigned, roleId));

  function handleOnUnassign(id) {
    const idx = unassignPersonnel.map((per) => per === id).indexOf(true);
    const copyPer = [...unassignPersonnel];
    if (idx === -1) {
      copyPer.push(id);
      setUnassignPersonnel(copyPer);
    } else {
      copyPer.splice(idx, 1);
      setUnassignPersonnel(copyPer);
    }
  }

  function handleOnSaveUnassign() {
    updatePersonnel(selectedEmployee[0]._id, unassignPersonnel, role._id);
    setUnassignPersonnel([]);
  }

  return (
    <Dialog
      open={viewCrewShow}
      onClose={handleViewCrewClose}
      maxWidth='xs'
      variant='outlined'
      color='primary'
      className={classes.dialog}
      fullWidth
    >
      <DialogTitle>
        <Grid container>
          <Grid item>
            {selectedEmployee[0].empName}
            <Grid item>
              <Typography variant='caption' className={classes.gridCaption}>
                {`${roleName}`}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </DialogTitle>

      <DialogContent dividers>
        {apiStatus &&
          apiStatus.isLoading &&
          apiStatus.reducer.includes("loadPersonnel") && (
            <div style={{ textAlign: "center" }}>
              <CircularProgress className={classes.progressBar} />
            </div>
          )}

        {!apiStatus.isLoading &&
          (personnel.length === 0 ? (
            <small className='text-muted'>
              {t("equipmentManager.no_emp_assigned")}
            </small>
          ) : (
            personnel.map((emp) => (
              <div key={emp._id}>
                <Grid container>
                  <Grid container className={classes.gridPadding}>
                    <Grid item xs={6}>
                      <Grid item>{emp.empName}</Grid>
                      <Grid item>
                        <Typography
                          variant='caption'
                          className={classes.gridCaption}
                        >
                          {emp.roleName}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={2}>
                      {" "}
                      {unassignPersonnel.includes(emp._id) ? (
                        <IconButton
                          aria-label='delete'
                          className={classes.deleteButton}
                          onClick={() => {
                            handleOnUnassign(emp._id);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      ) : (
                        <IconButton
                          aria-label='delete'
                          onClick={() => {
                            handleOnUnassign(emp._id);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            ))
          ))}
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          color='primary'
          onClick={handleViewCrewClose}
        >
          {t("commonActions.close")}
        </Button>
        <Button
          variant='contained'
          color='primary'
          onClick={() => {
            handleOnSaveUnassign();
          }}
          disabled={unassignPersonnel.length === 0}
        >
          {t("commonActions.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ViewCrewMembers.propTypes = {
  viewCrewShow: PropTypes.bool.isRequired,
  handleViewCrewClose: PropTypes.func.isRequired,
  selectedEmployee: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      empName: PropTypes.string.isRequired
    })
  ).isRequired,
  roleName: PropTypes.string.isRequired,
  role: PropTypes.shape({
    _id: PropTypes.string
  }).isRequired
};

export default ViewCrewMembers;
