import lodash from "lodash";

import { GET_PAYPERIODS_SUCCESS } from "../actions/actionTypes";

export default function PayPeriodsReducer(state = [], action) {
  switch (action.type) {
    case GET_PAYPERIODS_SUCCESS:
      return action.offset
        ? lodash
            .uniqBy([...state, ...action.payPeriods], "code")
            .sort((a, b) => new Date(b.endDate) - new Date(a.endDate))
        : lodash
            .uniqBy([...action.payPeriods], "code")
            .sort((a, b) => new Date(b.endDate) - new Date(a.endDate));
    default:
      return state;
  }
}
