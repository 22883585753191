import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import Checkbox from "@material-ui/core/Checkbox";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";

const TableHeaderWrapper = (props) => {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells
  } = props;
  const { t } = useTranslation();
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding='checkbox'>
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected >= rowCount && rowCount !== 0 && rowCount}
            onChange={onSelectAllClick}
          />
        </TableCell>
        {headCells &&
          headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align='left'
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {headCell.isSortable ? (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={order}
                  onClick={createSortHandler(headCell.id)}
                >
                  {t(headCell.label)}
                  {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </span>
                  ) : null}
                </TableSortLabel>
              ) : (
                t(headCell.label)
              )}
            </TableCell>
          ))}
      </TableRow>
      {rowCount === 0 && (
        <TableRow>
          <TableCell
            colSpan={headCells.length + 1}
            style={{ textAlign: "center", border: "none" }}
          >
            {" "}
            {t("commonActions.no_records")}
          </TableCell>
        </TableRow>
      )}
    </TableHead>
  );
};

TableHeaderWrapper.propTypes = {
  classes: PropTypes.shape({ visuallyHidden: PropTypes.string }).isRequired,
  numSelected: PropTypes.number,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  headCells: PropTypes.arrayOf(PropTypes.shape()).isRequired
};

TableHeaderWrapper.defaultProps = {
  numSelected: 0
};

export default TableHeaderWrapper;
