import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { cloneDeep, debounce } from "lodash";
import PropTypes from "prop-types";

import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    right: 0,
    marginTop: "10px"
  },
  list: {
    width: "100%",
    maxWidth: 360,
    boxShadow: "0 0 4px 0 rgba(0, 0, 0, 0.5)",
    backgroundColor: theme.palette.background.paper,
    padding: "8px 0"
  },
  listItem: {
    "&:selected": {
      backgroundColor: "#ffffff"
    },
    "&:hover": {
      backgroundColor: "#e5e5e5"
    }
  }
}));

const SearchAutocompleteComponent = ({
  handleSearch,
  searchResults,
  setOptionLabel,
  showOptions,
  handleListItem,
  clear,
  disableClearable,
  stopLoader,
  freeSolo,
  key
}) => {
  const classes = useStyles();
  const { apiStatus } = useSelector((state) => state);
  const [userInput, setUserInput] = useState(null);
  const [selectedValue, setSelectedValue] = useState(null);
  const [options, setOptions] = useState(searchResults);
  const { t } = useTranslation();

  const loading =
    (apiStatus || {}).isLoading &&
    apiStatus.reducer.find((reducerText) => reducerText.includes("Search"));

  const getSearchResults = () => {
    if (!userInput && selectedValue) {
      setOptions([]);
    } else if (userInput) {
      handleSearch(userInput);
    }
    if (!userInput && !selectedValue) {
      if (userInput === "") handleSearch(userInput);
      clear();
      setOptions([]);
    }
  };

  const setSearch = (searchValue) => {
    setUserInput(searchValue);
  };

  const searchHandler = useCallback(debounce(setSearch, 800), []);

  const getOptions = () => {
    const optionsArray = cloneDeep(searchResults) || [];
    setOptions(optionsArray);
  };

  useEffect(getSearchResults, [userInput, selectedValue]);
  useEffect(getOptions, [JSON.stringify(searchResults)]);

  return (
    <div className={classes.root}>
      <Autocomplete
        id='search-autocomplete'
        key={key}
        style={{ width: 200 }}
        loading={loading}
        loadingText={t("commonActions.loading")}
        noOptionsText={t("authorizerContainer.no_results_found")}
        getOptionLabel={(option) => setOptionLabel(option)}
        options={showOptions ? options : []}
        filterOptions={(x) => x}
        debug
        autoComplete
        autoHighlight
        disableOpenOnFocus
        freeSolo={freeSolo}
        disableClearable={disableClearable}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={t("commonActions.search_here")}
            fullWidth
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position='start'>
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment:
                loading && !stopLoader ? (
                  <CircularProgress color='secondary' size={30} />
                ) : (
                  false
                )
            }}
          />
        )}
        renderOption={(option) => (
          <Grid container alignItems='center'>
            <Grid item onClick={() => setSelectedValue(option)}>
              {setOptionLabel(option)}
            </Grid>
          </Grid>
        )}
        onChange={(event, value) => {
          event.persist();
          handleListItem(event, value);
          setUserInput(null);
        }}
        onInputChange={(event, value) => {
          event.persist();
          if (!value.includes(" - ")) searchHandler(value);
        }}
        onClose={(event) => {
          event.persist();
          clear();
          setOptions([]);
        }}
      />
    </div>
  );
};

SearchAutocompleteComponent.propTypes = {
  searchResults: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  showOptions: PropTypes.bool.isRequired,
  handleSearch: PropTypes.func.isRequired,
  setOptionLabel: PropTypes.func.isRequired,
  handleListItem: PropTypes.func,
  clear: PropTypes.func.isRequired,
  stopLoader: PropTypes.bool,
  disableClearable: PropTypes.bool.isRequired,
  freeSolo: PropTypes.bool.isRequired,
  key: PropTypes.string
};

SearchAutocompleteComponent.defaultProps = {
  handleListItem: {},
  stopLoader: false,
  key: ""
};

export default SearchAutocompleteComponent;
