import lodash from "lodash";

import {
  CLEAR_JOBS_SEARCH_RESULTS,
  SEARCH_JOBS,
  UNASSIGN_JOB_SUCCESS
} from "../actions/actionTypes";

export default function JobSearchReducer(state = [], action) {
  switch (action.type) {
    case SEARCH_JOBS: {
      return action.offset
        ? [...state, ...action.jobsSearchResults]
        : action.jobsSearchResults;
    }
    case UNASSIGN_JOB_SUCCESS: {
      const unassignedJobIndex = state.findIndex(
        (job) => job.code === action.jobCode[0]
      );
      const copyOfJobs = lodash.cloneDeep(state);
      if (unassignedJobIndex >= 0) {
        copyOfJobs[unassignedJobIndex].isAssigned = false;
        copyOfJobs[unassignedJobIndex].operationalUnits.forEach((ou, idx) => {
          ou.items.forEach((item, i) => {
            copyOfJobs[unassignedJobIndex].operationalUnits[idx].items[
              i
            ].selected = false;
          });
        });
      }
      return copyOfJobs;
    }
    case CLEAR_JOBS_SEARCH_RESULTS: {
      // Clear earch results from state so that upon searching the next time, previous results won't show up
      return [];
    }
    default:
      return state;
  }
}
