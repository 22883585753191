import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import Timeline from "@material-ui/lab/Timeline";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";

import CustomTooltip from "../UI/CustomTooltip";

const useStyles = makeStyles((theme) => ({
  timelineRoot: {
    padding: 0,
    "& .MuiTimelineItem-missingOppositeContent:before": {
      flex: 0,
      padding: 0
    }
  },
  timelineDotSuccess: {
    padding: 0,
    backgroundColor: theme.palette.success.main
  },
  timelineDotWarning: {
    padding: 0,
    backgroundColor: theme.palette.warning.main
  },
  success: {
    color: theme.palette.success.main
  },
  warning: {
    color: theme.palette.warning.main
  },
  customWidth: {
    maxWidth: 260,
    maxHeight: 260,
    overflow: "auto"
  },
  title: {
    fontWeight: "600",
    marginBottom: "5px"
  },
  name: {
    fontWeight: "500",
    fontSize: "14px"
  }
}));

// Utility function
const combineApprovals = (approvedBy, pendingApprovals) => {
  // Create an object to store the combined approvals by role level
  const combinedApprovals = {};

  // Add the approvedBy employees to the combinedApprovals object
  approvedBy.forEach((approval) => {
    const { empName, role } = approval;
    const { roleLevel } = role;

    if (!combinedApprovals[roleLevel]) {
      combinedApprovals[roleLevel] = {
        _id: role._id,
        roleName: role.roleName,
        roleLevel,
        isApprovalPending: false,
        employees: []
      };
    }

    combinedApprovals[roleLevel].employees.push(empName);
  });

  // Add the pendingApprovals to the combinedApprovals object
  pendingApprovals.forEach((approval) => {
    const { _id, roleName, roleLevel } = approval;

    combinedApprovals[roleLevel] = {
      _id,
      roleName,
      roleLevel,
      isApprovalPending: true,
      employees: []
    };
  });

  // Sort the combinedApprovals object by role level and convert to an array
  const sortedApprovals = Object.values(combinedApprovals).sort((a, b) => {
    return a.roleLevel - b.roleLevel;
  });

  return sortedApprovals;
};

const ApprovalsTimelineContent = ({ data }) => {
  const classes = useStyles();
  const combinedApprovals = combineApprovals(
    data.approvedBy,
    data.pendingApprovals
  );
  const { t } = useTranslation();

  return (
    <>
      <Typography className={classes.title} variant='subtitle1'>
        {t("payroll.approvals")}
      </Typography>
      <Timeline className={classes.timelineRoot}>
        {combinedApprovals.map((value, index) => (
          <TimelineItem key={value._id}>
            <TimelineSeparator>
              <TimelineDot
                className={
                  value.isApprovalPending
                    ? classes.timelineDotWarning
                    : classes.timelineDotSuccess
                }
              >
                {value.isApprovalPending ? <ErrorIcon /> : <CheckCircleIcon />}
              </TimelineDot>
              {combinedApprovals.length !== index + 1 && <TimelineConnector />}
            </TimelineSeparator>
            <TimelineContent>
              <Typography variant='subtitle1'>{value.roleName}</Typography>
              <Typography className={classes.name}>
                {value.isApprovalPending
                  ? t("payroll.pending")
                  : `${t("payroll.approved_by")} - ${value.employees.join(
                      ", "
                    )}`}
              </Typography>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </>
  );
};
ApprovalsTimelineContent.propTypes = {
  data: PropTypes.shape({
    approvedBy: PropTypes.arrayOf(PropTypes.shape({})),
    pendingApprovals: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired
};

const ApprovalsTimelineToolTip = ({ isApprovedByAllRoles = false, data }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setOpen((open) => !open);
  };
  return (
    <CustomTooltip
      content={<ApprovalsTimelineContent data={data} />}
      contentContainerClass={classes.customWidth}
      open={open}
      placement='bottom'
      onClose={() => setOpen(false)}
    >
      <IconButton onClick={handleClick}>
        {isApprovedByAllRoles ? (
          <CheckCircleIcon className={classes.success} />
        ) : (
          <ErrorIcon className={classes.warning} />
        )}
      </IconButton>
    </CustomTooltip>
  );
};
ApprovalsTimelineToolTip.propTypes = {
  isApprovedByAllRoles: PropTypes.bool,
  data: PropTypes.shape({}).isRequired
};

export default ApprovalsTimelineToolTip;
