import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

import * as PermissionsActions from "../../../redux/actions/PermissionsActions";
import * as RolesActions from "../../../redux/actions/RolesActions";

import ChangePermissions from "./ChangePermissions";

const useStyles = makeStyles(() => ({
  rowGrid: {
    margin: "4px",
    justifyContent: "center",
    alignItems: "center",
    display: "flex"
  },
  gridContainer: {
    "&:last-child": {
      "& span": {
        backgroundColor: "transparent"
      }
    }
  },
  rowGridLine: {
    margin: "4px",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    "& span": {
      position: "relative",
      width: "3px",
      backgroundColor: "#212834",
      height: "65%",
      marginTop: "52px"
    }
  },
  avatar: {
    position: "absolute",
    color: "#fff",
    backgroundColor: "#212834"
  },
  inputField: {
    width: "700px"
  },
  setDialogSize: {
    minWidth: "900px",
    position: "relative"
  }
}));

const ManageRolesComponent = (props) => {
  const { modalShow, onHide } = props;
  const { t } = useTranslation();

  const { permissions, roles } = useSelector((state) => state);
  const [show, setShow] = useState(false);
  const [permissionRole, setPermissionRole] = useState({});
  const [copyRoles, setCopyRoles] = useState(roles);

  const dispatch = useDispatch();
  const allPermissions = useRef();
  const classes = useStyles();

  useEffect(() => {
    allPermissions.current = dispatch(PermissionsActions.loadPermissions());
  }, [dispatch, allPermissions]);

  const updateHierarchy = (selectedRoles) =>
    dispatch(RolesActions.updateHierarchy(selectedRoles));

  const handlePermissionsClose = () => setShow(false);
  const handlePermissionsShow = () => setShow(true);

  function handleEditRole(e) {
    e.preventDefault();
    const newRoles = [...copyRoles];
    newRoles[e.target.name] = {
      ...newRoles[e.target.name],
      roleName: e.target.value
    };
    setCopyRoles(newRoles);
  }

  function handlePermissionsChange(perRole, permissionCheck) {
    const idx = copyRoles.map((role) => role._id === perRole._id).indexOf(true);
    const cr = copyRoles.map((r) => r);
    cr[idx] = { ...cr[idx], permissions: permissionCheck };
    setCopyRoles(cr);
  }

  function handleSaveChanges(e) {
    e.preventDefault();
    onHide();
    updateHierarchy(copyRoles);
  }

  return (
    <Dialog
      open={modalShow}
      onClose={onHide}
      maxWidth='md'
      className={classes.dialog}
      fullWidth
      variant='outlined'
      color='primary'
    >
      <DialogTitle>{t("permissions.manage_role_title")}</DialogTitle>
      <DialogContent dividers>
        <Grid container className={classes.setDialogSize}>
          {copyRoles.map((role, index) => (
            <Grid
              item
              container
              className={classes.gridContainer}
              key={role._id}
            >
              <Grid
                item
                container
                key={role.roleLevel}
                style={{ justifyContent: "space-evenly" }}
              >
                <Grid item xs={1} className={classes.rowGridLine}>
                  <Avatar className={classes.avatar}>{role.roleLevel}</Avatar>
                  <span className={classes.span} />
                </Grid>

                <Grid item xs={7} className={classes.rowGrid}>
                  <TextField
                    placeholder={`${t("authorizerContainer.enter_role")}`}
                    defaultValue={role.roleName}
                    name={`${index}`}
                    fullWidth
                    className={classes.inputField}
                    onFocus={(e) => {
                      handleEditRole(e);
                    }}
                    onChange={(e) => {
                      setPermissionRole(role);
                      handleEditRole(e);
                    }}
                    variant='outlined'
                  />
                </Grid>
                <Grid item xs={3} className={classes.rowGrid}>
                  {role.roleName !== "" ? (
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={() => {
                        setPermissionRole(role);
                        handlePermissionsShow();
                      }}
                    >
                      {t("permissions.change_permission_btn")}
                    </Button>
                  ) : (
                    <Button
                      variant='contained'
                      color='primary'
                      disabled
                      onClick={() => {
                        setPermissionRole(role);
                        handlePermissionsShow();
                      }}
                      style={{ margin: 8 }}
                    >
                      {t("permissions.change_permission_btn")}
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          ))}
          {show && (
            <ChangePermissions
              show={show}
              handlePermissionsClose={handlePermissionsClose}
              permissionRole={permissionRole}
              permissions={permissions}
              handlePermissionsChange={handlePermissionsChange}
            />
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' color='primary' onClick={onHide}>
          {t("commonActions.cancel")}
        </Button>
        <Button
          variant='contained'
          color='primary'
          onClick={(e) => handleSaveChanges(e)}
        >
          {t("commonActions.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ManageRolesComponent.propTypes = {
  onHide: PropTypes.func.isRequired,
  modalShow: PropTypes.bool.isRequired
};

export default ManageRolesComponent;
