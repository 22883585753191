import axios from "axios";

import BASE_PATH from "./config";

const instance = axios.create({
  baseURL: BASE_PATH,
  headers: { "Content-Type": "application/json" }
});

export default instance;
