import { GET_SYNC_STATUS } from "../actions/actionTypes";

export default function SyncStatusReducer(state = {}, action) {
  switch (action.type) {
    case GET_SYNC_STATUS:
      return action.syncStatus;
    default:
      return state;
  }
}
