import { lighten, makeStyles } from "@material-ui/core/styles";

export const toolbarStyles = makeStyles((theme) => ({
  root: {
    marginTop: "-24px"
  },
  highlight:
    theme.palette.type === "dark"
      ? {
          color: theme.palette.primary.main,
          backgroundColor: lighten(theme.palette.primary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.primary.dark
        },
  previewtitle: {
    flex: "1 1 100%",
    marginTop: "10px"
  },
  title: {
    fontSize: "24px",
    flex: "1 1 100%"
  },
  fab: {
    marginRight: "16px",
    textTransform: "none"
  },
  payPeriodLockButton: {
    marginLeft: "16px"
  },
  buttons: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end"
  },
  filter: {
    backgroundColor: "white",
    marginRight: "16px"
  }
}));

export const tableStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    fontSize: "10px !important"
  },
  container: {
    marginTop: theme.spacing(2),
    padding: "20px",
    maxHeight: "100vh"
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    maxHeight: "90vh",
    overflowY: "hidden",
    padding: "20px"
  },
  tableWrapper: {
    overflowX: "auto",
    maxHeight: "70vh",
    // eslint-disable-next-line no-useless-computed-key
    ["@media (min-height: 800px)"]: {
      maxHeight: "74vh"
    }
  },
  fixedHeader: {
    top: 0,
    right: 0,
    zIndex: 40,
    position: "sticky",
    height: "inherit",
    minWidth: "220px"
  },
  fixedColumn: {
    top: 0,
    right: 0,
    zIndex: 10,
    position: "sticky",
    backgroundColor: "#ffffff",
    height: "inherit"
  },
  sortHeader: {
    "&:focus": {
      color: "rgba(0, 0, 0, 0.87)"
    },
    "&:hover": {
      color: "rgba(0, 0, 0, 0.60)"
    }
  },
  sameShiftTextColor: {
    color: theme.palette.secondary.main
  },
  headerCell: {
    textTransform: "capitalize",
    border: "1px solid rgba(224, 224, 224, 1)",
    minWidth: "90px",
    paddingLeft: "8px",
    fontSize: "0.8125rem"
  },
  cell: {
    border: "1px solid rgba(224, 224, 224, 1)",
    borderBottom: "none",
    padding: "0 8px",
    width: "max-content",
    fontSize: "0.8125rem"
  },
  actionsCell: {
    display: "flex",
    border: "none",
    boxSizing: "border-box",
    alignItems: "center",
    justifyContent: "space-between",
    width: "inherit",
    padding: "0 4px",
    minWidth: "100%"
  },
  eqActionsCell: {
    justifyContent: "flex-end",
    width: "100%",
    margin: 0
  },
  totalHoursAllJobs: {
    minWidth: "110px"
  },
  loggedByCell: {
    minWidth: "170px"
  },
  invalidCell: {
    backgroundColor: "#f9868a !important"
  },
  unTrustedCell: {
    backgroundColor: "#ffdb87"
  },
  button: {
    width: "100%",
    minWidth: "max-content",
    borderRadius: "50px",
    textAlign: "center",
    maxHeight: "36px",
    "&:hover": {
      border: "none"
    }
  },
  fab: {
    margin: "0 10px",
    fontSize: "12px",
    minWidth: "max-content !important",
    right: "16px"
  },
  details: {
    alignItems: "center"
  },
  imageIcon: {
    height: "100%"
  },
  iconRoot: {
    textAlign: "center"
  },
  expand: {
    transform: "rotate(0deg)"
  },
  expandOpen: {
    transform: "rotate(180deg)"
  },
  paginationCell: {
    display: "flex",
    float: "right",
    right: 0,
    minHeight: "50px",
    zIndex: 10,
    position: "sticky",
    alignItems: "center"
  },
  paginationDropdown: { fontSize: "0.8125rem" },
  showMoreButton: { marginLeft: "24px", width: "max-content" },
  emptyCell: {
    textAlign: "center"
  },
  toolbar: theme.mixins.toolbar,
  drawer: {
    height: "100% !important",
    width: "400px"
  },
  highlight:
    theme.palette.type === "dark"
      ? {
          color: theme.palette.primary.main,
          backgroundColor: lighten(theme.palette.primary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.primary.dark
        }
}));
