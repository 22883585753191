import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  LinearProgress,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";

import { unassignEquipment } from "../../redux/actions/EquipmentsActions";

const useStyles = makeStyles(() => ({
  gridRow: {
    justifyContent: "space-between",
    alignItems: "center"
  },
  deleteButton: {
    color: "#c4302f",
    align: "center"
  }
}));

const UnassignEquipmentDialogComponent = (props) => {
  const { t } = useTranslation();

  const { unassignShow, handleUnassignClose, selectedEquipment } = props;
  const { updateEquipment, apiStatus } = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [unassign, setUnassign] = useState(selectedEquipment);

  useEffect(() => {
    if (
      Object.keys(updateEquipment).length &&
      unassign._id === updateEquipment._id
    )
      setUnassign(updateEquipment);
    else setUnassign(selectedEquipment);
  }, [selectedEquipment, updateEquipment, unassign]);

  const handleOnUnassign = (id) => {
    dispatch(unassignEquipment(id, selectedEquipment._id));
  };

  useEffect(() => {
    setUnassign(selectedEquipment);
  }, [selectedEquipment._id, selectedEquipment]);

  return (
    <Dialog
      open={unassignShow}
      onClose={handleUnassignClose}
      maxWidth='xs'
      variant='outlined'
      color='primary'
      fullWidth
    >
      {apiStatus &&
        apiStatus.isLoading &&
        apiStatus.reducer.includes("unassign") && (
          <LinearProgress className={classes.progressBar} />
        )}
      <DialogTitle>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>{unassign.name}</div>
          <div>
            <CloseIcon onClick={handleUnassignClose} />
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        <small className='text-muted'> {t("unAssignComp.assigned_to")}</small>
        {unassign &&
          unassign.assignedTo &&
          unassign.assignedTo.map((emp) => (
            <Grid container className={classes.gridRow} key={unassign._id}>
              <Grid item>
                <Typography variant='subtitle1'>{emp.empName}</Typography>
              </Grid>
              <Grid item style={{ marginBottom: "5px" }}>
                <Button
                  variant='outlined'
                  color='secondary'
                  onClick={() => handleOnUnassign(emp._id)}
                >
                  {t("equipmentManager.unassign_label")}
                </Button>
              </Grid>
            </Grid>
          ))}
      </DialogContent>
    </Dialog>
  );
};

UnassignEquipmentDialogComponent.propTypes = {
  unassignShow: PropTypes.bool.isRequired,
  handleUnassignClose: PropTypes.func.isRequired,
  selectedEquipment: PropTypes.shape().isRequired
};

export default UnassignEquipmentDialogComponent;
