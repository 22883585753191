import {
  APPROVE_BULK_TIMESHEET,
  APPROVE_CREW_TIMESHEET,
  LIST_APPROVED_TIMESHEET
} from "../actions/actionTypes";

export default function ApprovalTimesheetReducer(state = [], action) {
  switch (action.type) {
    case LIST_APPROVED_TIMESHEET:
      return action.listTimesheet;
    case APPROVE_CREW_TIMESHEET:
      return action.approvedTimesheet;
    case APPROVE_BULK_TIMESHEET:
      return action.approvedTimesheet;
    default:
      return state;
  }
}
