import React from "react";
import PropTypes from "prop-types";

import { MenuItem, Select } from "@material-ui/core";

const SimpleSelectorComponent = (props) => {
  const {
    currentValue,
    onChange,
    options,
    withDisabledOption = false,
    disabledOptionLabel
  } = props;
  return (
    <Select
      value={currentValue}
      onChange={onChange}
      fullWidth
      variant='outlined'
    >
      {withDisabledOption && (
        <MenuItem value='role' label='role' disabled>
          {disabledOptionLabel}
        </MenuItem>
      )}
      {options.map(
        (option) =>
          option.value !== "" && (
            <MenuItem key={option._id} value={option.value}>
              {option.name}
            </MenuItem>
          )
      )}
    </Select>
  );
};
SimpleSelectorComponent.propTypes = {
  currentValue: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  withDisabledOption: PropTypes.bool,
  disabledOptionLabel: PropTypes.string
};
export default SimpleSelectorComponent;
