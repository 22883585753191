import axios from "../../axios-jwt";

import { GET_SYNC_STATUS } from "./actionTypes";
import { apiFailed, apiStartLoader, apiStopLoader } from "./ApiStatusActions";

async function sync() {
  return axios.get("/viewpoint/sync");
}

async function syncStatusApi() {
  return axios.get("/viewpoint/syncstatus");
}

export function syncStatusSuccess(syncStatus) {
  return { type: GET_SYNC_STATUS, syncStatus };
}

export function getSyncStatus() {
  return (dispatch, getState) => {
    function stopSyncLoader() {
      if ((getState().apiStatus.reducer || "").includes("syncWithViewPoint")) {
        dispatch(apiStopLoader("syncWithViewPoint"));
      }
    }
    return syncStatusApi()
      .then((res) => {
        if (res.status === 200) {
          dispatch(syncStatusSuccess(res.data));
        }
        stopSyncLoader();
      })
      .catch(() => {
        stopSyncLoader();
      });
  };
}

export function syncWithViewPoint() {
  return (dispatch) => {
    dispatch(apiStartLoader("syncWithViewPoint"));
    return sync()
      .then((obj) => {
        if (obj.status === 200) {
          dispatch(getSyncStatus());
        }
      })
      .catch((err) => {
        dispatch(apiFailed(err));
        dispatch(apiStopLoader("syncWithViewPoint"));
      });
  };
}
