import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import { get, includes, range } from "lodash";
import { mapValues } from "lodash";
import PropTypes from "prop-types";

import Button from "@material-ui/core/Button";
import Collapse from "@material-ui/core/Collapse";
import Fab from "@material-ui/core/Fab";
import IconButton from "@material-ui/core/IconButton";
import LinearProgress from "@material-ui/core/LinearProgress";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { ReactComponent as ExpandAllIcon } from "../../../assets/img/dropdown1.svg";
import { ReactComponent as CollapseAllIcon } from "../../../assets/img/dropdown2.svg";
import * as AllCrewActions from "../../../redux/actions/AllCrewActions";
import * as ExportCsvActions from "../../../redux/actions/exportCsvActions";
import * as LabourTimeReportsPreviewActions from "../../../redux/actions/LabourTimeReportsPreviewActions";
import * as PersonnelActions from "../../../redux/actions/PersonnelActions";
import {
  tableStyles,
  toolbarStyles
} from "../../../styles/components/WeeklyReportsTableStyles";
import TimeEntryReportsFiltersDialog from "../TimeEntryReportsFiltersDialog";

import PreviewTimeReportsDetailsComponent from "./PreviewTimeReportsDetailsComponent";

const useStyles = makeStyles({
  toolBar: {
    padding: 0,
    marginBottom: "12px"
  },
  lastFab: {
    marginRight: 0
  },
  headerCell: {
    padding: "11px 0px 11px 8px"
  },
  actionsCenterCell: {
    justifyContent: "center",
    margin: 0
  },
  noBorder: {
    border: "none"
  }
});

const calculateQuery = (timeEntryReportFilter) => {
  const {
    dateFilter: { startDate, endDate },
    roleFilter,
    jobFilter,
    crewOfFilter,
    crewFilter,
    allCrewFilter,
    hoursTypeFilter,
    showOnlyUntrustedHours,
    ouFilterUnflattened,
    minMaxHours
  } = timeEntryReportFilter;

  let query = "";

  if (endDate && startDate) {
    query = query.concat(`startDate=${startDate}&endDate=${endDate}`);
  }

  if ((roleFilter || []).length) {
    const roles = roleFilter.map((role) => role.id).join(",");
    query = query.concat(`&roles=${roles}`);
  }

  if ((jobFilter || []).length) {
    const jobCode = jobFilter.map((job) => job.id).join(",");
    query = query.concat(`&jobCode=${jobCode}`);
  }

  if ((crewOfFilter || []).length) {
    const crewOfIds = crewOfFilter.map((cOf) => cOf._id).join(",");
    query = query.concat(`&crewOf=${crewOfIds}`);

    if ((crewFilter || []).length) {
      const crewIds = crewFilter.map((cr) => cr._id).join(",");
      query = query.concat(`&crew=${crewIds}`);
    }
  } else if ((allCrewFilter || []).length) {
    const crewIds = allCrewFilter.map((cr) => cr._id).join(",");
    query = query.concat(`&crew=${crewIds}`);
  }

  if ((hoursTypeFilter || []).length) {
    const hourTypes = hoursTypeFilter.map((type) => type.name).join(",");
    query = query.concat(`&hoursType=${hourTypes}`);
  }

  if (showOnlyUntrustedHours) {
    query = query.concat(`&untrustedEntriesOnly=${showOnlyUntrustedHours}`);
  }

  if (ouFilterUnflattened && !!Object.keys(ouFilterUnflattened).length) {
    const ouCodes = mapValues(ouFilterUnflattened, (unit) =>
      unit.map((ou) => ou.id).join(",")
    );
    query = query.concat(
      Object.keys(ouCodes).map((ou) => {
        if (ouCodes[ou] !== "") {
          return `&${ou}=${ouCodes[ou]}`;
        } else {
          return "";
        }
      })
    );
  }

  if (minMaxHours.minHours !== "" && minMaxHours.minHours >= 0) {
    query = query.concat(`&minimumHours=${minMaxHours.minHours}`);
  }

  if (minMaxHours.maxHours !== "" && minMaxHours.maxHours >= 0) {
    query = query.concat(`&maximumHours=${minMaxHours.maxHours}`);
  }

  return query;
};

const PreviewTimeReportsComponent = (props) => {
  const toolbarClasses = toolbarStyles();
  const classes = tableStyles();
  const localClasses = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [query, setQuery] = useState("");
  const {
    apiStatus,
    userDetails,
    selectedCompany,
    labourTimeReportsPreview,
    timeEntryReportFilter
  } = useSelector((state) => state);
  const prevCompanyCode = useRef(selectedCompany.code);
  const [showModFilter, setShowModFilter] = useState(false);
  const {
    userJobs,
    organizationDetails,
    handleDropdownScroll,
    setDropdownOffset,
    jobOffset,
    dropdownLimit,
    payPeriodsOptions,
    payPeriodOptionsScroll
  } = props;

  const disableOnLoad =
    apiStatus &&
    apiStatus.isLoading &&
    (apiStatus.reducer.includes("labourTimeReportsPreview") ||
      apiStatus.reducer.includes("exportCSVfile"));

  useEffect(() => {
    /* Here resetting all the offset value for currentFilter to 0, 
      so that if selectedCompany.code is changed the offset values get reset 
      to 0 and are not old values. 
      When coming from Time Entry tab to here, we need to preserve jobOffset
      hence its not 0.
    */
    setDropdownOffset({
      userJobs: jobOffset,
      crewMembers: 0,
      personnel: 0,
      organizationDetails: 0
    });
    /* Directing to TimeEntries tab on company change or if user manually route to this page 
       without filter selection */
    if (
      prevCompanyCode.current !== selectedCompany.code ||
      !timeEntryReportFilter.dateFilter.startDate
    ) {
      setTimeout(() => {
        history.push("/TimeEntries");
      }, [500]);
    }
    // eslint-disable-next-line
  }, [selectedCompany.code]);

  const loadCrewAndPersonnel = () => {
    if (
      selectedCompany.code &&
      userDetails.companyCode === selectedCompany.code
    ) {
      dispatch(AllCrewActions.loadAllCrewMembers(0, dropdownLimit));
      dispatch(PersonnelActions.assignedEmployeePersonnel(0, dropdownLimit));
    }
  };

  useEffect(loadCrewAndPersonnel, [selectedCompany, userDetails]);

  const [expanded, setExpanded] = useState({});
  const [order, setOrder] = useState({ empName: "asc" });
  const [sortBy, setSortBy] = useState("empName");
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(50);
  const [paginationLimit, setPaginationLimit] = useState(50);
  const onModifyFilterSave = () => {
    setExpanded({});
    if (offset > 0) setOffset(0);
  };
  const loadPreviewReports = () => {
    const calculatedQuery = calculateQuery(timeEntryReportFilter);
    const newQuery = `${calculatedQuery}&orderBy=${sortBy}-${order[sortBy]}`;

    dispatch(
      LabourTimeReportsPreviewActions.loadLabourTimeReportsPreview(
        newQuery,
        offset,
        limit
      )
    );
    setQuery(newQuery);
  };

  useEffect(loadPreviewReports, [
    timeEntryReportFilter,
    offset,
    limit,
    sortBy,
    order
  ]);

  const handleExpandClick = (e, index) => {
    // eslint-disable-next-line no-return-assign
    setExpanded((exp) => ({
      ...exp,
      [index]: (expanded[index] = !expanded[index])
    }));
  };

  const headers = [
    {
      id: "crew.empID",
      type: "string",
      disablePadding: true,
      label: t("payroll.empId_label"),
      sortable: true,
      sortId: "empID",
      classes: classes.cell
    },
    {
      id: "crew.empName",
      type: "string",
      disablePadding: true,
      label: t("payroll.name_label"),
      sortable: true,
      sortId: "empName",
      classes: classes.cell
    },
    {
      id: "totalHours",
      type: "number",
      disablePadding: true,
      label: t("payroll.total_hours"),
      sortable: true,
      sortId: "totalHours",
      classes: clsx(
        classes.cell,
        classes.totalHoursAllJobs,
        timeEntryReportFilter.showOnlyUntrustedHours && classes.unTrustedCell
      )
    },
    ...(timeEntryReportFilter.hoursTypeFilter.length
      ? [
          {
            id: "payType",
            type: "payType",
            disablePadding: true,
            label: t("payroll.pay_type"),
            sortable: false,
            classes: classes.cell
          }
        ]
      : []),
    {
      id: "crew.personnelTo",
      type: "array",
      mapKey: "empName",
      disablePadding: true,
      label: t("payroll.crew_Of"),
      sortable: false,
      classes: clsx(classes.cell, classes.loggedByCell)
    },
    {
      id: "actions",
      type: "actions",
      disablePadding: true,
      label: "",
      sortable: false,
      classes: clsx(classes.cell, classes.fixedColumn)
    }
  ];

  const handleSort = (event, sort) => {
    if (event) {
      setOrder((o) => ({
        ...o,
        [sort]: order[sort] === "asc" ? "desc" : "asc"
      }));
      setSortBy(sort);
      setOffset(0);
    }
  };

  const tableHeader = (
    <TableHead key='reports-table-header'>
      <TableRow key='reports-table-header-row'>
        {headers.map((header) => (
          <TableCell
            key={header.id || header.label}
            padding={header.disablePadding ? "none" : "default"}
            className={clsx(classes.headerCell, localClasses.headerCell, {
              [classes.fixedHeader]: !header.label
            })}
            sortDirection={
              header.sortable && sortBy === header.sortId
                ? order[sortBy]
                : false
            }
            scope='tr'
          >
            {header.sortable ? (
              <TableSortLabel
                className={classes.sortHeader}
                active={sortBy === header.sortId}
                direction={sortBy === header.sortId ? order[sortBy] : "asc"}
                onClick={(event) => handleSort(event, header.sortId)}
              >
                {header && header.label ? header.label : header.sortId}
              </TableSortLabel>
            ) : header && header.label ? (
              header.label
            ) : (
              <Button
                color='secondary'
                size='small'
                aria-label='expand-all'
                className={classes.button}
                disabled={disableOnLoad}
                onClick={() => {
                  if (
                    includes(expanded, false) &&
                    expanded.length === labourTimeReportsPreview.length
                  ) {
                    setExpanded((exp) => ({
                      ...exp,
                      // eslint-disable-next-line no-return-assign
                      ...labourTimeReportsPreview.map((e, i) => (e[i] = !e[i]))
                    }));
                  } else if (includes(expanded, true)) {
                    setExpanded((exp, i) => ({
                      ...exp,
                      ...labourTimeReportsPreview.map((e) => Boolean(e[i]))
                    }));
                  } else {
                    setExpanded((exp, i) => ({
                      ...exp,
                      ...labourTimeReportsPreview.map((e) => !e[i])
                    }));
                  }
                }}
                endIcon={
                  includes(expanded, true) ? (
                    <CollapseAllIcon
                      fill={disableOnLoad ? "rgba(0, 0, 0, 0.26)" : "#3a5998"}
                    />
                  ) : (
                    <ExpandAllIcon
                      fill={disableOnLoad ? "rgba(0, 0, 0, 0.26)" : "#3a5998"}
                    />
                  )
                }
              >
                {includes(expanded, true)
                  ? t("payroll.collapse_all_btn_label")
                  : t("payroll.expand_all_btn_label")}
              </Button>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );

  const exportXls = async () => {
    dispatch(
      ExportCsvActions.exportPreviewReportsCSV(`labourtimereports/csv?${query}`)
    )
      .then(() => {
        // Redirecting to Time Entries tab
        history.push("/TimeEntries");
      })
      .catch((error) => console.log(error));
  };

  return (
    <React.Fragment key='Reports'>
      {/* ---Toolbar--- */}
      <Toolbar
        className={clsx(toolbarClasses.root, localClasses.toolBar)}
        key='reports-table-toolbar'
      >
        <Typography className={toolbarClasses.previewtitle} variant='h5'>
          {`${t("payroll.reports_preview")} - ${
            timeEntryReportFilter.dateFilter.label
          }`}
        </Typography>
        <div className={toolbarClasses.buttons}>
          <Fab
            variant='extended'
            aria-label='modify-filter'
            size='medium'
            className={`${toolbarClasses.filter} ${toolbarClasses.fab}`}
            disabled={apiStatus.isLoading}
            onClick={() => setShowModFilter(true)}
          >
            {t("payroll.modify_filters")}
          </Fab>
          <Fab
            variant='extended'
            color='secondary'
            aria-label='export'
            size='medium'
            className={clsx(toolbarClasses.fab, localClasses.lastFab)}
            disabled={apiStatus.isLoading}
            onClick={exportXls}
          >
            {t("payroll.export_xls")}
          </Fab>
        </div>
      </Toolbar>

      {disableOnLoad && <LinearProgress />}

      {/* ---MainTable--- */}
      <div className={clsx(classes.tableWrapper)}>
        <Table
          className={classes.table}
          size='small'
          stickyHeader
          aria-label='preview-reports-table'
          key='preview-reports-table'
          padding='none'
        >
          {/* ---Header--- */}
          {tableHeader}

          <TableBody>
            {labourTimeReportsPreview.length ? (
              labourTimeReportsPreview.map((row, index) => (
                <>
                  <TableRow hover tabIndex={-1} key={row.crew._id || index}>
                    {row &&
                      headers.map((header) => (
                        <TableCell className={header.classes} key={header.id}>
                          {
                            {
                              string: get(row, header.id) || "-",

                              number:
                                (get(row, header.id) || "").toLocaleString(
                                  undefined,
                                  {
                                    minimumFractionDigits: 2
                                  }
                                ) || "-",

                              array:
                                get(row, "crew.personnelTo").length > 0 &&
                                Array.isArray(get(row, header.id))
                                  ? get(row, header.id)
                                      .map((log) => log[header.mapKey])
                                      .join(", ")
                                  : "-",
                              //this is not technically comming from API
                              payType: timeEntryReportFilter.hoursTypeFilter
                                .map((log) => log.name)
                                .join(", "),

                              actions: (
                                <div
                                  className={clsx(
                                    classes.actionsCell,
                                    localClasses.actionsCenterCell
                                  )}
                                >
                                  <IconButton
                                    className={clsx(classes.expand, {
                                      [classes.expandOpen]: expanded[index]
                                    })}
                                    disabled={disableOnLoad}
                                    onClick={(event) => {
                                      event.preventDefault();
                                      event.stopPropagation();
                                      handleExpandClick(event, index);
                                    }}
                                    aria-expanded={expanded}
                                    aria-label='expand-row'
                                  >
                                    <ExpandMoreIcon />
                                  </IconButton>
                                </div>
                              )
                            }[header.type]
                          }
                        </TableCell>
                      ))}
                  </TableRow>

                  {/* Expansion Cell */}
                  <TableRow key={`expansion-row-${row.crew._id}`}>
                    <TableCell
                      colSpan={headers.length + 1}
                      key='expansion-cell'
                    >
                      <Collapse
                        key={`collapse${index}`}
                        in={expanded[index]}
                        unmountOnExit
                      >
                        <PreviewTimeReportsDetailsComponent
                          jobWiseBreakDown={row.jobWiseBreakDown}
                        />
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </>
              ))
            ) : (
              <TableRow key='empty-row'>
                <TableCell
                  colSpan={headers.length + 1}
                  className={classes.emptyCell}
                  key='empty-cell'
                >
                  {apiStatus && !apiStatus.isLoading
                    ? t("commonActions.no_records")
                    : t("commonActions.loading_records")}
                </TableCell>
              </TableRow>
            )}

            {/* Pagination Row */}
            <TableRow key='pagination-row'>
              <TableCell
                colSpan={headers.length - 1}
                key='empty-cells'
                className={localClasses.noBorder}
              />
              <TableCell
                className={clsx(localClasses.noBorder, classes.paginationCell)}
                key='pagination-cell'
              >
                <Select
                  key='pagination-menu'
                  labelId='pagination-limit'
                  id='pagination-limit'
                  value={paginationLimit}
                  disableUnderline
                  onChange={(event) => {
                    setLimit(event.target.value);
                    setPaginationLimit(event.target.value);
                    setOffset(0);
                  }}
                  inputProps={{
                    className: classes.paginationDropdown
                  }}
                >
                  <MenuItem key={25} value={25}>
                    25
                  </MenuItem>
                  {range(50, 250, 50).map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
                <Button
                  key='show-more'
                  variant='outlined'
                  color='secondary'
                  size='small'
                  className={clsx(classes.button, classes.showMoreButton)}
                  disabled={disableOnLoad}
                  onClick={() => {
                    setLimit(paginationLimit);
                    setOffset((l) => l + paginationLimit);
                  }}
                >
                  {t("payroll.show_more")}
                </Button>
              </TableCell>
            </TableRow>
            <br />
          </TableBody>
        </Table>
      </div>

      {showModFilter && (
        <TimeEntryReportsFiltersDialog
          isAModifyDialog
          onModifyFilterSave={onModifyFilterSave}
          open={showModFilter}
          close={() => setShowModFilter(false)}
          userJobs={userJobs}
          organizationDetails={organizationDetails}
          handleDropdownScroll={handleDropdownScroll}
          payPeriodsOptions={payPeriodsOptions}
          payPeriodOptionsScroll={payPeriodOptionsScroll}
        />
      )}
    </React.Fragment>
  );
};

PreviewTimeReportsComponent.propTypes = {
  userJobs: PropTypes.arrayOf(
    PropTypes.shape({
      operationalUnits: PropTypes.arrayOf(PropTypes.shape()).isRequired,
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired
    })
  ).isRequired,
  organizationDetails: PropTypes.shape({
    timeEntriesRoleLevels: PropTypes.arrayOf(PropTypes.string).isRequired,
    OULevelNamesForTimeLogs: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    hoursType: PropTypes.arrayOf(PropTypes.string).isRequired,
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired
  }).isRequired,
  handleDropdownScroll: PropTypes.func.isRequired,
  setDropdownOffset: PropTypes.func.isRequired,
  jobOffset: PropTypes.number.isRequired,
  dropdownLimit: PropTypes.number.isRequired,
  payPeriodsOptions: PropTypes.arrayOf(PropTypes.shape()),
  payPeriodOptionsScroll: PropTypes.func
};

export default PreviewTimeReportsComponent;
