import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import { Dialog } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import * as RolesActions from "../../../redux/actions/RolesActions";

const AssignRoleComponent = (props) => {
  const { t } = useTranslation();
  const { SelectedEmployees, unSelectAll, show, handleClose, previosRole } =
    props;

  const { roles } = useSelector((state) => state);
  const dispatch = useDispatch();

  const [selectedRole, setSelectedRole] = useState({});

  // functions
  function handleSelect(e) {
    const { roleName } = roles.find((role) => role._id === e.target.value);
    setSelectedRole({
      _id: e.target.value,
      roleName
    });
  }

  function handleUpdateRole() {
    dispatch(
      RolesActions.updateRoles(SelectedEmployees, selectedRole, previosRole)
    );
  }

  return (
    <Dialog
      open={show}
      onClose={handleClose}
      variant='outlined'
      color='primary'
      maxWidth='xs'
      fullWidth
    >
      <DialogTitle id='customized-dialog-title' onClose={handleClose}>
        {t("authorizerContainer.assign_role_label")}
      </DialogTitle>

      <DialogContent dividers>
        <Grid container>
          <Select
            value={selectedRole._id || "role"}
            onChange={(e) => handleSelect(e)}
            fullWidth
            variant='outlined'
          >
            <MenuItem value='role' label='role' disabled>
              {t("authorizerContainer.select_role_menu_label")}
            </MenuItem>
            {roles.map(
              (role) =>
                role.roleName !== "" && (
                  <MenuItem key={role._id} value={role._id}>
                    {role.roleName}
                  </MenuItem>
                )
            )}
          </Select>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' color='primary' onClick={handleClose}>
          {t("commonActions.cancel")}
        </Button>
        {selectedRole._id !== undefined ? (
          <Button
            variant='contained'
            color='primary'
            onClick={() => {
              handleUpdateRole(SelectedEmployees, selectedRole);
              unSelectAll();
              handleClose();
            }}
          >
            {t("commonActions.save_changes")}
          </Button>
        ) : (
          <Button disabled>{t("commonActions.save_changes")}</Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

AssignRoleComponent.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  SelectedEmployees: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  unSelectAll: PropTypes.func.isRequired,
  previosRole: PropTypes.string
};

AssignRoleComponent.defaultProps = {
  previosRole: ""
};

export default AssignRoleComponent;
