import {
  GET_FILTER,
  OVERRIDE_FILTER,
  RESET_FILTER,
  SET_FILTER
} from "./actionTypes";

// Define action creators
export const setFilter = (filterType, filterValue) => ({
  type: SET_FILTER,
  filterType,
  filterValue
});

export const overrideFilter = (filterOverRideState) => ({
  type: OVERRIDE_FILTER,
  filterOverRideState
});

export const getFilter = (filterType) => ({
  type: GET_FILTER,
  filterType
});

export const resetFilter = () => ({
  type: RESET_FILTER
});
