import React from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import PropTypes from "prop-types";

import { amber, green } from "@material-ui/core/colors";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import { makeStyles } from "@material-ui/core/styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CloseIcon from "@material-ui/icons/Close";
import ErrorIcon from "@material-ui/icons/Error";

import { setApiState } from "../../redux/actions/ApiStatusActions";

const variantIcon = {
  success: CheckCircleIcon,
  error: ErrorIcon
};

const useStyles1 = makeStyles((theme) => ({
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  info: {
    backgroundColor: theme.palette.primary.main
  },
  warning: {
    backgroundColor: amber[700]
  },
  icon: {
    fontSize: 30
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
    color: "#fff"
  },
  message: {
    display: "flex",
    alignItems: "center",
    fontSize: "1rem"
  }
}));

function MySnackbarContentWrapper(props) {
  const classes = useStyles1();
  const { className, message, multipleMessages, onClose, variant, ...other } =
    props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={clsx(classes[variant], className)}
      aria-describedby='client-snackbar'
      message={
        multipleMessages.length ? (
          <List dense>
            {multipleMessages.map((item) =>
              item.length ? (
                <ListItem key={item}>
                  <ListItemIcon>
                    <Icon className={clsx(classes.icon, classes.iconVariant)} />
                  </ListItemIcon>
                  <ListItemText primary={item} className={classes.message} />
                </ListItem>
              ) : null
            )}
          </List>
        ) : (
          <span id='client-snackbar' className={classes.message}>
            <Icon className={clsx(classes.icon, classes.iconVariant)} />
            {message}
          </span>
        )
      }
      action={[
        <IconButton
          key='close'
          aria-label='close'
          color='inherit'
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>
      ]}
      {...other}
    />
  );
}

const SnackbarComponent = () => {
  const dispatch = useDispatch();
  const { apiStatus } = useSelector((state) => state);

  const [open, setOpen] = React.useState(true);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
    dispatch(setApiState());
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        open={open}
        autoHideDuration={5000}
        onClose={() => {
          handleClose();
          dispatch(setApiState());
        }}
      >
        <MySnackbarContentWrapper
          onClose={handleClose}
          variant={Object.keys(apiStatus.status)[0]}
          message={
            Object.keys(apiStatus.status)[0] === "success"
              ? apiStatus.status[`${Object.keys(apiStatus.status)[0]}`]
              : apiStatus.status[`${Object.keys(apiStatus.status)[0]}`]
          }
          multipleMessages={
            apiStatus &&
            apiStatus.status &&
            apiStatus.status.error &&
            apiStatus.status.error.includes("||")
              ? apiStatus.status.error.split("||")
              : []
          }
        />
      </Snackbar>
    </div>
  );
};

MySnackbarContentWrapper.propTypes = {
  variant: PropTypes.oneOf(["error", "success"]).isRequired,
  className: PropTypes.shape(),
  message: PropTypes.string.isRequired,
  multipleMessages: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  onClose: PropTypes.func.isRequired
};

MySnackbarContentWrapper.defaultProps = {
  className: {}
};

export default SnackbarComponent;
