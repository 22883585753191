import lodash from "lodash";

import {
  CLEAR_JOBS,
  LOAD_JOBS_SUCCESS,
  UNASSIGN_JOB_SUCCESS
} from "../actions/actionTypes";

export default function JobsReducer(state = [], action) {
  switch (action.type) {
    case LOAD_JOBS_SUCCESS:
      return action.offset ? [...state, ...action.jobs] : action.jobs;
    case UNASSIGN_JOB_SUCCESS: {
      const unassignedJobIndex = state.findIndex(
        (job) => job.code === action.jobCode[0]
      );
      const copyOfJobs = lodash.cloneDeep(state);
      if (unassignedJobIndex >= 0) {
        copyOfJobs[unassignedJobIndex].isAssigned = false;
        copyOfJobs[unassignedJobIndex].operationalUnits.forEach((ou, idx) => {
          ou.items.forEach((item, i) => {
            copyOfJobs[unassignedJobIndex].operationalUnits[idx].items[
              i
            ].selected = false;
          });
        });
      }
      return copyOfJobs;
    }
    case CLEAR_JOBS:
      return [];
    default:
      return state;
  }
}
