import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  withRouter
} from "react-router-dom";
import PropTypes from "prop-types";

import AdminComponent from "../containers/AdminComponent";
import PayrollHomeComponent from "../containers/PayrollHomeComponent";
import SignInComponent from "../containers/SignInComponent";
import { logout } from "../redux/actions/AuthActions";
import * as CompanyActions from "../redux/actions/CompaniesActions";
import loadUserDetails from "../redux/actions/UserDetailsActions";
import permissionsList from "../utillities/Permissions";
import { regReplaceURI } from "../utillities/regexConstants";

import PrivateRoute from "./PrivateRoute";

const RoutesComponent = ({ history }) => {
  const { userDetails, toSwitch, isAuth, companies, selectedCompany } =
    useSelector((state) => state);
  const dispatch = useDispatch();

  const handleOnRefresh = () => {
    // get userDetails on refresh as permissions can change
    if (isAuth) {
      dispatch(CompanyActions.loadCompanies());
      if (selectedCompany.code) dispatch(loadUserDetails());
    }

    if (
      companies.length === 1 &&
      !userDetails.isSuperAdmin &&
      userDetails.role &&
      userDetails.role.permissions &&
      !userDetails.role.permissions.includes(permissionsList.TS_DESKTOP.key)
    ) {
      dispatch(logout());
    }
  };

  useEffect(handleOnRefresh, [history]);

  // toSwitch default value is true
  // toSwitch is used to switch from authorizer to specific role or from Role to authorizer
  return (
    <BrowserRouter>
      {(userDetails.isSuperAdmin && toSwitch) ||
      (!toSwitch &&
        userDetails.role &&
        userDetails.role.permissions.includes(permissionsList.AUTHORIZER.key) &&
        !userDetails.isSuperAdmin) ? (
        <Switch>
          <Route path='/' exact component={SignInComponent} />
          <PrivateRoute path='/admin' component={AdminComponent} />
          <Redirect to='/' />
        </Switch>
      ) : (
        <Switch>
          <Route path='/' exact component={SignInComponent} />
          {userDetails.role && userDetails.role.roleName && (
            <PrivateRoute
              path={`/${userDetails.role.roleName.replace(regReplaceURI, "_")}`}
              component={PayrollHomeComponent}
            />
          )}
          <Redirect to='/' />
        </Switch>
      )}
    </BrowserRouter>
  );
};

RoutesComponent.propTypes = {
  history: PropTypes.shape({}).isRequired
};

export default withRouter(RoutesComponent);
