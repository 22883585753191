import axios from "../../axios-jwt";
import i18n from "../../i18n";

import { CLEAR_SEARCH_RESULTS, SEARCH_PERSONNEL } from "./actionTypes";
import {
  apiStartLoader,
  apiStopLoader,
  customFailedMessage
} from "./ApiStatusActions";

export async function searchPersonnel(param) {
  const resp = axios.get(
    `/employees?personnelAssigned=true&search=${param}&orderBy=empID-asc`
  );
  return resp;
}

export function searchPersonnelResults(personnelSearchResults) {
  return { type: SEARCH_PERSONNEL, personnelSearchResults };
}

export function searchPersonnelWithParam(queryParam) {
  return (dispatch) => {
    dispatch(apiStartLoader("personnelSearchResults"));
    searchPersonnel(queryParam)
      .then((res) => {
        if (res.status === 200) {
          dispatch(searchPersonnelResults(res.data.employees));
          dispatch(apiStopLoader("personnelSearchResults"));
        }
        return undefined;
      })
      .catch((error) => {
        if (error) dispatch(customFailedMessage(i18n.t("invalid_search_term")));
        dispatch(apiStopLoader("personnelSearchResults"));
      });
  };
}

export function clearPersonnelResults(actionType, clear) {
  return { type: CLEAR_SEARCH_RESULTS, actionType, clear };
}
