import axios from "../../axios-jwt";

import { GET_ORGANIZATION_DATA } from "./actionTypes";
import { apiFailed } from "./ApiStatusActions";
import { logout } from "./AuthActions";

async function getOrganizationDetails() {
  const resp = axios.get("organizations/me/");
  return resp;
}

export function loadOrganizationSuccess(organization) {
  return { type: GET_ORGANIZATION_DATA, organization };
}

export function loadOrganizationDetails() {
  return (dispatch) => {
    return getOrganizationDetails()
      .then((res) => {
        if (res.status === 200) {
          dispatch(loadOrganizationSuccess(res.data));
        }
      })
      .catch((err) => {
        dispatch(apiFailed(err));
        dispatch(logout());
      });
  };
}
