import {
  RESET_SELECTED_COMPANY,
  SELECTED_COMPANY
} from "../actions/actionTypes";

export default function SelectedCompanyReducer(state = [], action) {
  switch (action.type) {
    case SELECTED_COMPANY: {
      return { ...state, ...action.payload };
    }
    case RESET_SELECTED_COMPANY: {
      return [];
    }
    default:
      return state;
  }
}
