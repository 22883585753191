import { LOAD_USER_JOB_DETAILS } from "../actions/actionTypes";

export default function UserJobDetailsReducer(state = [], action) {
  switch (action.type) {
    case LOAD_USER_JOB_DETAILS:
      return action.offset ? [...state, ...action.userJobs] : action.userJobs;
    default:
      return state;
  }
}
