import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { flatMap, flatten, intersectionWith, mapValues, uniqBy } from "lodash";
import PropTypes from "prop-types";

import useInput from "../../custom-hooks/useInput";
import * as EmployeeActions from "../../redux/actions/EmployeeActions";
import * as EmployeeByJobSearchActions from "../../redux/actions/EmployeeByJobSearchActions";
import * as EquipmentsActions from "../../redux/actions/EquipmentsActions";
import * as EquipmentSearchActions from "../../redux/actions/EquipmentSearchActions";
import * as UserJobsSearchActions from "../../redux/actions/UserJobsSearchActions";
import { tableStyles } from "../../styles/components/WeeklyReportsTableStyles";
import DrawerComponent from "../UI/DrawerComponent";
import MultiSelectComponent from "../UI/MultiSelectComponent";

const EquipmentEntryReportsFiltersComponent = (props) => {
  const { t } = useTranslation();
  const classes = tableStyles();
  const dispatch = useDispatch();
  const {
    filterDrawerState,
    toggleDrawer,
    setFiltersState,
    organizationDetails,
    userJobs,
    jobOffset,
    setJobOffset
  } = props;
  const {
    equipments,
    userJobsSearchResults,
    employeeByJobs,
    employeeByJobSearchResults,
    equipmentSearchResults,
    selectedCompany,
    userDetails
  } = useSelector((state) => state);

  const [drawerState, setDrawerState] = useState(filterDrawerState);
  const [searchString, setSearchString] = useState({});
  const [query, setQuery] = useState("");
  const [currentFilter, setCurrentFilter] = useState("");
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [dropdownLimit, setDropdownLimit] = useState(20);
  const [dropdownOffset, setDropdownOffset] = useState({
    userJobs: jobOffset,
    employeeByJobs: 0,
    equipments: 0
  });
  const allEquipmentFilter = useInput();
  const equipmentFilter = useInput();
  const userJobFilter = useInput();
  const ouFilter = useInput();
  const employeeByJobsFilter = useInput();
  const uploadFilter = useInput();

  const searchEquipment = (query) =>
    query && dispatch(EquipmentSearchActions.searchEquipmentWithParam(query));
  const clearEquipmentList = (type, clear) =>
    dispatch(EquipmentSearchActions.clearEquipmentResults(type, clear));
  const searchEmployeeByJob = (query) =>
    query &&
    dispatch(EmployeeByJobSearchActions.searchEmployeeByJobWithParam(query));
  const clearEmployeeByJobList = (type, clear) =>
    dispatch(EmployeeByJobSearchActions.clearEmployeeByJobResults(type, clear));
  const searchUserJobs = (query) =>
    query && dispatch(UserJobsSearchActions.searchUserJobsWithParam(query));
  const clearUserJobsList = (type, clear) =>
    dispatch(UserJobsSearchActions.clearUserJobsResults(type, clear));

  const loadDetails = () => {
    if (
      selectedCompany.code &&
      userDetails.companyCode === selectedCompany.code
    ) {
      dispatch(
        EquipmentsActions.loadEquipments("orderBy=code-asc", 0, dropdownLimit)
      );
      dispatch(EmployeeActions.getEmployeeByJobs(0, dropdownLimit));
    }
  };

  useEffect(loadDetails, [selectedCompany, userDetails]);

  // Load options in filter dropdowns based on search and pagination conditions
  const dropdownOptions = (searchString, array, searchResults) => {
    let options = [];
    if (!searchString) options = array;
    else {
      if (searchResults.length) options = searchResults;
      else if (!searchResults.length && array.length) options = [];
    }
    return options;
  };

  const jobOptions = () =>
    dropdownOptions(
      (searchString || {})["userJobs"],
      userJobs,
      userJobsSearchResults
    ).map((option) => ({
      name: option.name,
      id: option.code,
      operationalUnits: option.operationalUnits
    }));

  const allEquipmentOptions = () =>
    dropdownOptions(
      (searchString || {})["equipments"],
      equipments,
      equipmentSearchResults
    ).map((option) => ({
      name: option.name || option.code,
      id: option.code,
      _id: option._id
    }));

  const employeeByJobsOptions = () =>
    dropdownOptions(
      (searchString || {})["employeeByJobs"],
      employeeByJobs,
      employeeByJobSearchResults
    ).map((option) => ({
      name: option.empName,
      id: option.empID,
      _id: option._id
    }));

  const ouFilterOptions = (data) => {
    if (!data || !data.value || !data.value.length || data.value.length > 1)
      return;
    const OU = organizationDetails.OULevelNamesForEquipmentLogs;
    const equipmentOU = intersectionWith(
      data.value[0].operationalUnits,
      OU,
      (un, na) => un.name === na.name
    );
    const operationalUnits = equipmentOU.map((unit) => ({
      label: unit.name,
      type: `operationalUnit_${unit.name}`,
      searchType: "ui", // To Decide if search needs to be done from frontend or with API
      data: flatten(unit.items).map((m) => ({ name: m.name, id: m.code }))
    }));

    return operationalUnits || [];
  };

  const selectedEmployeesEquipment = (data) => {
    if (!data || !data.value || !data.value.length) return;

    const options = intersectionWith(
      employeeByJobs,
      data.value,
      (per, val) => per.empID === val.id
    ).map((emp) =>
      emp.equipmentsAssigned.map((equipment) => ({
        name: equipment.name,
        id: equipment.code,
        _id: equipment._id
      }))
    );

    const config = {
      label: `${t("equipmentManager.title")}`,
      type: "selectedEmpEquipment",
      searchType: "ui", // To Decide if search needs to be done in the UI or  with API
      data: uniqBy(flatten(options), "id") || []
    };

    return config;
  };

  const dropdownConfigJob = {
    label: `${t("payroll.job_label")}`,
    type: "userJobs", // Label of reducer
    searchType: "api", // To Decide if search needs to be done in the UI or  with API
    data: jobOptions()
  };

  const dropdownConfigEmployee = {
    label: `${t("equipmentManager.employees")}`,
    type: "employeeByJobs", // Label of reducer
    searchType: "api", // To Decide if search needs to be done in the UI or  with API
    data: employeeByJobsOptions()
  };

  const dropdownConfigAllEquipment = {
    label: `${t("equipmentManager.title")}`,
    type: "equipments", // Label of reducer
    searchType: "api", // To Decide if search needs to be done in the UI or  with API
    data: allEquipmentOptions()
  };

  const dropdownConfigUploadStatus = {
    label: `${t("payroll.upload_label")}`,
    type: "uploadStatus",
    searchType: "ui", // To Decide if search needs to be done from frontend or with API
    data: [
      { id: "Uploaded", name: "Uploaded" },
      { id: "not uploaded", name: "Not Uploaded" }
    ]
  };

  const setData = () => {
    setQuery(""); // To make sure query is not set to undefined during re-render

    if (
      userJobFilter &&
      userJobFilter.value &&
      userJobFilter.value.length > 0
    ) {
      const jobCode = userJobFilter.value.map((job) => job.id).join(",");
      setQuery((qu) => qu.concat(`&jobCode=${jobCode}`));
    }

    if (
      employeeByJobsFilter &&
      employeeByJobsFilter.value &&
      employeeByJobsFilter.value.length > 0
    ) {
      allEquipmentFilter.onChange([]);
      const empIds = employeeByJobsFilter.value.map((emp) => emp._id).join(",");
      setQuery((qu) => qu.concat(`&assignedTo=${empIds}`));

      if (
        equipmentFilter &&
        equipmentFilter.value &&
        equipmentFilter.value.length > 0
      ) {
        const equipIds = equipmentFilter.value.map((eq) => eq._id).join(",");
        setQuery((qu) => qu.concat(`&equipments=${equipIds}`));
      }
    } else if (
      allEquipmentFilter &&
      allEquipmentFilter.value &&
      allEquipmentFilter.value.length > 0
    ) {
      const equipIds = allEquipmentFilter.value.map((eq) => eq._id).join(",");
      setQuery((qu) => qu.concat(`&equipments=${equipIds}`));
    }

    if (ouFilter.value && Object.keys(ouFilter.value).length > 0) {
      const ouCodes = mapValues(ouFilter.value, (unit) =>
        unit.map((ou) => ou.id).join(",")
      );
      setQuery((qu) =>
        qu.concat(
          Object.keys(ouCodes).map((ou) => {
            if (ouCodes[ou] !== "") {
              return `&${ou}=${ouCodes[ou]}`;
            } else {
              return "";
            }
          })
        )
      );
    }

    if (((uploadFilter || {}).value || []).length) {
      const uploadStatus = uploadFilter.value
        .map((type) => type.name)
        .join(",");
      const isNotUploaded = uploadStatus.includes("Not Uploaded");
      if (((uploadFilter || {}).value || []).length === 1)
        setQuery((qu) => qu.concat(`&uploaded=${!isNotUploaded}`));
    }
  };

  useEffect(setData, [
    userJobFilter.value.length,
    employeeByJobsFilter.value.length,
    equipmentFilter.value.length,
    allEquipmentFilter.value.length,
    uploadFilter.value.length,
    flatMap(ouFilter.value).length
  ]);

  const applyFilters = () => {
    setDrawerState({ right: false });
    setIsFilterApplied(true);
    setFiltersState({ right: false }, true, query);
  };

  const clearData = () => {
    setDropdownOffset({
      userJobs: jobOffset,
      employeeByJobs: 0,
      equipments: 0
    });
    setQuery("");
    setSearchString({});
    allEquipmentFilter.onChange([]);
    equipmentFilter.onChange([]);
    ouFilter.onChange([]);
    userJobFilter.onChange([]);
    employeeByJobsFilter.onChange([]);
    uploadFilter.onChange([]);
    clearEquipmentList("equipmentSearchResults", true);
    clearUserJobsList("userJobsSearchResults", true);
    clearEmployeeByJobList("employeeByJobSearchResults", true);
    setIsFilterApplied(false);
  };

  const resetFilters = () => {
    clearData();
    setDrawerState({ right: false });
    setFiltersState({ right: false }, false, "");
  };

  useEffect(resetFilters, [selectedCompany.code]);

  useEffect(() => {
    setDrawerState(filterDrawerState);
    if (!isFilterApplied) clearData();
    // eslint-disable-next-line
  }, [filterDrawerState, isFilterApplied]);

  const disableDrawerActions = () => {
    const length = (filter) => ((filter || {}).value || []).length;
    const checkFilterOptions = () =>
      !![
        length(allEquipmentFilter),
        length(equipmentFilter),
        length(userJobFilter),
        length(employeeByJobsFilter),
        length(ouFilter),
        length(uploadFilter)
      ].filter(Boolean).length;
    return checkFilterOptions();
  };

  const drawerActions = [
    {
      display: true,
      label: `${t("payroll.apply_btn_label")}`,
      action: applyFilters,
      position: "bottom",
      disabled: disableDrawerActions()
    },
    {
      display: true,
      label: `${t("payroll.reset_btn_label")}`,
      action: resetFilters,
      position: "top",
      disabled: disableDrawerActions()
    }
  ];

  const applyPagination = () => {
    switch (currentFilter) {
      case "equipments":
        dispatch(
          EquipmentsActions.loadEquipments(
            "orderBy=code-asc",
            dropdownOffset["equipments"],
            dropdownLimit
          )
        );
        break;
      case "userJobs":
        if (dropdownOffset["userJobs"])
          setJobOffset(dropdownOffset["userJobs"]);
        break;
      case "employeeByJobs":
        dispatch(
          EmployeeActions.getEmployeeByJobs(
            dropdownOffset["employeeByJobs"],
            dropdownLimit
          )
        );
        break;
      default:
        break;
    }
  };

  // Call API for filter dropdown/s if dropdownOffset[dropdownType] > 0
  useEffect(applyPagination, [dropdownOffset]);

  const handleDropdownScroll = (trigger, dropdownType) => {
    if (trigger) {
      setDropdownOffset((o) => ({
        ...o,
        [dropdownType]: o[dropdownType] + dropdownLimit
      }));
      setDropdownLimit(dropdownLimit);
      setCurrentFilter(dropdownType);
    } else {
      setDropdownOffset((o) => ({ ...o, [dropdownType]: jobOffset }));
      setDropdownLimit(0);
    }
  };

  const handleDropdownSearch = (type, query) => {
    if (!query) setSearchString({});
    else setSearchString((s) => ({ ...s, [type]: query }));

    switch (type) {
      case "equipments":
        if (query && query.length) searchEquipment(query);
        else clearEquipmentList("equipmentSearchResults", true);
        break;
      case "userJobs":
        if (query && query.length) searchUserJobs(query);
        else clearUserJobsList("userJobsSearchResults", true);
        break;
      case "employeeByJobs":
        if (query && query.length) searchEmployeeByJob(query);
        else clearEmployeeByJobList("employeeByJobSearchResults", true);
        break;
      default:
        break;
    }
  };

  const handleDropdownClose = (type, closed) => {
    if (!closed) return;
    setSearchString((s) => ({ ...s, [type]: "" }));

    switch (type) {
      case "equipments":
        clearEquipmentList("equipmentSearchResults", true);
        break;
      case "userJobs":
        clearUserJobsList("userJobsSearchResults", true);
        break;
      case "employeeByJobs":
        clearEmployeeByJobList("employeeByJobSearchResults", true);
        break;
      default:
        break;
    }
  };

  const employeeByJobsFilterDropdown = () => (
    <MultiSelectComponent
      key={dropdownConfigEmployee.type}
      dropdownConfig={dropdownConfigEmployee}
      {...employeeByJobsFilter}
      optionLabel={(option) => `${option.id} - ${option.name}`}
      handleScroll={handleDropdownScroll}
      handleSearch={handleDropdownSearch}
      handleClose={handleDropdownClose}
    />
  );

  const jobFilterDropdown = () => (
    <MultiSelectComponent
      key={dropdownConfigJob.type}
      dropdownConfig={dropdownConfigJob}
      {...userJobFilter}
      optionLabel={(option) => `${option.id} - ${option.name}`}
      handleScroll={handleDropdownScroll}
      handleSearch={handleDropdownSearch}
      handleClose={handleDropdownClose}
    />
  );

  const resetOperationalUnitsOnJobChange = () => {
    if (userJobFilter && userJobFilter.value) {
      if (userJobFilter.value.length === 1) {
        userJobs.map(
          (j) => userJobFilter.value[0].code !== j.code && ouFilter.onChange([])
        );
      } else if (
        userJobFilter.value.length > 1 ||
        userJobFilter.value.length === 0
      ) {
        ouFilter.onChange([]);
      }
    }
  };

  // Load the Operations Units if only one job is selected in userJobFilter
  useEffect(resetOperationalUnitsOnJobChange, [userJobFilter.value]);

  const ouFilterDropdown = () => {
    const ouOptions = ouFilterOptions(userJobFilter);

    return (
      userJobFilter &&
      !!(ouOptions || []).length &&
      ouOptions.map((ou) => (
        <MultiSelectComponent
          key={ou.label}
          dropdownConfig={ou}
          onChange={(val) =>
            ouFilter.onChange({ ...ouFilter.value, [ou.label]: val })
          }
          value={ouFilter.value[ou.label] || []}
          optionLabel={(option) => `${option.id} - ${option.name}`}
          handleScroll={handleDropdownScroll}
          handleSearch={handleDropdownSearch}
          handleClose={handleDropdownClose}
        />
      ))
    );
  };

  const resetEquipFilterOnChange = () => {
    if (!employeeByJobsFilter.value.length) {
      equipmentFilter.onChange([]);
    } else {
      const empByJobs = selectedEmployeesEquipment(employeeByJobsFilter);
      const options = intersectionWith(
        empByJobs.data,
        equipmentFilter.value,
        (emp, eq) => emp.id === eq.id
      );
      equipmentFilter.onChange(options);
    }
  };

  // Re-render the Equipment filter on every Employees change
  useEffect(resetEquipFilterOnChange, [employeeByJobsFilter.value]);

  const selectedEmpEqFilterDropdown = () => {
    const config = selectedEmployeesEquipment(employeeByJobsFilter) || {};

    return (
      employeeByJobsFilter && (
        <MultiSelectComponent
          key={config.type}
          dropdownConfig={config}
          {...equipmentFilter}
          optionLabel={(option) => `${option.id} - ${option.name || option.id}`}
          handleScroll={handleDropdownScroll}
          handleSearch={handleDropdownSearch}
          handleClose={handleDropdownClose}
        />
      )
    );
  };

  const allEquipmentFilterDropdown = () => (
    <MultiSelectComponent
      key={dropdownConfigAllEquipment.type}
      dropdownConfig={dropdownConfigAllEquipment}
      {...allEquipmentFilter}
      optionLabel={(option) => `${option.id} - ${option.name || option.id}`}
      handleScroll={handleDropdownScroll}
      handleSearch={handleDropdownSearch}
      handleClose={handleDropdownClose}
    />
  );

  const uploadedFilterDropdown = () => (
    <MultiSelectComponent
      key={dropdownConfigUploadStatus.type}
      dropdownConfig={dropdownConfigUploadStatus}
      {...uploadFilter}
      optionLabel={(option) => option.name}
      handleScroll={handleDropdownScroll}
      handleSearch={handleDropdownSearch}
      handleClose={handleDropdownClose}
    />
  );

  return (
    <DrawerComponent
      key={"equipment-report-filters"}
      header={t("payroll.filters")}
      openDrawer={drawerState}
      closeDrawer={toggleDrawer("right", false)}
      onKeyDown={toggleDrawer()}
      actions={drawerActions}
      drawerPaper={classes.drawer}
      handleScroll={() => false}
      drawerAttributes={
        <React.Fragment key={"EquipmentReportsDrawer"}>
          {employeeByJobsFilterDropdown()}
          {jobFilterDropdown()}
          {ouFilterDropdown()}
          {(() => {
            if (((employeeByJobsFilter || {}).value || []).length)
              return selectedEmpEqFilterDropdown();
            else return allEquipmentFilterDropdown();
          })()}
          {uploadedFilterDropdown()}
        </React.Fragment>
      }
    />
  );
};

EquipmentEntryReportsFiltersComponent.propTypes = {
  filterDrawerState: PropTypes.shape({
    right: PropTypes.bool
  }).isRequired,
  toggleDrawer: PropTypes.func.isRequired,
  setFiltersState: PropTypes.func.isRequired,
  organizationDetails: PropTypes.shape({
    timeEntriesRoleLevels: PropTypes.arrayOf(PropTypes.string).isRequired,
    OULevelNamesForTimeLogs: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    hoursType: PropTypes.arrayOf(PropTypes.string).isRequired,
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    OULevelNamesForEquipmentLogs: PropTypes.arrayOf(
      PropTypes.shape().isRequired
    ).isRequired
  }).isRequired,
  userJobs: PropTypes.arrayOf(
    PropTypes.shape({
      operationalUnits: PropTypes.arrayOf(PropTypes.shape()).isRequired,
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired
    })
  ).isRequired,
  jobOffset: PropTypes.number.isRequired,
  setJobOffset: PropTypes.func.isRequired
};

export default EquipmentEntryReportsFiltersComponent;
