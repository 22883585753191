import axios from "../../axios-jwt";

import {
  CLEAR_EMPLOYEE_SEARCH_RESULTS,
  SEARCH_EMPLOYEES,
  UPDATE_ROLE_EMPLOYEE
} from "./actionTypes";
import * as ApiStatusActions from "./ApiStatusActions";

async function searchEmployees(
  companyCode,
  selectedCompany,
  query,
  offset,
  limit,
  orderBy,
  order
) {
  let resp;
  if (selectedCompany.code) {
    resp = axios.get(
      `employees?companyCode=${selectedCompany.code}&search=${query}&offset=${offset}&limit=${limit}&orderBy=${orderBy}-${order}`
    );
  } else
    resp = axios.get(
      `employees?companyCode=${companyCode}&search=${query}&offset=${offset}&limit=${limit}&orderBy=${orderBy}-${order}`
    );
  return resp;
}

async function searchAppEmployees(
  companyCode,
  selectedCompany,
  query,
  offset,
  limit,
  order,
  orderBy,
  isNotInLastRole,
  role
) {
  return !isNotInLastRole
    ? axios.get(
        `employees?appUsers=true&companyCode=${
          selectedCompany.code || companyCode
        }&search=${query}&offset=${offset}&limit=${limit}&orderBy=${orderBy}-${order}`
      )
    : axios.get(
        `/employees?roleLevelOrder=${role.roleLevel}-desc&search=${query}&offset=${offset}&limit=${limit}&orderBy=${orderBy}-${order}`
      );
}

export function searchEmployeesResults(employeeSearchResults, offset) {
  return { type: SEARCH_EMPLOYEES, employeeSearchResults, offset };
}

export function clearEmployeeResults() {
  return { type: CLEAR_EMPLOYEE_SEARCH_RESULTS };
}

export function searchEmployeesWithParam(
  companyCode,
  queryParam,
  offset,
  limit,
  orderBy = "empName",
  order = "asc",
  isAppEmployeesSearch,
  isNotInLastRole,
  role
) {
  return (dispatch, getState) => {
    if (isAppEmployeesSearch) {
      dispatch(ApiStatusActions.apiStartLoader("AssignToStatus"));
      searchAppEmployees(
        companyCode,
        getState().selectedCompany,
        queryParam,
        offset,
        limit,
        order,
        orderBy,
        isNotInLastRole,
        role
      )
        .then((res) => {
          if (res.status === 200) {
            dispatch(searchEmployeesResults(res.data, offset));
            // dispatch(EmployeesActions.filterEmployees(res.data));
            dispatch(ApiStatusActions.apiStopLoader("AssignToStatus"));
          }
        })
        .catch(() =>
          dispatch(ApiStatusActions.apiStopLoader("AssignToStatus"))
        );
    } else {
      dispatch(ApiStatusActions.apiStartLoader("EmployeesSearchResults"));
      searchEmployees(
        companyCode,
        getState().selectedCompany,
        queryParam,
        offset,
        limit,
        orderBy,
        order
      )
        .then((res) => {
          if (res.status === 200) {
            dispatch(searchEmployeesResults(res.data, offset));
            // dispatch(EmployeesActions.filterEmployees(res.data));
            dispatch(ApiStatusActions.apiStopLoader("EmployeesSearchResults"));
          }
        })
        .catch(() =>
          dispatch(ApiStatusActions.apiStopLoader("EmployeesSearchResults"))
        );
    }
  };
}

export function updateRole(SelectedEmployees, selectedRole) {
  return (dispatch) => {
    return dispatch({
      type: UPDATE_ROLE_EMPLOYEE,
      SelectedEmployees,
      selectedRole
    });
  };
}

export function clearEmployees() {
  return (dispatch) => {
    return dispatch(clearEmployeeResults());
  };
}
