import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import i18n from "i18next";
import { orderBy, uniqBy } from "lodash";
import moment from "moment";
import PropTypes from "prop-types";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  TextField,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import useInfiniteScroll from "../../custom-hooks/useInfiniteScroll";
import * as TimelogsActions from "../../redux/actions/TimelogsActions";
import * as userJobsSearchActions from "../../redux/actions/UserJobsSearchActions";
import activityLogs from "../../utillities/ActivityLogs";
import DropdownSearchComponent from "../UI/DropdownSearchComponent";

const useStyles = makeStyles({
  tableWrapper: {
    minHeight: 570,
    maxHeight: 570,
    overflow: "auto",
    border: "1.5px solid black"
  },
  paper: {
    maxWidth: "630px"
  },
  dropdownGrid: {
    padding: "6px",
    marginTop: "12px"
  },
  paperTextfield: {
    textAlign: "center"
  },
  textFieldGrid: {
    padding: "6px",
    maxWidth: "14.28%"
  },
  textFeild: {
    textAlign: "center"
  },
  typography: {
    whiteSpace: "nowrap"
  },
  gridPadding: {
    padding: "18px"
  }
});

const BulkEntryComponent = (props) => {
  const { t } = useTranslation();

  const {
    open,
    Close,
    payPeriodIndex,
    userJobs,
    PayPeriodSelected,
    selected,
    rows,
    setSelected,
    setJobOffset,
    NumberOfDays
  } = props;

  const { payPeriods, organizationDetails, userJobsSearchResults } =
    useSelector((state) => state);
  const dispatch = useDispatch();

  const [jobSelected, setJobSelected] = useState(userJobs[0]);
  const [OUUpdate, setOUUpdate] = useState({});
  const [jobUpdated, setJobUpdated] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  const [scrollHeight, setScrollHeight] = useState(0);
  const [clientHeight, setClientHeight] = useState(0);
  const [selectedSearchJobs, setSelectedSearchJobs] = useState([]);
  const [search, setSearch] = useState("");
  const dropdownLimit = 20;

  const mergedJobs = (empJobs) =>
    uniqBy(
      orderBy([...empJobs, ...selectedSearchJobs], (job) => +job.code, "asc"),
      "code"
    ) || [];

  const [jobs, setJobs] = useState(mergedJobs(userJobs));

  const onJobChange = () => {
    setJobs(mergedJobs(userJobs));
  };
  useEffect(onJobChange, [selectedSearchJobs, userJobs]);

  const handleJobSearch = (searchTerm, id) => {
    if (searchTerm.length) {
      dispatch(userJobsSearchActions.searchUserJobsWithParam(searchTerm, id));
    }
    setSearch(searchTerm);
  };

  const [hourType, setHourType] = useState();

  const [selectedTime, setSelectedTime] = useState([]);
  const classes = useStyles();

  const EmployeeNames =
    selected.length &&
    selected.map(
      (empId) =>
        rows.length > 0 &&
        rows.find((row) => row.crew._id === empId).crew.empName
    );

  function handleSelectedTime(e, index) {
    if (
      (e.target.value <= 24 && e.target.value >= 0 && e.target.value !== " ") ||
      e.target.value === "."
    ) {
      const copy = selectedTime.slice();
      copy[index] = e.target.value;
      setSelectedTime(copy);
    }
  }

  function roundNumber(e, index) {
    if (e.target.value !== "") {
      const copy = selectedTime.slice();
      copy[index] = parseFloat(e.target.value).toFixed(2);
      setSelectedTime(copy);
    }
  }

  // Custom hook for infinite scroll on Job dropdown
  const [isFetching, toggle] = useInfiniteScroll(
    clientHeight,
    scrollTop,
    scrollHeight
  );

  const getScrollValue = () => {
    if (isFetching && !search.length) {
      setJobOffset((offset) => offset + dropdownLimit);
      toggle(isFetching);
      setScrollTop(0);
      setScrollHeight(0);
    }
  };

  useEffect(getScrollValue, [isFetching]);

  function handleOnSave() {
    if (selectedTime.length > 0) {
      let ob = {};
      Object.keys(OUUpdate).forEach((ou) => {
        ob = { ...ob, [ou]: OUUpdate[ou].code };
      });
      // eslint-disable-next-line array-callback-return
      let payload = selectedTime.map((hr, idx) => {
        const date = moment
          .utc(payPeriods[payPeriodIndex].startDate)
          .add(idx, "d")
          .format("DD-MM-YYYY");
        const dateOfEntry = new Date(
          moment.utc(payPeriods[payPeriodIndex].startDate).add(idx, "d")
        ).toISOString();
        if (hr !== "") {
          return selected.map((emp) => {
            return {
              crewId: emp,
              hoursWorked: hr,
              OULevelValues: ob,
              dateOfEntry,
              isTrustedInTime: false,
              isTrustedOutTime: false,
              date,
              jobCode: jobUpdated ? jobSelected.code : userJobs[0].code,
              hoursType: hourType && hourType.name,
              activityLogs: [
                {
                  code: activityLogs.CREATED.code,
                  timestamp: new Date().toISOString()
                }
              ]
            };
          });
        }
        return {};
      });
      payload = payload
        .flat()
        .filter(
          (field) =>
            field !== null &&
            field !== undefined &&
            Object.keys(field).length !== 0
        );
      dispatch(
        TimelogsActions.bulkEntryTimeLogs(
          payload,
          PayPeriodSelected[payPeriodIndex].id,
          selected.join(",")
        )
      );
      setSelected([]);
      Close();
    }
  }

  return (
    EmployeeNames.length > 0 && (
      <Dialog onClose={Close} open={open} maxWidth='md' fullWidth={false}>
        <DialogTitle
          id='customized-dialog-title'
          onClose={Close}
          disableTypography
        >
          <h3>{t("payroll.bulk_entry_title")}</h3>
          <div className={classes.paper}>
            <Typography noWrap className={classes.typography}>
              <small className='text-muted'>
                {`${selected.length} ${t(
                  "payroll.emp_selected"
                )} - ${EmployeeNames.join(",")}
        `}
              </small>
            </Typography>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <Paper elevation={0} className={classes.paper}>
            <Grid container>
              <Grid item xs={12}>
                <b style={{ padding: "18px" }}>
                  {PayPeriodSelected[payPeriodIndex].value}
                </b>
                <Grid container className={classes.gridPadding}>
                  <Grid
                    item
                    xs={12}
                    style={{ padding: "6px" }}
                    onScroll={(event) => {
                      event.persist();
                      setClientHeight(event.target.clientHeight);
                      setScrollTop(event.target.scrollTop);
                      setScrollHeight(event.target.scrollHeight);
                    }}
                  >
                    <DropdownSearchComponent
                      options={jobs || { code: "", name: "" }}
                      searchOptions={
                        search && search.length ? userJobsSearchResults : []
                      }
                      id='userJobs_bulkEntry'
                      disableUnderline={false}
                      value={
                        jobSelected && jobSelected.code
                          ? jobSelected
                          : userJobs[0] || []
                      }
                      handleChange={(change) => {
                        setJobSelected(change);
                        setOUUpdate({});
                        setJobUpdated(true);
                        if (
                          change &&
                          change.code &&
                          !selectedSearchJobs.find(
                            (job) => job.code === change.code
                          )
                        ) {
                          setSelectedSearchJobs([
                            ...selectedSearchJobs,
                            change
                          ]);
                        }
                        userJobsSearchActions.clearUserJobsResults(
                          "userJobsSearchResults"
                        );
                      }}
                      handleJobSearch={handleJobSearch}
                      onDropDownChange={() => setSearch("")}
                      fullWidth
                    />
                  </Grid>
                  {organizationDetails.OULevelNamesForTimeLogs.map(
                    (op, idx) => (
                      <Grid
                        item
                        xs={6}
                        className={classes.dropdownGrid}
                        key={idx}
                      >
                        <DropdownSearchComponent
                          id='ou_level_values'
                          options={
                            (
                              ((jobSelected || {}).operationalUnits || []).find(
                                (ou) => ou.name === op.name
                              ) || {}
                            ).items || []
                          }
                          disableUnderline={false}
                          value={(() => {
                            let label;
                            if (OUUpdate[op.name]) {
                              label = OUUpdate[op.name];
                            } else if (jobUpdated) {
                              label = { code: "", name: op.label };
                            } else label = { code: "", name: op.label };
                            return label;
                          })()}
                          handleChange={(change) => {
                            setOUUpdate((OU) => ({
                              ...OU,
                              [op.name]: {
                                code: (change || {}).code,
                                name: (change || {}).name
                              }
                            }));
                          }}
                          fullWidth
                        />
                      </Grid>
                    )
                  )}
                  {organizationDetails.hoursType && (
                    <Grid item xs={6} className={classes.dropdownGrid}>
                      <DropdownSearchComponent
                        id='bulkentry_hoursType'
                        options={organizationDetails.hoursType.map((ht) => {
                          return { name: `${ht}`, code: "" };
                        })}
                        disableUnderline={false}
                        value={(() => {
                          let label;
                          if (hourType && hourType.name)
                            label = { name: hourType.name, code: "" };
                          else {
                            label = { code: "", name: t("payroll.pay_type") };
                          }
                          return label;
                        })()}
                        handleChange={(change) => {
                          setHourType(change);
                        }}
                        fullWidth
                      />
                    </Grid>
                  )}
                </Grid>

                {NumberOfDays > 0 && (
                  <Grid container className={classes.gridPadding}>
                    {Array(NumberOfDays)
                      .fill()
                      .map((_, index) => (
                        <Grid
                          item
                          xs={2}
                          className={classes.textFieldGrid}
                          key={`${index}${i18n.language}`}
                        >
                          <Paper
                            className={classes.paperTextfield}
                            elevation={0}
                          >
                            <h6>
                              {moment
                                .utc(payPeriods[payPeriodIndex].startDate)
                                .locale(i18n.language)
                                .add(index, "d")
                                .format("ddd")}
                            </h6>

                            {moment
                              .utc(payPeriods[payPeriodIndex].startDate)
                              .add(index, "d")
                              .format("MM/DD")}
                            <TextField
                              placeholder='0.00'
                              className={classes.textField}
                              margin='normal'
                              variant='outlined'
                              value={selectedTime[index]}
                              onBlur={(e) => roundNumber(e, index)}
                              onFocus={(e) => {
                                handleSelectedTime(e, index);
                              }}
                              onChange={(e) => handleSelectedTime(e, index)}
                              inputProps={{
                                maxLength: 5,
                                "aria-label": "bare"
                              }}
                            />
                          </Paper>
                        </Grid>
                      ))}
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={Close} color='primary'>
            {t("commonActions.cancel")}
          </Button>
          <Button
            autoFocus
            onClick={handleOnSave}
            color='primary'
            disabled={selectedTime.filter((time) => time !== "").length === 0}
          >
            {t("commonActions.save")}
          </Button>
        </DialogActions>
      </Dialog>
    )
  );
};

BulkEntryComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  Close: PropTypes.func.isRequired,
  payPeriodIndex: PropTypes.number.isRequired,
  userJobs: PropTypes.arrayOf(
    PropTypes.shape({
      operationalUnits: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
      code: PropTypes.string.isRequired,
      name: PropTypes.string
    })
  ).isRequired,
  PayPeriodSelected: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      id: PropTypes.string
    })
  ).isRequired,
  selected: PropTypes.arrayOf(PropTypes.string).isRequired,
  rows: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  setSelected: PropTypes.func.isRequired,
  setJobOffset: PropTypes.func.isRequired,
  NumberOfDays: PropTypes.number.isRequired
};

export default BulkEntryComponent;
