import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import TableCell from "@material-ui/core/TableCell";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

import permissionsList from "../../../utillities/Permissions";

const AllEmployeesTableWrapperComponent = ({
  isItemSelected,
  row,
  handleDropdownButton,
  handleShow,
  selected,
  index,
  classes
}) => {
  const { t } = useTranslation();
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);

  const { userDetails } = useSelector((state) => state);

  const handleMenuClick = (event) => {
    event.stopPropagation();
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (event) => {
    event.stopPropagation();
    setMenuAnchorEl(null);
  };

  return (
    <>
      <TableCell padding='checkbox'>
        <Checkbox checked={isItemSelected} />
      </TableCell>
      <TableCell component='th' scope='row'>
        {row.empID}
      </TableCell>
      <TableCell align='left'>{row.empName}</TableCell>

      {row.roleName === null ||
      row.roleName === undefined ||
      row.roleName === "" ? (
        <TableCell align='left'>-</TableCell>
      ) : (
        <TableCell align='left'>{row.roleName}</TableCell>
      )}

      <TableCell>
        {(userDetails.isSuperAdmin ||
          userDetails.role.permissions.includes(
            permissionsList.AUTHORIZER_ROLE_SETUP.key
          )) && (
          <Button
            id='actions'
            aria-controls='actions-control'
            aria-haspopup='true'
            onClick={handleMenuClick}
            aria-label={index}
            style={{ float: "right" }}
            className={selected.length > 0 ? classes.buttonHidden : null}
          >
            <MoreHorizIcon />
          </Button>
        )}

        <Menu
          id='actions-menu'
          anchorEl={menuAnchorEl}
          keepMounted
          open={Boolean(menuAnchorEl)}
          onClose={handleMenuClose}
          elevation={1}
        >
          <MenuItem
            onClick={(e) => {
              handleMenuClose(e);
              handleDropdownButton(row._id);
              handleShow();
            }}
          >
            {t("authorizerContainer.assign_role_label")}
          </MenuItem>
        </Menu>
      </TableCell>
    </>
  );
};

AllEmployeesTableWrapperComponent.propTypes = {
  isItemSelected: PropTypes.bool.isRequired,
  row: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    empID: PropTypes.number.isRequired,
    empName: PropTypes.string.isRequired,
    roleName: PropTypes.string.isRequired,
    autoFillLunchTime: PropTypes.bool.isRequired
  }).isRequired,
  handleDropdownButton: PropTypes.func.isRequired,
  handleShow: PropTypes.func.isRequired,
  selected: PropTypes.arrayOf(PropTypes.string).isRequired,
  index: PropTypes.number.isRequired,
  classes: PropTypes.shape({
    buttonHidden: PropTypes.string
  }).isRequired
};

function shouldRowRerender(prevProps, nextProps) {
  // rerender component only when shouldComponentRerender string changes from previous props in next props
  if (prevProps.shouldComponentRerender === nextProps.shouldComponentRerender)
    return true;
  return false;
}

export default memo(AllEmployeesTableWrapperComponent, shouldRowRerender);
