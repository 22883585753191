export default {
  VPsuccess: {
    name: "Sync sucess",
    code: "VPsuccess"
  },
  VPerror: {
    name: "Sync error",
    code: "VPerror"
  },
  VPinprogress: {
    name: "Sync in progress",
    code: "VPinprogress"
  }
};
