import {
  FILTER_EMPLOYEES,
  LOAD_EMPLOYEES_SUCCESS,
  SORT_EMPLOYEES,
  UPDATE_EMPLOYEE_ROLE_SUCCESS
} from "../actions/actionTypes";

export default function EmployeesReducer(state = [], action) {
  switch (action.type) {
    case LOAD_EMPLOYEES_SUCCESS:
      return action.offset ? [...state, ...action.employees] : action.employees;
    case UPDATE_EMPLOYEE_ROLE_SUCCESS: {
      const copyEmployees = state.map((employee) => {
        if (action.SelectedEmployees.includes(employee._id)) {
          return {
            ...employee,
            role: {
              _id: action.selectedRole._id,
              roleName: action.selectedRole.roleName
            }
          };
        }
        return employee;
      });
      return copyEmployees;
    }
    case SORT_EMPLOYEES: {
      const copyEmployees = state.slice();
      if (action.sortBy === "name") {
        return [...copyEmployees].sort((a, b) =>
          a.empName.toLowerCase().localeCompare(b.empName.toLowerCase())
        );
      }
      return [...state].sort((a, b) => a.empID - b.empID);
    }
    case FILTER_EMPLOYEES:
      return action.employees;
    default:
      return state;
  }
}
