import { ACTIVITY_LOGS_EQUIPMENT_SUCCESS } from "../actions/actionTypes";

export default function equipmentLogReducer(state = [], action) {
  switch (action.type) {
    case ACTIVITY_LOGS_EQUIPMENT_SUCCESS:
      return action.offset
        ? [...state, ...action.activityLogs]
        : action.activityLogs;
    default:
      return state;
  }
}
