import { useEffect, useState } from "react";

const useInfiniteScroll = (clientHeight, scrollTop, scrollHeight) => {
  // scrollHeight = ENTIRE content + padding (visible or not)
  // offsetHeight = Visible content + padding + border + scrollbar
  // clientHeight = VISIBLE content + padding
  const [isFetching, setIsFetching] = useState(false);

  // Track the component scroll and then set isFetching to true accordingly
  const handleScroll = () => {
    if (scrollTop && scrollHeight) {
      if (scrollHeight - Math.ceil(clientHeight + scrollTop) > 1 || isFetching)
        return;
      if (
        scrollHeight - Math.ceil(clientHeight + scrollTop) <= 1 &&
        !isFetching
      )
        setIsFetching(true);
    }
  };

  useEffect(handleScroll, [scrollTop, scrollHeight]);

  return [isFetching, () => setIsFetching((fetching) => !fetching)];
};

export default useInfiniteScroll;
