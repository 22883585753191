import { makeStyles } from "@material-ui/core/styles";

const tableStyles = makeStyles((theme) => ({
  root: {
    // minWidth: "max-content"
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    backgroundColor: "#e4e9f0",
    borderRadius: "8px"
  },
  table: {
    // maxWidth: 750
  },
  tableWrapper: {
    overflowX: "auto"
  },
  headerCell: {
    padding: theme.spacing(1),
    textTransform: "capitalize",
    border: "1px solid rgba(224, 224, 224, 1)"
  },
  cell: {
    border: "1px solid rgba(224, 224, 224, 1)",
    padding: "0 8px",
    minWidth: "90px",
    width: "max-content"
  },
  colorCell: {
    border: "1px solid rgba(224, 224, 224, 1)",
    backgroundColor: "#ffdb87",
    paddingLeft: "8px"
  },
  emptyCell: {
    textAlign: "center"
  },
  disabledCell: {
    color: "rgba(0, 0, 0, 0.38) !important",
    cursor: "default"
  },
  button: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    borderRadius: "50px"
  },
  fab: {
    width: "max-content"
  },
  actionMenu: {
    padding: 0,
    right: 0
  },
  label: {
    width: "max-content",
    margin: "inherit",
    textTransform: "capitalize"
  },
  invalidValue: {
    color: "#f5494f",
    fontSize: "0.8125rem"
  },
  links: { color: "#4a90e2" },
  textField: {
    fontSize: "0.8125rem"
  },
  caption: {
    display: "flex"
  },
  captionLeft: {
    flex: 1
  },
  captionRight: {
    right: 0,
    position: "sticky"
  },
  newEntryButton: {
    left: "10px",
    position: "sticky"
  },
  note: {
    minWidth: "60px"
  },
  menu: {
    width: "100%",
    maxWidth: 360,
    display: "flex",
    justifyContent: "center"
  },
  menuButton: {
    boxSizing: "border-box",
    width: "100%",
    right: 0
  },
  menuItems: {
    fontSize: "16px",
    fontWeight: 400,
    "&:last-child": {
      marginBottom: "-8px"
    }
  },
  activityLogGrid: {
    padding: "8px"
  },
  paperWidth: {
    minWidth: "300px",
    maxWidth: "300px"
  },
  drawerPaper: {
    height: "100% !important"
  },
  fixedHeader: {
    top: 0,
    right: 0,
    zIndex: 20,
    position: "sticky",
    height: "inherit",
    backgroundColor: "#eff2f6"
  },
  fixedColumn: {
    right: 0,
    zIndex: 10,
    position: "sticky",
    height: "inherit",
    backgroundColor: "#eff2f6"
  },
  dividerStyles: {
    height: "2px"
  },
  blur: {
    opacity: "0.2"
  },
  eqDate: {
    width: "1%"
  },
  eqTime: {
    width: "8%"
  },
  eqCostType: {
    width: "12%"
  },
  eqStoreTime: {
    width: "20%"
  },
  location: {
    width: "10%"
  }
}));

export default tableStyles;
