import { orderBy } from "lodash";

import {
  GET_ASSIGNED_EMPLOYEES,
  GET_PERSONNEL_SUCCESS,
  UPDATE_PERSONNEL_SUCCESS
} from "../actions/actionTypes";

export default function PersonnelReducer(state = [], action) {
  switch (action.type) {
    case GET_PERSONNEL_SUCCESS:
      return orderBy(action.personnel, "empName", "asc");
    case UPDATE_PERSONNEL_SUCCESS:
      return state.filter((per) => !action.unassigned.includes(per._id));
    case GET_ASSIGNED_EMPLOYEES:
      return action.offset
        ? [...state, ...action.assignedEmployees]
        : action.assignedEmployees;
    default:
      return state;
  }
}
