import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import lodash from "lodash";
import PropTypes from "prop-types";

import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";

import TableHeaderWrapper from "../../UI/TableHeaderWrapper";

const useStyles = makeStyles(() => ({
  paper: {
    border: "1px solid rgba(224, 224, 224, 1)"
  },
  root: {
    width: "100%",
    display: "flex",
    backgroundColor: "white",
    overflowX: "hidden"
  },
  tableWrapper: {
    overflowX: "auto"
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "auto",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  height: {
    minHeight: (props) => (props.height ? props.height : "76vh"),
    maxHeight: (props) => (props.height ? props.height : "76vh !important")
  }
}));

const OperationalUnitsTable = (props) => {
  const classes = useStyles(props);
  const { t } = useTranslation();
  const { organizationDetails } = useSelector((state) => state);

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("empID");

  const { selectedJob, selected, setSelected } = props;
  const tableData = selectedJob.operationalUnits;

  const headerdata =
    tableData &&
    tableData.map((i) => {
      return [
        {
          id: i._id,
          numeric: false,
          disablePadding: false,
          label: t("authorizerContainer.code")
        },
        {
          id: i.label,
          numeric: false,
          disablePadding: false,
          label: i.label
        }
      ];
    });

  const headCells = (idx) => {
    return headerdata[idx];
  };
  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  };

  const handleNewJob = (copySelected) => {
    if (!Object.keys(copySelected).includes(selectedJob.code)) {
      copySelected = {
        ...copySelected,
        [selectedJob.code]: [
          ...organizationDetails.OULevelNamesForTimeLogs.map(() => [])
        ]
      };
    }
    return copySelected;
  };

  const handleSelectAllClick = (event, i, idx) => {
    let copySelected = lodash.cloneDeep(selected);
    copySelected = handleNewJob(copySelected);
    const NumberOfItemsSelected =
      selected[selectedJob.code] &&
      selected[selectedJob.code][idx].filter(
        (eachSelect) =>
          i.items.findIndex((item) => item._id === eachSelect) >= 0
      ).length;

    if (event.target.checked) {
      if (NumberOfItemsSelected > 0) copySelected[selectedJob.code][idx] = [];
      else
        copySelected[selectedJob.code][idx] = lodash.cloneDeep(
          i.items.map((item) => item._id)
        );
      setSelected(copySelected);
      return;
    }
    copySelected[selectedJob.code][idx] = [];
    setSelected(copySelected);
  };

  const handleClick = (event, row, idx) => {
    let copySelected = lodash.cloneDeep(selected);
    copySelected = handleNewJob(copySelected);
    const isOuValueAlreadySelected = copySelected[selectedJob.code][
      idx
    ].findIndex((select) => select === row._id);
    // selected job code is not present in selected

    if (isOuValueAlreadySelected === -1) {
      copySelected[selectedJob.code][idx].push(row._id); // to unselect a OU Value
    } else {
      copySelected[selectedJob.code][idx].splice(isOuValueAlreadySelected, 1); // to select OU value
    }
    setSelected(copySelected);
  };

  const isSelected = (id, idx) => {
    if (
      selected[selectedJob.code] &&
      selected[selectedJob.code][idx].find((select) => select === id._id)
    ) {
      return true;
    }
    return false;
  };

  return (
    <div className={classes.root}>
      {tableData &&
        tableData.map(
          (
            i,
            idx // table index
          ) => (
            <TableContainer
              className={`${classes.paper} ${classes.height}`}
              key={i._id}
            >
              <Table
                className={classes.table}
                aria-labelledby='tableTitle'
                size='medium'
                aria-label='enhanced table'
                stickyHeader
              >
                <TableHeaderWrapper
                  classes={classes}
                  numSelected={
                    selected[selectedJob.code] &&
                    selected[selectedJob.code][idx].filter(
                      (eachSelect) =>
                        i.items.findIndex((item) => item._id === eachSelect) >=
                        0
                    ).length
                  }
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={(e) => {
                    handleSelectAllClick(e, i, idx);
                  }}
                  onRequestSort={handleRequestSort}
                  rowCount={i.items.length}
                  headCells={headCells(idx)}
                />
                <TableBody>
                  {i.items.map((row, indx) => {
                    // row index
                    const isItemSelected = isSelected(row, idx);
                    const labelId = `enhanced-table-checkbox-${indx}`;
                    return (
                      <TableRow
                        hover
                        onClick={(event) => {
                          handleClick(event, row, idx);
                        }}
                        role='checkbox'
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row._id}
                        selected={isItemSelected}
                      >
                        <TableCell padding='checkbox'>
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </TableCell>
                        <TableCell component='th' id={labelId} scope='row'>
                          {row.code}
                        </TableCell>
                        <TableCell component='th' id={labelId} scope='row'>
                          {row.name}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )
        )}
    </div>
  );
};
OperationalUnitsTable.propTypes = {
  selectedJob: PropTypes.shape({
    code: PropTypes.string,
    operationalUnits: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired,
  selected: PropTypes.shape().isRequired,
  setSelected: PropTypes.func.isRequired
};

export default OperationalUnitsTable;
