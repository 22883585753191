import { Tooltip, withStyles } from "@material-ui/core";

const HoverTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    boxShadow: theme.shadows[4],
    fontSize: 12,
    fontWeight: "normal",
    margin: 0,
    padding: "10px"
  }
}))(Tooltip);

export default HoverTooltip;
