import * as idb from "idb";

const DBName = "Authentication-1";
const DBVersion = 1;
async function DBCreation() {
  const dbPromise = idb.openDB(DBName, DBVersion, {
    upgrade(db) {
      db.createObjectStore("keyval");
    }
  });
  return dbPromise;
}

const idbKeyVal = {
  async get(key) {
    return (await DBCreation()).get("keyval", key);
  },
  async adder(key, val) {
    return (await DBCreation()).add("keyval", val, key);
  },
  async set(key, val) {
    return (await DBCreation()).put("keyval", val, key);
  },
  async delete(key) {
    return (await DBCreation()).delete("keyval", key);
  },
  async clear() {
    return (await DBCreation()).clear("keyval");
  },
  async keys() {
    return (await DBCreation()).getAllKeys("keyval");
  }
};

export default idbKeyVal;
