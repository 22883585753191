import DBService from "../../services/DBService";
import { LOGIN, LOGIN_FAILED, LOGOUT } from "../actions/actionTypes";

export default function LoginStatusReducer(state = false, action) {
  switch (action.type) {
    case LOGIN:
      return true;
    case LOGOUT: {
      DBService.delete("jwt");
      DBService.delete("refreshToken");
      DBService.delete("currentStore");
      return false;
    }
    case LOGIN_FAILED:
      return false;
    default:
      return state;
  }
}
