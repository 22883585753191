import { LOAD_LABOUR_TIME_REPORTS_PREVIEW } from "../actions/actionTypes";

export default function LabourTimeReportsPreviewReducer(state = [], action) {
  switch (action.type) {
    case LOAD_LABOUR_TIME_REPORTS_PREVIEW:
      return action.offset
        ? [...state, ...action.labourTimeReports]
        : action.labourTimeReports;
    default:
      return state;
  }
}
