import i18n from "i18next";

import DBService from "./services/DBService";
import axios from "./axios-jwt";

axios.interceptors.request.use(
  async (config) => {
    const state = await DBService.get("currentStore");
    const token = await DBService.get("jwt");
    const { selectedCompany } = state || {};
    if (!token && !config.url.includes("auth/login")) {
      throw new Error("Something went wrong with cookies, please refresh.");
    }
    if (selectedCompany && selectedCompany.code)
      config.headers.company = selectedCompany.code;
    if (i18n.language) config.headers["Accept-Language"] = `${i18n.language}`;
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

const generateNewToken = (refreshToken) => {
  return axios.post("auth/refresh", {
    refreshToken
  });
};

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const token = await DBService.get("jwt");
    const refreshToken = await DBService.get("refreshToken");
    if (error.response === undefined) {
      return Promise.reject(error);
    }
    const {
      config,
      response: { status }
    } = error;
    const originalRequest = config;
    if (status === 401 && token !== undefined) {
      return generateNewToken(refreshToken)
        .then((response) => {
          if (response.status === 200) {
            DBService.set("jwt", response.data.jwt);
            axios.defaults.headers.common.Authorization = `Bearer ${token}`;
            return axios(originalRequest);
          }
        })
        .catch(() => {
          return Promise.reject(error);
        });
    }
    return Promise.reject(error);
  }
);
