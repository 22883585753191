import axios from "../../axios-jwt";

import { GET_NOTIFICATIONS } from "./actionTypes";
import { apiFailed } from "./ApiStatusActions";

async function getNotifications() {
  const resp = axios.get("/notifications");
  return resp;
}

export function loadNotificationsSuccess(notifications) {
  return { type: GET_NOTIFICATIONS, notifications };
}

export function loadNotifications() {
  return (dispatch) => {
    return getNotifications().then((res) => {
      if (res.status === 200) {
        dispatch(loadNotificationsSuccess(res.data));
      }
    });
  };
}

export function updateNotificationsAsRead(notificationsRead) {
  return axios.put("/notifications_mark_as_read", notificationsRead);
}

export function updateNotificationsStatus(notificationsRead) {
  return (dispatch) => {
    return updateNotificationsAsRead(notificationsRead)
      .then((res) => {
        if (res.status === 200) {
          dispatch(loadNotifications());
        }
      })
      .catch((err) => apiFailed(err));
  };
}
