import React, { useState } from "react";
import PropTypes from "prop-types";

import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import NoteIcon from "@material-ui/icons/Note";

import CustomTooltip from "../UI/CustomTooltip";

const useStyles = makeStyles(() => ({
  customWidth: {
    maxWidth: 200,
    maxHeight: 200,
    wordWrap: "break-word",
    overflow: "auto"
  }
}));

const NotesToolTip = ({ isOpen, note }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(isOpen);
  return (
    <CustomTooltip
      content={note}
      contentContainerClass={classes.customWidth}
      open={open}
      placement='bottom'
      onClose={() => setOpen(false)}
    >
      <IconButton onClick={() => setOpen(!open)} size='small'>
        <NoteIcon color='secondary' />
      </IconButton>
    </CustomTooltip>
  );
};

NotesToolTip.propTypes = {
  note: PropTypes.string,
  isOpen: PropTypes.bool
};

NotesToolTip.defaultProps = {
  note: "",
  isOpen: false
};

export default NotesToolTip;
