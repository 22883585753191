import { LOAD_FOREMEN } from "../actions/actionTypes";

export default function ForemenReducer(state = [], action) {
  switch (action.type) {
    case LOAD_FOREMEN: {
      return action.foremen;
    }
    default:
      return state;
  }
}
