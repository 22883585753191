/* eslint-disable no-console */
import { applyMiddleware, compose, createStore } from "redux";
import reduxImmutableStateInvariant from "redux-immutable-state-invariant";
import thunk from "redux-thunk";

import DBService from "../services/DBService";

import rootReducer from "./reducers/rootReducer";

const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose; // add support redux dev tools

async function initializeStore() {
  const persistedState = await DBService.get("currentStore");

  const middleware =
    process.env.NODE_ENV !== "production"
      ? [reduxImmutableStateInvariant(), thunk]
      : [thunk];

  const createdStore = createStore(
    rootReducer,
    persistedState,
    composeEnhancers(applyMiddleware(...middleware))
  );

  createdStore.subscribe(() => {
    DBService.set("currentStore", createdStore.getState());
    //?Comment below line to un-see current store state
    process.env.NODE_ENV !== "production" &&
      console.log("%cCurrentStore:", "color:green", createdStore.getState());
  });

  return createdStore;
}

const getStore = initializeStore;

export default getStore;
