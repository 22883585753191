import axios from "../../axios-jwt";

import {
  LOAD_EMPLOYEES_REPORTS_PREVIEW,
  LOAD_EQUIPMENTS_REPORTS_PREVIEW
} from "./actionTypes";
import * as ApiStatusActions from "./ApiStatusActions";

async function getEmployeeReports(query, offset, limit) {
  const resp = axios.get(
    `authoriser/employeereports?${query}&offset=${offset}&limit=${limit}`
  );
  return resp;
}

export function loadEmployeeReportsPreviewSuccess(employeeReports, offset) {
  return { type: LOAD_EMPLOYEES_REPORTS_PREVIEW, employeeReports, offset };
}

export function loadEmployeeReportsPreview(loadParams, offset, limit) {
  return async (dispatch) => {
    dispatch(ApiStatusActions.apiStartLoader("loadEmployeeReportsPreview"));
    try {
      const res = await getEmployeeReports(loadParams, offset, limit);
      if (res.status === 200) {
        dispatch(loadEmployeeReportsPreviewSuccess(res.data, offset));
        dispatch(ApiStatusActions.apiStopLoader("loadEmployeeReportsPreview"));
      }
    } catch (error) {
      console.log(dispatch(ApiStatusActions.apiFailed(error)));
      dispatch(ApiStatusActions.apiStopLoader("loadEmployeeReportsPreview"));
    }
  };
}

async function getEquipmentReports(query, offset, limit) {
  const resp = axios.get(
    `authoriser/equipmentreports?${query}&offset=${offset}&limit=${limit}`
  );
  return resp;
}

export function loadEquipmentReportsPreviewSuccess(equipmentReports, offset) {
  return { type: LOAD_EQUIPMENTS_REPORTS_PREVIEW, equipmentReports, offset };
}

export function loadEquipmentReportsPreview(loadParams, offset, limit) {
  return async (dispatch) => {
    dispatch(ApiStatusActions.apiStartLoader("loadEquipmentReportsPreview"));
    try {
      const res = await getEquipmentReports(loadParams, offset, limit);
      if (res.status === 200) {
        dispatch(loadEquipmentReportsPreviewSuccess(res.data, offset));
        dispatch(ApiStatusActions.apiStopLoader("loadEquipmentReportsPreview"));
      }
    } catch (error) {
      console.log(dispatch(ApiStatusActions.apiFailed(error)));
      dispatch(ApiStatusActions.apiStopLoader("loadEquipmentReportsPreview"));
    }
  };
}
