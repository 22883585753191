import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";

import { updatePersonnelTo } from "../../../redux/actions/RolesActions";

const useStyles = makeStyles(() => ({
  gridRow: {
    justifyContent: "space-between",
    alignItems: "center",
    padding: "4px"
  },
  deleteButton: {
    color: "#c4302f",
    align: "center"
  },
  gridCaption: {
    height: "50%",
    display: "flex"
  }
}));

// unassigning people who are reporting to crew members
const UnassignComponent = (props) => {
  const { t } = useTranslation();
  const { roles } = useSelector((state) => state);

  const { unassignShow, handleUnassignClose, employee, roleId } = props;
  const [unassignPersonnel, setUnassignPersonnel] = useState([]);

  const dispatch = useDispatch();
  const classes = useStyles();

  function handleOnUnassign(id) {
    const idx = unassignPersonnel.map((per) => per === id).indexOf(true);
    const copyPer = [...unassignPersonnel];
    if (idx === -1) {
      copyPer.push(id);
      setUnassignPersonnel(copyPer);
    } else {
      copyPer.splice(idx, 1);
      setUnassignPersonnel(copyPer);
    }
  }

  const getRoleNameById = (id) => {
    return (roles.find((role) => role._id === id) || {}).roleName || "";
  };

  function handleOnSaveUnassign() {
    dispatch(updatePersonnelTo(employee._id, unassignPersonnel, roleId));
  }

  return (
    <Dialog
      open={unassignShow}
      onClose={handleUnassignClose}
      maxWidth='xs'
      variant='outlined'
      color='primary'
      fullWidth
    >
      <DialogTitle>{employee.empName}</DialogTitle>
      <DialogContent>
        <small className='text-muted'>{t("unAssignComp.assigned_to")}</small>
        {employee.personnelTo.map((emp) => (
          <Grid container className={classes.gridRow} key={emp._id}>
            <Grid item xs={6}>
              <Grid item>
                <Typography variant='subtitle1'>{emp.empName}</Typography>
              </Grid>
              <Grid item>
                <Typography variant='caption' className={classes.gridCaption}>
                  {getRoleNameById(emp.role)}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={2}>
              {" "}
              {unassignPersonnel.includes(emp._id) ? (
                <IconButton
                  aria-label='delete'
                  className={classes.deleteButton}
                  onClick={() => {
                    handleOnUnassign(emp._id);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              ) : (
                <IconButton
                  aria-label='delete'
                  onClick={() => {
                    handleOnUnassign(emp._id);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </Grid>
          </Grid>
        ))}
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          color='primary'
          onClick={handleUnassignClose}
        >
          {t("commonActions.close")}
        </Button>
        <Button
          variant='contained'
          color='primary'
          disabled={!unassignPersonnel.length}
          onClick={() => {
            handleOnSaveUnassign();
            handleUnassignClose();
          }}
        >
          {t("commonActions.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

UnassignComponent.propTypes = {
  unassignShow: PropTypes.bool.isRequired,
  handleUnassignClose: PropTypes.func.isRequired,

  employee: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    empName: PropTypes.string.isRequired,
    personnelTo: PropTypes.arrayOf(PropTypes.shape({})).isRequired
  }).isRequired,
  roleId: PropTypes.string.isRequired
};

export default UnassignComponent;
