import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import lodash from "lodash";
import PropTypes from "prop-types";

import { Divider, LinearProgress } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import makeStyles from "@material-ui/core/styles/makeStyles";

import * as CompaniesActions from "../../../redux/actions/CompaniesActions";
import * as EmployeeActions from "../../../redux/actions/EmployeeActions";

import SelectJobComponent from "./SelectJobComponent";

const useStyles = makeStyles(() => ({
  dialogActions: {
    float: "right",
    display: "flex"
  },
  buttonSpacing: {
    marginRight: "12px"
  },
  selectedEmployee: {
    marginLeft: "10%"
  }
}));

const ManageJobsComponent = (props) => {
  const { show, handleClose, SelectedEmployee, roleEmployees } = props;
  const { apiStatus, companyJobs, jobsSearchResults } = useSelector(
    (state) => state
  );
  const dispatch = useDispatch();

  const classes = useStyles();
  const { t } = useTranslation();
  const [selected, setSelected] = useState({}); // array of jobs with selected operational unit value
  const jobsBeforeEdited = useRef({});
  const unassignJob = true;
  const loading =
    (apiStatus || {}).isLoading &&
    apiStatus.reducer.find((reducerText) => reducerText.includes("Jobs"));

  const onCompanyJobsChange = () => {
    let copySelected = lodash.cloneDeep(selected);
    [...companyJobs, ...jobsSearchResults].forEach((job) => {
      if (job.isAssigned) {
        if (!selected[job.code]) {
          const jobPreSelectedOUValues = job.operationalUnits.map((ou) => {
            return ou.items
              .filter((item) => item.selected)
              .map((item) => item._id);
          });
          jobsBeforeEdited.current = {
            ...jobsBeforeEdited.current,
            [job.code]: jobPreSelectedOUValues
          };
          copySelected = {
            ...copySelected,
            [job.code]: jobPreSelectedOUValues
          };
        }
      }
    });
    setSelected(copySelected);
  };
  useEffect(onCompanyJobsChange, [companyJobs, jobsSearchResults]);

  const handleOnSave = () => {
    const payloadSelectedJobs = Object.keys(selected).filter((jobCode) => {
      if (Object.keys(jobsBeforeEdited.current).includes(jobCode)) {
        return !!(
          jobsBeforeEdited.current[jobCode].filter(
            (ou, idx) =>
              !ou.every((code) => selected[jobCode][idx].includes(code))
          ).length ||
          selected[jobCode].filter(
            (ou, idx) =>
              !ou.every((code) =>
                jobsBeforeEdited.current[jobCode][idx].includes(code)
              )
          ).length
        );
      }
      return !!selected[jobCode].filter((ou) => ou.length).length;
    });
    dispatch(
      EmployeeActions.assignJobsToEmployees(
        SelectedEmployee,
        lodash.pick(selected, [...payloadSelectedJobs]),
        true
      )
    );
    dispatch(CompaniesActions.clearJobs());
    handleClose();
  };

  return (
    <>
      <Dialog open={show} fullWidth maxWidth='xl'>
        <DialogTitle id='max-width-dialog-title'>
          {t("ManageJobsComp.Manage_jobs")}
          <small className='text-muted' style={{ marginLeft: "20px" }}>
            {`${t("ManageJobsComp.selected_employee")}: ${roleEmployees
              .filter((emp) => SelectedEmployee.includes(emp._id))
              .map((e) => e.empName)
              .join(",")}`}
          </small>
          <div className={classes.dialogActions}>
            <Button
              onClick={() => {
                handleClose(false);
                dispatch(CompaniesActions.clearJobs());
              }}
              className={classes.buttonSpacing}
              variant='outlined'
              color='secondary'
            >
              {t("commonActions.cancel")}
            </Button>
            <Button
              onClick={() => {
                handleOnSave();
              }}
              variant='contained'
              color='secondary'
            >
              {t("commonActions.save")}
            </Button>
          </div>
        </DialogTitle>
        {loading && <LinearProgress />}
        <Divider />
        <DialogContent>
          <div style={{ display: "flex" }}>
            <SelectJobComponent
              selected={selected}
              setSelected={setSelected}
              unassignJob={unassignJob}
              empID={SelectedEmployee && SelectedEmployee[0]}
            />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

ManageJobsComponent.propTypes = {
  show: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  SelectedEmployee: PropTypes.arrayOf(PropTypes.string).isRequired,
  roleEmployees: PropTypes.arrayOf(PropTypes.shape()).isRequired
};

export default ManageJobsComponent;
