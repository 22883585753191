import lodash from "lodash";

import {
  CHANGE_SPECIFIC_ROLE_ROLESEARCH,
  CLEAR_SEARCH_RESULTS,
  EDIT_ASSIGN_ALL_JOBS,
  EDIT_AUTO_LUNCH,
  EDIT_REGISTER_FINGER_PRINT,
  SEARCH_ROLE_EMPLOYEES,
  UNASSIGN_CREW_PERSONNEL_TO_ROLESEARCH,
  UPDATE_CREW_PERSONNEL_TO_ROLESEARCH
} from "../actions/actionTypes";

export default function RoleSearchReducer(state = [], action) {
  switch (action.type) {
    case SEARCH_ROLE_EMPLOYEES: {
      return action.offset
        ? [...state, ...action.employeeSearchResults]
        : action.employeeSearchResults;
    }
    case CHANGE_SPECIFIC_ROLE_ROLESEARCH: {
      let copyEmployees = lodash.cloneDeep(state);
      copyEmployees = copyEmployees.filter((emp) =>
        emp._id === action.SelectedEmployee[0]
          ? emp.role._id === action.selectedRole._id
          : true
      );
      return copyEmployees;
    }
    case UPDATE_CREW_PERSONNEL_TO_ROLESEARCH: {
      const copyEmployees = lodash.cloneDeep(state);
      copyEmployees.forEach((emp, idx) => {
        const SelectedEmployeesIndex = action.SelectedEmployees.findIndex(
          (id) => id === emp._id
        );
        if (SelectedEmployeesIndex >= 0) {
          copyEmployees[idx].personnelTo.push(...action.personnelToArray);
          copyEmployees[idx].personnelTo = lodash.uniqBy(
            copyEmployees[idx].personnelTo,
            "_id"
          );
        }
      });
      return copyEmployees;
    }
    case UNASSIGN_CREW_PERSONNEL_TO_ROLESEARCH: {
      const copyEmployees = lodash.cloneDeep(state);
      copyEmployees.forEach((emp, idx) => {
        if (emp._id === action.id) {
          copyEmployees[idx].personnelTo = copyEmployees[
            idx
          ].personnelTo.filter((per) => !action.unassigned.includes(per._id));
        }
      });
      return copyEmployees;
    }
    case EDIT_AUTO_LUNCH: {
      const copyEmployees = lodash.cloneDeep(state);
      if (copyEmployees.length > 0) {
        copyEmployees.forEach((emp, idx) => {
          if (emp._id === action.empId) {
            copyEmployees[idx].autoFillLunchTime = action.autoLunchBoolean;
          }
        });
      }
      return copyEmployees;
    }
    case EDIT_REGISTER_FINGER_PRINT: {
      const copyEmployees = lodash.cloneDeep(state);
      if (copyEmployees.length > 0) {
        copyEmployees.forEach((emp, idx) => {
          if (emp._id === action.empId) {
            copyEmployees[idx].canRegisterFingerPrint =
              action.fingerPrintBoolean;
          }
        });
      }
      return copyEmployees;
    }
    case EDIT_ASSIGN_ALL_JOBS: {
      const copyEmployees = lodash.cloneDeep(state);
      if (copyEmployees.length > 0) {
        copyEmployees.forEach((emp, idx) => {
          if (emp._id === action.empId) {
            copyEmployees[idx].hasAllJobs = action.assignAllJobsBoolean;
          }
        });
      }
      return copyEmployees;
    }
    case CLEAR_SEARCH_RESULTS: {
      // Clear earch results from state so that upon searching the next time, previous results won't show up
      return [];
    }
    default:
      return state;
  }
}
