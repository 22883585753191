import axios from "../../axios-jwt";
import i18n from "../../i18n";

import {
  FILTER_EMPLOYEES,
  LOAD_EMPLOYEES_BY_JOB,
  LOAD_EMPLOYEES_SUCCESS,
  UPDATE_EMPLOYEE_ROLE_SUCCESS
} from "./actionTypes";
import {
  apiFailed,
  apiStartLoader,
  apiStopLoader,
  apiSuccess
} from "./ApiStatusActions";
import loadCount from "./CountActions";
import * as RoleEmployeesActions from "./RoleEmployeesActions";
import * as RoleSearchActions from "./RoleSearchActions";

async function getEmployees(
  userDetails,
  selectedCompany,
  offset,
  limit,
  orderBy,
  order
) {
  if (selectedCompany.code) {
    return axios.get(
      `employees?companyCode=${selectedCompany.code}&offset=${offset}&limit=${limit}&orderBy=${orderBy}-${order}`
    );
  }
  return axios.get(
    `employees?companyCode=${userDetails.companyCode}&offset=${offset}&limit=${limit}&orderBy=${orderBy}-${order}`
  );
}

async function getEmployeesByJobs(offset, limit) {
  return axios.get(
    `employees?reportingByJobs=true&orderBy=empID-asc&offset=${offset}&limit=${limit}`
  );
}

export function loadEmployeesSuccess(employees, offset) {
  return { type: LOAD_EMPLOYEES_SUCCESS, employees, offset };
}

export function loadEmployees(
  offset,
  limit,
  orderBy = "empName",
  order = "asc"
) {
  return (dispatch, getState) => {
    dispatch(apiStartLoader("allEmployees"));
    return getEmployees(
      getState().userDetails,
      getState().selectedCompany,
      offset,
      limit,
      orderBy,
      order
    )
      .then((obj) => {
        dispatch(loadCount("employees", obj.data.count));
        dispatch(loadEmployeesSuccess(obj.data.employees, offset));
        dispatch(apiStopLoader("allEmployees"));
      })
      .catch((error) => {
        dispatch(apiFailed(error));
        dispatch(apiStopLoader("allEmployees"));
      });
  };
}

export function filterEmployees(employees) {
  return { type: FILTER_EMPLOYEES, employees };
}

export function updateEmployeesRoleSuccess(SelectedEmployees, selectedRole) {
  return {
    type: UPDATE_EMPLOYEE_ROLE_SUCCESS,
    SelectedEmployees,
    selectedRole
  };
}

export function updateEmployeesRole(SelectedEmployees, selectedRole) {
  return (dispatch) => {
    return dispatch(
      updateEmployeesRoleSuccess(SelectedEmployees, selectedRole)
    );
  };
}

async function updatePersonnel(SelectedEmployees, selectedIds) {
  const data = selectedIds.map((eachId) => {
    return { _id: eachId, personnel: SelectedEmployees };
  });

  return axios({
    method: "PUT",
    url: "employees",
    data
  });
}

export function updateEmployeesPersonnel(
  SelectedEmployees,
  selectedIds,
  roleId
) {
  return (dispatch) => {
    return updatePersonnel(SelectedEmployees, selectedIds)
      .then((res) => {
        dispatch(apiStartLoader(roleId));
        if (res.status === 200) {
          dispatch(
            RoleEmployeesActions.UpdateCrewPersonnelTo(
              res.data,
              SelectedEmployees,
              roleId
            )
          );
          dispatch(
            RoleSearchActions.UpdateCrewPersonnelTo(
              res.data,
              SelectedEmployees,
              roleId
            )
          );
          dispatch(apiStopLoader(roleId));
        }
        return undefined;
      })
      .catch((err) => {
        dispatch(apiStopLoader(roleId));
        dispatch(apiFailed(err));
      });
  };
}

async function assignEmployees(
  selectedEmployees,
  selectedJobsOU,
  userDetails,
  selectedCompany,
  organizationDetails
) {
  // remove job with empty OperationalUnit values
  const jobs = Object.keys(selectedJobsOU).map((selectedJob) => ({
    code: selectedJob,
    companyCode: selectedCompany.code
      ? selectedCompany.code
      : userDetails.companyCode,
    operationalUnits: selectedJobsOU[selectedJob].map((items, idx) => ({
      name: organizationDetails.OULevelNamesForTimeLogs[idx].name,
      items
    }))
  }));
  const payload = {
    employees: selectedEmployees,
    jobs
  };
  return axios({
    method: "PUT",
    url: "employeejobs",
    data: payload
  });
}

export function assignJobsToEmployees(
  selectedEmployees,
  selectedOU,
  fromManageJobSection = false
) {
  return (dispatch, getState) => {
    dispatch(apiStartLoader("activeJobs"));
    assignEmployees(
      selectedEmployees,
      selectedOU,
      getState().userDetails,
      getState().selectedCompany,
      getState().organizationDetails
    )
      .then((emp) => {
        if (emp.status === 200) {
          fromManageJobSection
            ? dispatch(apiSuccess(i18n.t("assignJob.job_managed_success")))
            : dispatch(apiSuccess(i18n.t("assignJob.job_assigned_success")));
          dispatch(apiStopLoader("activeJobs"));
        }
        return undefined;
      })
      .catch((err) => {
        apiStopLoader("activeJobs");
        apiFailed(err);
      });
  };
}

export function loadEmployeesByJobSuccess(employees, offset) {
  return { type: LOAD_EMPLOYEES_BY_JOB, employees, offset };
}

export function getEmployeeByJobs(offset, limit) {
  return (dispatch) => {
    dispatch(apiStartLoader("employeeByJobs"));
    return getEmployeesByJobs(offset, limit)
      .then((obj) => {
        if (obj.status === 200) {
          dispatch(loadEmployeesByJobSuccess(obj.data.employees, offset));
          dispatch(loadCount("employeeByJobs", obj.data.count));
          dispatch(apiStopLoader("employeeByJobs"));
        }
      })
      .catch((err) => {
        if (err) {
          dispatch(apiFailed(err));
          dispatch(apiStopLoader("employeeByJobs"));
        }
      });
  };
}
