import { CLEAR_SEARCH_RESULTS, SEARCH_PERSONNEL } from "../actions/actionTypes";

export default function PersonnelSearchReducer(state = [], action) {
  switch (action.type) {
    case SEARCH_PERSONNEL: {
      return action.personnelSearchResults;
    }
    case CLEAR_SEARCH_RESULTS:
      // Clear search results from state so that upon searching the next time, previous results won't show up
      if (
        action.actionType === "personnelSearchResults" &&
        action.clear &&
        state.length
      )
        return [];
      else return state;
    default:
      return state;
  }
}
