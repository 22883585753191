import axios from "../../axios-jwt";
import i18n from "../../i18n";

import { GET_PAYPERIODS_SUCCESS } from "./actionTypes";
import {
  apiFailed,
  apiStartLoader,
  apiStopLoader,
  customFailedMessage
} from "./ApiStatusActions";

async function getPayPeriods(offset, limit, type) {
  const resp = axios.get(
    `/payperiods?type=${type}&offset=${offset}&limit=${limit}`
  );
  return resp;
}

export function loadPayPeriodsSuccess(payPeriods, offset, limit) {
  return { type: GET_PAYPERIODS_SUCCESS, payPeriods, offset, limit };
}

export function loadPayPeriods(offset = 0, limit = 10, type) {
  return (dispatch, getState) => {
    dispatch(apiStartLoader("PayPeriods"));
    return getPayPeriods(offset, limit, type)
      .then((obj) => {
        if (obj.status === 200) {
          if (
            [...getState().payPeriods, ...obj.data].filter(
              (payPeriod) => payPeriod.type === type
            ).length === 0
          ) {
            dispatch(customFailedMessage(i18n.t("payroll.no_pay_period")));
          }
          dispatch(loadPayPeriodsSuccess(obj.data, offset, limit));
        }
        dispatch(apiStopLoader("PayPeriods"));
      })
      .catch((err) => {
        dispatch(apiFailed(err));
        dispatch(apiStopLoader("PayPeriods"));
      });
  };
}
