import {
  GET_FILTER,
  OVERRIDE_FILTER,
  RESET_FILTER,
  SET_FILTER
} from "../actions/actionTypes";

// Define initial state
const initialState = {
  dateFilter: { startDate: null, endDate: null, label: "", isPayPeriod: true },
  roleFilter: [],
  jobFilter: [],
  crewOfFilter: [],
  crewFilter: [],
  allCrewFilter: [],
  hoursTypeFilter: [],
  ouFilter: [],
  showOnlyUntrustedHours: false,
  minMaxHours: { minHours: "", maxHours: "" }
};

const TimeEntryReportFilterReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FILTER:
      return {
        ...state,
        [action.filterType]: action.filterValue
      };
    case OVERRIDE_FILTER:
      return {
        ...state,
        ...action.filterOverRideState
      };
    case GET_FILTER:
      return state[action.filterType];
    case RESET_FILTER:
      return initialState;
    default:
      return state;
  }
};

export default TimeEntryReportFilterReducer;
