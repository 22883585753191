import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { get } from "lodash";
import PropTypes from "prop-types";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";

import tableStyles from "../../../styles/components/DetailsTableStyles";

const useStyles = makeStyles({
  cell: {
    padding: "8px !important"
  }
});

const PreviewReportsDetailsComponent = ({ details }) => {
  const classes = tableStyles();
  const localClasses = useStyles();
  const { t } = useTranslation();
  const [order, setOrder] = useState({ totalHours: "asc" });
  const [sortBy, setSortBy] = useState("empID");

  const headers = [
    {
      id: "empID",
      type: "string",
      disablePadding: true,
      label: t("authorizerContainer.emp_id"),
      sortable: true
    },
    {
      id: "empName",
      type: "string",
      disablePadding: true,
      label: t("authorizerContainer.name"),
      sortable: true
    },
    {
      id: "role.roleName",
      type: "string",
      disablePadding: true,
      label: t("authorizerContainer.role"),
      sortable: true
    }
  ];

  const handleSort = (event, sort) => {
    if (event) {
      setOrder((o) => ({
        ...o,
        [sort]: order[sort] === "asc" ? "desc" : "asc"
      }));
      setSortBy(sort);
    }
  };

  const sortedBreakDown = useMemo(() => {
    return [...details].sort((a, b) => {
      const aValue = get(a, sortBy);
      const bValue = get(b, sortBy);
      if (
        typeof aValue === "string" &&
        typeof aValue === "string" &&
        sortBy !== "empID"
      ) {
        return (
          aValue.localeCompare(bValue) * (order[sortBy] === "asc" ? 1 : -1)
        );
      } else {
        return (
          (+aValue > +bValue ? 1 : -1) * (order[sortBy] === "asc" ? 1 : -1)
        );
      }
    });
  }, [details, sortBy, order]);

  return (
    <Paper className={classes.paper}>
      <Table
        className={classes.table}
        size='small'
        aria-label='details-table'
        padding='none'
      >
        <TableHead>
          <TableRow>
            {headers.map((headCell, index) => (
              <TableCell
                key={index}
                padding={headCell.disablePadding ? "none" : "default"}
                className={clsx(classes.headerCell, localClasses.cell)}
              >
                {headCell.sortable ? (
                  <TableSortLabel
                    className={classes.sortHeader}
                    active={sortBy === headCell.id}
                    direction={sortBy === headCell.id ? order[sortBy] : "asc"}
                    onClick={(event) => handleSort(event, headCell.id)}
                  >
                    {headCell && headCell.label ? headCell.label : headCell.id}
                  </TableSortLabel>
                ) : (
                  headCell && headCell.label
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {details.length ? (
            sortedBreakDown.map((row, index) => (
              <TableRow hover tabIndex={-1} key={row.id || index}>
                {headers.map((header) => (
                  <TableCell
                    className={clsx(classes.cell, localClasses.cell)}
                    key={header.id}
                  >
                    {
                      {
                        string: get(row, header.id) || "-"
                      }[header.type]
                    }
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow key='empty-row'>
              <TableCell
                colSpan={headers.length + 1}
                className={clsx(classes.emptyCell, localClasses.cell)}
                key='empty-cell'
              >
                {t("commonActions.no_records")}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Paper>
  );
};

PreviewReportsDetailsComponent.propTypes = {
  details: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      empID: PropTypes.string,
      empName: PropTypes.string,
      role: PropTypes.string
    })
  ).isRequired
};

export default PreviewReportsDetailsComponent;
