import { GET_NOTIFICATIONS } from "../actions/actionTypes";

export default function NotificationsReducer(state = [], action) {
  switch (action.type) {
    case GET_NOTIFICATIONS: {
      return action.notifications;
    }
    default:
      return state;
  }
}
