import axios from "../../axios-jwt";
import i18n from "../../i18n";

import {
  CLEAR_SEARCH_RESULTS,
  SEACRH_TIME_ENTRY_EMPLOYEES
} from "./actionTypes";
import {
  apiStartLoader,
  apiStopLoader,
  customFailedMessage
} from "./ApiStatusActions";

async function searchTimeEntryEmployee(param) {
  const resp = axios.get(
    `/employees?timeEntryCrew=true&search=${param}&orderBy=empID-asc`
  );
  return resp;
}

export function searchTimeEntryEmployeeResults(timeEntryEmpSearchResults) {
  return { type: SEACRH_TIME_ENTRY_EMPLOYEES, timeEntryEmpSearchResults };
}

export function searchTimeEntryEmployeeWithParam(queryParam) {
  return (dispatch) => {
    dispatch(apiStartLoader("timeEntryEmpSearchResults"));
    searchTimeEntryEmployee(queryParam)
      .then((res) => {
        if (res.status === 200) {
          dispatch(searchTimeEntryEmployeeResults(res.data));
          dispatch(apiStopLoader("timeEntryEmpSearchResults"));
        }
        return undefined;
      })
      .catch((error) => {
        if (error) dispatch(customFailedMessage(i18n.t("invalid_search_term")));
        dispatch(apiStopLoader("timeEntryEmpSearchResults"));
      });
  };
}

export function clearTimeEntryEmployeeResults(actionType, clear) {
  return { type: CLEAR_SEARCH_RESULTS, actionType, clear };
}
