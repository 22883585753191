import axios from "../../axios-jwt";
import i18n from "../../i18n";

import { CLEAR_SEARCH_RESULTS, SEARCH_CREW } from "./actionTypes";
import {
  apiStartLoader,
  apiStopLoader,
  customFailedMessage
} from "./ApiStatusActions";

async function searchCrew(param) {
  const resp = axios.get(`crew?search=${param}&orderBy=empID-asc`);
  return resp;
}

export function searchCrewResults(crewSearchResults) {
  return { type: SEARCH_CREW, crewSearchResults };
}

export function searchCrewWithParam(queryParam) {
  return (dispatch) => {
    dispatch(apiStartLoader("crewSearchResults"));
    searchCrew(queryParam)
      .then((res) => {
        if (res.status === 200) {
          dispatch(searchCrewResults(res.data));
          dispatch(apiStopLoader("crewSearchResults"));
        }
        return undefined;
      })
      .catch((error) => {
        if (error) dispatch(customFailedMessage(i18n.t("invalid_search_term")));
        dispatch(apiStopLoader("crewSearchResults"));
      });
  };
}

export function clearCrewResults(actionType, clear) {
  return { type: CLEAR_SEARCH_RESULTS, actionType, clear };
}
