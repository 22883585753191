import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  cloneDeep,
  compact,
  debounce,
  differenceWith,
  findIndex
} from "lodash";
import PropTypes from "prop-types";

import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import CheckIcon from "@material-ui/icons/Check";
import Autocomplete from "@material-ui/lab/Autocomplete";

import useInfiniteScroll from "../../custom-hooks/useInfiniteScroll";
import {
  regFirstAndLastNameChar,
  regFirstName,
  regLastName
} from "../../utillities/regexConstants";

const useStyles = makeStyles((theme) => ({
  container: {
    width: 360,
    margin: "0 auto"
  },
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2)
    },
    marginTop: -theme.spacing(1) + 2,
    marginBottom: theme.spacing(2)
  },
  formControl: {
    margin: "0px 8px 0px 8px",
    width: 350,
    padding: "0px 20px 0px 20px"
  },
  noLabel: {
    marginTop: theme.spacing(2)
  },
  fab: {
    margin: theme.spacing(2),
    width: "100%",
    maxWidth: "max-content"
  },
  listItemPadding: {
    paddingTop: 0,
    paddingBottom: "1px",
    "&:focus": {
      outline: "none"
    }
  },
  dropDownLabel: {
    textTransform: "capitalize"
  },
  renderedSelectedStyle: {
    display: "initial",
    textOverflow: "ellipsis",
    maxWidth: "90%",
    whiteSpace: "nowrap",
    overflow: "hidden"
  },
  placeholder: {
    color: "rgba(0, 0, 0, 0.40)"
  },
  paper: {
    maxHeight: "500px"
  },
  label: {
    marginBottom: "4px"
  },
  input: { width: "88% !important" },
  inputRoot: { minHeight: "54px", padding: "2px 8px !important" },
  circularProgress: {
    padding: "4px",
    margiRight: "4px"
  },
  endAdornment: {
    position: "absolute",
    right: "0 !important",
    bottom: "0 !important",
    top: "auto !important"
  },
  option: {
    display: "flex",
    justifyContent: "space-between"
  },
  tag: { overflowX: "auto" }
}));

const MultiSelectComponent = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    dropdownConfig,
    onChange,
    value,
    handleScroll,
    handleSearch,
    handleClose,
    optionLabel,
    noLabelDivider
  } = props;
  const { apiStatus } = useSelector((state) => state);

  const [selectedOptions, setSelectedOptions] = useState(value || []);
  const [search, setSearch] = useState("");
  const [dropdownOptions, setDropdownOptions] = useState(dropdownConfig.data);
  const [scrollTop, setScrollTop] = useState(0);
  const [scrollHeight, setScrollHeight] = useState(0);
  const [clientHeight, setClientHeight] = useState(0);

  // Custom hook for infinite scroll
  const [isFetching, toggle] = useInfiniteScroll(
    clientHeight,
    scrollTop,
    scrollHeight
  );

  const loading =
    (apiStatus || {}).isLoading &&
    apiStatus.reducer.find(
      (reducerText) =>
        reducerText.substring(0, 4) === dropdownConfig.type.substring(0, 4)
    );

  const applyPagination = () => {
    if (isFetching) {
      handleScroll(isFetching, dropdownConfig.type);
      toggle(isFetching);
      setScrollTop(0);
      setScrollHeight(0);
    }
  };

  useEffect(applyPagination, [isFetching]);

  const resetOptions = () => {
    const data = cloneDeep(dropdownConfig.data);
    setDropdownOptions(data);
  };

  const resetSelectedOptions = () => {
    setSelectedOptions(value);
  };

  // Re-render to reflect changes in options and values from parent component
  useEffect(resetOptions, [dropdownConfig.data, dropdownConfig.data.length]);
  useEffect(resetSelectedOptions, [value.length]);

  // Return matching options based on search input
  const handleSearchFilter = () => {
    if (search === "" || search === " ") {
      setDropdownOptions(dropdownConfig.data);
      return;
    }

    const num = Number(search);
    let searchedItem;
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(num)) {
      searchedItem = dropdownConfig.data.filter((item) => {
        return (
          item.name.match(regFirstName(search)) ||
          item.name.toLowerCase().match(regFirstName(search)) ||
          item.name.match(regLastName(search)) ||
          item.name.toLowerCase().match(regLastName(search)) ||
          item.name.match(regFirstAndLastNameChar(search)) ||
          item.name.toLowerCase().match(regFirstAndLastNameChar(search))
        );
      });
      setDropdownOptions(searchedItem);
    } else {
      searchedItem = dropdownConfig.data.filter((el) => {
        return el.id === search;
      });
      setDropdownOptions(searchedItem);
    }
    if (searchedItem === undefined) setDropdownOptions(dropdownConfig.data);
  };

  const resetSearch = () => setSearch("");

  // Called on every search input where API is not needed
  useEffect(handleSearchFilter, [search]);

  const handleDebounceSearch = debounce((value) => {
    handleSearch(dropdownConfig.type, value);
  }, 300);

  return (
    <div
      key={dropdownConfig.type}
      className={classes.container}
      onScroll={(event) => {
        event.persist();
        setClientHeight(event.target.clientHeight);
        setScrollTop(event.target.scrollTop);
        setScrollHeight(event.target.scrollHeight);
      }}
    >
      {!noLabelDivider && (
        <>
          <label className={classes.dropDownLabel}>
            {dropdownConfig.label}
          </label>
          <Divider light />
        </>
      )}
      <Autocomplete
        multiple
        openOnFocus
        disableCloseOnSelect
        filterSelectedOptions
        loading={Boolean(loading)}
        loadingText={t("commonActions.loading")}
        noOptionsText={t("authorizerContainer.no_records")}
        classes={{
          root: classes.root,
          tag: classes.tag,
          paper: classes.paper,
          popper: classes.popper,
          option: classes.option,
          input: classes.input,
          inputRoot: classes.inputRoot,
          endAdornment: selectedOptions.length ? classes.endAdornment : ""
        }}
        id={dropdownConfig.type}
        options={dropdownOptions}
        value={selectedOptions}
        getOptionLabel={(option) => optionLabel(option || {})}
        getOptionSelected={(option, value) => option.id === value.id}
        filterOptions={(options) => {
          if (!(selectedOptions && selectedOptions.length)) return options;
          return differenceWith(
            compact(options || []),
            compact(selectedOptions || []),
            (per, val) => per.id === val.id
          );
        }}
        renderOption={(option) => (
          <>
            {optionLabel(option || {})}
            {findIndex(selectedOptions, ["id", option.id]) === -1 ? (
              <AddIcon />
            ) : (
              <CheckIcon />
            )}
          </>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            variant='outlined'
            placeholder={
              selectedOptions.length
                ? `${t("commonActions.Search")}...`
                : t("commonActions.select_btn_label")
            }
            margin='normal'
            fullWidth
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress
                      color='secondary'
                      size={30}
                      className={classes.circularProgress}
                    />
                  ) : (
                    params.InputProps.endAdornment
                  )}
                </>
              )
            }}
          />
        )}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Tooltip
              placement='left-start'
              arrow
              title={
                (optionLabel(option || {}) || "").length > 40
                  ? optionLabel(option || {})
                  : ""
              }
              key={index}
            >
              <Chip
                size='small'
                color='secondary'
                label={optionLabel(option || {})}
                {...getTagProps({ index })}
              />
            </Tooltip>
          ))
        }
        onChange={(event, value) => {
          if (event) event.persist();
          // Create an array with all falsey values (false, null, 0, "", undefined, and NaN) filtered.
          const values = compact(value);
          setSelectedOptions(values);
          onChange(values);
        }}
        onInputChange={(event, value, reason) => {
          if (event) event.persist();
          if (!reason === "input") return;
          if (dropdownConfig.searchType === "api") handleDebounceSearch(value);
          else setSearch(value.toLowerCase());
        }}
        onClose={(event, reason) => {
          if (event) event.persist();
          if (reason === "blur") resetSearch();
          handleClose(dropdownConfig.type, !!event);
        }}
      />
    </div>
  );
};

MultiSelectComponent.propTypes = {
  dropdownConfig: PropTypes.shape({
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    searchType: PropTypes.string.isRequired,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.any.isRequired,
        name: PropTypes.string.isRequired,
        _id: PropTypes.string
      })
    )
  }).isRequired,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  ),
  onChange: PropTypes.func.isRequired,
  handleScroll: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  optionLabel: PropTypes.func.isRequired,
  noLabelDivider: PropTypes.bool
};

MultiSelectComponent.defaultProps = {
  value: [],
  dropdownConfig: { data: [] },
  noLabelDivider: false
};

export default MultiSelectComponent;
