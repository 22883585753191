import React from "react";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import i18n from "i18next";
import moment from "moment";
import propTypes from "prop-types";

import { ThemeProvider } from "@material-ui/styles";

import RoutesComponent from "./Routes/RoutesComponent";
import theme from "./theme";

import "./styles/app.scss";

moment.updateLocale("es", {
  weekdaysShort: "Dom_Lun_Mar_Mié_Jue_Vie_Sáb".split("_"),
  monthsShort: "Ene_Feb_Mar_Abr_May_Jun_Jul_Ago_Sep_Oct_Nov_Dic".split("_")
});
moment.locale(i18n.language);
const App = (props) => {
  const { store } = props;

  return (
    <div>
      <ThemeProvider theme={theme}>
        <ReduxProvider store={store}>
          <link
            rel='stylesheet'
            href='https://fonts.googleapis.com/icon?family=Material+Icons'
          />
          <link
            rel='stylesheet'
            href='https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap'
          />
          <BrowserRouter>
            <RoutesComponent />
          </BrowserRouter>
        </ReduxProvider>
      </ThemeProvider>
    </div>
  );
};

App.propTypes = {
  store: propTypes.shape({
    subscribe: propTypes.func.isRequired,
    dispatch: propTypes.func.isRequired,
    getState: propTypes.func.isRequired
  }).isRequired
};

export default App;
