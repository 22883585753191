import { useState } from "react";

const useInput = (intialValue) => {
  const [state, setState] = useState(intialValue || []);

  return {
    value: state,
    onChange: (val) => {
      setState(val);
    }
  };
};

export default useInput;
