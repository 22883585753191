import axios from "../../axios-jwt";

import { GET_EMPLOYEES_NOT_CREW_MEMBERS } from "./actionTypes";
import * as ApiStatusActions from "./ApiStatusActions";

async function getUserDetails(
  userDetails,
  selectedCompany,
  offset,
  limit,
  orderBy,
  order,
  isNotInLastRole,
  role
) {
  return !isNotInLastRole
    ? axios.get(
        `employees?appUsers=true&companyCode=${
          selectedCompany.code || userDetails.companyCode
        }&offset=${offset}&limit=${limit}&orderBy=${orderBy}-${order}`
      )
    : axios.get(
        `/employees?roleLevelOrder=${role.roleLevel}-desc&offset=${offset}&limit=${limit}&orderBy=${orderBy}-${order}`
      );
}

export function loadEmployeesNotCrewDetailsSuccess(
  roleEmployeesNotCrew,
  offset
) {
  return { type: GET_EMPLOYEES_NOT_CREW_MEMBERS, roleEmployeesNotCrew, offset };
}

export function loadEmployeesNotCrewDetails(
  offset,
  limit,
  orderBy,
  order,
  isNotInLastRole,
  role
) {
  return (dispatch, getState) => {
    dispatch(ApiStatusActions.apiStartLoader("AssignToStatus"));
    return getUserDetails(
      getState().userDetails,
      getState().selectedCompany,
      offset,
      limit,
      orderBy,
      order,
      isNotInLastRole,
      role
    )
      .then((obj) => {
        if (obj.status === 200) {
          dispatch(
            loadEmployeesNotCrewDetailsSuccess(
              isNotInLastRole ? obj.data.employees : obj.data,
              offset
            )
          );
          dispatch(ApiStatusActions.apiStopLoader("AssignToStatus"));
        } else {
          dispatch(ApiStatusActions.apiStopLoader("AssignToStatus"));
        }
      })
      .catch(() => ApiStatusActions.apiStopLoader("AssignToStatus"));
  };
}
