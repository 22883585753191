/* eslint-disable react/no-array-index-key */
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { cloneDeep, orderBy, uniqBy } from "lodash";
import moment from "moment";
import PropTypes from "prop-types";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import useInfiniteScroll from "../../custom-hooks/useInfiniteScroll";
import { customFailedMessage } from "../../redux/actions/ApiStatusActions";
import * as EquipmentLogsActions from "../../redux/actions/EquipmentLogsActions";
import * as userJobsSearchActions from "../../redux/actions/UserJobsSearchActions";
import DropdownSearchComponent from "../UI/DropdownSearchComponent";

const useStyles = makeStyles({
  dialog: {
    minWidth: "700px"
  },
  tableWrapper: {
    minHeight: 622,
    maxHeight: 622,
    overflow: "auto",
    border: "1.5px solid black"
  },
  paper: {
    maxWidth: "810px"
  },
  dropdownGrid: {
    padding: "6px",
    marginTop: "12px"
  },
  paperTextfield: {
    textAlign: "center"
  },
  textFieldGrid: {
    padding: "6px",
    maxWidth: "14.28%"
  },
  timeOptionsText: {
    padding: "6px",
    maxWidth: "14.28%",
    display: "flex",
    alignItems: "center"
  },
  textFeild: {
    textAlign: "center",
    fontSize: "13px"
  },
  typography: {
    whiteSpace: "nowrap"
  },
  OperatedTimeText: {
    marginTop: "54px"
  },
  gridPadding: {
    padding: "18px",
    flexWrap: "inherit"
  },
  gridDisplayTimeOptions: {
    flexWrap: "inherit"
  },
  verticalDividerMargin: {
    marginRight: "30px",
    marginLeft: "30px"
  },
  horizontalDivider: {
    marginBottom: "30px"
  }
});

const BulkEntryComponent = (props) => {
  const { t, i18n } = useTranslation();

  const {
    open,
    Close,
    payPeriodIndex,
    userJobs,
    PayPeriodSelected,
    selected,
    setSelected,
    rows,
    setJobOffset,
    NumberOfDays
  } = props;

  const { payPeriods, organizationDetails, userJobsSearchResults } =
    useSelector((state) => state);
  const dispatch = useDispatch();

  const [jobSelected, setJobSelected] = useState(userJobs[0]);
  const [OUUpdate, setOUUpdate] = useState({});
  const [jobUpdated, setJobUpdated] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  const [scrollHeight, setScrollHeight] = useState(0);
  const [clientHeight, setClientHeight] = useState(0);
  const [selectedSearchJobs, setSelectedSearchJobs] = useState([]);
  const [search, setSearch] = useState("");
  const TimeOptionsToEnter = ["Operated", "Idle", "Stored"];
  const dropdownLimit = 20;

  const mergedJobs = (empJobs) =>
    uniqBy(
      orderBy([...empJobs, ...selectedSearchJobs], (job) => +job.code, "asc"),
      "code"
    ) || [];

  const [jobs, setJobs] = useState(mergedJobs(userJobs));

  const onJobChange = () => {
    setJobs(mergedJobs(userJobs));
  };
  useEffect(onJobChange, [selectedSearchJobs, userJobs]);

  const handleJobSearch = (searchTerm, id) => {
    if (searchTerm.length) {
      dispatch(userJobsSearchActions.searchUserJobsWithParam(searchTerm, id));
    }
    setSearch(searchTerm);
  };

  const [costType, setCostType] = useState();

  const textFieldRefs = useRef([3 * NumberOfDays]);

  const [selectedTime, setSelectedTime] = useState(
    TimeOptionsToEnter.map(() => [])
  );
  const classes = useStyles();

  const EmployeeNames =
    selected.length &&
    selected.map(
      (equipmentId) =>
        rows.length > 0 &&
        rows.find((row) => row.equipment._id === equipmentId).equipment.code
    );

  const hoursInWeek = () => {
    const copyOfSelectedTime = cloneDeep(selectedTime);
    TimeOptionsToEnter.forEach((timeOption, rowIndex) => {
      copyOfSelectedTime[rowIndex][NumberOfDays] = Array(NumberOfDays)
        .fill()
        .reduce(
          (sum, days, index) =>
            (
              parseFloat(sum) +
              parseFloat(copyOfSelectedTime[rowIndex][index] || 0)
            ).toFixed(2),
          0
        );
    });
    setSelectedTime(copyOfSelectedTime);
  };

  function handleSelectedTime(e, rowIndex, columnIndex) {
    const value = parseFloat(e.target.value) || "";
    if (
      (value <= 24 && value >= 0 && value !== "") ||
      value === "." ||
      e.target.value === ""
    ) {
      const copy = selectedTime.slice();
      let sumOfDayHours = value || 0;

      // validation for equipment hours not to exceed 24 hours
      TimeOptionsToEnter.forEach((timeOption, index) => {
        if (index !== rowIndex)
          sumOfDayHours += parseFloat(copy[index][columnIndex]) || 0;
      });
      if (sumOfDayHours > 24) {
        dispatch(
          customFailedMessage(t("equipmentManager.equipment_exceed_24hrs"))
        );
        setSelectedTime(copy);
        hoursInWeek();
      } else {
        copy[rowIndex][columnIndex] = e.target.value;
        setSelectedTime(copy);
        hoursInWeek();
      }
    }
  }

  function roundNumber(e, rowIndex, index) {
    if (e.target.value !== "") {
      const copy = selectedTime.slice();
      copy[rowIndex][index] = parseFloat(e.target.value).toFixed(2);
      setSelectedTime(copy);
    }
  }

  // Custom hook for infinite scroll on Job dropdown
  const [isFetching, toggle] = useInfiniteScroll(
    clientHeight,
    scrollTop,
    scrollHeight
  );

  const getScrollValue = () => {
    if (isFetching && !search.length) {
      setJobOffset((offset) => offset + dropdownLimit);
      toggle(isFetching);
      setScrollTop(0);
      setScrollHeight(0);
    }
  };

  useEffect(getScrollValue, [isFetching]);

  function handleKeyDown(event, keyCode, rowIndex, columnIndex) {
    const refIndex = (rowIdx = rowIndex) => rowIdx * NumberOfDays + columnIndex;
    const maxIndexAllowed = TimeOptionsToEnter.length * NumberOfDays;

    // left arrow = 37
    // up arrow = 38
    // right arrow = 39
    // down arrow = 40
    if ([37, 38, 39, 40].includes(keyCode)) {
      event.preventDefault();
      if (keyCode === 37)
        textFieldRefs.current[refIndex() - 1 > 0 ? refIndex() - 1 : 0].focus();
      else if (keyCode === 38)
        textFieldRefs.current[
          refIndex(rowIndex - 1) >= 0 ? refIndex(rowIndex - 1) : refIndex()
        ].focus();
      else if (keyCode === 39)
        textFieldRefs.current[
          refIndex() + 1 < maxIndexAllowed ? refIndex() + 1 : refIndex()
        ].focus();
      else
        textFieldRefs.current[
          refIndex(rowIndex + 1) < maxIndexAllowed
            ? refIndex(rowIndex + 1)
            : refIndex()
        ].focus();
    }
  }

  function handleOnSave() {
    if (selectedTime.length > 0) {
      let ob = {};
      Object.keys(OUUpdate).forEach((ou) => {
        ob = { ...ob, [ou]: OUUpdate[ou].code };
      });
      let payloadForAllCrew = [];
      const TimeOptions = ["operatedTime", "idleTime", "storeTime"];

      selected.forEach((employeeId) => {
        let payloadForEachCrewmember = [];
        Array(NumberOfDays)
          .fill()
          .forEach((day, columnIndex) => {
            const createTimelogForDay = {
              OULevelValues: ob,
              date: moment
                .utc(payPeriods[payPeriodIndex].startDate)
                .add(columnIndex, "d")
                .format("DD-MM-YYYY"),
              jobCode: jobUpdated ? jobSelected.code : userJobs[0].code,
              costType: costType.name
            };
            TimeOptions.forEach((option, rowIndex) => {
              if (selectedTime[rowIndex][columnIndex]) {
                createTimelogForDay[TimeOptions[rowIndex]] = parseFloat(
                  selectedTime[rowIndex][columnIndex]
                );
              } else {
                createTimelogForDay[TimeOptions[rowIndex]] = 0;
              }
            });
            if (
              TimeOptions.filter((option) => createTimelogForDay[option]).length
            ) {
              payloadForEachCrewmember = [
                ...payloadForEachCrewmember,
                createTimelogForDay
              ];
            }
          });
        payloadForAllCrew = [
          ...payloadForAllCrew,
          ...payloadForEachCrewmember.map((eachNewEntry) => {
            eachNewEntry.equipmentId = employeeId;
            return eachNewEntry;
          })
        ];
      });
      dispatch(
        EquipmentLogsActions.bulkEntryEquipment(
          payloadForAllCrew,
          payPeriods[payPeriodIndex]._id,
          selected.join(",")
        )
      );
      setSelected([]);
      Close();
    }
  }

  return (
    EmployeeNames.length > 0 && (
      <Dialog
        onClose={Close}
        open={open}
        maxWidth='md'
        className={classes.dialog}
        fullWidth={false}
      >
        <DialogTitle
          id='customized-dialog-title'
          onClose={Close}
          disableTypography
        >
          <h3>{t("payroll.bulk_entry_title")}</h3>
          <div className={classes.paper}>
            <Typography noWrap className={classes.typography}>
              <small className='text-muted'>
                {`${selected.length} ${t(
                  "equipmentManager.equipment_selected"
                )} - ${EmployeeNames.join(", ")}`}
              </small>
            </Typography>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <Paper elevation={0} className={classes.paper}>
            <Grid container>
              <Grid item xs={12}>
                <b style={{ padding: "18px" }}>
                  {PayPeriodSelected[payPeriodIndex].value}
                </b>
                <Grid container className={classes.gridPadding}>
                  <Grid
                    item
                    xs={4}
                    className={classes.dropdownGrid}
                    onScroll={(event) => {
                      event.persist();
                      setClientHeight(event.target.clientHeight);
                      setScrollTop(event.target.scrollTop);
                      setScrollHeight(event.target.scrollHeight);
                    }}
                  >
                    <DropdownSearchComponent
                      options={jobs || { code: "", name: "" }}
                      searchOptions={
                        search && search.length ? userJobsSearchResults : []
                      }
                      id='userJobs_bulkEntry'
                      disableUnderline={false}
                      value={
                        jobSelected && jobSelected.code
                          ? jobSelected
                          : userJobs[0] || []
                      }
                      handleChange={(change) => {
                        setJobSelected(change);
                        setOUUpdate({});
                        setJobUpdated(true);
                        if (
                          change &&
                          change.code &&
                          !selectedSearchJobs.find(
                            (job) => job.code === change.code
                          )
                        ) {
                          setSelectedSearchJobs([
                            ...selectedSearchJobs,
                            change
                          ]);
                        }
                        userJobsSearchActions.clearUserJobsResults(
                          "userJobsSearchResults"
                        );
                      }}
                      handleJobSearch={handleJobSearch}
                      onDropDownChange={() => setSearch("")}
                    />
                  </Grid>
                  {organizationDetails.OULevelNamesForEquipmentLogs.map(
                    (op, idx) => (
                      <Grid
                        item
                        xs={4}
                        className={classes.dropdownGrid}
                        key={idx}
                      >
                        <DropdownSearchComponent
                          id='equipment_bulk_OUValues'
                          options={
                            (
                              ((jobSelected || {}).operationalUnits || []).find(
                                (ou) => ou.name === op.name
                              ) || {}
                            ).items || []
                          }
                          disableUnderline={false}
                          value={(() => {
                            let label;
                            if (OUUpdate[op.name]) {
                              label = OUUpdate[op.name];
                            } else if (jobUpdated) {
                              label = { code: "", name: op.label };
                            } else label = { code: "", name: op.label };
                            return label;
                          })()}
                          handleChange={(change) => {
                            setOUUpdate((OU) => ({
                              ...OU,
                              [op.name]: {
                                code: (change || {}).code,
                                name: (change || {}).name
                              }
                            }));
                          }}
                        />
                      </Grid>
                    )
                  )}
                  {organizationDetails.costType && (
                    <Grid item xs={4} className={classes.dropdownGrid}>
                      <DropdownSearchComponent
                        id='equipment_bulk_costType'
                        options={organizationDetails.costType.map((ht) => ({
                          name: `${ht}`,
                          code: ""
                        }))}
                        disableUnderline={false}
                        value={(() => {
                          let label;
                          if (costType && costType.name)
                            label = { name: costType.name, code: "" };
                          else {
                            label = {
                              code: "",
                              name: t("equipmentManager.cost_type")
                            };
                          }
                          return label;
                        })()}
                        handleChange={(change) => {
                          setCostType(change);
                        }}
                      />
                    </Grid>
                  )}
                </Grid>
                <Divider className={classes.horizontalDivider} />
                {TimeOptionsToEnter.map(
                  (timeOption, rowIndex) =>
                    NumberOfDays > 0 && (
                      <Grid
                        container
                        className={classes.gridDisplayTimeOptions}
                        key={rowIndex}
                      >
                        <Grid item xs={3} className={classes.timeOptionsText}>
                          <b
                            className={
                              timeOption === "Operated"
                                ? classes.OperatedTimeText
                                : ""
                            }
                          >
                            {`${t(`equipmentManager.${timeOption}`)}  `}
                          </b>
                        </Grid>
                        {Array(NumberOfDays + 1)
                          .fill()
                          .map((_, index) => (
                            <>
                              {NumberOfDays === index && (
                                <Divider
                                  orientation='vertical'
                                  flexItem
                                  className={classes.verticalDividerMargin}
                                />
                              )}
                              <Grid
                                item
                                xs={2}
                                className={classes.textFieldGrid}
                                key={index}
                              >
                                <Paper
                                  className={classes.paperTextfield}
                                  elevation={0}
                                >
                                  {timeOption === "Operated" &&
                                    (NumberOfDays === index ? (
                                      <>
                                        <h6>{t("payroll.week")}</h6>
                                        {`${moment
                                          .utc(
                                            payPeriods[payPeriodIndex].startDate
                                          )
                                          .format("DD")} - ${moment
                                          .utc(
                                            payPeriods[payPeriodIndex].endDate
                                          )
                                          .format("DD")}`}
                                      </>
                                    ) : (
                                      <>
                                        <h6>
                                          {moment
                                            .utc(
                                              payPeriods[payPeriodIndex]
                                                .startDate
                                            )
                                            .locale(i18n.language)
                                            .add(index, "d")
                                            .format("ddd")}
                                        </h6>
                                        {moment
                                          .utc(
                                            payPeriods[payPeriodIndex].startDate
                                          )
                                          .add(index, "d")
                                          .format("MM/DD")}
                                      </>
                                    ))}

                                  <TextField
                                    placeholder='0.00'
                                    className={classes.textField}
                                    margin='normal'
                                    variant='outlined'
                                    value={selectedTime[rowIndex][index]}
                                    onBlur={(e) =>
                                      roundNumber(e, rowIndex, index)
                                    }
                                    disabled={NumberOfDays === index}
                                    inputRef={(el) =>
                                      (textFieldRefs.current[
                                        rowIndex * NumberOfDays + index
                                      ] = el)
                                    }
                                    onFocus={(e) => {
                                      handleSelectedTime(e, rowIndex, index);
                                    }}
                                    onChange={(e) =>
                                      handleSelectedTime(e, rowIndex, index)
                                    }
                                    onKeyDown={(event) => {
                                      const { keyCode } = event;
                                      handleKeyDown(
                                        event,
                                        keyCode,
                                        rowIndex,
                                        index
                                      );
                                    }}
                                    inputProps={{
                                      maxLength: 5,
                                      "aria-label": "bare"
                                    }}
                                  />
                                </Paper>
                              </Grid>
                            </>
                          ))}
                      </Grid>
                    )
                )}
              </Grid>
            </Grid>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={Close} color='primary'>
            {t("commonActions.cancel")}
          </Button>
          <Button
            autoFocus
            onClick={handleOnSave}
            color='primary'
            disabled={
              !(costType || {}).name ||
              !Object.keys(OUUpdate).length ||
              selectedTime.filter(
                (timeArray) =>
                  timeArray.filter((hours) => hours && parseFloat(hours) > 0)
                    .length
              ).length === 0
            }
          >
            {t("commonActions.save")}
          </Button>
        </DialogActions>
      </Dialog>
    )
  );
};

BulkEntryComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  Close: PropTypes.func.isRequired,
  payPeriodIndex: PropTypes.number.isRequired,
  userJobs: PropTypes.arrayOf(
    PropTypes.shape({
      operationalUnits: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
      code: PropTypes.string.isRequired,
      name: PropTypes.string
    })
  ).isRequired,
  PayPeriodSelected: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      id: PropTypes.string
    })
  ).isRequired,
  selected: PropTypes.arrayOf(PropTypes.string).isRequired,
  rows: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  setSelected: PropTypes.func.isRequired,
  setJobOffset: PropTypes.func.isRequired,
  NumberOfDays: PropTypes.number.isRequired
};

export default BulkEntryComponent;
