import { GET_ORGANIZATION_DATA } from "../actions/actionTypes";

export default function OrganizationsReducer(state = [], action) {
  switch (action.type) {
    case GET_ORGANIZATION_DATA: {
      return action.organization;
    }
    default:
      return state;
  }
}
