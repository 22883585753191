import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import lodash from "lodash";
import PropTypes from "prop-types";

import { Checkbox, LinearProgress } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

import useInfiniteScroll from "../../custom-hooks/useInfiniteScroll";
import * as EmployeeSearchActions from "../../redux/actions/EmployeeSearchActions";
import * as EquipmentsActions from "../../redux/actions/EquipmentsActions";
import * as RoleEmployeesActions from "../../redux/actions/RoleEmployeesActions";
import * as RoleEmployeesWithoutCrewActions from "../../redux/actions/RoleEmployeesWithoutCrewActions";
import * as RoleSearchActions from "../../redux/actions/RoleSearchActions";
import SearchAutocompleteComponent from "../UI/SearchAutocompleteComponent";

const useStyles = makeStyles(() => ({
  listHeight: {
    minHeight: "300px",
    maxHeight: "300px",
    overflow: "scroll"
  },
  selectedEquipment: {
    height: "41px",
    display: "flex",
    alignItems: "center",
    marginLeft: "16px"
  },
  buttonsAlign: {
    justifyContent: "space-evenly"
  }
}));

const AssignEquipmentDialogComponent = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { role, roles, selected, show, handleClose, unSelectAll } = props;
  const {
    roleEmployees,
    roleEmployeesNoCrew,
    apiStatus,
    userDetails,
    roleSearchResults,
    employeeSearchResults
  } = useSelector((state) => state);
  const [selectedEquipment, setSelectedEquipment] = useState(selected);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [changeReporting, setChangeReporting] = useState(false);
  const [offset, setOffset] = useState({ false: 0, true: 0 }); // false for foreman and true for all employees
  const [search, setSearch] = useState("");
  const [limit] = useState(50);
  const [scrollTop, setScrollTop] = useState(0);
  const [scrollHeight, setScrollHeight] = useState(0);
  const [clientHeight, setClientHeight] = useState(0);

  const searchEmployee = (query) => {
    setSearch(query);
  };
  const clearSearchedEmployees = () => {
    dispatch(RoleSearchActions.clearEmployees());
    dispatch(EmployeeSearchActions.clearEmployees());
  };
  const classes = useStyles();

  const filteredRoles = roles.filter((obj) => obj.roleName !== "");
  const lastSecondRole = filteredRoles[filteredRoles.length - 2];

  // Custom hook for infinite scroll
  const [isFetching, toggle] = useInfiniteScroll(
    clientHeight,
    scrollTop,
    scrollHeight
  );

  // Send scroll event result to parent component to send API calls for pagination
  useEffect(() => {
    if (isFetching && !apiStatus.isLoading) {
      setOffset((prevValue) => ({
        ...prevValue,
        [Boolean(changeReporting)]: offset[Boolean(changeReporting)] + limit
      }));
      toggle(isFetching);
      setScrollTop(0);
      setScrollHeight(0);
      setClientHeight(0);
    }
    // eslint-disable-next-line
  }, [isFetching]);

  function onOffsetChange() {
    if (offset[Boolean(changeReporting)] > 0) {
      if (
        changeReporting &&
        roleEmployeesNoCrew.length - offset[Boolean(changeReporting)] >= 0
      ) {
        dispatch(
          RoleEmployeesWithoutCrewActions.loadEmployeesNotCrewDetails(
            offset[Boolean(changeReporting)],
            limit,
            "empName",
            "asc"
          )
        );
      } else if (
        !changeReporting &&
        roleEmployees[lastSecondRole._id].length -
          offset[Boolean(changeReporting)] >=
          0
      ) {
        dispatch(
          RoleEmployeesActions.loadRoleEmployees(
            lastSecondRole._id,
            offset[Boolean(changeReporting)],
            limit,
            "empName",
            "asc"
          )
        );
      }
    }
  }
  useEffect(onOffsetChange, [offset]);

  function initialLoadEmployees() {
    dispatch(
      RoleEmployeesWithoutCrewActions.loadEmployeesNotCrewDetails(
        0,
        limit,
        "empName",
        "asc"
      )
    );
    dispatch(
      RoleEmployeesActions.loadRoleEmployees(
        lastSecondRole._id,
        0,
        limit,
        "empName",
        "asc"
      )
    );
  }
  useEffect(initialLoadEmployees, []);

  function onSearch() {
    if (search.length > 0) {
      if (!changeReporting) {
        dispatch(
          RoleSearchActions.searchEmployeesWithParam(
            lastSecondRole._id,
            search,
            0,
            limit,
            "empName",
            "asc"
          )
        );
      } else {
        dispatch(
          EmployeeSearchActions.searchEmployeesWithParam(
            userDetails.companyCode,
            search,
            0,
            limit,
            "empName",
            "asc",
            "app_employees"
          )
        );
      }
    }
  }
  useEffect(onSearch, [search]);

  const handleCheck = (id) => {
    const index = selectedEmployees.indexOf(id);
    const copyArray = lodash.cloneDeep(selectedEmployees);

    if (index !== -1) {
      copyArray.splice(index, 1);
    } else {
      copyArray.push(id);
    }
    setSelectedEmployees(copyArray);
  };

  const handleOnSave = () => {
    const payload = selected.map((eq) => ({
      _id: eq._id,
      employees: selectedEmployees
    }));

    if (selectedEmployees.length > 0) {
      dispatch(EquipmentsActions.updateEquipmentAssignment(payload));
    }
    setSelectedEmployees([]);
    setSelectedEquipment([]);
    unSelectAll();
  };

  const hangeChangeReporting = () => {
    setChangeReporting(!changeReporting);
    setSearch("");
  };

  return (
    <div className='assignEqComponent'>
      <Dialog
        open={show}
        onClose={handleClose}
        maxWidth='sm'
        variant='outlined'
        color='primary'
        fullWidth
      >
        <Grid>
          {apiStatus && apiStatus.isLoading && (
            <LinearProgress className={classes.progressBar} />
          )}
        </Grid>
        <DialogContent>
          <Grid container>
            <Grid item xs={6}>
              <DialogTitle> {t("equipmentManager.title")}</DialogTitle>
              <Divider variant='middle' />
              <Grid item className={classes.listHeight}>
                {selectedEquipment.length &&
                  selectedEquipment.map((eq) => (
                    <Grid item key={eq._id}>
                      <Grid
                        className={classes.selectedEquipment}
                      >{`${eq.code} - ${eq.name}`}</Grid>
                    </Grid>
                  ))}
              </Grid>

              <Grid container item xs={9} className={classes.buttonsAlign}>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => {
                    handleClose();
                    setSelectedEmployees([]);
                    setSelectedEquipment([]);
                  }}
                >
                  {t("commonActions.cancel")}
                </Button>
                <Button
                  variant='contained'
                  color='primary'
                  disabled={!selectedEmployees.length}
                  onClick={() => {
                    handleOnSave();
                    handleClose();
                  }}
                >
                  {t("equipmentManager.assign_btn")}
                </Button>
              </Grid>
            </Grid>

            {!changeReporting && role && (
              <Grid item xs={6}>
                <DialogTitle>
                  {lastSecondRole.roleName}
                  <SearchAutocompleteComponent
                    handleSearch={searchEmployee}
                    searchResults={roleSearchResults}
                    showOptions={false}
                    disableClearable
                    freeSolo
                    clear={clearSearchedEmployees}
                  />
                </DialogTitle>
                <Grid
                  item
                  className={classes.listHeight}
                  onScroll={(event) => {
                    event.persist();
                    setScrollTop(event.target.scrollTop);
                    setScrollHeight(event.target.scrollHeight);
                    setClientHeight(event.target.clientHeight);
                  }}
                >
                  {roleEmployees[lastSecondRole._id] &&
                  ((!roleEmployees[lastSecondRole._id].length &&
                    !search.length) ||
                    (search.length && !roleSearchResults.length)) ? (
                    <Grid
                      item
                      key={lastSecondRole._id}
                      style={{ textAlign: "center" }}
                    >
                      {t("commonActions.no_records")}
                    </Grid>
                  ) : (
                    roleEmployees[lastSecondRole._id] &&
                    roleEmployees[lastSecondRole._id].length > 0 &&
                    (search.length
                      ? roleSearchResults
                      : roleEmployees[lastSecondRole._id]
                    ).map((emp) => (
                      <Grid item key={emp._id}>
                        <Checkbox
                          type='checkbox'
                          checked={selectedEmployees.includes(emp._id)}
                          onClick={() => handleCheck(emp._id)}
                        />
                        {`${emp.empID} - ${emp.empName}`}
                      </Grid>
                    ))
                  )}
                </Grid>

                <Grid item>
                  <Button
                    variant='outlined'
                    color='primary'
                    onClick={() => hangeChangeReporting()}
                  >
                    {t("authorizerContainer.view")}{" "}
                    {t("authorizerContainer.all_emp_label")}
                  </Button>
                </Grid>
              </Grid>
            )}

            {changeReporting && role && (
              <Grid item xs={6}>
                <DialogTitle>
                  {t("authorizerContainer.all_emp_label")}
                  <SearchAutocompleteComponent
                    handleSearch={searchEmployee}
                    searchResults={roleSearchResults}
                    showOptions={false}
                    disableClearable
                    freeSolo
                    clear={clearSearchedEmployees}
                    key='assign_eqipment'
                  />
                </DialogTitle>
                <Grid
                  item
                  className={classes.listHeight}
                  onScroll={(event) => {
                    event.persist();
                    setScrollTop(event.target.scrollTop);
                    setScrollHeight(event.target.scrollHeight);
                    setClientHeight(event.target.clientHeight);
                  }}
                >
                  {(!roleEmployeesNoCrew.length && !search.length) ||
                  (search.length && !employeeSearchResults.length) ? (
                    <Grid
                      item
                      key={lastSecondRole._id}
                      style={{ textAlign: "center" }}
                    >
                      {t("commonActions.no_records")}
                    </Grid>
                  ) : (
                    roleEmployeesNoCrew.length &&
                    (search.length
                      ? employeeSearchResults
                      : roleEmployeesNoCrew
                    ).map((emp) => (
                      <Grid item key={emp._id}>
                        <Checkbox
                          type='checkbox'
                          checked={selectedEmployees.includes(emp._id)}
                          onClick={() => handleCheck(emp._id)}
                        />
                        {`${emp.empID} - ${emp.empName}`}
                      </Grid>
                    ))
                  )}
                </Grid>

                <Grid item>
                  <Button
                    variant='outlined'
                    color='primary'
                    onClick={() => hangeChangeReporting()}
                  >
                    {t("authorizerContainer.view")} {lastSecondRole.roleName}
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

AssignEquipmentDialogComponent.propTypes = {
  role: PropTypes.shape().isRequired,
  roles: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  equipments: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  selected: PropTypes.arrayOf(PropTypes.string).isRequired,
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  unSelectAll: PropTypes.func.isRequired
};

export default AssignEquipmentDialogComponent;
