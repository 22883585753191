import {
  OVERRIDE_AUTHORISERS_FILTER,
  RESET_AUTHORISERS_FILTER
} from "../actions/actionTypes";

// Define initial state
const initialState = {
  reportsOf: "employees",
  // Employees Substates
  employees: "allEmployees",
  jobFilter: [],
  selectedEmployees: [], //Personnel
  // Equipments Substates
  equipments: "",
  assignedTo: [],
  selectedEquipments: []
};

const AuthorisersReportFilterReducer = (state = initialState, action) => {
  switch (action.type) {
    case OVERRIDE_AUTHORISERS_FILTER:
      return {
        ...state,
        ...action.filterOverRideState
      };
    case RESET_AUTHORISERS_FILTER:
      return initialState;
    default:
      return state;
  }
};

export default AuthorisersReportFilterReducer;
