import React from "react";
import PropTypes from "prop-types";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  cancelButton: {
    borderRadius: "50px"
  },
  previewButton: {
    borderRadius: "50px",
    marginRight: "24px"
  }
}));

const FilterDialogComponent = (props) => {
  const {
    open,
    close,
    title,
    children,
    dialogAttributes: {
      cancelAction,
      cancelText,
      previewAction,
      previewText,
      isPreviewActionDisabled = false
    }
  } = props;
  const classes = useStyles();
  return (
    <Dialog open={open} onClose={close} maxWidth='sm' fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
      <DialogActions>
        <Button
          variant='outlined'
          color='primary'
          size='medium'
          onClick={cancelAction}
          className={classes.cancelButton}
        >
          {cancelText}
        </Button>
        <Button
          variant='contained'
          color='secondary'
          size='medium'
          disabled={isPreviewActionDisabled}
          onClick={previewAction}
          className={classes.previewButton}
        >
          {previewText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
FilterDialogComponent.propTypes = {
  title: PropTypes.string.isRequired,
  dialogAttributes: PropTypes.shape({
    cancelAction: PropTypes.func.isRequired,
    cancelText: PropTypes.string.isRequired,
    isPreviewActionDisabled: PropTypes.bool,
    previewAction: PropTypes.func.isRequired,
    previewText: PropTypes.string.isRequired
  }).isRequired,
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
};

export default FilterDialogComponent;
