import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { cloneDeep } from "lodash";
import PropTypes from "prop-types";

import { Fab, LinearProgress } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

import useInfiniteScroll from "../../../custom-hooks/useInfiniteScroll";
import * as RoleEmployeesActions from "../../../redux/actions/RoleEmployeesActions";
import * as RoleSearchActions from "../../../redux/actions/RoleSearchActions";
import useStyles from "../../../styles/components/AuthorizerEmployeesStyles";
import permissionsList from "../../../utillities/Permissions";
import SearchAutocompleteComponent from "../../UI/SearchAutocompleteComponent";
import TableHeaderWrapper from "../../UI/TableHeaderWrapper";
import AssignJobComponent from "../jobs/AssignJobComponent";
import ManageJobsComponent from "../jobs/ManageJobsComponent";

import AssignRoleComponent from "./AssignRoleComponent";
import AssignToComponent from "./AssignToComponent";
import SpecificRoleTableWrapperComponent from "./SpecificRoleTableWrapperComponent";
import UnassignComponent from "./UnassignComponent";
import ViewCrewMembers from "./ViewCrewMembers";

// table headers config

const SpecificRoleEmployeesComponent = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { role, lastRole, firstRole } = props;
  const { userDetails, roleEmployees, apiStatus, roleSearchResults } =
    useSelector((state) => state);
  const dispatch = useDispatch();

  const [selectedButtonAssign, setSelectedButtonAssign] = useState([]);
  const [selectedChangeRole, setSelectedChangeRole] = useState([]);
  const [unassignEmployee, setUnassignEmployee] = useState({});
  const [show, setShow] = useState(false);
  const [unassignShow, setUnassignShow] = useState(false);
  const [changeRoleShow, setChangeRoleShow] = useState(false);
  const [viewCrewShow, setViewCrewShow] = useState(false);
  const [assignJobShow, setAssignJobShow] = useState(false);
  const [manageJobsShow, setManageJobsShow] = useState(false);

  const [offset, setOffset] = useState(0);
  const [search, setSearch] = useState("");
  const [limit] = useState(50);

  const [rows, setRows] = useState([]); // rows of table
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("empName");
  const [selected, setSelected] = useState([]);
  const [headCells, setHeadCells] = useState([]);

  const [scrollTop, setScrollTop] = useState(0);
  const [scrollHeight, setScrollHeight] = useState(0);
  const [clientHeight, setClientHeight] = useState(0);
  const scrollRef = useRef();

  // on search
  const searchEmployee = (query) => {
    if (query.length > 0)
      dispatch(
        RoleSearchActions.searchEmployeesWithParam(
          role._id,
          query,
          0,
          limit,
          orderBy,
          order
        )
      );
    setSearch(query);
  };

  const clearSearchedEmployees = () => {
    if (!show) {
      dispatch(RoleSearchActions.clearEmployees());
    }
  };

  // Custom hook for infinite scroll
  const [isFetching, toggle] = useInfiniteScroll(
    clientHeight,
    scrollTop,
    scrollHeight
  );

  // Send scroll event result to parent component to send API calls for pagination
  useEffect(() => {
    if (isFetching && search.length === 0) {
      if (
        !roleSearchResults.length &&
        !apiStatus.isLoading &&
        roleEmployees[role._id].length - offset >= 0
      )
        setOffset((o) => o + limit);
      setScrollTop(0);
      setScrollHeight(0);
      setClientHeight(0);
    }
    if (isFetching) {
      toggle(isFetching);
    }
    // eslint-disable-next-line
  }, [isFetching]);

  function EmployeeOffsetChanges() {
    if (offset && roleEmployees[role._id].length - offset >= 0)
      dispatch(
        RoleEmployeesActions.loadRoleEmployees(
          role._id,
          offset,
          limit,
          orderBy,
          order
        )
      );
  }
  useEffect(EmployeeOffsetChanges, [offset]);

  function handleSorting() {
    setOffset(0);
    if (!search.length) {
      if (scrollRef.current) scrollRef.current.scrollTo(0, 0);
      dispatch(
        RoleEmployeesActions.loadRoleEmployees(
          role._id,
          0,
          limit,
          orderBy,
          order
        )
      );
    } else
      dispatch(
        RoleSearchActions.searchEmployeesWithParam(
          role._id,
          search,
          0,
          limit,
          orderBy,
          order
        )
      );
  }
  useEffect(handleSorting, [order, orderBy]);

  function RoleChangeInitialLoading() {
    if (roleEmployees[role._id] && search.length === 0) {
      const headCellsConfig = [
        {
          id: "empID",
          numeric: true,
          disablePadding: false,
          label: "authorizerContainer.emp_id",
          isSortable: true
        },
        {
          id: "empName",
          numeric: false,
          disablePadding: false,
          label: "authorizerContainer.name",
          isSortable: true
        },
        {
          id: "personnelTo",
          numeric: false,
          disablePadding: false,
          label: "authorizerContainer.reporting_to",
          isSortable: false
        },
        {
          id: "autoLunch",
          numeric: false,
          disablePadding: false,
          label: "authorizerContainer.auto_lunch",
          isSortable: false
        },
        {
          id: "registerFingerprint",
          numeric: false,
          disablePadding: false,
          label: "authorizerContainer.register_fingerprint",
          isSortable: false
        },
        {
          id: "assignAllJobs",
          numeric: false,
          disablePadding: false,
          label: "authorizerContainer.assign_all_jobs",
          isSortable: false
        },
        {
          id: "dropDown",
          numeric: false,
          disablePadding: false,
          label: "",
          isSortable: false
        }
      ];
      // set headers for each role
      setHeadCells(() => {
        let copyHeadCells = cloneDeep(headCellsConfig);
        function sliceHeaderCells(idx, headers) {
          if (idx >= 0) {
            headers.splice(idx, 1);
          }
          return headers;
        }
        function removeHeader(id) {
          const idx = copyHeadCells.map((hc) => hc.id === id).indexOf(true);
          copyHeadCells = sliceHeaderCells(idx, copyHeadCells);
        }
        if (role._id === firstRole._id) {
          removeHeader("personnelTo");
        }
        if (role._id === lastRole._id) {
          removeHeader("autoLunch");
        }
        if (
          !userDetails.isSuperAdmin ||
          (userDetails.isSuperAdmin && role._id === lastRole._id) ||
          role.permissions.includes(permissionsList.LABOR_ENTRY_KIOSK.key)
        ) {
          removeHeader("registerFingerprint");
        }
        if (!role.permissions.includes(permissionsList.LABOR_UPLOAD.key))
          removeHeader("assignAllJobs");
        return copyHeadCells;
      });

      // setting rows on employees change
      setRows(
        roleEmployees[role._id].map((emp) => {
          return {
            empID: parseInt(emp.empID, 10),
            _id: emp._id,
            empName: emp.empName,
            personnelTo: emp.personnelTo.length > 0 ? emp.personnelTo : "",
            autoFillLunchTime: emp.autoFillLunchTime
              ? emp.autoFillLunchTime
              : false,
            canRegisterFingerPrint: emp.canRegisterFingerPrint
              ? emp.canRegisterFingerPrint
              : false,
            personnel: emp.personnel.length > 0 ? emp.personnel : "",
            hasAllJobs: emp.hasAllJobs ? emp.hasAllJobs : false,
            roleId: emp.role._id
          };
        })
      );
    }
  }
  useEffect(RoleChangeInitialLoading, [roleEmployees[role._id], userDetails]);

  function onRoleSearch() {
    if (search.length > 0 || roleEmployees[role._id]) {
      setRows(
        (search.length > 0 ? roleSearchResults : roleEmployees[role._id]).map(
          (emp) => {
            return {
              empID: parseInt(emp.empID, 10),
              _id: emp._id,
              empName: emp.empName,
              personnelTo: emp.personnelTo.length > 0 ? emp.personnelTo : "",
              autoFillLunchTime: emp.autoFillLunchTime
                ? emp.autoFillLunchTime
                : false,
              canRegisterFingerPrint: emp.canRegisterFingerPrint
                ? emp.canRegisterFingerPrint
                : false,
              personnel: emp.personnel.length > 0 ? emp.personnel : "",
              hasAllJobs: emp.hasAllJobs ? emp.hasAllJobs : false,
              roleId: emp.role._id
            };
          }
        )
      );
    }
  }
  useEffect(onRoleSearch, [roleSearchResults]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleChangeRoleShow = () => {
    setChangeRoleShow(true);
  };
  const handleChangeRoleClose = () => {
    setChangeRoleShow(false);
  };

  const handleUnassignShow = () => setUnassignShow(true);
  const handleUnassignClose = () => setUnassignShow(false);

  const handleViewCrewShow = () => setViewCrewShow(true);
  const handleViewCrewClose = () => setViewCrewShow(false);

  const handleAssignJobShow = () => setAssignJobShow(true);
  const handleAssignJobClose = () => setAssignJobShow(false);

  // functions
  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (selected.length > 0) {
      setSelected([]);
      return;
    }
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  function handleDropdownButton(id) {
    setSelectedButtonAssign([id]);
  }

  function handleChangeRole(id) {
    setSelectedChangeRole([id]);
  }

  function unSelectAll() {
    setSelected([]);
  }

  const handleClick = (event, rowIndex, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <div className={classes.paper} elevation={1}>
      <Toolbar className={classes.toolbar}>
        <Typography className={classes.title} variant='h4' id='tableTitle'>
          {role.roleName}
          <p className={classes.count}>{`1 - ${
            roleEmployees[role._id] ? roleEmployees[role._id].length : 0
          } of ${role.count}`}</p>
        </Typography>
        <div className={classes.UserActions}>
          {selected.length > 0 && firstRole._id !== role._id && (
            <Fab
              variant='extended'
              color='primary'
              onClick={handleShow}
              className={classes.headerButton}
              disabled={
                !userDetails.isSuperAdmin &&
                !userDetails.role.permissions.includes(
                  permissionsList.AUTHORIZER_ASSIGN_PERSONNEL.key
                )
              }
            >
              {t("equipmentManager.assign_to")}
            </Fab>
          )}
          {selected.length > 0 && role._id !== lastRole._id && (
            <Fab
              variant='extended'
              color='primary'
              onClick={handleAssignJobShow}
              className={classes.headerButton}
              disabled={
                !userDetails.isSuperAdmin &&
                !userDetails.role.permissions.includes(
                  permissionsList.ASSIGN_JOBS.key
                )
              }
            >
              {t("assignJob.title")}
            </Fab>
          )}
          <SearchAutocompleteComponent
            handleSearch={searchEmployee}
            searchResults={roleSearchResults}
            showOptions={false}
            disableClearable
            freeSolo
            clear={clearSearchedEmployees}
            key={`${role._id}_role_search`}
          />
        </div>
      </Toolbar>
      {apiStatus &&
        apiStatus.isLoading &&
        (apiStatus.reducer.includes(role._id) ||
          apiStatus.reducer.includes("RoleChange")) && (
          <LinearProgress className={classes.progressBar} />
        )}

      {roleEmployees[role._id] && (
        <Paper
          className={classes.tableWrapper}
          ref={scrollRef}
          onScroll={(event) => {
            event.persist();
            setScrollTop(event.target.scrollTop);
            setScrollHeight(event.target.scrollHeight);
            setClientHeight(event.target.clientHeight);
          }}
        >
          <Table
            className={classes.table}
            aria-labelledby='tableTitle'
            aria-label='enhanced table'
            size='small'
            stickyHeader
          >
            <TableHeaderWrapper
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={role.count}
              headCells={headCells}
            />

            <TableBody>
              {rows.length > 0
                ? rows.map((row, index) => {
                    const isItemSelected = isSelected(row._id);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <SpecificRoleTableWrapperComponent
                        key={row._id}
                        row={row}
                        isFirstRole={firstRole._id === role._id}
                        selected={selected}
                        classes={classes}
                        role={role}
                        isLastRole={lastRole._id === role._id}
                        index={index}
                        isItemSelected={isItemSelected}
                        labelId={labelId}
                        handleViewCrewShow={handleViewCrewShow}
                        handleUnassignShow={handleUnassignShow}
                        handleChangeRole={handleChangeRole}
                        handleChangeRoleShow={handleChangeRoleShow}
                        setUnassignEmployee={setUnassignEmployee}
                        handleDropdownButton={handleDropdownButton}
                        setManageJobsShow={setManageJobsShow}
                        handleShow={handleShow}
                        shouldComponentRerender={`${apiStatus.isLoading}${selected.length}`}
                        headCells={headCells}
                        handleClick={handleClick}
                      />
                    );
                  })
                : search.length > 0 && (
                    <TableRow>
                      <TableCell
                        colSpan={headCells.length + 1}
                        style={{ textAlign: "center", border: "none" }}
                      >
                        {t("commonActions.no_records")}
                      </TableCell>
                    </TableRow>
                  )}
            </TableBody>
          </Table>
          {show && (
            <AssignToComponent
              role={role}
              checkBox={selected.length > 0 ? selected : selectedButtonAssign}
              show={show}
              handleClose={handleClose}
              unSelectAll={unSelectAll}
            />
          )}
          {selected.length === 0 && changeRoleShow && (
            <AssignRoleComponent
              show={changeRoleShow}
              handleClose={handleChangeRoleClose}
              SelectedEmployees={selectedChangeRole}
              unSelectAll={unSelectAll}
              previosRole={role._id}
            />
          )}
          {manageJobsShow && (
            <ManageJobsComponent
              key='manageJobsFromRole'
              show={manageJobsShow}
              handleClose={setManageJobsShow}
              SelectedEmployee={selectedChangeRole}
              roleEmployees={rows}
            />
          )}
          {unassignShow && (
            <UnassignComponent
              unassignShow={unassignShow}
              handleUnassignClose={handleUnassignClose}
              employee={unassignEmployee}
              roleId={role._id}
            />
          )}
          {viewCrewShow && (
            <ViewCrewMembers
              viewCrewShow={viewCrewShow}
              handleViewCrewClose={handleViewCrewClose}
              selectedEmployee={selectedButtonAssign}
              roleName={role.roleName}
              role={role}
            />
          )}
          {assignJobShow && (
            <AssignJobComponent
              assignJobShow={assignJobShow}
              handleAssignJobClose={handleAssignJobClose}
              selectedEmployees={selected}
              unSelectAll={unSelectAll}
              roleEmployees={rows}
            />
          )}
        </Paper>
      )}
    </div>
  );
};

SpecificRoleEmployeesComponent.propTypes = {
  role: PropTypes.shape({
    employees: PropTypes.arrayOf(),
    roleName: PropTypes.string,
    permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
    count: PropTypes.number.isRequired,
    _id: PropTypes.string
  }).isRequired,
  lastRole: PropTypes.shape({
    _id: PropTypes.string
  }).isRequired,
  firstRole: PropTypes.shape({
    _id: PropTypes.string
  }).isRequired
};

export default SpecificRoleEmployeesComponent;
