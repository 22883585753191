import axios from "../../axios-jwt";
import i18n from "../../i18n";

import {
  CLEAR_SEARCH_RESULTS,
  SEACRH_EMPLOYEES_BY_EQUIPMENT
} from "./actionTypes";
import {
  apiStartLoader,
  apiStopLoader,
  customFailedMessage
} from "./ApiStatusActions";

export async function searchEmployeesByEquipment(
  userDetails,
  selectedCompany,
  param
) {
  if (selectedCompany.code) {
    return axios.get(
      `employees?companyCode=${selectedCompany.code}&equipmentsAssigned=true&search=${param}&orderBy=empID-asc`
    );
  }
  return axios.get(
    `employees?companyCode=${userDetails.companyCode}&equipmentsAssigned=true&search=${param}&orderBy=empID-asc`
  );
}

export function searchEmployeesByEquipmentResults(empByEqSearchResults) {
  return { type: SEACRH_EMPLOYEES_BY_EQUIPMENT, empByEqSearchResults };
}

export function searchEmployeesByEquipmentWithParam(queryParam, id) {
  return (dispatch, getState) => {
    dispatch(apiStartLoader(id || "emplByEqSearchResults"));
    searchEmployeesByEquipment(
      getState().userDetails,
      getState().selectedCompany,
      queryParam
    )
      .then((res) => {
        if (res.status === 200) {
          dispatch(searchEmployeesByEquipmentResults(res.data));
          dispatch(apiStopLoader(id || "emplByEqSearchResults"));
        }
        return undefined;
      })
      .catch((error) => {
        if (error) dispatch(customFailedMessage(i18n.t("invalid_search_term")));
        dispatch(apiStopLoader(id || "emplByEqSearchResults"));
      });
  };
}

export function clearEmployeesByEquipmentResults(actionType, clear) {
  return { type: CLEAR_SEARCH_RESULTS, actionType, clear };
}
