import axios from "../../axios-jwt";

import {
  CLEAR_ARF_SEARCH_RESULTS,
  LOAD_ARF_SUCCESS,
  RESET_ARF_EQUIPMENTS_LOADED_RESULTS
} from "./actionTypes";
import { apiFailed, apiStartLoader, apiStopLoader } from "./ApiStatusActions";
import { getEmployeesByEquipment } from "./EmployeesByEquipmentActions";
import { searchEmployeesByEquipment } from "./EmployeesByEquipmentSearchActions";
import { searchEquipments } from "./EquipmentSearchActions";
import { getAssignedEmployees } from "./PersonnelActions";
import { searchPersonnel } from "./PersonnelSearchActions";
// Common actions
export function loadARFSuccess(key, data, offset) {
  return { type: LOAD_ARF_SUCCESS, payload: { key, data }, offset };
}

export const clearARFSearchResults = (actionType) => {
  return {
    type: CLEAR_ARF_SEARCH_RESULTS,
    actionType
  };
};

export const resetARFEquipmentsLoadedResults = () => ({
  type: RESET_ARF_EQUIPMENTS_LOADED_RESULTS
});

// Company Job actions
async function getJobs(
  userDetails,
  selectedcompany,
  offset,
  limit,
  orderBy = "code",
  order = "asc"
) {
  if (selectedcompany) {
    return axios.get(
      `companies/${selectedcompany.code}/jobs?offset=${offset}&limit=${limit}&orderBy=${orderBy}-${order}`
    );
  }
  return axios.get(
    `companies/${userDetails.companyCode}/jobs?offset=${offset}&limit=${limit}&orderBy=${orderBy}-${order}`
  );
}

async function getSearchedJobs(
  userDetails,
  selectedcompany,
  search,
  offset,
  limit,
  orderBy = "code",
  order = "asc"
) {
  if (selectedcompany) {
    return axios.get(
      `companies/${selectedcompany.code}/jobs?search=${search}&offset=${offset}&limit=${limit}&orderBy=${orderBy}-${order}`
    );
  }
  return axios.get(
    `companies/${userDetails.companyCode}/jobs?search=${search}&offset=${offset}&limit=${limit}&orderBy=${orderBy}-${order}`
  );
}

export function getCompanyJobs(
  offset = 0,
  limit = 20,
  orderBy = "code",
  order = "asc"
) {
  return async (dispatch, getState) => {
    dispatch(apiStartLoader("companyJobs"));
    try {
      const obj = await getJobs(
        getState().userDetails,
        getState().selectedCompany,
        offset,
        limit,
        orderBy,
        order
      );
      dispatch(loadARFSuccess("companyJobs", obj.data.jobs, offset));
      dispatch(apiStopLoader("companyJobs"));
    } catch (err) {
      console.log(dispatch(apiFailed(err)));
      dispatch(apiStopLoader("companyJobs"));
    }
  };
}

export function getSearchedCompanyJobs(
  search,
  offset = 0,
  limit = 50,
  orderBy = "code",
  order = "asc"
) {
  return async (dispatch, getState) => {
    dispatch(apiStartLoader("companyJobsSearchResults"));
    try {
      const obj = await getSearchedJobs(
        getState().userDetails,
        getState().selectedCompany,
        search,
        offset,
        limit,
        orderBy,
        order
      );
      dispatch(
        loadARFSuccess("companyJobsSearchResults", obj.data.jobs, offset)
      );
      dispatch(apiStopLoader("companyJobsSearchResults"));
    } catch (err) {
      console.log(dispatch(apiFailed(err)));
      dispatch(apiStopLoader("companyJobsSearchResults"));
    }
  };
}

// Personnels actions

export function getAssignedEmployeePersonnel(offset = 0, limit = 20) {
  return async (dispatch) => {
    dispatch(apiStartLoader("personnel"));
    try {
      const obj = await getAssignedEmployees(offset, limit);
      if (obj.status === 200) {
        dispatch(loadARFSuccess("personnel", obj.data.employees, offset));
        dispatch(apiStopLoader("personnel"));
      }
    } catch (err) {
      console.log(dispatch(apiFailed(err)));
      dispatch(apiStopLoader("personnel"));
    }
  };
}

export function getSearchedPersonnel(queryParam) {
  return async (dispatch) => {
    dispatch(apiStartLoader("personnelSearchResults"));
    try {
      const obj = await searchPersonnel(queryParam);
      if (obj.status === 200) {
        dispatch(loadARFSuccess("personnelSearchResults", obj.data.employees));
        dispatch(apiStopLoader("personnelSearchResults"));
      }
    } catch (err) {
      console.log(dispatch(apiFailed(err)));
      dispatch(apiStopLoader("personnelSearchResults"));
    }
  };
}

// Employees By Equipment action (Assigned to)

export function loadEmployeesByEquipment(offset = 0, limit = 20) {
  return async (dispatch, getState) => {
    dispatch(apiStartLoader("employeesByEquipment"));
    try {
      const obj = await getEmployeesByEquipment(
        getState().userDetails,
        getState().selectedCompany,
        offset,
        limit
      );
      dispatch(
        loadARFSuccess("employeesByEquipment", obj.data.employees, offset)
      );
      dispatch(apiStopLoader("employeesByEquipment"));
    } catch (error) {
      console.log(dispatch(apiFailed(error)));
      dispatch(apiStopLoader("employeesByEquipment"));
    }
  };
}

export function getSearchedEmployeesByEquipment(queryParam) {
  return async (dispatch, getState) => {
    dispatch(apiStartLoader("employeesByEqSearchResults"));
    try {
      const res = await searchEmployeesByEquipment(
        getState().userDetails,
        getState().selectedCompany,
        queryParam
      );
      if (res.status === 200) {
        dispatch(loadARFSuccess("employeesByEqSearchResults", res.data));
        dispatch(apiStopLoader("employeesByEqSearchResults"));
      }
    } catch (err) {
      console.log(dispatch(apiFailed(err)));
      dispatch(apiStopLoader("employeesByEqSearchResults"));
    }
  };
}

// Equipments action (Select Equipments)

async function getEquipments(
  userCompanyCode,
  changedCompanyCode,
  offset,
  limit
) {
  let resp;
  if (changedCompanyCode) {
    resp = axios.get(
      `equipments?companyCode=${changedCompanyCode}&offset=${offset}&limit=${limit}`
    );
  } else
    resp = axios.get(
      `equipments?companyCode=${userCompanyCode}&offset=${offset}&limit=${limit}`
    );
  return resp;
}

export function loadEquipments(offset = 0, limit = 20) {
  return async (dispatch, getState) => {
    dispatch(apiStartLoader("equipments"));
    try {
      const res = await getEquipments(
        getState().userDetails.companyCode,
        getState().selectedCompany.code,
        offset,
        limit
      );
      if (res.status === 200) {
        dispatch(loadARFSuccess("equipments", res.data.equipments, offset));
        dispatch(apiStopLoader("equipments"));
      }
    } catch (error) {
      console.log(dispatch(apiFailed(error)));
      dispatch(apiStopLoader("equipment"));
    }
  };
}

export function getSearchedEquipments(queryParam) {
  return async (dispatch, getState) => {
    dispatch(apiStartLoader("equipmentsSearchResults"));
    try {
      const res = await searchEquipments(
        getState().userDetails.companyCode,
        getState().selectedCompany.companyCode,
        queryParam
      );
      if (res.status === 200) {
        dispatch(loadARFSuccess("equipmentsSearchResults", res.data));
        dispatch(apiStopLoader("equipmentsSearchResults"));
      }
    } catch (err) {
      console.log(dispatch(apiFailed(err)));
      dispatch(apiStopLoader("equipmentsSearchResults"));
    }
  };
}
