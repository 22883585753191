// List of all the action types
export const LOAD_COMPANIES_SUCCESS = "LOAD_COMPANIES_SUCCESS";

export const LOAD_MULTIPLE_COMPANIES_ASSIGNED =
  "LOAD_MULTIPLE_COMPANIES_ASSIGNED";

export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const LOGIN_FAILED = "LOGIN_FAILED";

export const SELECTED_COMPANY = "SELECTED_COMPANY";
export const RESET_SELECTED_COMPANY = "RESET_SELECTED_COMPANY";

export const API_FAILED = "API_FAILED";
export const API_SUCCESS = "API_SUCCESS";
export const SET_API_STATUS = "SET_API_STATUS";
export const API_START_LOADER = "API_START_LOADER";
export const API_STOP_LOADER = "API_STOP_LOADER";

export const CREATE_TIME_LOG = "CREATE_TIME_LOG";
export const LOAD_TIME_LOGS = "LOAD_TIME_LOGS";
export const EDIT_TIME_LOGS = "EDIT_TIME_LOGS";
export const SPLIT_TIME_LOGS = "SPLIT_TIME_LOGS";

export const LOAD_WEEKLY_REPORTS = "LOAD_WEEKLY_REPORTS";
export const LOAD_WEEKLY_REPORT_BY_ID = "LOAD_WEEKLY_REPORT_BY_ID";
export const LOAD_BULK_WEEKLY_REPORTS = "LOAD_BULK_WEEKLY_REPORTS";
export const SORT_WEEKLY_REPORTS = "SORT_WEEKLY_REPORTS";

export const SEARCH_CREW = "SEARCH_CREW";
export const CLEAR_SEARCH_RESULTS = "CLEAR_SEARCH_RESULTS";

export const SEACRH_FOREMAN_ASSIGNTO = "SEACRH_FOREMAN_ASSIGNTO";
export const CLEAR_FOREMAN_SEARCH_RESULTS = "CLEAR_FOREMAN_SEARCH_RESULTS";

export const LOAD_FOREMEN = "LOAD_FOREMEN";

export const FILTER_WEEKLY_REPORTS = "FILTER_WEEKLY_REPORTS";
export const UPDATE_TIMELOGS_FOR_WEEK = "UPDATE_TIMELOGS_FOR_WEEK";

export const APPROVE_BULK_TIMESHEET = "APPROVE_BULK_TIMESHEET";
export const APPROVE_CREW_TIMESHEET = "APPROVE_CREW_TIMESHEET";
export const LIST_APPROVED_TIMESHEET = "LIST_APPROVED_TIMESHEET";

export const LOAD_EMPLOYEES_SUCCESS = "LOAD_EMPLOYEES_SUCCESS";
export const UPDATE_EMPLOYEE_ROLE_SUCCESS = "UPDATE_EMPLOYEE_ROLE_SUCCESS";
export const SEARCH_EMPLOYEES = "SEARCH_EMPLOYEES";
export const UPDATE_ROLE_EMPLOYEE = "UPDATE_ROLE_EMPLOYEE";
export const EDIT_AUTO_LUNCH = "EDIT_AUTO_LUNCH";
export const EDIT_REGISTER_FINGER_PRINT = "EDIT_REGISTER_FINGER_PRINT";
export const EDIT_ASSIGN_ALL_JOBS = "EDIT_ASSIGN_ALL_JOBS";
export const SORT_EMPLOYEES = "SORT_EMPLOYEES";
export const FILTER_EMPLOYEES = "FILTER_EMPLOYEES";
export const CLEAR_EMPLOYEE_SEARCH_RESULTS = "CLEAR_EMPLOYEE_SEARCH_RESULTS";

export const GET_ORGANIZATION_DATA = "GET_ORGANIZATION_DATA";

export const GET_PAYPERIODS_SUCCESS = "GET_PAYPERIODS_SUCCESS";

export const GET_PERMISSIONS_SUCCESS = "GET_PERMISSIONS_SUCCESS";

export const GET_PERSONNEL_SUCCESS = "GET_PERSONNEL_SUCCESS";
export const UPDATE_PERSONNEL_SUCCESS = "UPDATE_PERSONNEL_SUCCESS";
export const SEARCH_PERSONNEL = "SEARCH_PERSONNEL";

export const LOAD_ROLES_SUCCESS = "LOAD_ROLES_SUCCESS";
export const UPDATE_PERTICULAR_ROLE_SUCCESS = "UPDATE_PERTICULAR_ROLE_SUCCESS";

export const GET_USER_DETAILS = "GET_USER_DETAILS";
export const LOAD_USER_JOB_DETAILS = "LOAD_USER_JOB_DETAILS";
export const SEARCH_USER_JOBS = "SEARCH_USER_JOBS";

export const ALL_CREW = "ALL_CREW";

export const USER_LOGOUT = "USER_LOGOUT";

export const SWITCH = "SWITCH";
export const LOAD_EQUIPMENT = "LOAD_EQUIPMENT";
export const UPDATE_EQUIPMENT = "UPDATE_EQUIPMENT";
export const SEARCH_EQUIPMENT = "SEARCH_EQUIPMENT";
export const SORT_EQUIPMENT = "SORT_EQUIPMENT";
export const FILTER_EQUIPMENT = "FILTER_EQUIPMENT";
export const UPDATE_SEARCHED_EQUIPMENT = "UPDATE_SEARCHED_EQUIPMENT";
export const LOAD_EQUIPMENT_REPORTS = "LOAD_EQUIPMENT_REPORTS";
export const SORT_EQUIPMENT_REPORTS = "SORT_EQUIPMENT_REPORTS";
export const LOAD_EQUIPMENT_REPORT_BY_ID = "LOAD_EQUIPMENT_REPORT_BY_ID";
export const LOAD_BULK_EQUIPMENT_REPORTS = "LOAD_BULK_EQUIPMENT_REPORTS";

export const LOAD_JOBS_SUCCESS = "LOAD_JOBS_SUCCESS";
export const UNASSIGN_JOB_SUCCESS = "UNASSIGN_JOB_SUCCESS";
export const CLEAR_JOBS = "CLEAR_JOBS";

export const ACTIVITY_LOGS_TIMEENTRY = "ACTIVITY_LOGS_TIMEENTRY";
export const UNASSIGN_EQUIPMENT_SUCCESS = "UNASSIGN_EQUIPMENT_SUCCESS";
export const GET_ASSIGNED_EMPLOYEES = "GET_ASSIGNED_EMPLOYEES";
export const ACTIVITY_LOGS_EQUIPMENT_SUCCESS =
  "ACTIVITY_LOGS_EQUIPMENT_SUCCESS";
export const FILTERED_EQUIMENT_REPORT = "FILTERED_EQUIMENT_REPORT";
export const LOAD_EMPLOYEES_BY_JOB = "LOAD_EMPLOYEES_BY_JOB";
export const SEACRH_EMPLOYEES_BY_JOB = "SEACRH_EMPLOYEES_BY_JOB";
export const LOAD_EMPLOYEES_BY_EQUIPMENT = "LOAD_EMPLOYEES_BY_EQUIPMENT";
export const SEACRH_EMPLOYEES_BY_EQUIPMENT = "SEACRH_EMPLOYEES_BY_EQUIPMENT";

export const LOAD_COUNT = "LOAD_COUNT";
export const UPDATE_COUNT = "UPDATE_COUNT";
export const CLEAR_COUNT = "CLEAR_COUNT";

export const GET_ROLE_EMPLOYEES = "LOAD_EMPLOYEES_BY_JOB";
export const CHANGE_SPECIFIC_ROLE = "CHANGE_SPECIFIC_ROLE";
export const UPDATE_CREW_PERSONNEL_TO = "UPDATE_CREW_PERSONNEL_TO";
export const UNASSIGN_CREW_PERSONNEL_TO = "UNASSIGN_CREW_PERSONNEL_TO";

export const SEARCH_ROLE_EMPLOYEES = "SEARCH_ROLE_EMPLOYEES";
export const CHANGE_SPECIFIC_ROLE_ROLESEARCH =
  "CHANGE_SPECIFIC_ROLE_ROLESEARCH";
export const UPDATE_CREW_PERSONNEL_TO_ROLESEARCH =
  "UPDATE_CREW_PERSONNEL_TO_ROLESEARCH";
export const UNASSIGN_CREW_PERSONNEL_TO_ROLESEARCH =
  "UNASSIGN_CREW_PERSONNEL_TO_ROLESEARCH";

export const GET_EMPLOYEES_NOT_CREW_MEMBERS = "GET_EMPLOYEES_NOT_CREW_MEMBERS";

export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";

export const SEARCH_JOBS = "SEARCH_JOBS";
export const CLEAR_JOBS_SEARCH_RESULTS = "CLEAR_JOBS_SEARCH_RESULTS";

export const SEACRH_TIME_ENTRY_EMPLOYEES = "SEACRH_TIME_ENTRY_EMPLOYEES";

export const GET_PENDING_APPROVALS_COUNT_SUCCESS =
  "GET_PENDING_APPROVALS_COUNT_SUCCESS";

export const EQUIPMENT_INVALID_ENTRIES = "EQUIPMENT_INVALID_ENTRIES";
export const CLEAR_EQUIPMENT_INVALID_ENTRIES =
  "CLEAR_EQUIPMENT_INVALID_ENTRIES";

export const GET_SYNC_STATUS = "GET_SYNC_STATUS";

export const SET_FILTER = "SET_FILTER";
export const GET_FILTER = "GET_FILTER";
export const RESET_FILTER = "RESET_FILTER";
export const OVERRIDE_FILTER = "OVERRIDE_FILTER";

export const LOAD_LABOUR_TIME_REPORTS_PREVIEW =
  "LOAD_LABOUR_TIME_REPORTS_PREVIEW";

export const OVERRIDE_AUTHORISERS_FILTER = "OVERRIDE_AUTHORISERS_FILTER";
export const RESET_AUTHORISERS_FILTER = "RESET_AUTHORISERS_FILTER";

export const CLEAR_ARF_SEARCH_RESULTS = "CLEAR_ARF_SEARCH_RESULTS";
export const LOAD_ARF_SUCCESS = "LOAD_ARF_SUCCESS";
export const RESET_ARF_EQUIPMENTS_LOADED_RESULTS =
  "RESET_ARF_EQUIPMENTS_LOADED_RESULTS";

export const LOAD_EMPLOYEES_REPORTS_PREVIEW = "LOAD_EMPLOYEES_REPORTS_PREVIEW";
export const LOAD_EQUIPMENTS_REPORTS_PREVIEW =
  "LOAD_EQUIPMENTS_REPORTS_PREVIEW";
