import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Switch
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";

import permissionsList from "../../../utillities/Permissions";

const ChangePermissions = (props) => {
  const {
    show,
    handlePermissionsClose,
    permissionRole,
    handlePermissionsChange,
    permissions
  } = props;
  const [permissionCheck, setPermissionCheck] = useState([]);
  const { t } = useTranslation();

  const handlePermissionsCheck = () => {
    setPermissionCheck(
      permissionRole.permissions.filter((includedPer) => {
        return permissions
          .map((validPer) => validPer.key === includedPer)
          .includes(true);
      })
    );
  };

  useEffect(handlePermissionsCheck, [permissionRole]);

  function handlePermissionChange(permission) {
    const idx = permissionCheck.indexOf(permission);

    const copyPermissionsCheck = permissionCheck.slice();

    if (idx !== -1) {
      copyPermissionsCheck.splice(idx, 1);
      setPermissionCheck(copyPermissionsCheck);
    } else {
      copyPermissionsCheck.push(permission);
      setPermissionCheck(copyPermissionsCheck);
    }
  }

  const allowMobileOrDesktopAccess = (permission) => {
    const tsMobileEnabled = permissionCheck.includes(
      permissionsList.TS_MOBILE.key
    );
    const tsDesktopEnabled = permissionCheck.includes(
      permissionsList.TS_DESKTOP.key
    );
    if (tsMobileEnabled && tsDesktopEnabled) return false; // if existing role permissions contain ts_desktop and ts_mobile.
    if (tsMobileEnabled && permission === permissionsList.TS_DESKTOP.key)
      return true;
    if (tsDesktopEnabled && permission === permissionsList.TS_MOBILE.key)
      return true;
    return false;
  };

  return (
    <Dialog
      open={show}
      onClose={handlePermissionsClose}
      maxWidth='sm'
      fullWidth
    >
      <DialogTitle>
        {permissionRole.roleName !== "" &&
          `${permissionRole.roleName}  ${t(
            "permissions.change_permission_title"
          )}`}
      </DialogTitle>
      <DialogContent dividers>
        <Grid container>
          <Grid item>
            <small className='text-muted'>
              {t("permissions.change_permission_title")}
            </small>
          </Grid>
        </Grid>
        <Grid container>
          {permissions.map((per) => (
            <Grid
              container
              item
              xs={6}
              key={per.key}
              style={{ alignItems: "center" }}
            >
              <Tooltip
                title={per.description}
                id={per.key}
                placement='right-end'
                arrow
              >
                <Grid item>
                  <Switch
                    id={per.key}
                    label={per.label}
                    checked={permissionCheck.includes(per.key)}
                    disabled={allowMobileOrDesktopAccess(per.key)}
                    onChange={() => handlePermissionChange(per.key)}
                  />
                  {per.label}
                </Grid>
              </Tooltip>
            </Grid>
          ))}
        </Grid>
        <small className='text-muted'>
          {t("permissions.save_permission_msg")}
        </small>
      </DialogContent>

      <DialogActions>
        <Button
          color='primary'
          variant='contained'
          onClick={handlePermissionsClose}
        >
          {t("commonActions.cancel")}
        </Button>
        <Button
          color='primary'
          variant='contained'
          onClick={() => {
            handlePermissionsChange(permissionRole, permissionCheck);
            handlePermissionsClose();
          }}
        >
          {t("commonActions.ok")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ChangePermissions.propTypes = {
  show: PropTypes.bool.isRequired,
  handlePermissionsClose: PropTypes.func.isRequired,
  permissionRole: PropTypes.shape({
    roleName: PropTypes.string,
    permissions: PropTypes.arrayOf(PropTypes.string)
  }).isRequired,
  permissions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  handlePermissionsChange: PropTypes.func.isRequired
};
export default ChangePermissions;
