import axios from "../../axios-jwt";

import {
  ACTIVITY_LOGS_EQUIPMENT_SUCCESS,
  FILTER_EQUIPMENT,
  LOAD_EQUIPMENT,
  SORT_EQUIPMENT,
  UNASSIGN_EQUIPMENT_SUCCESS,
  UPDATE_EQUIPMENT
} from "./actionTypes";
import * as ApiStatusActions from "./ApiStatusActions";
import loadCount from "./CountActions";
import * as EquipmentSearchActions from "./EquipmentSearchActions";

async function getEquipments(
  userCompanyCode,
  changedCompanyCode,
  otherParams,
  offset,
  limit
) {
  let resp;
  if (changedCompanyCode) {
    resp = axios.get(
      `equipments?companyCode=${changedCompanyCode}&${otherParams}&offset=${offset}&limit=${limit}`
    );
  } else
    resp = axios.get(
      `equipments?companyCode=${userCompanyCode}&${otherParams}&offset=${offset}&limit=${limit}`
    );
  return resp;
}

async function updateEquipments(payload) {
  const resp = axios.put("/equipments", payload);
  return resp;
}

async function getEquipmentLogs(equipmentID, offset, limit) {
  const resp = axios.get(
    `equipments/${equipmentID}/activityLogs?offset=${offset}&limit=${limit}`
  );
  return resp;
}

function unassignEquipments(empIds, equipmentId) {
  const resp = axios({
    method: "DELETE",
    url: `/equipments/${equipmentId}/employees${`?_ids=${empIds}`}`
  });
  return resp;
}

export function loadEquipmentsSuccess(equipments, offset) {
  return { type: LOAD_EQUIPMENT, equipments, offset };
}

export function updateEquipmentsSuccess(equipments) {
  return { type: UPDATE_EQUIPMENT, equipments };
}

export function unassignEquipmentSuccess(equipment) {
  return { type: UNASSIGN_EQUIPMENT_SUCCESS, equipment };
}

export function activityLogsEquipmentSuccess(offset, activityLogs) {
  return { type: ACTIVITY_LOGS_EQUIPMENT_SUCCESS, offset, activityLogs };
}

export function loadSortedEquipmentSuccess(offset, equipments) {
  return { type: SORT_EQUIPMENT, offset, equipments };
}

export function loadFilteredEquipmentSuccess(offset, equipments) {
  return { type: FILTER_EQUIPMENT, offset, equipments };
}

export function loadEquipmentActivityLogs(equipmentID, offset, limit) {
  return (dispatch) => {
    dispatch(ApiStatusActions.apiStartLoader("activityLogsEquipment"));
    getEquipmentLogs(equipmentID, offset, limit)
      .then((res) => {
        if (res.status === 200) {
          dispatch(activityLogsEquipmentSuccess(offset, res.data));
        }
        dispatch(ApiStatusActions.apiStopLoader("activityLogsEquipment"));
        return undefined;
      })
      .catch((error) => {
        console.log(dispatch(ApiStatusActions.apiFailed(error)));
        dispatch(ApiStatusActions.apiStopLoader("activityLogsEquipment"));
      });
  };
}

export function loadEquipments(otherParams, offset, limit) {
  return (dispatch, getState) => {
    dispatch(ApiStatusActions.apiStartLoader("equipment"));
    getEquipments(
      getState().userDetails.companyCode,
      getState().selectedCompany.code,
      otherParams,
      offset,
      limit
    )
      .then((res) => {
        if (res.status === 200) {
          dispatch(loadEquipmentsSuccess(res.data.equipments, offset));
          dispatch(loadCount("equipment", res.data.count));
          dispatch(ApiStatusActions.apiStopLoader("equipment"));
        }
        return undefined;
      })
      .catch((error) => {
        console.log(dispatch(ApiStatusActions.apiFailed(error)));
        dispatch(ApiStatusActions.apiStopLoader("equipment"));
      });
  };
}

export function updateEquipmentAssignment(payload) {
  return (dispatch, getState) => {
    dispatch(ApiStatusActions.apiStartLoader("equipment"));
    updateEquipments(payload)
      .then((res) => {
        if (res.status === 200) {
          // Update initially loaded equipments after assignment
          if (
            getState().equipments.length &&
            !getState().equipmentSearchResults.length
          )
            dispatch(updateEquipmentsSuccess(res.data));
          // If searched equipments are assigned, update the equipmentSearchResults reducer
          else
            dispatch(
              EquipmentSearchActions.updateSearchedEquipmentsSuccess(res.data)
            );
          dispatch(ApiStatusActions.apiStopLoader("equipment"));
        }
        return undefined;
      })
      .catch((error) => {
        console.log(dispatch(ApiStatusActions.apiFailed(error)));
        dispatch(ApiStatusActions.apiStopLoader("equipment"));
      });
  };
}

export function unassignEquipment(empIds, equipmentId) {
  return (dispatch, getState) => {
    dispatch(ApiStatusActions.apiStartLoader("unassignEquipment"));
    return unassignEquipments(empIds, equipmentId)
      .then((resp) => {
        if (resp.status === 200) {
          dispatch(unassignEquipmentSuccess(resp.data));
          // Update initially loaded equipments after unassignment
          if (
            getState().equipments.length &&
            !getState().equipmentSearchResults.length
          )
            dispatch(updateEquipmentsSuccess([resp.data]));
          // If searched equipments are unassigned, update the equipmentSearchResults reducer
          else
            dispatch(
              EquipmentSearchActions.updateSearchedEquipmentsSuccess([
                resp.data
              ])
            );
          dispatch(ApiStatusActions.apiStopLoader("unassignEquipment"));
        }
        return undefined;
      })
      .catch((error) => {
        console.log(dispatch(ApiStatusActions.apiFailed(error)));
        dispatch(ApiStatusActions.apiStopLoader("unassignEquipment"));
      });
  };
}

export function sortEquipment(sortParams, offset, limit) {
  return (dispatch, getState) => {
    dispatch(ApiStatusActions.apiStartLoader("equipment"));
    getEquipments(
      getState().userDetails.companyCode,
      getState().selectedCompany.code,
      sortParams,
      offset,
      limit
    )
      .then((res) => {
        if (res.status === 200) {
          dispatch(
            loadSortedEquipmentSuccess(offset, res.data.equipments || res.data)
          );
          dispatch(ApiStatusActions.apiStopLoader("equipment"));
        }
        return undefined;
      })
      .catch((error) => {
        console.log(dispatch(ApiStatusActions.apiFailed(error)));
        dispatch(ApiStatusActions.apiStopLoader("equipment"));
      });
  };
}

export function filterEquipment(filterParams, offset, limit) {
  return (dispatch, getState) => {
    dispatch(ApiStatusActions.apiStartLoader("equipment"));
    getEquipments(
      getState().userDetails.companyCode,
      getState().selectedCompany.code,
      filterParams,
      offset,
      limit
    )
      .then((res) => {
        if (res.status === 200) {
          dispatch(loadCount("equipment_filters", res.data.count));
          dispatch(loadFilteredEquipmentSuccess(offset, res.data.equipments));
          dispatch(ApiStatusActions.apiStopLoader("equipment"));
        }
        return undefined;
      })
      .catch((error) => {
        console.log(dispatch(ApiStatusActions.apiFailed(error)));
        dispatch(ApiStatusActions.apiStopLoader("equipment"));
      });
  };
}
