import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { Checkbox, FormControlLabel, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  container: {
    width: 360,
    margin: "0 auto 16px auto"
  },
  groupLabel: {
    textTransform: "capitalize"
  },
  formControlLabel: {
    margin: 0
  }
}));

const GroupCheckboxComponent = ({ label, groupState, onChange }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.container}>
      <label className={classes.groupLabel}>{label}</label>
      <Grid container spacing={2}>
        {Object.keys(groupState).map((val) => (
          <Grid item xs={6} key={val}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={groupState[val]}
                  onChange={onChange}
                  name={val}
                />
              }
              label={t(`payroll.missing_info_labels.${val}`)}
              className={classes.formControlLabel}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

GroupCheckboxComponent.propTypes = {
  label: PropTypes.string.isRequired,
  groupState: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func.isRequired
};

export default GroupCheckboxComponent;
