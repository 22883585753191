import {
  CLEAR_EMPLOYEE_SEARCH_RESULTS,
  SEARCH_EMPLOYEES,
  UPDATE_ROLE_EMPLOYEE
} from "../actions/actionTypes";

export default function EmployeeSearchReducer(state = [], action) {
  switch (action.type) {
    case SEARCH_EMPLOYEES: {
      return action.offset
        ? [...state, ...action.employeeSearchResults]
        : action.employeeSearchResults;
    }
    case UPDATE_ROLE_EMPLOYEE: {
      const copyEmployees = state.map((employee) => {
        if (action.SelectedEmployees.includes(employee._id)) {
          return {
            ...employee,
            role: {
              _id: action.selectedRole._id,
              roleName: action.selectedRole.roleName
            }
          };
        }
        return employee;
      });
      return copyEmployees;
    }
    case CLEAR_EMPLOYEE_SEARCH_RESULTS: {
      // Clear earch results from state so that upon searching the next time, previous results won't show up
      return [];
    }
    default:
      return state;
  }
}
