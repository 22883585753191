import i18n from "i18next";

import {
  API_FAILED,
  API_START_LOADER,
  API_STOP_LOADER,
  API_SUCCESS,
  SET_API_STATUS
} from "./actionTypes";

export function apiFailed(error) {
  if (error === "Unauthorized") {
    return { type: API_FAILED, status: { error } };
  }
  if (error && error.response && error.response.status && error.response.data) {
    return { type: API_FAILED, status: { error: error.response.data.message } };
  }
  if (!navigator.onLine) {
    return {
      type: API_FAILED,
      status: { error: i18n.t("no_internet") }
    };
  }
  if (error && error.message) {
    return {
      type: API_FAILED,
      status: { error: error.message }
    };
  }
  return { type: API_FAILED, status: { error: i18n.t("wrong_with_network") } };
}

export function customFailedMessage(message) {
  return { type: API_FAILED, status: { error: message } };
}

export function apiSuccess(message) {
  if (message) {
    return { type: API_SUCCESS, status: { success: message } };
  }
  return { type: API_SUCCESS, status: { success: "Action success" } };
}

export function apiStartLoader(reducer) {
  return { type: API_START_LOADER, reducer };
}

export function apiStopLoader(reducer) {
  return { type: API_STOP_LOADER, reducer };
}

export function setApiState() {
  return { type: SET_API_STATUS };
}
