import { orderBy } from "lodash";

import {
  FILTER_EQUIPMENT,
  LOAD_EQUIPMENT,
  SORT_EQUIPMENT,
  UPDATE_EQUIPMENT
} from "../actions/actionTypes";

export default function equipmentsReducer(state = [], action) {
  switch (action.type) {
    case LOAD_EQUIPMENT:
      return action.offset
        ? [...state, ...action.equipments]
        : orderBy(action.equipments, "name", "asc");
    case UPDATE_EQUIPMENT: {
      const equipments = state.map((eq) => eq);
      const udpates = equipments.map((equipment) => {
        const udpatedEquipment = action.equipments.find(
          (update) => update._id === equipment._id
        );
        return udpatedEquipment
          ? { ...equipment, assignedTo: udpatedEquipment.assignedTo }
          : equipment;
      });
      return udpates;
    }
    case SORT_EQUIPMENT:
      return action.offset
        ? [...state, ...action.equipments]
        : action.equipments;
    case FILTER_EQUIPMENT:
      return action.offset
        ? [...state, ...action.equipments]
        : action.equipments;
    default:
      return state;
  }
}
