import axios from "../../axios-jwt";
import i18n from "../../i18n";

import {
  ACTIVITY_LOGS_TIMEENTRY,
  CREATE_TIME_LOG,
  EDIT_TIME_LOGS,
  LOAD_TIME_LOGS,
  SPLIT_TIME_LOGS
} from "./actionTypes";
import {
  apiFailed,
  apiStartLoader,
  apiStopLoader,
  apiSuccess,
  customFailedMessage
} from "./ApiStatusActions";
import loadPendingApprovalsCount from "./PendingApprovalsCountActions";
import * as WeeklyReportsActions from "./WeeklyReportsActions";

async function createTimelog(payload) {
  const resp = axios.post("timelogs", payload);
  return resp;
}

async function getTimelogs(payPeriodId, crewId) {
  const resp = axios.get(`timelogs?payperiod=${payPeriodId}&crew=${crewId}`);
  return resp;
}

async function editTimelogs(payload) {
  const resp = axios.put("timelogs", payload);
  return resp;
}

export function createTimelogsSuccess(timelog) {
  return { type: CREATE_TIME_LOG, timelog };
}

export function loadTimelogsSuccess(timelogs) {
  return { type: LOAD_TIME_LOGS, timelogs };
}

export const editTimelogsSuccess = (timelogs) => {
  return { type: EDIT_TIME_LOGS, timelogs };
};

export const splitTimelogsSuccess = (timelogs) => {
  return { type: SPLIT_TIME_LOGS, timelogs };
};

export const activityLogsSuccess = (activityLogsTimeEntries) => {
  return { type: ACTIVITY_LOGS_TIMEENTRY, activityLogsTimeEntries };
};

export function loadTimelogs(payPeriodId, crewId) {
  return (dispatch) => {
    dispatch(apiStartLoader());
    return getTimelogs(payPeriodId, crewId)
      .then((obj) => {
        dispatch(loadTimelogsSuccess(obj.data));
        dispatch(apiStopLoader());
      })
      .catch((error) => {
        dispatch(apiStopLoader());
        console.log(dispatch(apiFailed(error)));
      });
  };
}

export function createTimelogObject(payload) {
  return (dispatch) => {
    dispatch(apiStartLoader("weeklyReports"));
    createTimelog(payload)
      .then((res) => {
        if (res.status === 200) {
          dispatch(apiSuccess(i18n.t("payroll.new_entry_created")));
          dispatch(createTimelogsSuccess(res.data));
          dispatch(apiStopLoader("weeklyReports"));
        }
        return undefined;
      })
      .catch((error) => {
        dispatch(apiStopLoader("weeklyReports"));
        console.log(dispatch(apiFailed(error)));
      });
  };
}

export function updateTimelogs(
  type,
  payload,
  payPeriodId,
  crewId,
  filterQuery
) {
  return (dispatch) => {
    dispatch(apiStartLoader("weeklyReports"));
    editTimelogs(payload)
      .then((res) => {
        if (res.status === 200) {
          res.data.map((log) => {
            if (log.error)
              return dispatch(
                customFailedMessage(
                  res.data.map((l) => l.error && l.error.message).join("||")
                )
              );
            if (!log.error && type === "create")
              return dispatch(apiSuccess(i18n.t("payroll.new_entry_created")));
            if (!log.error && type === "edit")
              return dispatch(apiSuccess(i18n.t("payroll.saved")));
            return dispatch(apiSuccess(i18n.t("payroll.deleted")));
          });
          dispatch(
            WeeklyReportsActions.loadWeeklyReportById(
              payPeriodId,
              crewId,
              filterQuery
            )
          );
          dispatch(apiStopLoader("weeklyReports"));
        }
        return undefined;
      })
      .then(() => {
        dispatch(loadPendingApprovalsCount(payPeriodId));
      })
      .catch((error) => {
        dispatch(apiStopLoader("weeklyReports"));
        console.log(dispatch(apiFailed(error)));
      });
  };
}

export function bulkEntryTimeLogs(payload, payPeriodId, crewIds) {
  return (dispatch) => {
    dispatch(apiStartLoader("weeklyReports"));
    axios
      .put("/timelogs/bulk", payload)
      .then((res) => {
        if (res.status === 200) {
          dispatch(apiSuccess(res.data.message));
          dispatch(
            WeeklyReportsActions.loadBulkWeeklyReports(payPeriodId, crewIds)
          );
          dispatch(apiStopLoader("weeklyReports"));
        }
      })
      .then(() => {
        dispatch(loadPendingApprovalsCount(payPeriodId));
      })
      .catch((error) => {
        console.log(dispatch(apiFailed(error)));
        dispatch(apiStopLoader("weeklyReports"));
      });
    dispatch({ type: "DEFAULT" });
  };
}

export function splitTimelogs(payload, payPeriodId, crewId) {
  return (dispatch) => {
    dispatch(apiStartLoader("weeklyReports"));
    editTimelogs(payload, payPeriodId, crewId)
      .then((res) => {
        if (res.status === 200) {
          if (res.data[0].error) {
            dispatch(apiFailed(res.data[0].error));
            dispatch(apiStopLoader("weeklyReports"));
          } else {
            dispatch(apiSuccess(i18n.t("payroll.saved")));
            dispatch(splitTimelogsSuccess(res.data));
            dispatch(
              WeeklyReportsActions.loadWeeklyReportById(payPeriodId, crewId)
            );
            dispatch(apiStopLoader("weeklyReports"));
          }
        }
      })
      .catch((error) => {
        console.log(dispatch(apiFailed(error)));
        dispatch(apiStopLoader("weeklyReports"));
      });
  };
}

export function getActivityLogs(id) {
  return (dispatch) => {
    dispatch(apiStartLoader("activityLogsTimeEntries"));
    axios
      .get(`timelogs/${id}/activitylogs`)
      .then((res) => {
        dispatch(activityLogsSuccess(res.data));
        dispatch(apiStopLoader("activityLogsTimeEntries"));
      })
      .catch((err) => {
        dispatch(apiFailed(err));
        dispatch(apiStopLoader("activityLogsTimeEntries"));
      });
  };
}
