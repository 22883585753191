import { UNASSIGN_EQUIPMENT_SUCCESS } from "../actions/actionTypes";

export default function unassignEquipmentReducer(state = [], action) {
  switch (action.type) {
    case UNASSIGN_EQUIPMENT_SUCCESS:
      return action.equipment;
    default:
      return state;
  }
}
