import axios from "../../axios-jwt";
import i18n from "../../i18n";

import { EQUIPMENT_INVALID_ENTRIES } from "./actionTypes";
import {
  apiFailed,
  apiStartLoader,
  apiStopLoader,
  apiSuccess,
  customFailedMessage
} from "./ApiStatusActions";
import * as EquipmentReportsActions from "./EquipmentReportsActions";

async function editEquipmentLogs(payload) {
  const resp = axios.put("equipmentlogs", payload);
  return resp;
}

export function updateEquipmentLogs(type, payload, payPeriodId, equipmentId) {
  return (dispatch) => {
    dispatch(apiStartLoader("equipmentReports"));
    editEquipmentLogs(payload)
      .then((res) => {
        if (res.status === 200) {
          res.data.map((log) => {
            if (log.error)
              return dispatch(
                customFailedMessage(
                  res.data.map((l) => l.error && l.error.message).join("||")
                )
              );
            if (!log.error && type === "create")
              return dispatch(apiSuccess(i18n.t("payroll.new_entry_created")));
            if (!log.error && type === "edit")
              return dispatch(apiSuccess(i18n.t("payroll.saved")));
            return dispatch(apiSuccess(i18n.t("payroll.deleted")));
          });
          dispatch(
            EquipmentReportsActions.loadEquipmentReportById(
              payPeriodId,
              equipmentId
            )
          );
          dispatch(apiStopLoader("equipmentReports"));
        }
        return undefined;
      })
      .catch((error) => {
        dispatch(apiStopLoader("equipmentReports"));
        console.log(dispatch(apiFailed(error)));
      });
  };
}

export function bulkEntryEquipment(payload, payPeriodId, equipmentId) {
  return (dispatch) => {
    dispatch(apiStartLoader("equipmentReports"));
    axios
      .put("/equipmentlogs/bulk", payload)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.hasEntriesExceedingTotalHoursLimit) {
            dispatch({
              type: EQUIPMENT_INVALID_ENTRIES,
              equipmentInvalidEntries: res.data.equipmentsInInvalidEntries
            });
          } else {
            dispatch(apiSuccess(res.data.message));
          }
          dispatch(
            EquipmentReportsActions.loadBulkWeeklyReports(
              payPeriodId,
              equipmentId
            )
          );
          dispatch(apiStopLoader("equipmentReports"));
        }
      })
      .catch((error) => {
        console.log(dispatch(apiFailed(error)));
        dispatch(apiStopLoader("equipmentReports"));
      });
    dispatch({ type: "DEFAULT" });
  };
}
