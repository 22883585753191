import axios from "../../axios-jwt";
import i18n from "../../i18n";

import { CLEAR_SEARCH_RESULTS, SEARCH_USER_JOBS } from "./actionTypes";
import {
  apiStartLoader,
  apiStopLoader,
  customFailedMessage
} from "./ApiStatusActions";

async function searchUserJobs(param) {
  const resp = axios.get(`employees/me/jobs?search=${param}&orderBy=code-asc`);
  return resp;
}

export function searchUserJobsResults(userJobsSearchResults) {
  return { type: SEARCH_USER_JOBS, userJobsSearchResults };
}

export function searchUserJobsWithParam(queryParam, dropDownSearch) {
  return (dispatch) => {
    dispatch(apiStartLoader(dropDownSearch || "userJobsSearchResults"));
    searchUserJobs(queryParam)
      .then((res) => {
        if (res.status === 200) {
          dispatch(searchUserJobsResults(res.data));
          dispatch(apiStopLoader(dropDownSearch || "userJobsSearchResults"));
        }
        return undefined;
      })
      .catch((error) => {
        if (error) dispatch(customFailedMessage(i18n.t("invalid_search_term")));
        dispatch(apiStopLoader(dropDownSearch || "userJobsSearchResults"));
      });
  };
}

export function clearUserJobsResults(actionType, clear) {
  return { type: CLEAR_SEARCH_RESULTS, actionType, clear };
}
