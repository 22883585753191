import { LOAD_EMPLOYEES_BY_JOB } from "../actions/actionTypes";

export default function EmployeeByJobsReducer(state = [], action) {
  switch (action.type) {
    case LOAD_EMPLOYEES_BY_JOB:
      return action.offset ? [...state, ...action.employees] : action.employees;
    default:
      return state;
  }
}
