import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { cloneDeep } from "lodash";
import PropTypes from "prop-types";

import Checkbox from "@material-ui/core/Checkbox";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

import useInfiniteScroll from "../../../custom-hooks/useInfiniteScroll";
import TableHeaderWrapper from "../../UI/TableHeaderWrapper";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%"
  },
  paper: {
    minHeight: "500px",
    maxHeight: "500px",
    overflow: "scroll",
    width: "100%"
  },
  progressBar: { position: "sticky", zIndex: 40 },
  tableWrapper: {
    overflowX: "auto"
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  }
}));

const SelectEmployeesTableComponent = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    employees,
    selected,
    setSelected,
    handleScroll,
    selectedDropDown,
    order,
    setOrder,
    orderBy,
    setOrderBy
  } = props;
  const [scrollTop, setScrollTop] = useState(0);
  const [scrollHeight, setScrollHeight] = useState(0);
  const [clientHeight, setClientHeight] = useState(0);

  // Custom hook for infinite scroll
  const [isFetching, toggle] = useInfiniteScroll(
    clientHeight,
    scrollTop,
    scrollHeight
  );

  // Send scroll event result to parent component to send API calls for pagination
  useEffect(() => {
    if (isFetching) {
      handleScroll(isFetching);
      toggle(isFetching);
      setScrollTop(0);
      setScrollHeight(0);
      setClientHeight(0);
    }
    // eslint-disable-next-line
  }, [isFetching]);

  const headerdata = [
    {
      id: "empID",
      numeric: false,
      disablePadding: false,
      label: t("authorizerContainer.emp_id"),
      isSortable: true
    },
    {
      id: "empName",
      numeric: false,
      disablePadding: false,
      label: t("authorizerContainer.name"),
      isSortable: true
    },
    {
      id: "Role",
      numeric: false,
      disablePadding: false,
      label: t("authorizerContainer.role"),
      isSortable: false
    }
  ];

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  };
  // handling checkboxes
  const handleSelectAllClick = (event) => {
    const copySelected = cloneDeep(selected);
    if (employees.filter((emp) => selected.includes(emp._id)).length > 0) {
      setSelected(
        copySelected.filter(
          (select) => !employees.find((emp) => emp._id === select)
        )
      );
      return;
    }
    if (event.target.checked) {
      const newSelecteds = employees.map((n) => n._id);
      setSelected((select) => [...select, ...newSelecteds]);
      return;
    }
    setSelected(
      copySelected.filter((select) =>
        employees.find((emp) => emp._id === select)
      )
    );
  };

  const handleClick = (event, rowIndex, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <Paper
      className={classes.paper}
      onScroll={(event) => {
        event.persist();
        setScrollTop(event.target.scrollTop);
        setScrollHeight(event.target.scrollHeight);
        setClientHeight(event.target.clientHeight);
      }}
    >
      <Table
        className={classes.table}
        aria-labelledby='tableTitle'
        size='small'
        aria-label='enhanced table'
        stickyHeader
        key={selectedDropDown}
      >
        <TableHeaderWrapper
          classes={classes}
          numSelected={
            employees &&
            employees.length &&
            employees.filter((emp) => selected.includes(emp._id)).length
          }
          order={order}
          x={orderBy}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          rowCount={employees && employees.length}
          headCells={headerdata}
        />
        {employees && employees.length > 0 && (
          <TableBody>
            {employees.map((row, index) => {
              // row index
              const isItemSelected = isSelected(row._id);
              const labelId = `enhanced-table-checkbox-${index}`;
              return (
                <TableRow
                  hover
                  onClick={(event) => handleClick(event, index, row._id)}
                  role='checkbox'
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row._id}
                  selected={isItemSelected}
                >
                  <TableCell padding='checkbox'>
                    <Checkbox
                      checked={isItemSelected}
                      inputProps={{ "aria-labelledby": labelId }}
                    />
                  </TableCell>
                  <TableCell component='th' id={labelId} scope='row'>
                    {row.empID}
                  </TableCell>
                  <TableCell component='th' id={labelId} scope='row'>
                    {row.empName}
                  </TableCell>
                  <TableCell component='th' id={labelId} scope='row'>
                    {row.role && row.role.roleName}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        )}
      </Table>
    </Paper>
  );
};
SelectEmployeesTableComponent.propTypes = {
  employees: PropTypes.shape().isRequired,
  setSelected: PropTypes.func.isRequired,
  selected: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleScroll: PropTypes.func.isRequired,
  selectedDropDown: PropTypes.string.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  setOrder: PropTypes.func.isRequired,
  setOrderBy: PropTypes.func.isRequired
};

export default SelectEmployeesTableComponent;
