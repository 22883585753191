import React from "react";
import PropTypes from "prop-types";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const ConfirmationDialogComponent = (props) => {
  const { open, close, selectedData, handleDialog, dialogAttributes } = props;
  return (
    <div>
      <Dialog
        BackdropProps={{ style: { invisible: false } }}
        open={open}
        onClose={close}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        {dialogAttributes.title && (
          <DialogTitle id='alert-dialog-title'>
            {dialogAttributes.title}
          </DialogTitle>
        )}
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {dialogAttributes.contentText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {dialogAttributes.cancel && (
            <Button onClick={() => close()} color='primary'>
              {dialogAttributes.cancel}
            </Button>
          )}
          {dialogAttributes.accept && (
            <Button
              onClick={() => {
                handleDialog(selectedData);
                close();
              }}
              color='primary'
            >
              {dialogAttributes.accept}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};
ConfirmationDialogComponent.propTypes = {
  dialogAttributes: PropTypes.shape({
    title: PropTypes.string.isRequired,
    contentText: PropTypes.string.isRequired,
    cancel: PropTypes.string.isRequired,
    accept: PropTypes.string.isRequired
  }).isRequired,
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  handleDialog: PropTypes.func.isRequired,
  selectedData: PropTypes.string
};

export default ConfirmationDialogComponent;
