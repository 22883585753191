import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import lodash from "lodash";
import PropTypes from "prop-types";

import { Divider, LinearProgress } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";

import * as EmployeeActions from "../../../redux/actions/EmployeeActions";

import SelectJobComponent from "./SelectJobComponent";

const useStyles = makeStyles(() => ({
  title: {
    display: "flex",
    alignItems: "baseline",
    overflow: "hidden"
  },
  AssignJob: {
    minWidth: "10%"
  },
  typography: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    marginLeft: "6px"
  }
}));

const AssignJobComponent = (props) => {
  const { apiStatus } = useSelector((state) => state);
  const { t } = useTranslation();

  const headerConfigJobs = [
    {
      id: "code",
      disablePadding: false,
      label: t("authorizerContainer.code"),
      isSortable: false
    },
    {
      id: "name",
      disablePadding: false,
      label: t("authorizerContainer.jobs_capitalized"),
      isSortable: false
    }
  ];
  const dispatch = useDispatch();
  const classes = useStyles();

  const {
    assignJobShow,
    handleAssignJobClose,
    selectedEmployees,
    unSelectAll,
    roleEmployees
  } = props;

  const [selected, setSelected] = useState({}); // array of jobs with selected operational unit values
  function handleOnSave() {
    const editedJobsToSave = Object.keys(selected).filter(
      (jobCode) => selected[jobCode].filter((ou) => ou.length).length
    );
    dispatch(
      EmployeeActions.assignJobsToEmployees(
        selectedEmployees,
        lodash.pick(selected, [...editedJobsToSave])
      )
    );
    handleAssignJobClose();
    unSelectAll();
  }

  return (
    <>
      <Dialog open={assignJobShow} fullWidth maxWidth='lg'>
        <DialogTitle
          id='max-width-dialog-title'
          className={classes.dialogTitle}
        >
          <div className={classes.title}>
            <span className={classes.AssignJob}>{t("assignJob.title")}</span>
            <span className={classes.typography}>
              <Typography noWrap>
                <small className='text-muted'>
                  {`${selectedEmployees.length} ${t(
                    "payroll.emp_selected"
                  )} - ${roleEmployees
                    .filter((emp) => selectedEmployees.includes(emp._id))
                    .map((e) => e.empName)
                    .join(",")}
                `}
                </small>
              </Typography>
            </span>
          </div>
        </DialogTitle>
        {apiStatus && apiStatus.isLoading && <LinearProgress />}
        <Divider />
        <DialogContent>
          <div style={{ display: "flex" }}>
            <SelectJobComponent
              selected={selected}
              setSelected={setSelected}
              headerConfigJobs={headerConfigJobs}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleAssignJobClose()} color='primary'>
            {t("commonActions.cancel")}
          </Button>
          <Button
            onClick={() => {
              handleOnSave();
            }}
            color='primary'
          >
            {t("commonActions.save")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

AssignJobComponent.propTypes = {
  assignJobShow: PropTypes.bool.isRequired,
  handleAssignJobClose: PropTypes.func.isRequired,
  selectedEmployees: PropTypes.arrayOf(PropTypes.string).isRequired,
  unSelectAll: PropTypes.func.isRequired,
  roleEmployees: PropTypes.arrayOf(PropTypes.shape()).isRequired
};

export default AssignJobComponent;
