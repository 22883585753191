import axios from "../../axios-jwt";

import {
  CHANGE_SPECIFIC_ROLE,
  EDIT_ASSIGN_ALL_JOBS,
  EDIT_AUTO_LUNCH,
  EDIT_REGISTER_FINGER_PRINT,
  GET_ROLE_EMPLOYEES,
  UNASSIGN_CREW_PERSONNEL_TO,
  UPDATE_CREW_PERSONNEL_TO
} from "./actionTypes";
import * as ApiStatusActions from "./ApiStatusActions";

async function getRoleEmployees(id, offset, limit, order, orderBy) {
  const resp = axios.get(
    `roles/${id}/employees?offset=${offset}&limit=${limit}&orderBy=${order}-${orderBy}`
  );
  return resp;
}

export function loadRoleEmployeesSuccess(id, employees, offset) {
  return { type: GET_ROLE_EMPLOYEES, id, employees, offset };
}

export function loadRoleEmployees(
  id,
  offset,
  limit,
  order = "empName",
  orderBy = "asc"
) {
  return (dispatch) => {
    dispatch(ApiStatusActions.apiStartLoader(id));
    return getRoleEmployees(id, offset, limit, order, orderBy)
      .then((obj) => {
        if (obj.status === 200) {
          dispatch(loadRoleEmployeesSuccess(id, obj.data, offset));
          dispatch(ApiStatusActions.apiStopLoader(id));
        }
      })
      .catch((err) => {
        dispatch(ApiStatusActions.apiStopLoader(id));
        dispatch(ApiStatusActions.apiFailed(err));
      });
  };
}

// change roles from specific role
export function UpdateSpecificRoleEmployees(
  SelectedEmployee,
  previousRole,
  selectedRole
) {
  return (dispatch) => {
    dispatch({
      type: CHANGE_SPECIFIC_ROLE,
      SelectedEmployee,
      previousRole,
      selectedRole
    });
  };
}

// when assigned to clicked from crew members
export function UpdateCrewPersonnelTo(
  personnelToArray,
  SelectedEmployees,
  roleId
) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_CREW_PERSONNEL_TO,
      personnelToArray,
      SelectedEmployees,
      roleId
    });
  };
}

// when role user is unassigned from crew members or last level
export function UnassignPersonnelTo(id, unassigned, roleId) {
  return (dispatch) => {
    dispatch({ type: UNASSIGN_CREW_PERSONNEL_TO, id, unassigned, roleId });
  };
}

const putAutoLunch = (empId, autoLunchBoolean) => {
  return axios({
    method: "PUT",
    url: "employees",
    data: [{ _id: empId, autoFillLunchTime: autoLunchBoolean }]
  });
};

export function autoLunch(roleId, empId, autoLunchBoolean) {
  return (dispatch) => {
    dispatch(ApiStatusActions.apiStartLoader(roleId));
    return putAutoLunch(empId, autoLunchBoolean)
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: EDIT_AUTO_LUNCH, roleId, empId, autoLunchBoolean });
          dispatch(ApiStatusActions.apiStopLoader(roleId));
        }
      })
      .catch((err) => {
        dispatch(ApiStatusActions.apiFailed(err));
        dispatch(ApiStatusActions.apiStopLoader(roleId));
      });
  };
}

const employeePermissionChange = (empId, data) => {
  return axios({
    method: "PUT",
    url: `employees/${empId}`,
    data
  });
};

export function registerFingerPrint(roleId, empId, fingerPrintBoolean) {
  return (dispatch) => {
    dispatch(ApiStatusActions.apiStartLoader(roleId));
    return employeePermissionChange(empId, {
      canRegisterFingerPrint: fingerPrintBoolean
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: EDIT_REGISTER_FINGER_PRINT,
            roleId,
            empId,
            fingerPrintBoolean
          });
          dispatch(ApiStatusActions.apiStopLoader(roleId));
        }
      })
      .catch((err) => {
        dispatch(ApiStatusActions.apiFailed(err));
        dispatch(ApiStatusActions.apiStopLoader(roleId));
      });
  };
}

export function assignAllJobs(roleId, empId, assignAllJobsBoolean) {
  return (dispatch) => {
    dispatch(ApiStatusActions.apiStartLoader(roleId));
    return employeePermissionChange(empId, { hasAllJobs: assignAllJobsBoolean })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: EDIT_ASSIGN_ALL_JOBS,
            roleId,
            empId,
            assignAllJobsBoolean
          });
          dispatch(ApiStatusActions.apiStopLoader(roleId));
        }
      })
      .catch((err) => {
        dispatch(ApiStatusActions.apiFailed(err));
        dispatch(ApiStatusActions.apiStopLoader(roleId));
      });
  };
}
