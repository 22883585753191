import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import i18n from "i18next";
import {
  cloneDeep,
  findLastIndex,
  flatten,
  get,
  includes,
  range,
  uniq,
  uniqBy
} from "lodash";
import moment from "moment";
import PropTypes from "prop-types";

import { Box, LinearProgress } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Collapse from "@material-ui/core/Collapse";
import Fab from "@material-ui/core/Fab";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FilterListIcon from "@material-ui/icons/FilterList";

import { ReactComponent as ExpandAllIcon } from "../../assets/img/dropdown1.svg";
import { ReactComponent as CollapseAllIcon } from "../../assets/img/dropdown2.svg";
import * as ApprovalActions from "../../redux/actions/ApprovalActions";
import * as TimeEntryEmployeeSearchActions from "../../redux/actions/TimeEntryEmployeeSearchActions";
import * as TimeEntryReportFilterActions from "../../redux/actions/TimeEntryReportFilterActions";
import * as WeeklyReportsActions from "../../redux/actions/WeeklyReportsActions";
import {
  tableStyles,
  toolbarStyles
} from "../../styles/components/WeeklyReportsTableStyles";
import { DATE_TIME_FORMAT } from "../../utillities/DateFormats";
import permissionsList from "../../utillities/Permissions";
import SearchAutocompleteComponent from "../UI/SearchAutocompleteComponent";

import ApprovalsTimelineToolTip from "./ApprovalsTimelineToolTip";
import BulkEntryComponent from "./BulkEntryComponent";
import TimeEntryDetailsComponent from "./TimeEntryDetailsComponent";
import TimeEntryReportsFiltersComponent from "./TimeEntryReportsFiltersComponent";

const TimeEntryReportsComponent = (props) => {
  const { t } = useTranslation();

  const classes = tableStyles();
  const toolbarClasses = toolbarStyles();
  const dispatch = useDispatch();
  const {
    organizationDetails,
    userJobs,
    payPeriodsOptions,
    payPeriodsHandler,
    payPeriodId,
    payPeriodIndex,
    dateRange,
    isPayPeriodLocked,
    setJobOffset,
    jobOffset,
    exportReportClickedRef,
    dropdownLimit,
    setDropdownOffset,
    handleDropdownScroll,
    currentFilter,
    setLockUnlockPayPeriodConfirmationDialog
  } = props;

  const {
    weeklyReports,
    userDetails,
    apiStatus,
    timeEntryEmpSearchResults,
    selectedCompany,
    payPeriods
  } = useSelector((state) => state);

  useEffect(() => {
    //Setting back to false on comp. mount
    exportReportClickedRef.current = false;
    return () => {
      /* Resetting the filter conditionally on unmount based on exportReportClickedRef 
        such that filter is not reset if exportReportClickedRef.current is true */
      if (!exportReportClickedRef.current)
        dispatch(TimeEntryReportFilterActions.resetFilter());
    };
    // eslint-disable-next-line
  }, []);

  const approveTimesheetRef = useRef();
  const prevPayperiodRef = useRef(payPeriodId);

  const [selected, setSelected] = useState([]);
  const [bulkEntryShow, setBulkEntryShow] = useState(false);
  const [expanded, setExpanded] = useState({});
  const [order, setOrder] = useState({ empName: "asc" });
  const [sortBy, setSortBy] = useState("empName");
  const [isSortApplied, setIsSortApplied] = useState(false);
  const [filterQuery, setFilterQuery] = useState("");
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(50);
  const [paginationLimit, setPaginationLimit] = useState(50);
  const [filterDrawerState, setFilterDrawerState] = useState({
    right: false
  });

  const sortReports = (query, sortOffset, sortLimit) =>
    dispatch(
      WeeklyReportsActions.sortWeeklyReports(query, sortOffset, sortLimit)
    );
  const searchTimeEntryEmp = (query) =>
    query &&
    dispatch(
      TimeEntryEmployeeSearchActions.searchTimeEntryEmployeeWithParam(query)
    );
  const clearTimeEntryEmp = (type, clear) =>
    dispatch(
      TimeEntryEmployeeSearchActions.clearTimeEntryEmployeeResults(type, clear)
    );

  const roleLevel = userDetails && userDetails.role.roleLevel;
  const isPayrollUser =
    userDetails &&
    userDetails.role.permissions.includes(permissionsList.LABOR_UPLOAD.key);
  const disableOnLoad =
    apiStatus &&
    apiStatus.isLoading &&
    (apiStatus.reducer.includes("weeklyReports") ||
      apiStatus.reducer.includes("PendingApprovalsCount"));

  const loadWeeklyReports = (isExported = false, setQuery = null) => {
    if (!payPeriodId) return;
    if (selectedCompany.code === userDetails.companyCode) {
      // Reset offset to 0 if payperiod is changed
      const currentOffset =
        prevPayperiodRef.current !== payPeriodId || isExported ? 0 : offset;

      const query =
        filterQuery.length || (setQuery || "").length
          ? `payperiod=${payPeriodId}${
              setQuery !== null ? setQuery : filterQuery
            }&orderBy=${sortBy}-${order[sortBy]}`
          : `payperiod=${payPeriodId}&orderBy=${sortBy}-${order[sortBy]}`;

      switch (true) {
        case isSortApplied:
          sortReports(query, currentOffset, limit);
          setIsSortApplied(false);
          break;
        case isFilterApplied && Object.keys(filterQuery).length:
          dispatch(
            WeeklyReportsActions.filteredWeeklyReports(
              query,
              currentOffset,
              limit
            )
          );
          break;
        default:
          dispatch(
            WeeklyReportsActions.loadWeeklyReports(query, currentOffset, limit)
          );
          break;
      }
    }
    prevPayperiodRef.current = payPeriodId;
  };

  useEffect(loadWeeklyReports, [payPeriodId, offset, limit, sortBy, order]);

  const onPayPeriodChange = () => {
    setSelected([]);
  };
  useEffect(onPayPeriodChange, [payPeriodId]);

  const isTimeSheetExported = useRef(
    apiStatus.reducer.includes("exportCSVfile")
  );
  const loadEntriesAfterExport = () => {
    if (!apiStatus.reducer.includes("exportCSVfile") && isTimeSheetExported) {
      if (!offset) loadWeeklyReports(true);
      else setOffset(0);
      isTimeSheetExported.current = apiStatus.reducer.includes("exportCSVfile");
    }
  };
  useEffect(loadEntriesAfterExport, [
    apiStatus.reducer.includes("exportCSVfile")
  ]);

  const hoursTypeOptions = () =>
    ((organizationDetails || {}).hoursType || []).map((type, index) => ({
      name: type,
      id: index
    }));

  const toggleDrawer = (side, isOpen) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setFilterDrawerState({ [side]: isOpen });
  };

  const setFiltersState = (drawerState, filterApplied, query) => {
    setSelected([]);
    setExpanded([]);
    setFilterDrawerState(drawerState);
    setIsFilterApplied(filterApplied);
    setFilterQuery(query);
    if (query !== filterQuery && offset === 0) loadWeeklyReports(false, query);
    if (query !== filterQuery && offset > 0) setOffset(0);

    if (!query.length) setOffset(0);
  };

  const handleExpandClick = (e, index) => {
    // eslint-disable-next-line no-return-assign
    setExpanded((exp) => ({
      ...exp,
      [index]: (expanded[index] = !expanded[index])
    }));
  };

  const handleBulkEntryClickOpen = () => {
    setBulkEntryShow(true);
  };

  const handleBulkEntryClose = () => {
    setBulkEntryShow(false);
  };

  const headers = uniqBy(
    [
      {
        id: "crew.empID",
        type: "string",
        numeric: false,
        disablePadding: true,
        label: t("payroll.empId_label"),
        sortable: true,
        classes: classes.cell
      },
      {
        id: "crew.empName",
        type: "string",
        numeric: false,
        disablePadding: true,
        label: t("payroll.name_label"),
        sortable: true,
        classes: classes.cell
      },
      ...dateRange.map((date, index) => ({
        id: moment.utc(date).format("DD-MM-YYYY"),
        date: moment.utc(date).format("DD-MM-YYYY"),
        key: "hoursWorkedByJobs",
        index,
        numeric: false,
        disablePadding: true,
        label: moment.utc(date).locale(i18n.language).format("ddd MM/DD"),
        sortable: false,
        classes: classes.cell
      })),
      {
        id: "totalHoursOfWeekByJobs",
        type: "number",
        numeric: false,
        disablePadding: true,
        label: `${t("payroll.total_hours_job_label")}`,
        sortable: false,
        classes: classes.cell
      },
      {
        id: "totalHoursOfWeek",
        type: "number",
        numeric: false,
        disablePadding: true,
        label: `${t("payroll.total_hours_all_label")}`,
        sortable: false,
        classes: classes.cell
      },
      {
        id: "loggedBy",
        type: "array",
        mapKey: "empName",
        numeric: false,
        disablePadding: true,
        label: `${t("payroll.logged_by_label")}`,
        sortable: false,
        classes: clsx(classes.cell, classes.loggedByCell)
      },
      {
        id: "approvedBy",
        type: "array",
        mapKey: "empName",
        numeric: false,
        disablePadding: true,
        label: `${t("payroll.approved_by")}`,
        sortable: false,
        classes: clsx(classes.cell, classes.loggedByCell)
      },
      {
        id: "actions",
        type: "actions",
        numeric: false,
        disablePadding: true,
        label: "",
        sortable: false,
        classes: clsx(classes.cell, classes.fixedColumn)
      }
    ],
    "id"
  );

  const handleSelectAllClick = (event) => {
    if (selected.length > 0) {
      setSelected([]);
      return;
    }
    if (event.target.checked) {
      const newSelecteds =
        weeklyReports && weeklyReports.map((n) => n.crew._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleEmpListItemClick = (event, option) => {
    if (option) {
      const query = `payperiod=${payPeriodId}&crew=${option._id}`;
      dispatch(WeeklyReportsActions.filteredWeeklyReports(query, 0, 1));
    } else {
      const query = `payperiod=${payPeriodId}`;
      dispatch(WeeklyReportsActions.loadWeeklyReports(query, 0, limit));
    }
    clearTimeEntryEmp("timeEntryEmpSearchResults", true);
    setExpanded({});
    setSelected([]);
  };

  const handleSort = (event, sort) => {
    if (event) {
      setIsSortApplied(true);
      setOrder((o) => ({
        ...o,
        [sort.split(".")[1]]:
          order[sort.split(".")[1]] === "asc" ? "desc" : "asc"
      }));
      setSortBy(sort.split(".")[1]);
      setOffset(0);
    }
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Check for invalid entries for a whole week and update state of APPROVE button accordingly
  const checkInvalidEntries = (row) => {
    const timeLogs = cloneDeep((row || {}).timelogsForWeek || []);
    const invalidEntries = timeLogs
      // eslint-disable-next-line no-prototype-builtins
      .map((log) => log.hasOwnProperty("invalid"))
      .includes(true);
    return invalidEntries;
  };

  const checkUntrustedEntriesByDate = (row, date) => {
    const timeLogs = cloneDeep(row.timelogsForWeek);
    let firstPunchInLogOfTheDate;
    let lastPunchOutLogOfTheDate;
    const actualTimelogsInTheDate = timeLogs.filter(
      (log) => moment.utc(log.actualDate).format("DD-MM-YYYY") === date
    );

    // donot show untrusted when day's first punchIn is trusted and last punchOut is trusted.
    if (actualTimelogsInTheDate.length) {
      firstPunchInLogOfTheDate = actualTimelogsInTheDate.reduce(
        (log, logWithLeastPunchIn) =>
          (log.punchIn || log.hoursWorked >= 0) &&
          moment(log.punchIn, DATE_TIME_FORMAT).isSameOrBefore(
            moment(logWithLeastPunchIn.punchIn || log.punchIn, DATE_TIME_FORMAT)
          )
            ? log
            : logWithLeastPunchIn
      );
      lastPunchOutLogOfTheDate = actualTimelogsInTheDate.reduce(
        (log, logWithLeastPunchOut) =>
          (log.punchOut || log.hoursWorked >= 0) &&
          moment(log.punchOut, DATE_TIME_FORMAT).isSameOrAfter(
            moment(
              logWithLeastPunchOut.punchOut || log.punchOut,
              DATE_TIME_FORMAT
            )
          )
            ? log
            : logWithLeastPunchOut
      );
    } else {
      return false;
    }
    return !(
      firstPunchInLogOfTheDate.isTrustedInTime &&
      lastPunchOutLogOfTheDate.isTrustedOutTime
    );
  };

  // Check for invalid entries for a particular day and add invalid indicator to that day's cell in the table row
  const checkInvalidEntriesbyDate = (row, date) => {
    const timeLogs = cloneDeep(row.timelogsForWeek);
    const invalidEntries = timeLogs
      .filter((log) => moment.utc(log.actualDate).format("DD-MM-YYYY") === date)
      // eslint-disable-next-line no-prototype-builtins
      .map((match) => match.hasOwnProperty("invalid"))
      .includes(true);

    return invalidEntries;
  };

  const areYouNotHaveAccessToAllJobCombinations = (row) => {
    return !row.timelogsForWeek.some((entry) => entry.hasAccess === true);
  };

  // Function for deciding wether timesheet can be approved or not
  // It also updates the label of button to the current state of timesheet
  // and disables the button accordingly
  const manageApproveButton = (row) => {
    const lengthOfRow = row && row.timelogsForWeek.length;
    const JobsLoggedInRow = uniqBy(row.approvals, "jobCode").length;
    const approvalLength =
      row &&
      row.approvals &&
      flatten(
        row.approvals.map((i) =>
          uniq(i.approvalRoles)
            .map((role) => role <= roleLevel)
            .includes(true)
        )
      ).filter((jobsApproved) => jobsApproved === true).length;
    const isHigherRoleEmployeeApproved =
      row &&
      row.approvals &&
      flatten(
        row.approvals.map((i) =>
          uniq(i.approvalRoles)
            .map((role) => role < roleLevel)
            .includes(true)
        )
      ).filter((jobsApproved) => jobsApproved === true).length;
    let label;
    switch (true) {
      case lengthOfRow === 0: // If there are no timelog entries
        label = `${t("payroll.no_timelog_entries")}`;
        break;
      case lengthOfRow > 0 && approvalLength === 0: // if there are timelog entries but no approvalRoles
        label = checkInvalidEntries(row)
          ? `${t("payroll.invalid_time_entries")}`
          : `${t("payroll.approve")}`;
        break;
      case lengthOfRow > 0 && approvalLength > 0: // if there are timelog entries and approvalRoles
        if (checkInvalidEntries(row)) {
          label = `${t("payroll.invalid_time_entries")}`;
        } else {
          label =
            // eslint-disable-next-line no-nested-ternary
            JobsLoggedInRow <= approvalLength
              ? isHigherRoleEmployeeApproved === approvalLength
                ? `${t("payroll.approved")}`
                : `${t("payroll.unapprove")}`
              : `${t("payroll.approve")}`;
        }
        break;
      default:
        break;
    }
    return label;
  };

  // check if the user have permission to approve weekly timelogs
  const checkApprovePermission = () => {
    return (
      !userDetails.isSuperAdmin &&
      !userDetails.role.permissions.includes(permissionsList.LABOR_APPROVAL.key)
    );
  };

  // check if selected time entries contain invalid records for disabling Approve Selected button
  const checkSelectedHasInvalidEntries = () => {
    const rows = cloneDeep(
      selected.map((id) =>
        weeklyReports.find((report) => report.crew._id === id)
      )
    );
    return rows.map((row) => checkInvalidEntries(row)).includes(true);
  };

  // This function would be called on click of Approve all or Approve button
  // This would create an array of object containg crewID, payperiodID, jobCode &
  // would make an api request to approve timesheet
  const approveTimesheet = (e, row) => {
    let allCrewSelectedArr = [];
    if (row === undefined) {
      allCrewSelectedArr = selected.map((id) => {
        return uniqBy(
          weeklyReports
            .find((report) => report.crew._id === id)
            .timelogsForWeek.filter((log) => log.jobCode !== undefined),
          "jobCode"
        ).map((uniqJob) => {
          return {
            crewID: id,
            payPeriod: payPeriodId,
            jobCode: uniqJob.jobCode
          };
        });
      });
    } else {
      allCrewSelectedArr = uniqBy(
        weeklyReports
          .find((report) => report.crew._id === row.crew._id)
          .timelogsForWeek.filter((log) => log.jobCode !== undefined),
        "jobCode"
      ).map((uniqJob) => {
        return {
          crewID: row.crew._id,
          payPeriod: payPeriodId,
          jobCode: uniqJob.jobCode
        };
      });
    }
    approveTimesheetRef.current = dispatch(
      ApprovalActions.approveBulkTimesheet(
        payPeriodId,
        [].concat(...allCrewSelectedArr),
        filterQuery
      )
    );
    setSelected([]);
  };

  const getHoursWorkedOnSameDate = (row, date) => {
    return row.timelogsForWeek
      .filter(
        (log) =>
          moment.utc(log.date, "DD-MM-YYYY").format("DD-MM-YYYY") === date &&
          moment.utc(log.date, "DD-MM-YYYY").format("DD-MM-YYYY") ===
            moment.utc(log.actualDate).format("DD-MM-YYYY") &&
          !log.invalid
      )
      .reduce(
        (totalHours, currentTimeLog) =>
          totalHours + (currentTimeLog.hoursWorked || 0),
        0
      );
  };

  const tableToolbar = () => {
    return (
      <Toolbar className={toolbarClasses.root} key='timeEntry-table-toolbar'>
        <Typography
          className={toolbarClasses.title}
          variant='h6'
          id='tableTitle'
        >
          {payPeriodsHandler()}

          {userDetails.role.permissions.includes(
            permissionsList.LABOUR_ENTRY_PAYPERIOD_UNLOCK.key
          ) &&
            payPeriods.length &&
            isPayPeriodLocked && (
              <Fab
                variant='extended'
                color='secondary'
                size='medium'
                onClick={() => setLockUnlockPayPeriodConfirmationDialog(true)}
                className={toolbarClasses.payPeriodLockButton}
              >
                {t("commonActions.unlock_payperiod")}
              </Fab>
            )}
          {userDetails.role.permissions.includes(
            permissionsList.LABOUR_ENTRY_PAYPERIOD_LOCK.key
          ) &&
            payPeriods.length &&
            // Here intentionally checking 'false', because we don't want to show lock payperiod button if its 'undefined'
            isPayPeriodLocked === false && (
              <Fab
                variant='extended'
                color='secondary'
                size='medium'
                onClick={() => setLockUnlockPayPeriodConfirmationDialog(true)}
                className={toolbarClasses.payPeriodLockButton}
              >
                {t("payroll.lock_payperiod")}
              </Fab>
            )}
        </Typography>

        <div className={toolbarClasses.buttons}>
          {selected.length > 0 && userJobs.length > 0 && (
            <>
              <Tooltip
                title={(() => {
                  if (checkApprovePermission())
                    return t("payroll.permission_denied");
                  if (checkSelectedHasInvalidEntries())
                    return t("payroll.selected_invalid_entries");
                  return "";
                })()}
                arrow
              >
                <span>
                  <Fab
                    variant='extended'
                    color='secondary'
                    aria-label='approve-all'
                    className={toolbarClasses.fab}
                    onClick={approveTimesheet}
                    disabled={
                      isPayPeriodLocked ||
                      checkApprovePermission() ||
                      checkSelectedHasInvalidEntries()
                    }
                  >
                    {t("payroll.approve_timesheet_btn_label")}
                  </Fab>
                </span>
              </Tooltip>
              {(userDetails.isSuperAdmin ||
                userDetails.role.permissions.includes(
                  permissionsList.LABOR_BULK_ENTRY.key
                )) && (
                <Fab
                  variant='extended'
                  color='secondary'
                  aria-label='bulk-entry'
                  className={toolbarClasses.fab}
                  onClick={() => {
                    handleBulkEntryClickOpen();
                  }}
                  disabled={isPayPeriodLocked}
                >
                  {t("payroll.bulk_entry_title")}
                </Fab>
              )}
            </>
          )}
          <Fab
            onClick={toggleDrawer("right", true)}
            variant='extended'
            aria-label='filter'
            className={clsx(toolbarClasses.filter, toolbarClasses.fab)}
          >
            <FilterListIcon />
            {t("payroll.filter_btn_label")}
          </Fab>

          {/* Search Crew Members */}
          <SearchAutocompleteComponent
            handleSearch={searchTimeEntryEmp}
            searchResults={timeEntryEmpSearchResults}
            setOptionLabel={(option) => `${option.empID} - ${option.empName}`}
            showOptions
            handleListItem={handleEmpListItemClick}
            clear={() =>
              clearTimeEntryEmp(
                "timeEntryEmpSearchResults",
                !timeEntryEmpSearchResults.length
              )
            }
            disableClearable={false}
            freeSolo={false}
            key={payPeriodId}
          />
        </div>
      </Toolbar>
    );
  };

  const tableHeader = (
    <TableHead key='timeEntry-table-header'>
      <TableRow key='timeEntry-table-header-row'>
        <TableCell padding='checkbox' className={classes.cell} scope='tr'>
          <Checkbox
            color='secondary'
            indeterminate={
              selected.length > 0 && selected.length < weeklyReports.length
            }
            checked={
              selected.length === weeklyReports.length && selected.length !== 0
            }
            onClick={handleSelectAllClick}
            inputProps={{ "aria-label": "select all rows" }}
          />
        </TableCell>

        {headers.length > 5 &&
          headers.map((header) => (
            <TableCell
              key={header.id || header.label}
              padding={header.disablePadding ? "none" : "default"}
              className={clsx(classes.headerCell, {
                [classes.fixedHeader]: !header.label
              })}
              sortDirection={
                header.sortable && sortBy === header.id.split(".")[1]
                  ? order[sortBy]
                  : false
              }
              scope='tr'
            >
              {header.sortable ? (
                <TableSortLabel
                  className={classes.sortHeader}
                  active={sortBy === header.id.split(".")[1]}
                  direction={order[sortBy]}
                  onClick={(event) => handleSort(event, header.id)}
                >
                  {header && header.label ? header.label : header.id}
                </TableSortLabel>
              ) : header && header.label ? (
                header.label
              ) : (
                <Button
                  color='secondary'
                  size='small'
                  aria-label='expand-all'
                  className={classes.button}
                  disabled={disableOnLoad}
                  onClick={() => {
                    if (
                      includes(expanded, false) &&
                      expanded.length === weeklyReports.length
                    ) {
                      setExpanded((exp) => ({
                        ...exp,
                        // eslint-disable-next-line no-return-assign
                        ...weeklyReports.map((e, i) => (e[i] = !e[i]))
                      }));
                    } else if (includes(expanded, true)) {
                      setExpanded((exp, i) => ({
                        ...exp,
                        ...weeklyReports.map((e) => Boolean(e[i]))
                      }));
                    } else {
                      setExpanded((exp, i) => ({
                        ...exp,
                        ...weeklyReports.map((e) => !e[i])
                      }));
                    }
                  }}
                  endIcon={
                    includes(expanded, true) ? (
                      <CollapseAllIcon
                        fill={disableOnLoad ? "rgba(0, 0, 0, 0.26)" : "#3a5998"}
                      />
                    ) : (
                      <ExpandAllIcon
                        fill={disableOnLoad ? "rgba(0, 0, 0, 0.26)" : "#3a5998"}
                      />
                    )
                  }
                >
                  {includes(expanded, true)
                    ? t("payroll.collapse_all_btn_label")
                    : t("payroll.expand_all_btn_label")}
                </Button>
              )}
            </TableCell>
          ))}
      </TableRow>
    </TableHead>
  );

  return (
    <React.Fragment key='TimeEntryReports'>
      {payPeriodId && dateRange.length && (
        <React.Fragment key='TimeEntryReportsPaper'>
          {/* Table Toolbar */}
          {tableToolbar()}
          {apiStatus &&
            apiStatus.isLoading &&
            (apiStatus.reducer.includes("weeklyReports") ||
              apiStatus.reducer.includes("PendingApprovalsCount")) && (
              <LinearProgress />
            )}
          {/* Table Starts Here */}
          <div className={classes.tableWrapper}>
            <Table
              className={classes.table}
              size='small'
              stickyHeader
              aria-label='weekly-reports-table'
              key='weekly-reports-table'
              padding='none'
            >
              {/* Table Column Headers */}
              {tableHeader}

              <TableBody>
                {/* Table Rows */}
                {weeklyReports.length && userJobs.length ? (
                  weeklyReports.map((row, index) => {
                    const isItemSelected = isSelected(
                      row.crew._id ? row.crew._id : null
                    );
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <>
                        <TableRow
                          hover
                          role='checkbox'
                          onClick={(event) => handleClick(event, row.crew._id)}
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.crew._id || index}
                          selected={isItemSelected}
                        >
                          <TableCell
                            padding='checkbox'
                            className={classes.cell}
                            key='checkbox'
                          >
                            <Checkbox
                              color='secondary'
                              checked={isItemSelected}
                              inputProps={{ "aria-labelledby": labelId }}
                            />
                          </TableCell>

                          {row &&
                            headers.map((header) =>
                              (() => {
                                if (header.date) {
                                  return (
                                    <TableCell
                                      key={header.date}
                                      className={clsx(classes.cell, {
                                        [classes.invalidCell]:
                                          checkInvalidEntriesbyDate(
                                            row,
                                            header.date
                                          ),
                                        [classes.unTrustedCell]:
                                          checkUntrustedEntriesByDate(
                                            row,
                                            header.date
                                          )
                                      })}
                                    >
                                      {(() => {
                                        const day =
                                          row.daysInWeek[
                                            findLastIndex(
                                              row.daysInWeek || [],
                                              (eachDay) =>
                                                eachDay.dayOfWeek ===
                                                header.date
                                            )
                                          ];
                                        const { totalHoursOfDay } = day || {};
                                        const totalHoursWorkedOnSameDay =
                                          day &&
                                          row.timelogsForWeek.length &&
                                          getHoursWorkedOnSameDate(
                                            row,
                                            day.dayOfWeek
                                          );
                                        if (totalHoursOfDay) {
                                          if (
                                            totalHoursOfDay -
                                              totalHoursWorkedOnSameDay ===
                                            0
                                          )
                                            return totalHoursWorkedOnSameDay;
                                          return (
                                            <span>
                                              {totalHoursWorkedOnSameDay}
                                              <span
                                                className={
                                                  classes.sameShiftTextColor
                                                }
                                              >
                                                {` + ${Math.abs(
                                                  totalHoursOfDay -
                                                    totalHoursWorkedOnSameDay
                                                )}`}
                                              </span>
                                            </span>
                                          );
                                        }
                                        return "-";
                                      })()}
                                    </TableCell>
                                  );
                                }

                                return (
                                  <TableCell
                                    className={header.classes}
                                    key={header.id}
                                  >
                                    {
                                      {
                                        string: get(row, header.id) || "-",
                                        number:
                                          (
                                            get(row, header.id) || ""
                                          ).toLocaleString(undefined, {
                                            minimumFractionDigits: 2
                                          }) || "-",
                                        array:
                                          (((row || {})[header.id] || [])
                                            .length &&
                                            row[header.id]
                                              .map((log) => log[header.mapKey])
                                              .join(", ")) ||
                                          "-",
                                        actions: (
                                          <div className={classes.actionsCell}>
                                            <Button
                                              variant='outlined'
                                              color='secondary'
                                              size='small'
                                              aria-label='approve'
                                              className={classes.button}
                                              onClick={(event) => {
                                                event.preventDefault();
                                                event.stopPropagation();
                                                if (
                                                  manageApproveButton(row) ===
                                                  `${t("payroll.approve")}`
                                                )
                                                  approveTimesheet(event, row);
                                                else
                                                  dispatch(
                                                    ApprovalActions.unapproveTimesheet(
                                                      payPeriodId,
                                                      row.crew._id,
                                                      filterQuery
                                                    )
                                                  );
                                              }}
                                              disabled={
                                                ![
                                                  `${t("payroll.approve")}`,
                                                  `${t("payroll.unapprove")}`
                                                ].some(
                                                  (str) =>
                                                    str ===
                                                    manageApproveButton(row)
                                                ) ||
                                                (!userDetails.isSuperAdmin &&
                                                  !userDetails.role.permissions.includes(
                                                    permissionsList
                                                      .LABOR_APPROVAL.key
                                                  )) ||
                                                isPayPeriodLocked ||
                                                areYouNotHaveAccessToAllJobCombinations(
                                                  row
                                                )
                                              }
                                            >
                                              {manageApproveButton(row)}
                                            </Button>
                                            {manageApproveButton(row) ===
                                              t("payroll.approved") ||
                                            manageApproveButton(row) ===
                                              t("payroll.unapprove") ? (
                                              <ApprovalsTimelineToolTip
                                                isApprovedByAllRoles={
                                                  row.pendingApprovals &&
                                                  row.pendingApprovals
                                                    .length === 0
                                                }
                                                data={row}
                                              />
                                            ) : (
                                              <Box component='span' m={3}></Box>
                                            )}
                                            <IconButton
                                              className={clsx(classes.expand, {
                                                [classes.expandOpen]:
                                                  expanded[index]
                                              })}
                                              disabled={disableOnLoad}
                                              onClick={(event) => {
                                                event.preventDefault();
                                                event.stopPropagation();
                                                handleExpandClick(event, index);
                                              }}
                                              aria-expanded={expanded}
                                              aria-label='expand-row'
                                            >
                                              <ExpandMoreIcon />
                                            </IconButton>
                                          </div>
                                        )
                                      }[header.type]
                                    }
                                  </TableCell>
                                );
                              })()
                            )}
                        </TableRow>

                        {/* Expansion Cell */}
                        <TableRow key={`expansion-row-${row.crew._id}`}>
                          <TableCell
                            colSpan={headers.length + 1}
                            key='expansion-cell'
                          >
                            <Collapse
                              key={`collapse${index}`}
                              in={expanded[index]}
                              unmountOnExit
                            >
                              <TimeEntryDetailsComponent
                                key={JSON.stringify(row.timelogsForWeek)}
                                isPayPeriodLocked={isPayPeriodLocked}
                                rowIndex={index}
                                userJobs={userJobs}
                                payPeriodId={payPeriodId}
                                crew={get(row, "crew")}
                                timelogsForWeek={row.timelogsForWeek}
                                isPayrollUser={isPayrollUser}
                                jobsInWeek={row.jobsInWeek}
                                approvals={row.approvals}
                                OULevelNamesForTimeLogs={
                                  organizationDetails.OULevelNamesForTimeLogs
                                }
                                hoursTypeOptions={hoursTypeOptions()}
                                setJobOffset={setJobOffset}
                                jobOffset={jobOffset}
                                filterQuery={filterQuery}
                                handleCollapse={() => {
                                  setExpanded((exp) => ({
                                    ...exp,
                                    [index]: false
                                  }));
                                  setSelected([]);
                                }}
                                roleLevel={roleLevel}
                                unUploadedJobs={row.unUploadedJobs}
                              />
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })
                ) : (
                  <TableRow key='empty-row'>
                    <TableCell
                      colSpan={headers.length + 1}
                      className={classes.emptyCell}
                      key='empty-cell'
                    >
                      {apiStatus && !apiStatus.isLoading
                        ? t("commonActions.no_records")
                        : t("commonActions.loading_records")}
                    </TableCell>
                  </TableRow>
                )}

                {/* Pagination Row */}
                <TableRow key='pagination-row'>
                  <TableCell colSpan={headers.length} key='empty-cells' />
                  <TableCell
                    className={classes.paginationCell}
                    key='pagination-cell'
                  >
                    <Select
                      key='pagination-menu'
                      labelId='pagination-limit'
                      id='pagination-limit'
                      value={paginationLimit}
                      disableUnderline
                      onChange={(event) => {
                        setLimit(event.target.value);
                        setPaginationLimit(event.target.value);
                        setSelected([]);
                        setOffset(0);
                      }}
                      inputProps={{
                        className: classes.paginationDropdown
                      }}
                    >
                      <MenuItem key={25} value={25}>
                        25
                      </MenuItem>
                      {range(50, 250, 50).map((item) => (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                    <Button
                      key='show-more'
                      variant='outlined'
                      color='secondary'
                      size='small'
                      className={clsx(classes.button, classes.showMoreButton)}
                      disabled={disableOnLoad}
                      onClick={() => {
                        setLimit(paginationLimit);
                        setOffset((l) => l + paginationLimit);
                      }}
                    >
                      {t("payroll.show_more")}
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            {/* Table Ends Here */}
          </div>
        </React.Fragment>
      )}

      <TimeEntryReportsFiltersComponent
        filterDrawerState={filterDrawerState}
        toggleDrawer={toggleDrawer}
        setFiltersState={setFiltersState}
        organizationDetails={organizationDetails}
        userJobs={userJobs}
        payPeriodId={payPeriodId}
        jobOffset={jobOffset}
        setJobOffset={setJobOffset}
        handleDropdownScroll={handleDropdownScroll}
        dropdownLimit={dropdownLimit}
        setDropdownOffset={setDropdownOffset}
        currentFilter={currentFilter}
      />

      {bulkEntryShow && userJobs.length > 0 ? (
        <BulkEntryComponent
          key='bulk-entry'
          open={bulkEntryShow}
          Close={handleBulkEntryClose}
          rows={weeklyReports}
          payPeriodIndex={payPeriodIndex}
          userJobs={userJobs}
          PayPeriodSelected={payPeriodsOptions}
          selected={selected}
          setSelected={setSelected}
          jobOffset={jobOffset}
          setJobOffset={setJobOffset}
          NumberOfDays={headers.filter((header) => header.date).length}
        />
      ) : null}
    </React.Fragment>
  );
};

TimeEntryReportsComponent.propTypes = {
  organizationDetails: PropTypes.shape({
    timeEntriesRoleLevels: PropTypes.arrayOf(PropTypes.string).isRequired,
    OULevelNamesForTimeLogs: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    hoursType: PropTypes.arrayOf(PropTypes.string).isRequired,
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired
  }).isRequired,
  isPayPeriodLocked: PropTypes.bool.isRequired,
  userJobs: PropTypes.arrayOf(
    PropTypes.shape({
      operationalUnits: PropTypes.arrayOf(PropTypes.shape()).isRequired,
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired
    })
  ).isRequired,
  payPeriodsOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  payPeriodsHandler: PropTypes.func.isRequired,
  payPeriodId: PropTypes.string.isRequired,
  payPeriodIndex: PropTypes.number.isRequired,
  dateRange: PropTypes.arrayOf(PropTypes.string).isRequired,
  jobOffset: PropTypes.number.isRequired,
  setJobOffset: PropTypes.func.isRequired,
  exportReportClickedRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.bool })
  ]),
  dropdownLimit: PropTypes.number.isRequired,
  setDropdownOffset: PropTypes.func.isRequired,
  handleDropdownScroll: PropTypes.func.isRequired,
  currentFilter: PropTypes.string.isRequired,
  setLockUnlockPayPeriodConfirmationDialog: PropTypes.func.isRequired
};

export default TimeEntryReportsComponent;
