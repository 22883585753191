import axios from "../../axios-jwt";
import BASE_PATH from "../../config";
import i18n from "../../i18n";

import {
  LOAD_ROLES_SUCCESS,
  UPDATE_PERTICULAR_ROLE_SUCCESS
} from "./actionTypes";
import {
  apiFailed,
  apiStartLoader,
  apiStopLoader,
  apiSuccess
} from "./ApiStatusActions";
import * as EmployeeActions from "./EmployeeActions";
import * as EmployeeSearchActions from "./EmployeeSearchActions";
import * as RoleEmployeesActions from "./RoleEmployeesActions";
import * as RoleSearchActions from "./RoleSearchActions";
import loadUserDetails from "./UserDetailsActions";

async function getRoles(userDetails, selectedCompany) {
  if (selectedCompany.code) {
    return axios.get(`roles?companyCode=${selectedCompany.code}`);
  }
  return axios.get(`roles?companyCode=${userDetails.companyCode}`);
}

export function loadRolesSuccess(roles) {
  return { type: LOAD_ROLES_SUCCESS, roles };
}

export function loadRoles() {
  return (dispatch, getState) => {
    dispatch(apiStartLoader("Roles"));
    return getRoles(getState().userDetails, getState().selectedCompany)
      .then((obj) => {
        dispatch(loadRolesSuccess(obj.data));
        dispatch(apiStopLoader("Roles"));
      })
      .catch((err) => {
        dispatch(apiStopLoader("Roles"));
        apiFailed(err);
      });
  };
}

async function getUpdatedRoles(SelectedEmployees, selectedRole) {
  // api call with  selected Employees and selected Role
  const obj = SelectedEmployees;

  return axios({
    method: "PUT",
    url: `roles/${selectedRole._id}/employees`,
    data: obj
  });
}

export function updateRoles(SelectedEmployees, selectedRole, previousRole) {
  return (dispatch) => {
    dispatch(apiStartLoader("RoleChange"));
    getUpdatedRoles(SelectedEmployees, selectedRole)
      .then((res) => {
        if (res.status === 200) {
          dispatch(
            EmployeeSearchActions.updateRole(SelectedEmployees, selectedRole)
          );
          dispatch(
            EmployeeActions.updateEmployeesRole(SelectedEmployees, selectedRole)
          );
          dispatch(loadRoles());
          dispatch(apiStopLoader("RoleChange"));
          dispatch(apiSuccess(i18n.t("permissions.role_updated_success")));
          if (SelectedEmployees.length === 1 && previousRole.length > 0) {
            dispatch(
              RoleEmployeesActions.UpdateSpecificRoleEmployees(
                SelectedEmployees,
                previousRole,
                selectedRole
              )
            );
            dispatch(
              RoleSearchActions.UpdateSpecificRoleEmployees(
                SelectedEmployees,
                previousRole,
                selectedRole
              )
            );
          }
        }
        return undefined;
        // handle roles not updated successfully in server
      })
      .catch((err) => {
        dispatch(apiStopLoader("RoleChange"));
        apiFailed(err);
      });
  };
}

async function updateHirarchyApiCall(selectedRoles) {
  // api call with  selected Employees and selected Role
  // api call for updating roles and permissions

  const obj = selectedRoles.map((role) => {
    return {
      _id: role._id,
      roleName: role.roleName,
      permissions: role.permissions
    };
  });
  return axios({
    method: "PUT",
    url: `${BASE_PATH}roles`,
    data: obj
  });
}

export function updateHierarchy(selectedRoles) {
  return (dispatch, getState) => {
    dispatch(apiStartLoader("RoleChange"));
    updateHirarchyApiCall(selectedRoles).then((rolesData) => {
      if (rolesData.status === 200) {
        dispatch(apiSuccess(i18n.t("update_sucessfully")));
        dispatch(apiStopLoader("RoleChange"));
        getRoles(getState().userDetails, getState().selectedCompany)
          .then((obj) => {
            dispatch(loadRolesSuccess(obj.data));
          })
          .then(() => dispatch(loadUserDetails()))
          .catch((err) => {
            apiFailed(err);
            dispatch(apiStopLoader("RoleChange"));
          });
      }
    });
  };
}

function updatePerticularRoleApiCall(id) {
  return axios.get(`roles/${id}`);
}

function updatePerticularRoleSuccess(role) {
  return { type: UPDATE_PERTICULAR_ROLE_SUCCESS, role };
}

export function updatePerticularRole(id) {
  return (dispatch) => {
    updatePerticularRoleApiCall(id).then((res) => {
      if (res.status === 200) {
        dispatch(updatePerticularRoleSuccess(res.data));
      }
    });
  };
}

function loadUpdatePersonnelTo(id, unassigned) {
  const data = unassigned;
  const resp = axios({
    method: "DELETE",
    url: `/employees/${id}/personnelTo?_ids=${data.join()}`
  });
  return resp;
}

export function updatePersonnelTo(id, unassigned, roleId) {
  return (dispatch) => {
    dispatch(apiStartLoader(id));
    return loadUpdatePersonnelTo(id, unassigned)
      .then((resp) => {
        if (resp.status === 200) {
          dispatch(
            RoleEmployeesActions.UnassignPersonnelTo(id, unassigned, roleId)
          );
          dispatch(
            RoleSearchActions.UnassignPersonnelTo(id, unassigned, roleId)
          );
          dispatch(apiStopLoader(id));
        }
      })
      .catch((err) => {
        dispatch(apiFailed(err));
        dispatch(apiStopLoader(id));
      });
  };
}
