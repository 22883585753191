import React from "react";
import ReactDOM from "react-dom";

import "./interceptor";
import "./i18n";

import { checkAuth } from "./redux/actions/AuthActions";
import getStore from "./redux/storeConfig";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

getStore().then((store) => {
  store.dispatch(checkAuth()).then(() => {
    ReactDOM.render(<App store={store} />, document.getElementById("root"));
  });
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
