import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { get } from "lodash";
import PropTypes from "prop-types";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";

import tableStyles from "../../../styles/components/DetailsTableStyles";

const useStyles = makeStyles({
  cell: {
    padding: "8px !important"
  }
});

const PreviewTimeReportsDetailsComponent = ({ jobWiseBreakDown }) => {
  const classes = tableStyles();
  const localClasses = useStyles();
  const { t } = useTranslation();
  const [order, setOrder] = useState({ totalHours: "asc" });
  const [sortBy, setSortBy] = useState("totalHours");

  const headers = [
    {
      id: "job",
      type: "string",
      disablePadding: true,
      label: t("payroll.job_label"),
      sortable: true
    },
    {
      id: "phases",
      type: "string",
      disablePadding: true,
      label: t("payroll.phase"),
      sortable: false
    },
    {
      id: "crafts",
      type: "string",
      disablePadding: true,
      label: t("payroll.craft"),
      sortable: false
    },
    {
      id: "totalHours",
      type: "number",
      disablePadding: false,
      label: t("payroll.total_hours"),
      sortable: true
    }
  ];

  const handleSort = (event, sort) => {
    if (event) {
      setOrder((o) => ({
        ...o,
        [sort]: order[sort] === "asc" ? "desc" : "asc"
      }));
      setSortBy(sort);
    }
  };

  const sortedBreakDown = useMemo(() => {
    return [...jobWiseBreakDown].sort((a, b) => {
      const aValue = a[sortBy];
      const bValue = b[sortBy];
      if (typeof aValue === "string" && typeof aValue === "string") {
        return (
          aValue.localeCompare(bValue) * (order[sortBy] === "asc" ? 1 : -1)
        );
      } else {
        return (aValue > bValue ? 1 : -1) * (order[sortBy] === "asc" ? 1 : -1);
      }
    });
  }, [jobWiseBreakDown, sortBy, order]);

  return (
    <Paper className={classes.paper}>
      <Table
        className={classes.table}
        size='small'
        aria-label='details-table'
        padding='none'
      >
        <TableHead>
          <TableRow>
            {headers.map((headCell, index) => (
              <TableCell
                key={index}
                padding={headCell.disablePadding ? "none" : "default"}
                className={clsx(classes.headerCell)}
              >
                {headCell.sortable ? (
                  <TableSortLabel
                    className={classes.sortHeader}
                    active={sortBy === headCell.id}
                    direction={sortBy === headCell.id ? order[sortBy] : "asc"}
                    onClick={(event) => handleSort(event, headCell.id)}
                  >
                    {headCell && headCell.label ? headCell.label : headCell.id}
                  </TableSortLabel>
                ) : (
                  headCell && headCell.label
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {jobWiseBreakDown.length ? (
            sortedBreakDown.map((row, index) => (
              <TableRow hover tabIndex={-1} key={row.id || index}>
                {headers.map((header) => (
                  <TableCell
                    className={clsx(classes.cell, localClasses.cell)}
                    key={header.id}
                  >
                    {
                      {
                        string: get(row, header.id) || "-",

                        number:
                          (get(row, header.id) || "").toLocaleString(
                            undefined,
                            {
                              minimumFractionDigits: 2
                            }
                          ) || "-"
                      }[header.type]
                    }
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow key='empty-row'>
              <TableCell
                colSpan={headers.length + 1}
                className={clsx(classes.emptyCell, localClasses.cell)}
                key='empty-cell'
              >
                {t("commonActions.no_records")}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Paper>
  );
};

PreviewTimeReportsDetailsComponent.propTypes = {
  jobWiseBreakDown: PropTypes.arrayOf(
    PropTypes.shape({
      job: PropTypes.string,
      phases: PropTypes.string,
      crafts: PropTypes.string,
      totalHours: PropTypes.number
    })
  ).isRequired
};

export default PreviewTimeReportsDetailsComponent;
