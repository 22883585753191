import axios from "../../axios-jwt";

import {
  FILTER_WEEKLY_REPORTS,
  LOAD_BULK_WEEKLY_REPORTS,
  LOAD_WEEKLY_REPORT_BY_ID,
  LOAD_WEEKLY_REPORTS,
  SORT_WEEKLY_REPORTS,
  UPDATE_TIMELOGS_FOR_WEEK
} from "./actionTypes";
import * as ApiStatusActions from "./ApiStatusActions";

async function getWeeklyReports(query, offset, limit) {
  const resp = axios.get(
    `weeklyreports?${query}&offset=${offset}&limit=${limit}`
  );
  return resp;
}

async function getWeeklyReportById(payPeriodId, crewId, filterQuery = "") {
  const resp = axios.get(
    `weeklyreports?payperiod=${payPeriodId}&crew=${crewId}${filterQuery}`
  );
  return resp;
}

async function getFilteredWeeklyReports(payload, offset, limit) {
  const resp = axios.get(
    `weeklyreports?${payload}&offset=${offset}&limit=${limit}`
  );
  return resp;
}

export function loadWeeklyReportsSuccess(weeklyReports, offset) {
  return { type: LOAD_WEEKLY_REPORTS, weeklyReports, offset };
}

export function loadWeeklyReportByIdSuccess(weeklyReport) {
  return { type: LOAD_WEEKLY_REPORT_BY_ID, weeklyReport };
}

export function loadBulkWeeklyReportsSuccess(weeklyReports) {
  return { type: LOAD_BULK_WEEKLY_REPORTS, weeklyReports };
}

export function loadSortedWeeklyReportsSuccess(weeklyReports, offset) {
  return { type: SORT_WEEKLY_REPORTS, weeklyReports, offset };
}

export function loadFilteredWeeklyReportsSuccess(weeklyReports, offset) {
  return { type: FILTER_WEEKLY_REPORTS, weeklyReports, offset };
}

export function updateTimelogsForCrewMember(actionType, crewId, timelogs) {
  return { type: UPDATE_TIMELOGS_FOR_WEEK, actionType, crewId, timelogs };
}

export function loadWeeklyReports(loadParams, offset, limit) {
  return (dispatch) => {
    dispatch(ApiStatusActions.apiStartLoader("weeklyReports"));
    getWeeklyReports(loadParams, offset, limit)
      .then((res) => {
        if (res.status === 200) {
          dispatch(loadWeeklyReportsSuccess(res.data, offset));
          dispatch(ApiStatusActions.apiStopLoader("weeklyReports"));
        }
        return undefined;
      })
      .catch((error) => {
        console.log(dispatch(ApiStatusActions.apiFailed(error)));
        dispatch(ApiStatusActions.apiStopLoader("weeklyReports"));
      });
  };
}

export function loadWeeklyReportById(payPeriodId, crewId, filterQuery) {
  return (dispatch) => {
    dispatch(ApiStatusActions.apiStartLoader("weeklyReports"));
    getWeeklyReportById(payPeriodId, crewId, filterQuery)
      .then((res) => {
        if (res.status === 200) {
          dispatch(loadWeeklyReportByIdSuccess(res.data));
          dispatch(ApiStatusActions.apiStopLoader("weeklyReports"));
        }
        return undefined;
      })
      .catch((error) => {
        console.log(dispatch(ApiStatusActions.apiFailed(error)));
        dispatch(ApiStatusActions.apiStopLoader("weeklyReports"));
      });
  };
}

export function loadBulkWeeklyReports(payPeriodId, crewIds, filterQuery = "") {
  return (dispatch) => {
    dispatch(ApiStatusActions.apiStartLoader("weeklyReports"));
    getFilteredWeeklyReports(
      `payperiod=${payPeriodId}&crew=${crewIds}${filterQuery}`,
      0,
      crewIds.split(",").length
    )
      .then((res) => {
        if (res.status === 200) {
          dispatch(loadBulkWeeklyReportsSuccess(res.data));
          dispatch(ApiStatusActions.apiStopLoader("weeklyReports"));
        }
        return undefined;
      })
      .catch((error) => {
        console.log(dispatch(ApiStatusActions.apiFailed(error)));
        dispatch(ApiStatusActions.apiStopLoader("weeklyReports"));
      });
  };
}

export function filteredWeeklyReports(payload, offset, limit) {
  return (dispatch) => {
    dispatch(ApiStatusActions.apiStartLoader("weeklyReports"));
    getFilteredWeeklyReports(payload, offset, limit)
      .then((res) => {
        if (res.status === 200) {
          dispatch(loadFilteredWeeklyReportsSuccess(res.data, offset));
          dispatch(ApiStatusActions.apiStopLoader("weeklyReports"));
        }
        return undefined;
      })
      .catch((error) => {
        console.log(dispatch(ApiStatusActions.apiFailed(error)));
        dispatch(ApiStatusActions.apiStopLoader("weeklyReports"));
      });
  };
}

export function sortWeeklyReports(sortParams, offset, limit) {
  return (dispatch) => {
    dispatch(ApiStatusActions.apiStartLoader("weeklyReports"));
    getFilteredWeeklyReports(sortParams, offset, limit)
      .then((res) => {
        if (res.status === 200) {
          dispatch(loadSortedWeeklyReportsSuccess(res.data, offset));
          dispatch(ApiStatusActions.apiStopLoader("weeklyReports"));
        }
        return undefined;
      })
      .catch((error) => {
        console.log(dispatch(ApiStatusActions.apiFailed(error)));
        dispatch(ApiStatusActions.apiStopLoader("weeklyReports"));
      });
  };
}
