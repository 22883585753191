import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import PropTypes from "prop-types";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isAuth } = useSelector((state) => state);

  return (
    <Route
      {...rest}
      render={
        (props) => (isAuth ? <Component {...props} /> : <Redirect to='/' />)
        // eslint-disable-next-line react/jsx-curly-newline
      }
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired
};

export default PrivateRoute;
