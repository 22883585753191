import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";

import { PayPeriodOptionItem } from "../../../containers/PayrollHomeComponent";

const useStyles = makeStyles(() => ({
  menuPaper: {
    maxHeight: "312px",
    overflowY: "scroll"
  },
  payPeriodsDropdown: {
    fontSize: "0.8125rem"
  },
  payPeriodsSelected: {
    backgroundColor: "rgba(0, 0, 0, 0.08)"
  }
}));

const CustomPayPeriodPicker = ({
  payPeriodsOptions,
  payPeriodOptionsScroll,
  localPayPeriodIndex,
  setPeriodValue,
  setLocalPayPeriodIndex
}) => {
  const classes = useStyles();
  const { payPeriods } = useSelector((state) => state);

  const handlePayPeriodsOptionClick = (idx) => {
    setLocalPayPeriodIndex(idx);
    setPeriodValue({
      startDate: payPeriods[idx].startDate,
      endDate: payPeriods[idx].endDate,
      label: (payPeriodsOptions[idx] || {}).label,
      isPayPeriod: true
    });
  };
  return (
    <div onScroll={payPeriodOptionsScroll} className={classes.menuPaper}>
      {payPeriodsOptions.map((o, idx) => (
        <MenuItem
          value={idx}
          key={o.id}
          className={`${classes.payPeriodsDropdown} ${
            idx === localPayPeriodIndex ? classes.payPeriodsSelected : ""
          }`}
          onClick={() => handlePayPeriodsOptionClick(idx)}
        >
          <PayPeriodOptionItem option={o} />
        </MenuItem>
      ))}
    </div>
  );
};
CustomPayPeriodPicker.propTypes = {
  payPeriodsOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  payPeriodOptionsScroll: PropTypes.func,
  localPayPeriodIndex: PropTypes.number,
  setPeriodValue: PropTypes.func,
  setLocalPayPeriodIndex: PropTypes.func
};
export default CustomPayPeriodPicker;
