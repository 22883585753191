import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Button, Fab } from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";

import useInfiniteScroll from "../../custom-hooks/useInfiniteScroll";

const useStyles = makeStyles((theme) => ({
  drawerHeader: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "12px",
    top: 0,
    position: "sticky",
    zIndex: 30,
    background: "#fff"
  },
  header: {
    margin: theme.spacing(3),
    fontWeight: 300,
    fontSize: 24,
    marginBottom: 0
  },
  drawerActions: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "lightgrey",
    position: "sticky",
    bottom: 0,
    top: "calc(100% - 50px)",
    right: 0
  },
  fab: {
    margin: theme.spacing(3),
    width: "100%",
    maxWidth: "max-content",
    textTransform: "none"
  }
}));

const DrawerComponent = (props) => {
  // openDrawer is of type : "{["side"]: open}" where side can be left, top, right, bottom
  // closeDrawer to be used to close it by setting "{["side"]: false}"
  // darwerAttributes holds the value to to be displayed in drawer. It can be a component, text etc.
  // actions is a object which contains info about the action button like display, label etc.

  const {
    header,
    openDrawer,
    closeDrawer,
    drawerAttributes,
    actions,
    drawerPaper,
    handleScroll
  } = props;
  const classes = useStyles();
  const [scrollTop, setScrollTop] = useState(0);
  const [scrollHeight, setScrollHeight] = useState(0);

  // Custom hook for infinite scroll
  const [isFetching, toggle] = useInfiniteScroll(
    window.innerHeight,
    scrollTop,
    scrollHeight
  );

  // Send scroll event result to parent component to send API calls for pagination
  useEffect(() => {
    if (isFetching) {
      handleScroll(isFetching);
      toggle(isFetching);
      setScrollTop(0);
      setScrollHeight(0);
    }
    // eslint-disable-next-line
  }, [isFetching]);

  return (
    <>
      <Drawer
        key={"drawer"}
        className={classes.heightPaper}
        anchor={Object.keys(openDrawer)[0]}
        open={Object.values(openDrawer)[0]}
        onClose={closeDrawer}
        classes={{
          paper: drawerPaper
        }}
        onScroll={(event) => {
          event.persist();
          setScrollTop(event.target.scrollTop);
          setScrollHeight(event.target.scrollHeight);
        }}
      >
        <div className={classes.drawerHeader}>
          <label className={classes.header}>{header}</label>
          {actions ? (
            actions.map((i) =>
              i.position === "top" && i.display ? (
                <Fab
                  key={i.label}
                  className={classes.fab}
                  variant='extended'
                  color='primary'
                  onClick={i.action}
                >
                  {i.label}
                </Fab>
              ) : (
                <React.Fragment key='empty'></React.Fragment>
              )
            )
          ) : (
            <Button onClick={closeDrawer} style={{ marginTop: "24px" }}>
              <CloseIcon />
            </Button>
          )}
        </div>
        {drawerAttributes}
        {actions &&
          actions.map((i) =>
            i.position === "bottom" && i.display ? (
              <div key={i.label} className={classes.drawerActions}>
                <Fab
                  key={i.label}
                  className={classes.fab}
                  variant='extended'
                  color='primary'
                  onClick={i.action}
                  disabled={!i.disabled}
                >
                  {i.label}
                </Fab>
              </div>
            ) : (
              <React.Fragment key='empty'></React.Fragment>
            )
          )}
      </Drawer>
    </>
  );
};

DrawerComponent.propTypes = {
  openDrawer: PropTypes.shape({}).isRequired,
  header: PropTypes.string.isRequired,
  closeDrawer: PropTypes.func.isRequired,
  drawerAttributes: PropTypes.shape({}).isRequired,
  actions: PropTypes.arrayOf(PropTypes.shape()),
  drawerPaper: PropTypes.string,
  handleScroll: PropTypes.func.isRequired
};

DrawerComponent.defaultProps = {
  actions: undefined,
  drawerPaper: ""
};

export default DrawerComponent;
