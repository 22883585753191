import axios from "../../axios-jwt";

import { LOAD_USER_JOB_DETAILS } from "./actionTypes";
import { apiFailed, apiStartLoader, apiStopLoader } from "./ApiStatusActions";

async function getUserJobDetails(offset, limit) {
  const resp = axios.get(
    `employees/me/jobs?orderBy=code-asc&offset=${offset}&limit=${limit}`
  );
  return resp;
}

export function loadUserJobDetailsSuccess(userJobs, offset) {
  return { type: LOAD_USER_JOB_DETAILS, userJobs, offset };
}

export default function loadUserJobDetails(offset, limit) {
  return (dispatch) => {
    dispatch(apiStartLoader("userJobsList"));
    return getUserJobDetails(offset, limit)
      .then((res) => {
        if (res.status === 200) {
          dispatch(loadUserJobDetailsSuccess(res.data, offset));
        }
        dispatch(apiStopLoader("userJobsList"));
      })
      .catch((error) => {
        dispatch(apiFailed(error));
        dispatch(apiStopLoader("userJobsList"));
      });
  };
}
