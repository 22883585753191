import { CLEAR_SEARCH_RESULTS, SEARCH_USER_JOBS } from "../actions/actionTypes";

export default function UserJobsSearchReducer(state = [], action) {
  switch (action.type) {
    case SEARCH_USER_JOBS: {
      return action.userJobsSearchResults;
    }
    case CLEAR_SEARCH_RESULTS: {
      // Clear search results from state so that upon searching the next time, previous results won't show up
      if (
        action.actionType === "userJobsSearchResults" &&
        action.clear &&
        state.length
      )
        return [];
      return state;
    }
    default:
      return state;
  }
}
