import { makeStyles } from "@material-ui/core/styles";

// styles of AllEmployeesComponent
const useStyles = makeStyles((theme) => ({
  paper: {
    height: "100%",
    marginBottom: theme.spacing(2),
    overflowY: "hidden"
  },
  table: {
    maxHeight: "90vh"
  },
  count: { fontSize: "0.875rem", display: "inline", marginLeft: "26px" },
  tableWrapper: {
    top: "154px",
    position: "fixed",
    maxHeight: "80vh",
    overflowY: "auto",
    width: "78%"
  },
  toolbar: {
    position: "fixed",
    width: "78%",
    justifyContent: "space-between",
    zIndex: 40,
    backgroundColor: "#fff"
  },
  progressBar: {
    width: "99%",
    zIndex: "40",
    top: "62px"
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  buttonHidden: {
    visibility: "hidden"
  },
  headerButton: {
    marginRight: "16px"
  },
  UserActions: {
    display: "flex",
    float: "right"
  }
}));

export default useStyles;
