import axios from "../../axios-jwt";

import { GET_USER_DETAILS } from "./actionTypes";
import { apiFailed, apiStartLoader, apiStopLoader } from "./ApiStatusActions";

async function getUserDetails() {
  const resp = axios.get("employees/me");
  return resp;
}

export function loadUserDetailsSuccess(userDetails) {
  return { type: GET_USER_DETAILS, userDetails };
}

export default function loadUserDetails() {
  return (dispatch) => {
    dispatch(apiStartLoader("UserDetails"));
    return getUserDetails()
      .then((obj) => {
        if (obj.status === 200) {
          dispatch(loadUserDetailsSuccess(obj.data));
          dispatch(apiStopLoader("UserDetails"));
        }
      })
      .catch((err) => {
        dispatch(apiFailed(err));
        dispatch(apiStopLoader("UserDetails"));
      });
  };
}
