import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { Grid, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  container: {
    width: 360,
    margin: "0 auto 16px auto"
  },
  mainLabel: {
    textTransform: "capitalize"
  },
  error: {
    width: 360,
    margin: "0 auto",
    color: "red"
  }
}));

const MinMaxHourFields = ({
  label,
  minHours,
  setMinHours,
  maxHours,
  setMaxHours,
  error,
  setError
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const handleMinChange = (event) => {
    const value = event.target.value;
    if (/^\d+$/.test(value)) {
      setMinHours(value);
      if (
        value !== "" &&
        maxHours !== "" &&
        Number(value) >= Number(maxHours)
      ) {
        setError(t("error_msg_max>min"));
      } else {
        setError("");
      }
    } else {
      setMinHours("");
      // Setting max hours to 1 if its 0
      if (maxHours !== "" && Number(maxHours) < 1) {
        setMaxHours(1);
      }
      setError("");
    }
  };

  const handleMaxChange = (event) => {
    const value = event.target.value;
    if (/^\d+$/.test(value)) {
      setMaxHours(value);
      if (
        value !== "" &&
        minHours !== "" &&
        Number(value) <= Number(minHours)
      ) {
        setError(t("error_msg_max>min"));
      } else if (value !== "" && Number(value) < 1) {
        // Error out if maxHours is 0 or less than 1
        setError(t("error_msg_max_atleast_1"));
      } else {
        setError("");
      }
    } else {
      setMaxHours("");
      setError("");
    }
  };

  return (
    <div className={classes.container} key={"minMaxHourField"}>
      {label && <label className={classes.mainLabel}>{label}</label>}
      <Grid container spacing={2}>
        <Grid item xs={6} key={"min"}>
          <TextField
            placeholder={t("payroll.min")}
            variant='outlined'
            type='number'
            value={minHours}
            onChange={handleMinChange}
            inputProps={{ min: 0 }}
            onKeyDown={(e) => {
              if (e.key === "." || e.key === "-") {
                e.preventDefault();
              }
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={6} key={"max"}>
          <TextField
            placeholder={t("payroll.max")}
            variant='outlined'
            type='number'
            value={maxHours}
            onChange={handleMaxChange}
            inputProps={{ min: 1 }}
            onKeyDown={(e) => {
              if (e.key === "." || e.key === "-") {
                e.preventDefault();
              }
            }}
            fullWidth
          />
        </Grid>
        {error && <p className={classes.error}>{error}</p>}
      </Grid>
    </div>
  );
};

MinMaxHourFields.propTypes = {
  label: PropTypes.string,
  minHours: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  maxHours: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  setMinHours: PropTypes.func.isRequired,
  setMaxHours: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
  setError: PropTypes.func.isRequired
};

export default MinMaxHourFields;
